// NPM imports.
import React from 'react';
import { useSelector } from 'react-redux';

import { Loader } from '@domain/components';
import { companyNames, proccessType } from '@domain/constants';
import { InstructionPage } from '@domain/pages';
import questionnaire from '../questionnaire';
import { useLocation } from 'react-router-dom';

function Instructions() {

  const location = useLocation()
  const incident = useSelector(state => state.incident);
  const bodyshop = useSelector(state => state.bodyshop);
  const insurer = useSelector(state => state.insurer);

  if (!incident || !bodyshop || !insurer) {
    return <Loader />;
  }

  const isVIP = insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand)

  const currentPath = location.pathname;
  const { additionalIntakeComment, checkInAddress, customerFirstName, customerLastName, vehicleModelName, vehicleManufacurerName } = incident;

  const progress = questionnaire.find(page => page.path === currentPath).name

  const checklist = {
    driverDocumentToSign: 'Documenten ter ondertekening',
    driverJacket: isVIP ? 'VIP Schadeservice jas' : 'Schadeservice jas',
    driverSeatCover: isVIP ? 'VIP Stoelhoes' : 'Stoelhoes',
  }

  const infosContent = isVIP
    ? `Haal de ${!vehicleManufacurerName && !vehicleModelName ? 'auto' : vehicleManufacurerName + ' ' + vehicleModelName} bij de klant op bovenstaand adres.
  Vergeet niet samen de Check-in door te lopen, de VIP jas te dragen en de VIP stoelhoes om de berijderstoel te doen.`
    : `Haal de ${!vehicleManufacurerName && !vehicleModelName ? 'auto' : vehicleManufacurerName + ' ' + vehicleModelName} bij de klant op bovenstaand adres.
  Vergeet niet samen de Check-in door te lopen.`;

  const notesContent = additionalIntakeComment || '';

  return (
    <InstructionPage
      appointmentAdress={checkInAddress}
      checklist={checklist}
      customerFirstName={customerFirstName}
      customerLastName={customerLastName}
      currentPath={currentPath}
      infosContent={infosContent}
      initProgress={progress}
      notesContent={notesContent}
      questionnaire={questionnaire}
      save={true}
      vehicleManufacurerName={!!vehicleManufacurerName ? vehicleManufacurerName : ''}
      vehicleModelName={!!vehicleModelName ? vehicleModelName : ''}
    />
  )
}

export default Instructions;
