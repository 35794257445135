import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const useTracking = () => {

  const consent = useSelector((state) => state.consentCode)
  const PID = useSelector((state) => state.pid)
  const FID = useSelector((state) => state.fid)
  const UUID = useSelector((state) => state.uuid)
  const incident = useSelector((state) => state.incident)
  const insurer = useSelector((state) => state.insurer)

  const [identifiedHotjar, setIdentifiedHotjar] = useState(false)
  const [identifiedGA, setIdentifiedGA] = useState(false)

  const licensePlate = incident ? incident.licensePlate : ''
  const companyName = insurer ? insurer.brandName : ''

  useEffect(() => {
    if (consent && consent.ga === true && window.gtag && insurer && insurer.brandName) {
      window.gtag('set', 'user_properties', {
        company: `${insurer.brandName}`,
      });
    }
  }, [consent?.ga, insurer])


  useEffect(() => {
    if (UUID && !identifiedHotjar && consent && consent.hotjar === true && window.hj) {
      window.hj('identify', UUID, {
        PID: PID || '',
        FID: FID || '',
        company: companyName || '',
        licensePlate: licensePlate || '',
        uuid: UUID || '',
      })
      setIdentifiedHotjar(true)
    }
  }, [UUID, identifiedHotjar])

  useEffect(() => {
    if (UUID && !identifiedGA && consent && consent.ga === true && window.gtag) {
      window.gtag('set', { 'user_id': `${UUID}` })
      setIdentifiedGA(true)
    }
  }, [UUID, identifiedGA, consent?.ga])

}

export default useTracking