import React, { useState, useCallback, useEffect, useRef } from 'react';

import { styled } from '@mui/material/styles';

import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import Item from './Item';
import { Colors, Shadows } from '@domain/theming';
import { camelCaseToPascalCase } from '@domain/helpers';
import { useScaling } from '@domain/hooks';


const PREFIX = 'ListSelection';

const classes = {
  subheaderRoot: `${PREFIX}-subheaderRoot`
};

const StyledList = styled(List, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})(({ $scaling, $listTitle }) => ({
  flex: '1 0 0',
  width: '100%',
  // maxWidth: large ? 540 : 360,
  maxHeight: $scaling.scaleUpTo4K(400) + 'px',
  paddingBottom: $listTitle ? $scaling.scaleUpTo4K(7) : 0,
  marginBottom: $scaling.scaleUpTo4K(15),
  background: Colors.WHITE,
  overflow: 'auto',
  [`& .${classes.subheaderRoot}`]: {
    boxShadow: Shadows.SHADOW1,
  }
}));


function ListSelection({
  large = false,
  listItems = [{ title: '', subtitle: 'problem encountered, try again' }],
  listTitle,
  onClickItem,
  preselectedItemUUID,
  selectedListName,
}) {
  const scaling = useScaling();
  const selected = () => {
    if (preselectedItemUUID && listItems.length >= 1) {
      return listItems.findIndex(item => item.uuid === preselectedItemUUID)
    }
  }
  const [selectedItem, setSelectedItem] = useState(selected());
  const preselected = useRef(false);

  const onClick = useCallback(({ title, uuid }, i) => {
    setSelectedItem(value => {
      return i;
    });
    if (onClickItem) {
      onClickItem({ title, listTitle, uuid });
    }
  }, [listTitle, onClickItem]);

  useEffect(() => {
    if (selectedListName !== listTitle && selectedItem !== -1) {
      setSelectedItem(-1);
    }
  }, [listTitle, selectedItem, selectedListName]);

  useEffect(() => {
    if (preselected.current) {
      return;
    }
    if (preselectedItemUUID && listItems.length >= 1) {
      setSelectedItem(listItems.findIndex(item => item.uuid === preselectedItemUUID));
      preselected.current = true;
    }
  }, [preselected, listItems, preselectedItemUUID]);




  const renderListItems = useCallback((item, i) => {
    const { subtitle, title, uuid } = item;
    if (!title && !subtitle) {
      return;
    }

    return (
      <Item
        key={'listItem' + i}
        onClick={() => onClick({ title, uuid }, i)}
        selected={selectedItem === i}
        subtitle={!!subtitle ? subtitle : null}
        title={title}
        uuid={uuid}
      />
    );
  }, [selectedItem, onClick])

  return (
    <StyledList
      disablePadding={true}
      $listTitle={listTitle}
      $scaling={scaling}
      subheader={listTitle &&
        <>
          <ListSubheader className={classes.subheaderRoot} component="div">
            {camelCaseToPascalCase(listTitle.replace("bedrijven", "").replace("carRefinishers", "Dealers"))}
          </ListSubheader>
          <Divider />
        </>
      }
    >
      {listItems.map(renderListItems)}
    </StyledList >
  );
}

ListSelection.propTypes = {
  large: PropTypes.bool,
  listItems: PropTypes.array,
  listTitle: PropTypes.string,
  onClickItem: PropTypes.func,
  selectedListName: PropTypes.string,
}

export default ListSelection;
