import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



function ConfirmationDialog({
  open,
  onYesClick = () => { },
  onNoClick = () => { },
  title,
  content,
  yesLabel = 'Ja',
  noLabel = 'Nee',
  showYesButton = true,
  showNoButton = true,
}) {
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {showYesButton && (
            <Button onClick={onYesClick} color="primary">
              {yesLabel}
            </Button>
          )}
          {showNoButton && (
            <Button onClick={onNoClick} color="primary" autoFocus>
              {noLabel}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationDialog;
