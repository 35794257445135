import React, { useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';

import {
  CompanyLogo,
  Heading,
  Loader,
  Main,
  NavBar,
} from '@domain/components';
import { companyNames, proccessType } from '@domain/constants';
import { chooseLogoSize, chooseLogoToBeDisplayed } from '@domain/helpers';
import { useThemes, useScaling } from '@domain/hooks';

const StyledMain = styled(Main)`
  .authentication-page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 40px;
  }
  &.main--dimmed.main--more-dimmed {
    &:after {
      z-index: -1;
      background-color: rgba(0, 0, 0, 0.75);
    }
  }
`;

function NoConfigPage() {

  const insurer = useSelector(state => state.insurer);

  const isThemed = !!insurer && insurer.brand !== companyNames.VIP && insurer.objectType !== proccessType.DI;
  const isSG = !!insurer && insurer.objectType === proccessType.Schadegarant;
  const themes = useMemo(() => [isThemed ? 'theme-custom' : 'theme-dark'], [isThemed]);
  useThemes(themes);
  const themeContext = useTheme()

  const scaling = useScaling();

  if (!insurer) {
    return <Loader />;
  }

  const isVIP = !!insurer && insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand)

  const companyLogo = {
    ...chooseLogoToBeDisplayed(insurer),
    className: isVIP ? 'vip-logo' : '',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  const middleLogo = {
    src: insurer && insurer.logoURL ? insurer.logoURL : '',
    alt: insurer && insurer.brandName ? insurer.brandName : 'uw verzekeraar',
    height: 60,
    // width: 'auto',
  }

  const chooseMiddleLogo = isSG
    ? <CompanyLogo {...middleLogo} />
    : <CompanyLogo {...companyLogo} />

  return (
    <React.Fragment>
      <NavBar isVIP={isVIP}>
        <div></div>
        {chooseMiddleLogo}
        <div></div>
      </NavBar>
      <StyledMain className="main--more-dimmed" $scaling={scaling}>
        <div className="authentication-page-content">
          <Heading level="2" className="authentication-heading">
            Deze pagina is niet beschikbaar. Onze excuses voor het ongemak.
          </Heading>
        </div>
      </StyledMain>
    </React.Fragment>
  );
}

export default NoConfigPage;
