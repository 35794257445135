import { object, string } from 'yup';

const validationSchema = object({
  advisorComment: string()
    .label('Opmerkingen')
    .required()
});

const validationSchemaNotRequired = object({
  advisorComment: string()
    .label('Opmerkingen')
});

export { validationSchema, validationSchemaNotRequired };