import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import {
  Button,
  Container,
  FAQ,
  GridContainer,
  GridColumn,
  Heading,
  IconButton,
  IconLink,
  Legend,
  Main,
  NavBar,
  ProgressionIndicator,
  Text,
  UseIcon,
  BodyshopWidget,
  Loader,
  Paragraph
} from '@domain/components';
import {
  getPrevNextPagePath,
  goToNextPage,
  browserStorage,
  mapYupValidationSchemaErrors,
  compareArrays,
} from '@domain/helpers';
import { useNavigate, useLocation } from 'react-router-dom'
import { useFaq, useScaling, useReloadBodyshopList, useSaveIncidentFactory } from '@domain/hooks';
import { styled } from '@mui/material/styles';
import { useQuestionnaire } from '@domain/providers';
import validationSchema from './validationSchema';
const faqs = [
  {
    question: 'Waarom krijg ik een hersteller voorgesteld op basis van de naam?',
    answer: 'Wij proberen op basis van de naam van het herstelbedrijf deze alvast op te zoeken zodat u niet zelf alles hoeft in te vullen. Lukt dit niet dan kunt u zelf de contactgegevens van de hersteller toevoegen.',
  },
  {
    question: 'Waarom moet ik aangeven welk herstelbedrijf ik wil gebruiken?',
    answer: 'Voor het afhandelen van de schade hebben we de contactgegevens van het herstelbedrijf nodig. Op deze manier kunnen wij direct met het herstelbedrijf schakelen op het moment dat dat nodig is.',
  }
];

const StyledMain = styled(Main, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  .bodyshop-selectem {
    &__container {
      .grid__column {
        flex: 0 0 auto;
      }
    }
    &__title {
      flex: 1 1 50%;
      padding-bottom: 30px;
      h1 {
        margin-bottom: 0;
      }
    }
    &__main {
      flex: 1 1 50%;
      .loader {
        width: 100%;
      }
    }
    &__footer {
      margin-top: auto;
      flex-direction: row;
      justify-content: flex-end;
      &--button-container {
        width: 100%;
        .button {
          width: 100%;
        }
      }
    }
  }

  .screen-desktop & {
    .bodyshop-selectem {
      &__container {
        justify-content: center;
        padding: ${props => props.$scaling.scaleUpTo4K(137)}px ${props => props.$scaling.scaleUpTo4K(120)}px ${props => props.$scaling.scaleUpTo4K(40)}px;
        flex-direction: row;
        flex-wrap: wrap;
      }
      &__title {
        justify-content: center;
        padding: 0 ${props => props.$scaling.scaleUpTo4K(40)}px ${props => props.$scaling.scaleUpTo4K(60)}px 0;
        flex: 1 1 50%;
      }
      &__main {
        padding: 0;
        flex-direction: column;
        flex: 1 1 50%;
        align-items: flex-end;
        .loader {
          max-width: ${props => props.$scaling.scaleUpTo4K(640)}px
        }
      }
      &__footer {
        margin-top: 0;
        margin-bottom: auto;
        padding-top: ${props => props.$scaling.scaleUpTo4K(20)}px;
        &--button-container {
          width: auto;
          .button {
            width: ${props => props.$scaling.scaleUpTo4K(240)}px;
          }
        }
      }
    }
    .grid__column--main {
      max-width: none;
    }
  }

  .screen-ie & {
    .bodyshop-selectem {
      &__title {
        flex-basis: calc(50% - 40px); // IE doesn't take into account the padding which made the container too big
      }
      &__main {
        flex-basis: calc(50% - 40px); // IE doesn't take into account the padding which made the container too big
      }
    }
  }

  @media screen and (max-width: 1028px) {
    
    .screen-desktop & {
      .bodyshop-selectem {
        &__container {
          flex-direction: column;
          align-items: flex-start;
          padding: 30px 40px 40px;
          .grid__column {
            flex: 0 0 auto;
            padding-top: 0;
          }
        }
        &__title {
          text-align: center;
          legend {
            text-align: center;
          }
          h1 {
            max-width: none;
          }
        }
        &__main {
          margin-top: 0;
        }
        &__footer {
          max-width: 500px;
          margin: auto auto 0;
          padding-bottom: 0;
          &--button-container {
            margin: auto;
          }
        }
      }
    }
  }

  @media screen and (max-width: 580px) {
    .screen-desktop & {
      .bodyshop-selectem {
        &__container {
          .grid__column {
          }
        }
        &__title {
          text-align: left;
          legend {
            text-align: left;
          }
          h1 {
            margin-left: 0;
            flex-grow: 0;
          }
        }
        &__main {
        }
        &__footer {
          &--button-container {
            width: 100%;
            .button {
              width: 100%;
            }
          }
        }
      }
    }
  }
`;

const StyledBodyshopWidget = styled(BodyshopWidget)`
  margin: auto;
`;

const carRefinisherUUID = 'carRefinisherUUID';
const newCarRefinisher = 'newCarRefinisher';

function BodyshopSelectEM() {

  const navigate = useNavigate()
  const location = useLocation()
  const scaling = useScaling();
  const { questionnaire } = useQuestionnaire();
  const bsLists = useSelector(state => state.bodyshopLists);
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)
  const [
    faqVisible,
    handleOnHelpClick,
    clickOutSideFaq,
    faqListProps,
    faqSeen,
  ] = useFaq();

  const [createBodyshopMode, setCreateBodyshopMode] = useState(false);
  const [bodyshopUUID, setBodyshopUUID] = useState();
  const [newBodyshop, setNewBodyshop] = useState();
  const preselect = useRef(false);
  const [nextPageEnabled, setNextPageEnabled] = useState(false)
  const [errors, setErrors] = useState({})
  const reloadBodyshopList = useReloadBodyshopList()
  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
    setNextPageEnabled(
      createBodyshopMode ? !!newBodyshop && !Object.keys(errors).length : !!bodyshopUUID && bodyshopUUID !== ''
    );
  }, [bodyshopUUID, createBodyshopMode, errors, newBodyshop])


  const currentPath = location.pathname;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);

  useEffect(() => {
    if (!!questionnaire && questionnaire.length) {
      const progress = questionnaire.find(page => page.path === currentPath).name
      browserStorage.setProgress(progress);
    }
  }, [currentPath, questionnaire])

  useEffect(() => {
    if (!!incident.carRefinisherUUID) {
      preselect.current = true;
      setBodyshopUUID(incident.carRefinisherUUID);
    }
  }, [incident]);

  useEffect(() => {
    async function testInputs() {
      try {
        await validationSchema.validate(newBodyshop, { abortEarly: false });
        if (Object.keys(errors).length) {
          setErrors({});
        }
      } catch (errorResult) {
        const currentValidationErrors = mapYupValidationSchemaErrors(errorResult.inner)
        if (!compareArrays(Object.keys(currentValidationErrors), Object.keys(errors))) {
          setErrors(currentValidationErrors);
        }
      }
    }
    testInputs();
  }, [newBodyshop, errors]);

  const handleBodyshopWidgetChange = (
    createMode,
    selectedBodyshop,
    newBodyshop
  ) => {
    setCreateBodyshopMode(createMode);
    if (createMode) {
      if (!!newBodyshop && newBodyshop.companyName) {
        setNewBodyshop(newBodyshop);
      }
      if (!!newBodyshop && (!newBodyshop.companyName)) {
        setNewBodyshop(null);
      }
    } else {
      if (!!selectedBodyshop) {
        if (selectedBodyshop.uuid === incident.carRefinisherUUID) {
          preselect.current = true;
        }
        setBodyshopUUID(selectedBodyshop.uuid);
      } else {
        setBodyshopUUID(null)
        preselect.current = false
      }
    }
    if (bodyshopUUID && bodyshopUUID.length > 0 && preselect.current) {
      return bodyshopUUID;
    }
  }

  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath

  async function handleClick(event) {
    event.preventDefault();
    setButtonClicked(true)

    const savedIncident = !createBodyshopMode ? await saveIncident({ ...incident, [carRefinisherUUID]: bodyshopUUID }, isPageBeforeSuccess) : !Object.keys(errors).length ? await saveIncident({ ...incident, [newCarRefinisher]: newBodyshop }) : null;
    if (savedIncident) {
      if (createBodyshopMode) {
        reloadBodyshopList()
      }
      goToNextPage(navigate, questionnaire, savedIncident, currentPath, files);
    }
  }

  if (!insurer || !questionnaire || !incident) {
    return <Loader />;
  }

  const pagePath = getPrevNextPagePath(
    questionnaire,
    incident,
    currentPath,
    files,
  );
  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;

  const main = {
    faqVisible: faqVisible,
    dimmed: faqVisible,
    className: 'main__damage main__damage--bodyshop-selectem',
    callBack: clickOutSideFaq,
    $scaling: scaling,
  }

  const iconLink = (triggerKey) => ({
    type: 'arrow-left',
    theme: 'secondary',
    to: pagePath.prevPage,
    replace: true,
    triggerKey
  });

  const iconLinkForward = (triggerKey) => ({
    type: 'arrow-right',
    theme: 'secondary',
    to: nextPage,
    replace: true,
    triggerKey
  });

  const iconButton = {
    type: faqVisible ? 'arrow-right' : 'questionmark',
    className: faqSeen ? '' : 'unclicked-faq-button',
    theme: 'secondary',
    onClick: handleOnHelpClick,
    faq: true,
  };

  const button = {
    className: 'button--icon',
    justify: 'space-between',
    onClick: handleClick,
    shadow: true,
    theme: nextPageEnabled ? 'primary' : 'disabled',
  };

  return (
    <React.Fragment>
      <FAQ
        faqListProps={faqListProps}
        faqs={faqs}
        faqLink={insurer.helpcenterLink}
        faqVisible={faqVisible}
      />
      <StyledMain {...main}>
        <NavBar>
          <Container className="icon-link-container">
            <IconLink {...iconLink([37])} />
            {nextPageEnabled && nextPage && <IconLink {...iconLinkForward([39])} />}
          </Container>
          <ProgressionIndicator steps={3} current={2} />
          <Container className="faq-button-container">
            <IconButton {...iconButton} />
          </Container>
        </NavBar>
        <GridContainer main={true} className="bodyshop-selectem__container">
          <GridColumn className="bodyshop-selectem__title">
            <Legend>Contactgegevens en offerte van uw</Legend>
            <Heading level="1">Herstelbedrijf</Heading>
            <Paragraph>Vul de contactgegevens van uw herstelbedrijf in zodat wij eventueel contact op kunnen nemen.</Paragraph>
          </GridColumn>
          <GridColumn className="grid__column--main bodyshop-selectem__main">
            {!!bsLists ?
              <StyledBodyshopWidget
                callback={handleBodyshopWidgetChange}
                list={bsLists.carRefinishers}
                showSelected={!!bodyshopUUID && preselect.current}
                selectedUUID={bodyshopUUID}
                buttonClicked={buttonClicked}
                errors={errors} /> :
              <Loader />
            }
          </GridColumn>
          <GridColumn
            className=" grid__column--footer bodyshop-selectem__footer"
          >
            <Container className="bodyshop-selectem__footer--button-container">
              {' '}
              <Button {...button}>
                Volgende
                <UseIcon name="arrow-right" className="button__icon" />
              </Button>
              <Text className="of--enter">
                of <b>ENTER</b>
              </Text>
            </Container>
          </GridColumn>
        </GridContainer>
      </StyledMain>
    </React.Fragment>
  )
}

export default BodyshopSelectEM;
