// NPM imports.
import React, { useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';

import { GlobalStyles } from '@mui/material';

// Bounded context imports.
import Start from './Start';
import DamageImages from './DamageImages';
import OtherFilesUpload from './OtherFilesUpload';
import PoliceReport from './PoliceReport';
import GreenCard from './GreenCard';
import LicensePlateRegister from './LicensePlateRegister';
import Saf from './Saf';

// Routes for damage flow.
import routes from './routes';
import { useScaling } from '@domain/hooks';

import { useQuestionnaire } from '@domain/providers';
import { checkforValidPath } from '@domain/helpers';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import GuidedDamageImages from './GuidedDamageImages';

const UploadsStyle = `
  .main__damage {
    .h1 {
      margin-bottom: 42px;
    }
  }
  .screen-desktop & {
    .main__damage {
      .grid__column--main {
        padding-right: 0;
      }
      .legend {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(24)}px;
      }
      h1 {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(50)}px;
        // max-width: 500px;
      }
      h1 + .button {
        margin-top: ${props => props.$scaling.scaleUpTo4K(70)}px;
      }
    }
  }
  @media screen and (max-width: 1028px) {
    .screen-desktop & {
      .main__damage {
        .grid__column--main {
          padding-right: 0;
        }
        .damage-region {
          margin: auto;
        }
        h1 {
          margin: 0 auto 50px;
        }
      }
    }
    .screen-ie & {
      .main__damage {
        .grid-container__main {
          // display:block;
          legend {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
  @media screen and (max-width: 580px) {
    .screen-desktop & {
      .main__damage {
        h1 {
          margin-left: 0;
        }
      }
    }
    .screen-ie & {
      .main__damage {
        .grid-container__main {
          legend {
            text-align: left;
          }
        }
      }
    }
  }
`;

function Uploads() {
  const scaling = useScaling();
  const location = useLocation()

  const { questionnaire } = useQuestionnaire();

  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)

  const validPath = (!!questionnaire && !!questionnaire.find(page => page.path === location.pathname)) || (location.pathname.includes('/bekijken'))
  const newValidPath = checkforValidPath(incident, questionnaire, files, insurer).path
  const navigate = useNavigate()

  useEffect(() => {
    if (!!questionnaire && questionnaire.length && !validPath) {
      navigate(newValidPath)
    }
  }, [questionnaire, validPath])
  return (
    <>
      <Routes>
        <Route path={routes.start} element={<Start />} />
        <Route path={routes.otherFiles} element={<OtherFilesUpload />} />
        <Route path={routes.saf} element={<Saf />} />
        <Route path={routes.safRequired} element={<Saf />} />
        <Route path={routes.policeReport} element={<PoliceReport />} />
        <Route path={`${routes.damageImages}/*`} element={<DamageImages />} />
        <Route path={`${routes.extraDamageImages}/*`} element={<DamageImages />} />
        <Route path={`${routes.guidedDamageImages}/*`} element={files?.damageImages?.length > 0 && !files.damageImages.find(img => img.imageCategory) ? <DamageImages /> : <GuidedDamageImages />} />
        <Route path={routes.greenCard} element={<GreenCard />} />
        <Route path={routes.licensePlateRegister} element={<LicensePlateRegister />} />
        <Route path='*' element={<Navigate to={routes.start} />} />
      </Routes>
      <GlobalStyles scaling={scaling} styles={UploadsStyle} />
    </>
  );
}

export default Uploads;
