import React from 'react';

import { styled } from '@mui/material/styles';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useScaling } from '@domain/hooks';
import { isMobile } from 'react-device-detect';

const PREFIX = 'Item';

const classes = {
  root: `${PREFIX}-root`,
  selected: `${PREFIX}-selected`,
  primary: `${PREFIX}-primary`,
  secondary: `${PREFIX}-secondary`
};

const StyledListItem = styled(ListItem, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})((
  {
    theme, $scaling
  }
) => ({
  [`& .${classes.root}`]: {
    '&:hover': {
      backgroundColor: alpha(theme.colors.primary, 0.18)
    },
  },

  [`& .${classes.selected}`]: {
    '&&': {
      backgroundColor: alpha(theme.colors.primary, 0.28),
      '&:hover': {
        backgroundColor: alpha(theme.colors.primary, 0.28)
      }
    }
  },

  [`& .${classes.primary}`]: {
    fontSize: (!isMobile ? $scaling.textSize(16) : $scaling.textSizeMobile(16)) + 'px',
    lineHeight: (!isMobile ? $scaling.textSize(20) : $scaling.textSizeMobile(20)) + 'px',
  },

  [`& .${classes.secondary}`]: {
    fontSize: (!isMobile ? $scaling.textSize(16) : $scaling.textSizeMobile(14)) + 'px',
    lineHeight: (!isMobile ? $scaling.textSize(16) : $scaling.textSizeMobile(18)) + 'px',
  }
}));

function Item({
  onClick,
  selectable = true,
  selected,
  subtitle,
  title,
}) {
  const scaling = useScaling();



  return (
    <StyledListItem button={selectable} classes={{ root: classes.root, selected: classes.selected }} onClick={onClick} selected={selected} $scaling={scaling}>
      <ListItemText classes={{ primary: classes.primary, secondary: classes.secondary }} primary={title} secondary={subtitle} />
    </StyledListItem>
  );
}

Item.propTypes = {
  onClick: PropTypes.func,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Item;
