import React, { useContext, } from 'react';
import { useSelector } from 'react-redux';

import { useSaveIncidentFactory } from '@domain/hooks';
import { sanitisePhoneNumber, getPrevNextPagePath, browserStorage, generatePageContent, chooseLogoSize } from '@domain/helpers';
import { TextInputsPage } from '@domain/pages';
import validationSchema from './validation-schema';
import { Loader } from '@domain/components';
import { isMobile } from 'react-device-detect';
import { detectContentType } from '@domain/content';
import { useQuestionnaire } from '@domain/providers';
import { useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';

function Contact() {

  const { questionnaire } = useQuestionnaire();
  const inCreation = useSelector(state => state.inCreation);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector((state) => state.files)

  const tempSaved = browserStorage.getTempSaved()

  const incident = useSelector((state) => state.incident);
  const themeContext = useTheme();

  const fields = [
    {
      name: 'customerEmail',
      label: 'E-mailadres',
      disabled: !inCreation || tempSaved,
    },
    {
      name: 'customerMobileNumber',
      label: 'Telefoonnummer',
    },
  ];

  const location = useLocation()
  const currentPath = location.pathname;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);

  const controlValue = (name, value) => {
    if (name !== 'customerMobileNumber') {
      return value;
    }
    const newValue = sanitisePhoneNumber(value);
    return newValue;
  };

  if (!insurer || !questionnaire || !incident) {
    return <Loader />;
  }

  const pagePath = getPrevNextPagePath(
    questionnaire,
    incident,
    currentPath,
    files
  );
  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;

  const contentType = detectContentType(insurer)
  const pageName = page.name
  const content = generatePageContent(contentType, insurer, incident, inCreation, isMobile, pageName)

  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const useCustomLogo = content["use-custom-logo"]
  const heading = content["title"]
  const faqs = content["faqs"]

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  return (
    <TextInputsPage
      buttonText={!!inCreation ? 'Volgende' : 'Correct'}
      controlValue={controlValue}
      faqs={faqs}
      fields={fields}
      formID="contact"
      heading={heading}
      nextPage={nextPage}
      prevPage={pagePath.prevPage}
      currentPath={currentPath}
      progressIndicatorCurrent={1}
      progressIndicatorSteps={3}
      questionnaire={questionnaire}
      saveIncident={saveIncident}
      validationSchema={validationSchema}
      customHelpLink={customHelpLink}
      helpLinkText={helpLinkText}
      customMiddleLogo={useCustomLogo}
      middleLogo={middleLogo}
      files={files}
    />
  );
}

export default Contact;
