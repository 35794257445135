import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { styled } from '@mui/material/styles';

import { UseIcon, Tooltip } from '../..';
import { Colors } from '@domain/theming';
import { isMobile } from 'react-device-detect';
import { useScaling } from '@domain/hooks';
import Loader from '../../Loader';

const squareSize = 48;
const squareSizeSmall = 40;

const StyledLi = styled('li', {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  position: relative;
  z-index: 4;
  .theme-custom & {
    .image-checkmark {
      background: ${props => props.theme.colors.primary};
    }
  }
  &.image__viewer--dulled {
    filter: brightness(0.3);
  }
  &.image__viewer--noimage {
    .image-container {
      background-image: url('/img/svg/thumbnail-border.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-clip: content-box;
    }
  }
  &.image__viewer--is-dragging {
    z-index: 1;
  }
  .image-checkmark {
    background: ${Colors.BLACK};
    color: ${Colors.WHITE};
    border: 1px solid ${Colors.WHITE};
    border-radius: 50%;
    position: absolute;
    top: -10px;
    right: -10px;
    .screen-ie & {
      border: none;
    }
  }
`;

const StyledImg = styled('img', {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  height: ${squareSize}px;
  width: ${squareSize}px;
  border-radius: 5px;
  object-fit: cover;
  border: 1px solid ${Colors.BLACK};
  &.image__viewer__img--extra {
    filter: brightness(0);
  }
  &.image__viewer__img--highlighted:not(.extra__images__number) {
    z-index: 2;
  }
  .screen-desktop & {
    height: ${props => props.$scaling.scaleUpTo4K(squareSize)}px;
    width: ${props => props.$scaling.scaleUpTo4K(squareSize)}px;
  }
  @media screen and (max-width: 360px) {
    height: ${squareSizeSmall}px;
    width: ${squareSizeSmall}px;
  }
`;

const StyledImageContainer = styled('label', {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  position: relative;
  height: ${squareSize}px;
  width: ${squareSize}px;
  background: transparent;
  border-radius: 8px;
  display: block;
  cursor: pointer;
  &.image-container--loading {
    cursor: wait;
  }
  .extra__images__number {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${Colors.WHITE};
    font-size: ${props => !isMobile ? props.$scaling.textSize(12) : props.$scaling.textSizeMobile(16)}px;
    line-height: ${props => !isMobile ? props.$scaling.textSize(20) : props.$scaling.textSizeMobile(20)}px;
    width: 100%;
    height: 100%;
  }
  .imageviewer__loader {
    transform: scale(0.75) translate(-7px, -10px);
  }
  .image-loading-circle__outer--black {
    background-color: ${Colors.BLACK};
  
    .image-loading-circle__inner--white:before {
      background-color: ${Colors.WHITE};
    }
  }
  .screen-desktop & {
    width: ${props => props.$scaling.scaleUpTo4K(squareSize)}px;
    height: ${props => props.$scaling.scaleUpTo4K(squareSize)}px;
  }
  @media screen and (max-width: 360px) {
    height: ${squareSizeSmall}px;
    width: ${squareSizeSmall}px;
  }
`;

// TODO: Improve alt text
function ImageViewer({
  src,
  extraImages = 0,
  onClick,
  selected,
  clickDelete,
  clickView,
  dulled,
  loading,
  htmlFor,
  isDragging
}) {
  const scaling = useScaling();
  const imageViewerClassNames = classNames(
    'image__viewer',
    {
      'image__viewer--image': src,
      'image__viewer--noimage': !src || loading
    },
    { 'image__viewer--selected': selected },
    { 'image__viewer--dulled': dulled && !selected },
    {
      'image__viewer--is-dragging': isDragging
    }
  );

  const imageClassNames = classNames(
    'image__viewer__img',
    {
      'image__viewer__img--extra': extraImages > 0
    },
    {
      'image__viewer__img--highlighted': selected
    }
  );

  const imageContainerClassNames = classNames('image-container', {
    'image-container--loading': loading
  });

  const labelContents = () => {
    if (loading) {
      if (extraImages) {
        return <Loader size="small" color="white" />
      }

      return <Loader size="small" />
    }

    if (src) {
      return (
        <div className="">
          <StyledImg
            onClick={event => {
              event.stopPropagation();
              onClick(event);
            }}
            className={imageClassNames}
            src={src}
            alt="Gebruikers foto"
            $scaling={scaling}
          />
          {selected && !extraImages && (
            <Tooltip clickDelete={clickDelete} clickView={clickView} />
          )}
          {extraImages > 0 && (
            <span
              onClick={event => {
                event.stopPropagation();
                clickView(0);
              }}
              className="extra__images__number"
            >
              +{extraImages + 1}
            </span>
          )}
        </div>
      );
    }
  };

  return (
    <StyledLi className={imageViewerClassNames} $scaling={scaling}>
      <StyledImageContainer
        className={imageContainerClassNames}
        htmlFor={htmlFor}
        $scaling={scaling}
      >
        {labelContents()}
      </StyledImageContainer>
      {src && !extraImages && (
        <UseIcon
          name="checkmark"
          className="image-checkmark"
          width="19"
          height="19"
        />
      )}
    </StyledLi>
  );
}

ImageViewer.propTypes = {
  src: PropTypes.string,
  extraImages: PropTypes.number
};

export default ImageViewer;
