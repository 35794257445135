const leasingCompanies = [
  "AA Lease",
  "Toyota Louwman Financial Services B.V (voorheen Alcredis Lease)",
  "ALD Automotive",
  "Alphabet",
  "Arval",
  "Athlon",
  "Bourguignon Lease",
  "Business Lease",
  "Direct Lease Schadenet",
  "Friesland Lease",
  "Go private Lease",
  "Hitachi Capital Mobility",
  "J & T Autolease BV Schadenet",
  "Just Lease",
  "Leaseplan",
  "LeaseVisie",
  "MHC Mobility",
  "Multilease Tesla",
  "Noordlease",
  "Personal Car Lease",
  "Pro Lease",
  "Rebel lease",
  "Roteb Lease",
  "Special Lease",
  "Stellantis lease",
  "Strix Lease Service",
  "TB Lease",
  "Terberg Business Lease Group",
  "Van Mossel Autolease",
  "Vision Car Lease",
  "VWPFS Dutchlease",
  "WagenPlan",
  "Way 2 Drive",
  "Zuidlease",

]
export default leasingCompanies;













