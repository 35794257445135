import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CollapsibleHeader from './CollapsibleHeader';
import CollapsibleContent from './CollapsibleContent';

function Collapsible({ children, title, collapsed = true }) {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const classes = classNames('collapsible', {
    'collapsible--collapsed': isCollapsed
  });

  function handleOnCollapsibleHeaderClick(event) {
    setIsCollapsed(collapsed => !collapsed);
  }

  return (
    <div className={classes}>
      <CollapsibleHeader
        onClick={handleOnCollapsibleHeaderClick}
        collapsed={isCollapsed}
      >
        {title}
      </CollapsibleHeader>
      <CollapsibleContent collapsed={isCollapsed}>
        {React.Children.toArray(children)}
      </CollapsibleContent>
    </div>
  );
}

Collapsible.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  collapsed: PropTypes.bool,
  className: PropTypes.string
};

export default Collapsible;
