import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

import {
  Button,
  Checkbox,
  Container,
  GridColumn,
  GridContainer,
  Heading,
  Main,
  Text,
  UseIcon
} from '@domain/components';
import { browserStorage, goToNextPage } from '@domain/helpers';
import classNames from 'classnames';
import { companyNames, proccessType } from '@domain/constants';
import { useSaveIncident } from '@domain/hooks';
import { useNavigate } from 'react-router-dom'

const StyledMain = styled(Main, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
.h4 {
  margin-bottom: 10px;
}
.checkbox {
  margin-bottom: 16px;
}
.instructions__header {
  padding: 32px 32px 0;
}
.instructions__info,
.instructions__data {
  .grid__column {
    padding: 0 32px;
  }
}
.instructions__checklist {
  padding: 0 32px;
  .screen-desktop:not(.screen-tablet) & {
    width: 50%;
  }
}
.instructions__footer {
  padding: 0 32px 32px;
  margin-top: auto;
  flex: 0 0 auto;
  flex-direction: column;
  button {
    margin-left: auto;
  }
}
@media (max-width: 580px) {
  .screen-desktop:not(.screen-tablet) & {
    .instructions__info {
      flex-wrap: wrap;
      .grid__column {
        width: 100%;
      }
    }
    .instructions__data {
      flex-wrap: wrap;
      .grid__column {
        width: 50%;
      }
      .instructions__data--car-model {
        width: 100%
      }
    }
    .instructions__checklist {
      width: 100%;
    }
    .instructions__footer {
      button {
        width: 100%;
      }
    }
  }
}
`;



function InstructionPage({
  appointmentAdress,
  carModel,
  checklist,
  checkOut,
  customerFirstName,
  customerLastName,
  currentPath,
  infosContent,
  initProgress,
  notesContent,
  pageClassNames,
  questionnaire,
  save = false,
  vehicleManufacurerName,
  vehicleModelName,
}) {
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const saveIncident = useSaveIncident();
  const navigate = useNavigate()
  // The checklist should follow this format:
  // {
  //   attributeNameInServer: 'Attribute Display Name',
  //   etc...
  // }
  const checklistKeys = Object.keys(checklist);
  const checklistNames = checklistKeys.map((key) => checklist[key]);
  const checkboxStateInit = checklistKeys.map((key, i) => {
    return incident[key] ? true : false;
  });
  const [checkboxState, setCheckboxState] = useState(checkboxStateInit);

  const isThemed = !!insurer && insurer.brand !== companyNames.VIP && insurer.objectType !== proccessType.DI;

  const { city = '', houseNumber = '', houseNumberAddition = '', street = '', zipcode = '' } = appointmentAdress || {};
  const appointmentAdressFormatted = street + ' ' + houseNumber + houseNumberAddition + '\n' + zipcode + ' ' + city;

  const onClickCheckbox = (i) => {
    setCheckboxState(prevValue => {
      const newValue = [...prevValue]
      newValue[i] = !prevValue[i];
      return newValue;
    });
  }

  const onClickButton = async () => {
    if (save) {
      const values = {}
      checklistKeys.map((key, i) => {
        values[key] = checkboxState[i];
        return null;
      })
      await saveIncident({ ...incident, ...values });
    }
    goToNextPage(navigate, questionnaire, incident, currentPath);
  };

  if (!!initProgress) {
    browserStorage.setProgress(initProgress);
  }

  const classes = classNames('main__instructions', pageClassNames)

  const button = {
    onClick: onClickButton,
    className: 'button--icon button--next__page',
    theme: isThemed ? 'primary' : 'primary',
    justify: 'space-between'
  };

  return (
    <React.Fragment>
      <StyledMain className={classes}>
        <GridContainer className="instructions__header">
          <GridColumn>
            <Heading level="1">Instructies voor het {checkOut ? 'terugbrengen' : 'ophalen'} van de auto</Heading>
          </GridColumn>
        </GridContainer>

        <GridContainer direction="row" className="instructions__data">
          <GridColumn>
            <Heading level="4">Naam klant</Heading>
            <Text>{customerFirstName + ' ' + customerLastName}</Text>
          </GridColumn>
          <GridColumn>
            {!!appointmentAdress &&
              <><Heading level="4">Ophaallocatie</Heading>
                <Text>{!!appointmentAdress && appointmentAdressFormatted}</Text></>}
          </GridColumn>
          <GridColumn className="instructions__data--car-model">
            {!!vehicleManufacurerName && !!vehicleModelName &&
              <>
                <Heading level="4">Op te halen auto</Heading>
                <Text>{vehicleManufacurerName + ' ' + vehicleModelName}</Text>
              </>
            }
          </GridColumn>
          <GridColumn>
            <Container />
          </GridColumn>
        </GridContainer>

        <GridContainer direction="row" className="instructions__info">
          <GridColumn>
            <Heading level="4">Algemene informatie</Heading>
            <Text>{infosContent}</Text>
          </GridColumn>
          <GridColumn>
            {(notesContent || notesContent !== '') &&
              <React.Fragment>
                <Heading level="4">Notities</Heading>
                <Text>{notesContent}</Text>
              </React.Fragment>
            }
          </GridColumn>
        </GridContainer>

        <GridContainer className="instructions__checklist">
          <GridColumn>
            <Container>
              <Heading level="4">Checklist</Heading>
              <Container className="instructions__checklist--group">
                {checklistNames.map((item, i) => {
                  const name = item;
                  return (
                    <React.Fragment key={name}>
                      <Checkbox
                        name={name}
                        id={name}
                        onClick={() => onClickCheckbox(i)}
                        value={checkboxState[i]}
                      >{name}
                        <UseIcon name="checkmark" className="checkbox__icon" />
                      </Checkbox>
                    </React.Fragment>
                  );
                })}

              </Container>
            </Container>
          </GridColumn>
        </GridContainer>
        <GridContainer className="instructions__footer">
          <GridColumn>
            <Button {...button} >
              Volgende
              <UseIcon name="arrow-right" className="button__icon" />
            </Button>
          </GridColumn>
        </GridContainer>
      </StyledMain>
    </React.Fragment>
  );
}

InstructionPage.propTypes = {
  appointmentAdress: PropTypes.object.isRequired,
  checklist: PropTypes.object.isRequired,
  checkOut: PropTypes.bool,
  customerFirstName: PropTypes.string.isRequired,
  customerLastName: PropTypes.string.isRequired,
  currentPath: PropTypes.string.isRequired,
  infosContent: PropTypes.string.isRequired,
  initProgress: PropTypes.number,
  notesContent: PropTypes.string.isRequired,
  vehicleManufacturerName: PropTypes.string,
  vehicleModelName: PropTypes.string,
}

export default InstructionPage;
