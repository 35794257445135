import { datadogLogs } from '@datadog/browser-logs'

import { browserStorage } from '@domain/helpers';
import { FilesService } from '@domain/services';
import { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDispatchError, useSaveIncident, useSaveTempIncidentToCorePlatform } from '..';

function useSaveCompleteIncident() {

  const incident = useSelector((state) => state.incident);
  const files = useSelector((state) => state.files)
  const insurer = useSelector(state => state.insurer);

  const { dispatchNewError } = useDispatchError();
  const incidentSaving = useRef(false);
  const tempSaved = browserStorage.getTempSaved()
  const saveIncident = useSaveIncident()
  const saveTempIncidentToCorePlatform = useSaveTempIncidentToCorePlatform();
  const saveCompleteIncident = useCallback(
    async (newDetails, isPageBeforeSuccess = false) => {
      if (incidentSaving.current) {
        return;
      }
      incidentSaving.current = true;
      if (tempSaved || incident.status) {
        try {
          const incidentCommit = await saveIncident({ ...incident, ...newDetails }, isPageBeforeSuccess)
          incidentSaving.current = false;
          return incidentCommit;
        } catch ({ error }) {
          const errorLogMessage = "Error occurred inside useSaveCompleteIncident trying to post incident:"
          const errorInstance = new Error(errorLogMessage)
          datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
          dispatchNewError(error);
          incidentSaving.current = false;
          return {};
        }
      } else {
        try {
          const incidentNew = await saveTempIncidentToCorePlatform({ ...incident, ...newDetails })
          if (incidentNew.errorCode === 19) {
            return incident
          }
          const incidentCommit = await saveIncident({ ...incidentNew, ...newDetails }, isPageBeforeSuccess)
          await FilesService.postTempStoredFiles(files, incidentCommit, incidentNew.token, insurer)
          incidentSaving.current = false;
          return incidentCommit;
        } catch ({ error }) {
          const errorLogMessage = "Error occurred inside useSaveCompleteIncident trying to post incident:"
          const errorInstance = new Error(errorLogMessage)
          datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
          dispatchNewError(error);
          incidentSaving.current = false;
          return {};
        }
      }
    },
    [dispatchNewError, saveIncident, saveTempIncidentToCorePlatform, tempSaved, incident, files, insurer],
  );

  return saveCompleteIncident
}

export default useSaveCompleteIncident;
