import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

import ProgressionIndicatorItem from './ProgressionIndicatorItem';

const StyledContainer = styled('div', {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  display: flex;
  justify-content: center;
  align-items: center;
`;

function ProgressionIndicator({ steps, current, color }) {
  const indicators = [];
  // Loops are faster then .forEach .map etc. due to coercing
  // Reverse loops are the fastests if the order does not matter.
  for (let i = 0; i < steps; i += 1) {
    indicators.push(
      <ProgressionIndicatorItem
        color={color}
        active={current - 1 === i}
        key={`progression-indicator-item-${i}`}
      />
    );
  }

  return <StyledContainer className="progression-indicator">{indicators}</StyledContainer>;
}

ProgressionIndicator.propTypes = {
  steps: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired
};

export default ProgressionIndicator;
