import React, { useMemo, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useThemes, useScaling } from '@domain/hooks';
import {
  Header,
  Main,
  Text,
  CheckList,
  CheckListItem,
  Button,
  CompanyLogo,
  BackgroundVideo,
  Paragraph,
  Heading,
  Container,
  Section,
} from '@domain/components';
import { companyNames } from '@domain/constants';
import { isMobile } from 'react-device-detect';
import { Colors } from '@domain/theming';
import routes from '../routes';
import baseRoutes from '../../routes';
import { chooseLogoSize, chooseLogoToBeDisplayed } from '@domain/helpers';
import { useNavigate } from 'react-router-dom'
const StyledMain = styled(Main, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  .welcome__heading--container {
    .h4 {
      color: ${Colors.WHITE};
      margin-bottom: 10px;
    }
    .h1 {
      + h4 {
        // the second heading for Rhion
        margin-top: 50px;
      }
    }
  }
  .welcome__content {
    padding: 0 32px 32px;
    display: flex;
    flex-direction: column;
  }
  .welcome__checklist {
    margin-bottom: 60px;
  }
  .welcome__heading--container {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 40px;
    padding-right: 25px;
    .h1__second__title {
      max-width: 600px;
      padding: 30px 0 20px;
    }
  }
  .welcome__checklist--and--button--container {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: flex-end;
    min-height: none;
  }
  
  .screen-desktop & {
    .welcome__content {
      padding: ${props => props.$scaling.scaleUpTo4K(20)}px ${props => props.$scaling.scaleUpTo4K(40)}px ${props => props.$scaling.scaleUpTo4K(40)}px;
    }
    .welcome__heading--container {
      justify-content: flex-start;
      padding-top: ${props => props.$scaling.scaleUpTo4K(30)}px;
      padding-right: 0;
      .h4 {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(10)}px;
      }
      .h1 {
        + .h4 {
          margin-top: ${props => props.$scaling.scaleUpTo4K(50)}px;
        }
      }
    }
    .welcome__checklist--and--button--container {
      flex-direction: row;
      justify-content: flex-start;
      padding-top: 10px;
      padding-right: 0;
    }
    .welcome__checklist,
    .welcome__button {
      margin: auto 0 0 0;
    }
    .welcome__checklist {
      width: 100%; // for IE
      max-width: ${props => props.$scaling.scaleUpTo4K(450)}px;
      padding-right: ${props => props.$scaling.scaleUpTo4K(50)}px;
    }
    .welcome__button {
      height: ${props => props.$scaling.scaleUpTo4K(64)}px;
      width: ${props => props.$scaling.scaleUpTo4K(240)}px;
      margin: auto 0 0 auto;
    }
    .welcome__text {
      display: inline-block;
      max-width: ${props => props.$scaling.scaleUpTo4K(440)}px;
    }
  }

  @media screen and (max-width: 1028px) {
    .screen-desktop & {
      .welcome__checklist--and--button--container {
        .welcome__checklist {
          padding: 50px 50px 0 0;
        }
      }
    }
  }

  @media screen and (max-width: 580px) {
    .screen-desktop & {
      .welcome__heading--container {
        flex: 0 0 auto;
      }
      .welcome__checklist--and--button--container {
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-end;
        flex: 1 0 auto;
        .welcome__checklist {
          padding: 50px 0 50px 0;
          margin: 0 auto;
        }
        button {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
`;

function Welcome({ branding, search }) {

  const isVIP = branding.brand === companyNames.VIP || !branding.brand;
  const isRhionCasco = branding.brand === companyNames.Rhion;

  const isThemed = branding.brand !== companyNames.VIP;
  const themes = useMemo(() => isThemed ? ['theme-custom'] : ['home-layout'], [isThemed]);
  useThemes(themes);

  const navigate = useNavigate()
  const themeContext = useTheme();

  const scaling = useScaling();

  async function handleOnClick() {
    navigate(baseRoutes.preview + routes.timeline + search);
  }


  const fakeInsurer = {
    brand: branding.brand,
    brandLogoURL: branding.logoURL,
    brandName: branding.brandName,
  }

  const companyLogo = {
    align: isMobile ? 'center' : 'left',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
    ...chooseLogoToBeDisplayed(fakeInsurer),
    alt: branding.brandName,
  };

  const button = {
    theme: 'primary',
    onClick: handleOnClick,
    className: 'button--next__page welcome__button',
    justify: 'center',
    boxShadow: 3,
  };

  const heading = isVIP ? 'VIP schadeservice' : null
  const secondHeading = 'In vier stappen het beste herstelbedrijf voor uw schade'

  const firstItemChecklist = isVIP ? `Kosteloos aangeboden door ${branding.brandName}` : 'Geen verhoogd eigen risico'
  const secondItemChecklist = isVIP ? 'Altijd inzicht in de voortgang van het proces' : 'Gratis haal- en brengservice of vervangend vervoer'
  const thirdItemChecklist = isVIP ? `Gratis haal & breng service, vervangend vervoer en geen verhoogd eigen risico` : 'Altijd inzicht in de voortgang van het herstelproces'

  const text = () => {
    if (isRhionCasco) {
      return (<Text className="welcome__text">
        U heeft gekozen voor een aangesloten hersteller uit het rhion netwerk. Wij zorgen ervoor dat u zo snel mogelijk weer met de auto de weg op kunt.
        Om u zo goed mogelijk van dienst te kunnen zijn, ontvangen wij graag wat informatie.
      </Text>)
    } else {
      return (<Paragraph>
        <Text className="welcome__text">
          Wij zorgen ervoor dat u weer zo snel en makkelijk mogelijk met de auto de weg op kunt.
        </Text>
      </Paragraph>)
    }
  }

  const checkList = () => {
    return (
      <CheckList className="welcome__checklist">
        <CheckListItem>
          {firstItemChecklist}
        </CheckListItem>
        <CheckListItem>
          {secondItemChecklist}
        </CheckListItem>
        <CheckListItem>
          {thirdItemChecklist}
        </CheckListItem>
      </CheckList>)
  }

  return (
    <div className="screen-desktop">
      <StyledMain $scaling={scaling}>
        <Header><CompanyLogo {...companyLogo} /></Header>
        <Section className="welcome__content">
          <Container className="welcome__heading--container">
            {heading && <Heading level="4">Welkom bij de</Heading>}
            {heading && <Heading level="1" className="h1__title">{heading}</Heading>}
            {!isVIP && <Heading level="1" className="h1__second__title">{secondHeading}</Heading>}
            {text()}
          </Container>
          <Container className="welcome__checklist--and--button--container">
            {checkList()}
            <Button {...button}>Start</Button>
          </Container>
        </Section>
      </StyledMain>
      <BackgroundVideo
        autoPlay={true}
        img={!isVIP || isThemed}
        imgFileName="BSSP03.jpg"
        loop={true}
        type="video/mp4"
        videoFileName="DEF1.mp4"
      />
    </div>
  );
}

export default Welcome;
