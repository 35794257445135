import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import validationSchema from './validation-schema';

import { companyNames, proccessType } from '@domain/constants';
import { useSaveIncidentFactory } from '@domain/hooks';
import { TextInputsPage } from '@domain/pages';
import { useQuestionnaire } from '@domain/providers';
import { getPrevNextPagePath, browserStorage, generatePageContent, chooseLogoSize } from '@domain/helpers';
import { useLocation } from 'react-router-dom';
import { Loader } from '@domain/components';
import { detectContentType } from '@domain/content';
import { useTheme } from '@mui/material';

const fieldsPick = [
  {
    name: 'driverBirthday',
    mandatory: 'true',
  },
];


function DriverBirthday() {

  const { questionnaire } = useQuestionnaire();
  const spCase = useSelector(state => state.spCase);
  const inCreation = useSelector(state => state.inCreation);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)
  const isVIP = (insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand));
  const isSchadegarant = (insurer.objectType === proccessType.Schadegarant)
  const incident = useSelector((state) => state.incident);
  const themeContext = useTheme();

  const [isSaving, setIsSaving] = useState(false);
  const dateTimeFormat = 'YYYY-MM-DDT12:mm:ss.sssZ';
  const dateFormat = 'YYYY-MM-DD';

  const tempSaved = browserStorage.getTempSaved()

  const location = useLocation()
  const currentPath = location.pathname;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);


  const fields = useMemo(() => [
    {
      name: 'driverBirthDayPortal',
      label: 'Geboortedatum',
      type: 'birthdate',
      displayLabel: false,
    },
  ], [inCreation, isVIP, spCase, tempSaved, isSchadegarant]);

  const convertDateToDateTime = useCallback(function convert({
    driverBirthDayPortal,
  }) {
    const driverBirthday = moment(driverBirthDayPortal).format(dateTimeFormat);
    return { driverBirthday };
  },
    []);

  const convertDateTimeToDate = useCallback(function convert({
    driverBirthday,
  }) {
    if (!driverBirthday) {
      return { driverBirthDayPortal: '' };
    }

    const driverBirthDayPortal = moment(driverBirthday).format(dateFormat);
    return { driverBirthDayPortal };
  },
    []);

  const checkDriverBirthday = useCallback((incident, postedIncident) => {
    const driverBirthday = moment(incident.driverBirthday).format(dateFormat);
    const postedDriverBirthday = moment(postedIncident.driverBirthday).format(
      dateFormat,
    );

    return driverBirthday === postedDriverBirthday;
  }, []);

  const customCheck = useMemo(
    () => ({
      driverBirthday: checkDriverBirthday,
    }),
    [checkDriverBirthday],
  );

  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath

  async function onValidated(values) {
    if (isSaving) {
      return;
    }
    setIsSaving(true);


    const newIncident = await saveIncident({ ...values }, isPageBeforeSuccess);
    if (!newIncident) {
      return;
    }
    setIsSaving(false);

    return true
  }

  if (!insurer || !questionnaire) {
    return <Loader />;
  }

  const pagePath = getPrevNextPagePath(
    questionnaire,
    incident,
    currentPath,
    files
  );

  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, inCreation, false, pageName, null, spCase)

  const heading = content["title"]
  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const useCustomLogo = content["use-custom-logo"]
  const faqs = content["faqs"]

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }
  return (
    <TextInputsPage
      buttonText={!!inCreation ? 'Volgende' : 'Correct'}
      convertPortalValuesToServerValues={convertDateToDateTime}
      convertServerValuesToPortalValues={convertDateTimeToDate}
      currentPath={currentPath}
      customCheck={customCheck}
      customOnValidated={onValidated}
      faqs={faqs}
      fields={fields}
      fieldsPick={fieldsPick}
      formID="driverBirthday"
      heading={heading}
      nextPage={nextPage}
      prevPage={pagePath.prevPage}
      progressIndicatorCurrent={1}
      progressIndicatorSteps={3}
      questionnaire={questionnaire}
      saveIncident={saveIncident}
      validationSchema={validationSchema}
      isVIP={isVIP}
      customHelpLink={customHelpLink}
      helpLinkText={helpLinkText}
      customMiddleLogo={useCustomLogo}
      middleLogo={middleLogo}
      files={files}
    />
  );
}

export default DriverBirthday;
