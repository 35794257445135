import React from 'react';
import RadioPage from '@domain/pages/RadioPage';

const createdByDealer = 'createdByDealerINP';
function CreatedByDealer() {

  const fields = [{
    id: 1,
    value: 'yes',
    triggerKeys: [106, 74, 49],
    label: 'Ja'
  }, {
    id: 2,
    value: 'no',
    triggerKeys: [110, 78, 50],
    label: 'Nee'
  }
  ]

  return <RadioPage fieldName={createdByDealer} styleLabel={'driveable-damage'} fields={fields} />
}

export default CreatedByDealer;