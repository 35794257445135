import { datadogLogs } from '@datadog/browser-logs'

import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BodyshopIncidentService, IncidentNewService } from '@domain/services';
import { INCIDENT_UPDATED, UPDATE_EXCLUDED_PAGES } from '@domain/action-types';
import { useDispatchError } from '..';
import { proccessType } from '@domain/constants'
import { browserStorage } from '@domain/helpers';

function useSaveTempIncidentToCorePlatform(location) {
  // This function is for saving, for the first time, an incident to the platform.
  const dispatch = useDispatch();
  const { dispatchNewError } = useDispatchError();
  const pid = useSelector(state => state.pid);
  const fid = useSelector(state => state.fid)
  const rid = useSelector(state => state.rid)
  const uuid = useSelector(state => state.uuid);
  const incident = useSelector((state) => state.incident);
  const pagesExcluded = useSelector(state => state.pagesExcluded);
  const insurer = useSelector(state => state.insurer) || { brandName: 'emptyInsurer', HelpcenterLink: 'emptyHelpcenterLink' };

  const savingIncident = useRef(false);

  const saveBodyshopIncident = useCallback(
    async function (newDetails) {
      if (savingIncident.current) {
        return;
      }

      savingIncident.current = true;

      const progressStr = browserStorage.getProgress()

      const completeDetails = pagesExcluded
        ? { ...newDetails, progressStr, pagesExcluded }
        : { ...newDetails, progressStr };

      const IncidentService = insurer && insurer.objectType === proccessType.DI
        ? BodyshopIncidentService.save(pid, uuid, { ...incident, ...completeDetails }, fid)
        : IncidentNewService.save({ ...incident, ...completeDetails }, pid, fid, rid)

      try {
        const response = await IncidentService
        if (response.errorCode === 19) {
          dispatchNewError(response.errorCode);
          return response
        }
        // Adds a flag to the incident: tempSaved
        const incident = { ...response, tempSaved: true };
        const storageList = Object.keys(localStorage);
        browserStorage.clearLocalStorage([...storageList], ['consentCode', 'bannerOpen', 'radius', 'carMake', 'progressStr', 'spCase']);
        browserStorage.setTempSaved(true)
        browserStorage.setAuthenticationToken(incident.token)
        browserStorage.setUUID(incident.uuid)

        //Saves / creates the persistent incident
        dispatch({
          type: INCIDENT_UPDATED,
          incident: { ...incident },
        })

        savingIncident.current = false;
        if (incident.errorCode === 23) {
          const pages = pagesExcluded ? pagesExcluded.split(' ') : []
          const updatedPages = pages.includes('bodyshop_select') ?
            pages.filter(page => page !== 'bodyshop_select') : pages

          const updatedPagesExcluded = updatedPages.join(' ')
          if (updatedPagesExcluded !== pagesExcluded) {
            browserStorage.setPagesExcluded(updatedPagesExcluded)
            dispatch({
              type: UPDATE_EXCLUDED_PAGES,
              pages: updatedPagesExcluded,
            });
          }
        }
        return incident;
      } catch ({ error }) {
        const usedService = insurer && insurer.objectType === proccessType.DI ? 'BodyshopIncidentService.save' : 'IncidentNewService.save'
        const errorLogMessage = `Error occurred trying to post new incident to core platform (${usedService}):`
        const errorInstance = new Error(errorLogMessage)
        datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
        dispatchNewError(error);
      }
      savingIncident.current = false;
    },
    [incident, pagesExcluded, dispatch, dispatchNewError, pid, fid, rid, uuid, insurer],
  );

  return saveBodyshopIncident;
}

export default useSaveTempIncidentToCorePlatform;
