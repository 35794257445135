
let inMemoryStorage = {}

// test if localStorage is suported
function isLocalStorageSupported() {
  try {
    const key = "test-key";
    localStorage.setItem(key, 'test-value');
    localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
}
//clear LocalStorage
const clearLocalStorage = (keys, keysToKeep) => {
  if (isLocalStorageSupported()) {
    keys.forEach(key => {
      if (keysToKeep && keysToKeep.includes(key)) {
        return;
      }
      localStorage.removeItem(key);
    });
  } else {
    keys.forEach(key => {
      if (keysToKeep && keysToKeep.includes(key)) {
        return;
      }
      delete inMemoryStorage[key]
    });
  }
}

//clear SessionStorage
const clearSessionStorage = (keys, keysToKeep) => {
  if (isLocalStorageSupported()) {
    keys.forEach(key => {
      if (keysToKeep && keysToKeep.includes(key)) {
        return;
      }
      sessionStorage.removeItem(key);
    });
  } else {
    keys.forEach(key => {
      if (keysToKeep && keysToKeep.includes(key)) {
        return;
      }
      delete inMemoryStorage[key]
    });
  }
}

//save set of values to LocalStorage
const saveValuesInLocalStorage = values => {
  if (isLocalStorageSupported()) {
    for (let key in values) {
      let value = values[key];
      // Prevents storing NaN
      if (['errorCode', 'progress'].includes(key)) {
        value = Number.isInteger(+value) ? value : 0;
      }
      localStorage.setItem(key, value);
    }
  } else {
    for (let key in values) {
      let value = values[key];
      // Prevents storing NaN
      if (['errorCode', 'progress'].includes(key)) {
        value = Number.isInteger(+value) ? value : 0;
      }
      inMemoryStorage[key] = value
    }
  }
};

//get set of values from LocalStorage
const pickValuesFromLocalStorage = (...keys) => {
  if (isLocalStorageSupported()) {
    const obj = {};
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = localStorage.getItem(key);
      if (value) {
        obj[key] = value;
      }
    }
    return obj;
  } else {
    const obj = {};
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = inMemoryStorage[key];
      if (value) {
        obj[key] = value;
      }
    }
    return obj;
  }
}

// authtoken
function getAuthenticationToken() {
  if (isLocalStorageSupported()) {
    return sessionStorage.getItem('token');
  }
  if (inMemoryStorage.hasOwnProperty('token')) {
    return inMemoryStorage['token'];
  }
  return null;
}

function removeAuthenticationToken() {
  if (isLocalStorageSupported()) {
    sessionStorage.removeItem('token');
  } else {
    delete inMemoryStorage['token']
  }
}

function setAuthenticationToken(token) {
  if (isLocalStorageSupported()) {
    sessionStorage.setItem('token', token)
  } else {
    inMemoryStorage['token'] = token
  }
}

//banner
function getBannerOpen() {
  if (isLocalStorageSupported()) {
    return localStorage.getItem('bannerOpen');
  }
  if (inMemoryStorage.hasOwnProperty('bannerOpen')) {
    return inMemoryStorage['bannerOpen'];
  }
  return null;
}

function setBannerOpen(bannerOpen) {
  if (isLocalStorageSupported()) {
    localStorage.setItem('bannerOpen', (bannerOpen ? 'true' : 'false'));
  } else {
    inMemoryStorage['bannerOpen'] = bannerOpen ? 'true' : 'false'
  }
}


//carMake
function getCarMake() {
  if (isLocalStorageSupported()) {
    return localStorage.getItem('carMake')
  }
  if (inMemoryStorage.hasOwnProperty('carMake')) {
    return inMemoryStorage['carMake'];
  }
  return null;
}

function setCarMake(value) {
  if (isLocalStorageSupported()) {
    localStorage.setItem('carMake', value)
  } else {
    inMemoryStorage['carMake'] = value
  }
}

//companyToken
function getCompanyToken() {
  if (isLocalStorageSupported()) {
    return localStorage.getItem('companyToken')
  }
  if (inMemoryStorage.hasOwnProperty('companyToken')) {
    return inMemoryStorage['companyToken'];
  }
  return null;
}

function setCompanyToken(companyToken) {
  if (isLocalStorageSupported()) {
    return localStorage.setItem('companyToken', companyToken)
  } else {
    inMemoryStorage['companyToken'] = companyToken
  }
}

//ConsentCode
function getConsentCode() {
  if (isLocalStorageSupported()) {
    const data = localStorage.getItem('consentCode')
    return data ? JSON.parse(data) : null
  }
  if (inMemoryStorage.hasOwnProperty('consentCode')) {
    return inMemoryStorage['consentCode'];
  }
  return null;
}
function setConsentCode(consentCode) {
  if (isLocalStorageSupported()) {
    localStorage.setItem('consentCode', JSON.stringify(consentCode))
  } else {
    inMemoryStorage['consentCode'] = JSON.stringify(consentCode)
  }
}

//consentFilled
function getConsentFilled() {
  if (isLocalStorageSupported()) {
    return localStorage.getItem('consentFilled');
  }
  if (inMemoryStorage.hasOwnProperty('consentFilled')) {
    return inMemoryStorage['consentFilled'];
  }
  return null;
}

function setConsentFilled(consentFilled) {
  if (isLocalStorageSupported()) {
    localStorage.setItem('consentFilled', (consentFilled ? 'true' : 'false'))
  } else {
    inMemoryStorage['consentFilled'] = consentFilled ? 'true' : 'false'
  }
}

// faqSeen
function getFaqSeen() {
  if (isLocalStorageSupported()) {
    return JSON.parse(sessionStorage.getItem('faqSeen') || false)
  }
  if (inMemoryStorage.hasOwnProperty('faqSeen')) {
    return inMemoryStorage['faqSeen'];
  }
  return null;
}

function setFaqSeen(faqSeen) {
  if (isLocalStorageSupported()) {
    sessionStorage.setItem('faqSeen', faqSeen)
  } else {
    inMemoryStorage['faqSeen'] = faqSeen
  }
}
//flow
function getFlow() {
  if (isLocalStorageSupported()) {
    return sessionStorage.getItem('flow')
  }
  if (inMemoryStorage.hasOwnProperty('flow')) {
    return inMemoryStorage['flow'];
  }
  return null;
}

function setFlow(flow) {
  if (isLocalStorageSupported()) {
    sessionStorage.setItem('flow', flow)
  } else {
    inMemoryStorage['flow'] = flow
  }
}

//inCreation
function getInCreation() {
  if (isLocalStorageSupported()) {
    return sessionStorage.getItem('inCreation');
  }
  if (inMemoryStorage.hasOwnProperty('inCreation')) {
    return inMemoryStorage['inCreation'];
  }
  return null;
}

function setInCreation(inCreation) {
  if (isLocalStorageSupported()) {
    sessionStorage.setItem('inCreation', inCreation)
  } else {
    inMemoryStorage['inCreation'] = inCreation
  }
}


//inBodshopChange
function getInBodyshopChange() {
  if (isLocalStorageSupported()) {
    return sessionStorage.getItem('inBodyshopChange');
  }
  if (inMemoryStorage.hasOwnProperty('inBodyshopChange')) {
    return inMemoryStorage['inBodyshopChange'];
  }
  return null;
}

function setInBodyshopChange(inBodyshopChange = true) {
  if (isLocalStorageSupported()) {
    return sessionStorage.setItem('inBodyshopChange', inBodyshopChange);
  } else {
    inMemoryStorage['inBodyshopChange'] = inBodyshopChange
  }
}

function clearInBodyshopChange() {
  if (isLocalStorageSupported()) {
    return sessionStorage.removeItem('inBodyshopChange');
  } else {
    delete inMemoryStorage['inBodyshopChange']
  }
}

//pagesExcluded
function getPagesExcluded() {
  if (isLocalStorageSupported()) {
    return sessionStorage.getItem('pagesExcluded')
  }
  if (inMemoryStorage.hasOwnProperty('pagesExcluded')) {
    return inMemoryStorage['pagesExcluded'];
  }
  return null;
}

function setPagesExcluded(value) {
  if (isLocalStorageSupported()) {
    sessionStorage.setItem('pagesExcluded', value)
  } else {
    inMemoryStorage['pagesExcluded'] = value
  }
}
//PID FID RID
function getPID() {
  if (isLocalStorageSupported()) {
    return sessionStorage.getItem('pid');
  }
  if (inMemoryStorage.hasOwnProperty('pid')) {
    return inMemoryStorage['pid'];
  }
  return null;
}
function setPID(PID) {
  if (isLocalStorageSupported()) {
    sessionStorage.setItem('pid', PID)
  } else {
    inMemoryStorage['pid'] = PID
  }
}
function getFID() {
  if (isLocalStorageSupported()) {
    return sessionStorage.getItem('fid');
  }
  if (inMemoryStorage.hasOwnProperty('fid')) {
    return inMemoryStorage['fid'];
  }
  return null;
}

function setFID(FID) {
  if (isLocalStorageSupported()) {
    sessionStorage.setItem('fid', FID)
  } else {
    inMemoryStorage['fid'] = FID
  }
}
function getRID() {
  if (isLocalStorageSupported()) {
    return sessionStorage.getItem('rid');
  }
  if (inMemoryStorage.hasOwnProperty('rid')) {
    return inMemoryStorage['rid'];
  }
  return null;
}

function setRID(RID) {
  if (isLocalStorageSupported()) {
    sessionStorage.setItem('rid', RID)
  } else {
    inMemoryStorage['rid'] = RID
  }
}

//progress
function getProgress() {
  if (isLocalStorageSupported()) {
    return localStorage.getItem('progressStr')
  }
  if (inMemoryStorage.hasOwnProperty('progressStr')) {
    return inMemoryStorage['progressStr'];
  }
  return null;
}

function setProgress(progress) {
  if (isLocalStorageSupported()) {
    localStorage.setItem('progressStr', progress);
  } else {
    inMemoryStorage['progressStr'] = progress
  }
}

//radius
function getRadius() {
  if (isLocalStorageSupported()) {
    return localStorage.getItem('radius')
  }
  if (inMemoryStorage.hasOwnProperty('radius')) {
    return inMemoryStorage['radius'];
  }
  return null;
}

function setRadius(value) {
  if (isLocalStorageSupported()) {
    localStorage.setItem('radius', value)
  } else {
    inMemoryStorage['radius'] = value
  }
}

//searchZipcode
function getSearchZipcode() {
  if (isLocalStorageSupported()) {
    return localStorage.getItem('searchZipcode')
  }
  if (inMemoryStorage.hasOwnProperty('searchZipcode')) {
    return inMemoryStorage['searchZipcode'];
  }
  return null;
}

function setSearchZipcode(value) {
  if (isLocalStorageSupported()) {
    localStorage.setItem('searchZipcode', value)
  } else {
    inMemoryStorage['searchZipcode'] = value
  }
}

//tempSaved
function getTempSaved() {
  if (isLocalStorageSupported()) {
    return JSON.parse(sessionStorage.getItem('tempSaved'))
  }
  if (inMemoryStorage.hasOwnProperty('tempSaved')) {
    return inMemoryStorage['tempSaved'];
  }
  return null;
}

function setTempSaved(status) {
  if (isLocalStorageSupported()) {
    sessionStorage.setItem('tempSaved', status)
  } else {
    inMemoryStorage['tempSaved'] = status
  }
}

//UUID
function getUUID() {
  if (isLocalStorageSupported()) {
    return localStorage.getItem('uuid');
  }
  if (inMemoryStorage.hasOwnProperty('uuid')) {
    return inMemoryStorage['uuid'];
  }
  return null;
}

function setUUID(uuid) {
  if (isLocalStorageSupported()) {
    localStorage.setItem('uuid', uuid);
  } else {
    inMemoryStorage['uuid'] = uuid
  }
}


//PDF DOSSIER (ON TRACK&TRACE)
function getStoredPdfDossierResponse() {
  if (isLocalStorageSupported()) {
    return JSON.parse(sessionStorage.getItem('pdfDossierResponse'));
  }
  if (inMemoryStorage.hasOwnProperty('pdfDossierResponse')) {
    return inMemoryStorage['pdfDossierResponse'];
  }
  return null;
}
function setStoredPdfDossierResponse(response) {
  if (isLocalStorageSupported()) {
    return sessionStorage.setItem('pdfDossierResponse', JSON.stringify(response));
  } else {
    inMemoryStorage['pdfDossierResponse'] = JSON.stringify(response)
  }
}

export default {
  clearLocalStorage,
  clearSessionStorage,
  saveValuesInLocalStorage,
  pickValuesFromLocalStorage,
  getAuthenticationToken,
  setAuthenticationToken,
  removeAuthenticationToken,
  getBannerOpen,
  setBannerOpen,
  getCarMake,
  setCarMake,
  getCompanyToken,
  setCompanyToken,
  getConsentCode,
  setConsentCode,
  getConsentFilled,
  setConsentFilled,
  getFaqSeen,
  setFaqSeen,
  getFlow,
  setFlow,
  getInCreation,
  setInCreation,
  getInBodyshopChange,
  setInBodyshopChange,
  clearInBodyshopChange,
  getPID,
  setPID,
  getFID,
  setFID,
  getRID,
  setRID,
  getProgress,
  setProgress,
  getRadius,
  setRadius,
  getSearchZipcode,
  setSearchZipcode,
  getPagesExcluded,
  setPagesExcluded,
  getTempSaved,
  setTempSaved,
  getUUID,
  setUUID,
  getStoredPdfDossierResponse,
  setStoredPdfDossierResponse,
};
