import React, { useState, memo, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';
import { Colors } from '@domain/theming';
import { useInputBlur, useScaling } from '@domain/hooks';
import TextField from '@mui/material/TextField';
import Container from '../Container';

const PREFIX = 'index';

const classes = {
  textField: `${PREFIX}-textField`,
  inputRoot: `${PREFIX}-inputRoot`,
  inputInput: `${PREFIX}-inputInput`,
  inputError: `${PREFIX}-inputError`,
  inputLabelRoot: `${PREFIX}-inputLabelRoot`,
  inputLabelShrink: `${PREFIX}-inputLabelShrink`,
  inputUnderline: `${PREFIX}-inputUnderline`,
  inputLabelClasses: `${PREFIX}-inputLabelClasses`,
  inputDisabled: `${PREFIX}-inputDisabled`,
  inputLabelDisabled: `${PREFIX}-inputLabelDisabled`,
  focused: `${PREFIX}-focused`,
  inputLabelError: `${PREFIX}-inputLabelError`,
  dense: `${PREFIX}-dense`,
  menu: `${PREFIX}-menu`
};

const StyledContainer = styled(Container, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})(() => ({
  [`& .${classes.textField}`]: {
    //main container
    width: '100%',
    background: Colors.YELLOW_LIGHT,
    height: 'auto',
    margin: props =>
      props.$dynamicFontSize ? `${props.$scaling.scaleUpTo4K(52)}px 0 ${props.$scaling.scaleUpTo4K(30)}px` : '42px 0 20px',
    '&:after': {
      content: '"NL"',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '42px',
      height: '100%',
      color: Colors.WHITE,
      fontSize: props =>
        props.$dynamicFontSize ?
          (isMobile
            ? props.$scaling.textSizeMobile(14)
            : props.$scaling.textSize(14)) + 'px' :
          '14px',
      lineHeight: '20px',
      letterSpacing: '0.0625em',
      background: Colors.BLUE_DARK,
      backgroundImage: 'url(/img/eu-stars.svg)',
      backgroundPosition: 'top',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '60% 55%',
      textAlign: 'center',
      padding: '0 0 0 2px',
    },
  },

  [`& .${classes.inputRoot}`]: {
    //input container
    // fontSize: '16px',
    fontFamily: 'Gabriel Sans',
    borderColor: Colors.BLACK,
    color: Colors.BLACK,
    padding: '7px 46px 0',
  },

  [`& .${classes.inputInput}`]: {
    textAlign: 'center',
    fontSize: props =>
      props.$dynamicFontSize ?
        (isMobile
          ? props.$scaling.textSizeMobile(32)
          : props.$scaling.textSize(32)) + 'px' :
        '32px',
    fontWeight: 'bold',
    lineHeight: props =>
      props.$dynamicFontSize ?
        (isMobile
          ? props.$scaling.textSizeMobile(36)
          : props.$scaling.textSize(36)) + 'px' :
        '36px',
    height: props =>
      props.$dynamicFontSize ?
        (isMobile
          ? props.$scaling.textSizeMobile(48)
          : props.$scaling.textSize(48)) + 'px' :
        '48px',
    boxSizing: 'border-box',
  },

  [`& .${classes.inputError}`]: {
    color: Colors.RED,
  },

  [`& .${classes.inputLabelRoot}`]: {
    height: props =>
      props.$dynamicFontSize ?
        (isMobile
          ? props.$scaling.textSizeMobile(48)
          : props.$scaling.textSize(48)) + 'px' :
        '48px',
    maxWidth: '100%',
    top: 'calc(50% + 10px)',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // padding: '6px 0 7px',
    color: Colors.BLACK,
    fontFamily: 'inherit',
    fontSize: props =>
      props.$dynamicFontSize ?
        (isMobile
          ? props.$scaling.textSizeMobile(32)
          : props.$scaling.textSize(32)) + 'px' :
        '32px',
    fontWeight: 'bold',
    lineHeight: '36px',
    letterSpacing: '0.03125em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    transition: '200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
    '&$focused': {
      color: Colors.BLACK,
      fontWeight: 'bold',
    },
    '&$inputLabelError': {
      color: Colors.BLACK,
    },
    '&+$inputRoot': {
      marginTop: 0,
    },
  },

  [`& .${classes.inputLabelShrink}`]: {
    top: '-22px',
    left: 0,
    transform: 'translate(0, 0) scale(1)',
    color: Colors.BLACK,
    lineHeight: '14px',
    letterSpacing: '0.0625em',
    fontSize: props =>
      props.$dynamicFontSize ?
        (isMobile
          ? props.$scaling.textSizeMobile(16)
          : props.$scaling.textSize(16)) + 'px' :
        '16px',
    transition: '200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  },

  [`& .${classes.inputUnderline}`]: {
    '&$inputError': {
      '&:before': {
        borderColor: Colors.RED,
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: `1px solid ${Colors.RED}`,
      },
      '&:after': {
        borderBottom: `2px solid ${Colors.RED}`,
      },
    },
    '&:before': {
      borderColor: Colors.BLACK,
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: `1px solid ${Colors.BLACK}`,
    },
    '&:after': {
      borderBottom: `2px solid ${Colors.BLACK}`,
    },
  },

  [`& .${classes.inputLabelClasses}`]: {},
  [`& .${classes.inputDisabled}`]: {},
  [`& .${classes.inputLabelDisabled}`]: {},
  [`& .${classes.focused}`]: {},
  [`& .${classes.inputLabelError}`]: {},

  [`& .${classes.dense}`]: {
    marginTop: 19,
  },

  [`& .${classes.menu}`]: {
    width: 200,
  }
}));

const LicenseInput = ({
  dynamicFontSize,
  label,
  name,
  onChange,
  type,
  value,
  onBlur,
  error,
  disabled = false,
}) => {

  const scaling = useScaling();

  const [inputEl, setInputEl] = useState(null);

  useInputBlur(inputEl, onBlur);

  const setInputRef = useCallback((el) => setInputEl(el), []);

  return (
    <StyledContainer flexGrow="0" width="100%" $scaling={scaling} $dynamicFontSize={dynamicFontSize}>
      <TextField
        inputRef={setInputRef}
        required
        disabled={disabled}
        id={name}
        label={label}
        value={value}
        className={classes.textField}
        name={name}
        onChange={onChange}
        error={error}
        type={type}
        InputProps={{
          classes: {
            root: classes.inputRoot,
            underline: classes.inputUnderline,
            disabled: classes.inputDisabled,
            error: classes.inputError,
            input: classes.inputInput,
          },
          spellCheck: false,
        }}
        InputLabelProps={{
          htmlFor: name,
          classes: {
            root: classes.inputLabelRoot,
            shrink: classes.inputLabelShrink,
            focused: classes.focused,
            formControl: classes.inputLabelFormControl,
            disabled: classes.inputLabelDisabled,
            error: classes.inputLabelError,
          },
          shrink: true,
        }}
      />
    </StyledContainer>
  );
};

export default memo(LicenseInput);
