import React, { useMemo } from 'react';

import {
  Button,
  Container,
  FAQ,
  Form,
  GridColumn,
  GridContainer,
  Heading,
  IconButton,
  IconLink,
  Input,
  Legend,
  Main,
  NavBar,
  ProgressionIndicator,
  Text,
  UseIcon,
  ValidationFeedback,
} from '@domain/components';
import { useFaq, useScaling, useThemes } from '@domain/hooks';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'
import routes from '../routes';
import baseRoutes from '../../routes';
import { companyNames } from '@domain/constants';

const fields = [
  {
    name: 'customerFirstName',
    label: 'Voornaam',
  },
  {
    name: 'customerLastName',
    label: 'Achternaam',
  },
];


const StyledMain = styled(Main, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  &.main__personal--date {
    .validation-feedback {
      max-width: ${props => props.$scaling.scaleUpTo4K(338)}px;
      margin: 0;
    }
  }
`;

function Question({ branding, search }) {


  const navigate = useNavigate()
  const isDI = branding.di;
  const isThemed = branding.brand !== companyNames.VIP && !isDI;

  const themes = useMemo(() => isThemed ? ['theme-custom'] : [], [isThemed]);
  useThemes(themes);

  const scaling = useScaling();
  const [
    faqVisible,
    handleOnHelpClick,
    clickOutSideFaq,
    faqListProps,
    faqSeen,
  ] = useFaq();

  const prevPage = baseRoutes.preview + routes.timeline + search;

  const nextPage = baseRoutes.preview + routes.welcome + search;

  const handleOnClickButton = () => {
    navigate(nextPage);
  }

  const main = {
    faqVisible: faqVisible,
    dimmed: faqVisible,
    className: 'main__personal',
    callBack: clickOutSideFaq,
    $scaling: scaling,
  };
  const iconLink = {
    type: 'arrow-left',
    theme: 'secondary',
    to: prevPage,
    // onClick: () => navigate(prevPage),
  };

  const iconLinkForward = {
    type: 'arrow-right',
    theme: 'secondary',
    to: nextPage,
    // onClick: () => navigate(nextPage),
  };

  const iconButton = {
    type: faqVisible ? 'arrow-right' : 'questionmark',
    className: faqSeen ? '' : 'unclicked-faq-button',
    theme: 'secondary',
    onClick: handleOnHelpClick,
    faq: true,
  };

  const button = {
    theme: 'disabled',
    className: 'button--icon',
    onClick: handleOnClickButton,
    justify: 'space-between',
    shadow: true,
  };

  const faqs = useMemo(
    () => [
      {
        question: 'Waarom hebben jullie mijn adres nodig?',
        answer: isDI
          ? 'Onderdeel van deze digitale intake is dat we aan de hand van uw adres kunnen bepalen wat de dichtstbijzijnde vestiging is.'
          : 'Onderdeel van deze dienst is dat we aan de hand van uw adres kunnen bepalen wat het beste herstelbedrijf in uw regio is om uw schade te herstellen. Zij zullen uw auto ook bij u op komen halen.'
      },
      {
        question: 'Met wie worden mijn gegevens gedeeld?',
        answer: isDI
          ? 'Uw gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van uw auto.'
          : 'Uw gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van uw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.'
      },
      ...(!isDI ? [{
        question: 'Hoe komen jullie aan mijn gegevens?',
        answer:
          'U heeft de schade bij uw volmacht of verzekeraar gemeld. Dit zijn de gegevens die bekend zijn bij deze volmacht of verzekeraar.',
      }] : []),
    ], [isDI]);

  const values = {
    firstName: 'Jan',
    lastName: 'de Vries',
  }
  const errors = {
    firstName: false,
    lastName: 'Fout tekst',
  }
  const formInput = ({ disabled, name, label, type, displayLabel = true }) => ({
    type: type || 'text',
    name,
    id: name,
    valid: !errors[name],
    onChange: () => { },
    label,
    regexPass: !errors[name],
    disabled,
    value: values[name],
    defaultValue: values[name],
    displayLabel,
  });

  function renderInputAndError(field, i) {
    const { name } = field;

    return (
      <Container key={name} className="form-input">
        <Input
          {...formInput(field)}
        />
        <Container className="validation__container">
          {false && errors[name] && (
            <ValidationFeedback type="error">{errors[name]}</ValidationFeedback>
          )}
        </Container>
      </Container>
    );
  }

  return (
    <div className="screen-desktop">
      <FAQ
        faqListProps={faqListProps}
        faqs={faqs}
        faqLink="#"
        faqVisible={faqVisible}
      />
      <StyledMain {...main}>
        <NavBar>
          <Container className="icon-link-container">
            <IconLink {...iconLink} />
            <IconLink {...iconLinkForward} />
          </Container>
          <ProgressionIndicator
            steps={1}
            current={3}
          />
          <Container className="faq-button-container">
            <IconButton {...iconButton} size="large" />
          </Container>
        </NavBar>
        <GridContainer direction="column" main={true} className="two-third">
          <GridColumn className="grid__column--main" justify={'flex-end'}>
            <Legend>Persoonlijke gegevens</Legend>
            <Heading level="1">Controleer uw naam</Heading>
            <Form formID="name" className="form--text-inputs-page">{fields.map(renderInputAndError)}</Form>
          </GridColumn>
          <GridColumn span={6} className="grid__column--footer" justify={'flex-end'}>
            <Container className="validation__container">
            </Container>
            <Button {...button}>
              Correct
              <UseIcon name="arrow-right" className="button__icon" />
            </Button>
            <Text className="of--enter">
              of <b>ENTER</b>
            </Text>
          </GridColumn>
        </GridContainer>
      </StyledMain>
    </div>
  );
};

export default Question;
