import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

import { useFaq, useFilesEndpoint, useScaling } from '@domain/hooks';
import {
  Button,
  NavBar,
  ProgressionIndicator,
  IconButton,
  Main,
  ImageWheelContainer,
  ImageWheelImage,
  FAQ,
  DecorativeImage,
  Container,
  Section,
  IconLink,
  Loader,
  CompanyLogo
} from '@domain/components';
import { isMobile } from 'react-device-detect';
import { stripLastPathSegment } from '@domain/helpers';
import { Colors } from '@domain/theming';
import { useNavigate } from 'react-router-dom';
const StyledMain = styled(Main, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;
  max-width: 100%;
  .image-delete__navbar-container {
    width: 100%;
  }
  .image-delete__navbar {
    position: absolute;
    width: 100%;
  }
  .image__delete__section {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }
    .image__delete__section_img {
    width: 100%;
    align-items:center;
    max-width: 100%;
    padding-top: 120px;
    display: flex;
    flex-direction: column;
  }
  .image__delete__selected {
    width: 100%;
    flex: 1 0 73%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
  }
  .image-delete__img-container {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
  }
  .image__delete__wheel__container {
    overflow-x: auto;
    padding: 0 32px;
  }
  .button--image-delete {
    margin: 0 32px;
    width: auto;
  }

  .screen-desktop & {
    .grid-container__main {
      .grid__column--main {
        align-items: center;
        height: 100%;
      }
    }
    .image__delete__selected {
      filter: drop-shadow(0px ${props => props.$scaling.scaleUpTo4K(3)}px ${props => props.$scaling.scaleUpTo4K(2)}px rgba(0, 0, 0, 0.1))
        drop-shadow(-${props => props.$scaling.scaleUpTo4K(4)}px ${props => props.$scaling.scaleUpTo4K(6)}px ${props => props.$scaling.scaleUpTo4K(7)}px rgba(0, 0, 0, 0.3));
      object-fit: contain;
      height: 100%;
      width: ${props => props.$scaling.scaleUpTo4K(500)}px;
      margin: ${props => props.$scaling.scaleUpTo4K(33)}px 0;
    }
  }

  .theme-custom & {
    .image-delete__navbar {
      // background: rgba($white, 0.6);
      .icon-link-icon {
        color: ${Colors.WHITE};
        background: ${props => props.theme.colors.primary};
        border-color: ${props => props.theme.colors.primary};
      }
      .icon-button-icon {
        color: ${Colors.WHITE};
        background: ${props => props.theme.colors.primary};
        border-color: ${props => props.theme.colors.primary};
      }
    }
  }

   @media (max-width: 580px) {
    .grid-container__main {
      .grid__column--main {
        align-items: center;
        height: 100%;
      }
    }
    .image__delete__selected {
      filter: drop-shadow(0px ${props => props.$scaling.scaleUpTo4K(3)}px ${props => props.$scaling.scaleUpTo4K(2)}px rgba(0, 0, 0, 0.1))
        drop-shadow(-${props => props.$scaling.scaleUpTo4K(4)}px ${props => props.$scaling.scaleUpTo4K(6)}px ${props => props.$scaling.scaleUpTo4K(7)}px rgba(0, 0, 0, 0.3));
      object-fit: contain;
      height: auto;
      width: ${props => props.$scaling.scaleUpTo4K(300)}px;
      margin: ${props => props.$scaling.scaleUpTo4K(33)}px 0;
    }
    }
`;

function DeleteImagesPage({
  currentPath,
  faqs,
  prevPage,
  filesSetName,
  filesSet,
  insurer,
  customHelpLink,
  helpLinkText,
  customMiddleLogo = false,
  middleLogo,
}) {
  const scaling = useScaling();
  const navigate = useNavigate()
  const selectedImage = useSelector(state => state.selectedImage);
  const { deleteFile, setSelectedImage } = useFilesEndpoint(filesSetName);

  function onDeleteClick(i) {
    deleteFile(filesSet[i]);
  }

  useEffect(() => {
    if (selectedImage === null) {
      setSelectedImage(0);
    }
  }, [selectedImage, setSelectedImage]);

  useEffect(() => {
    return () => setSelectedImage(null);
  }, [setSelectedImage]);

  useEffect(() => {
    if (filesSet && selectedImage && !filesSet[selectedImage]) {
      setSelectedImage(selectedImage - 1);
    }

    if (filesSet && filesSet.length === 0) {
      navigate(stripLastPathSegment(currentPath) + '/upload');
    }
  }, [filesSet, currentPath, selectedImage, setSelectedImage]);

  const carouselImages = filesSet ? filesSet.map(image => image.content) : [];

  const [
    faqVisible,
    handleOnHelpClick,
    clickOutSideFaq,
    faqListProps,
    faqSeen,
  ] = useFaq();

  const main = {
    faqVisible: faqVisible,
    dimmed: faqVisible,
    className: 'main__uploads--image-delete',
    callBack: clickOutSideFaq,
    $scaling: scaling,
  };

  const iconLink = (triggerKey) => ({
    type: 'arrow-left',
    theme: isMobile ? 'primary' : 'secondary',
    to: prevPage,
    triggerKey
  });

  const iconButton = {
    type: faqVisible ? 'arrow-right' : 'questionmark',
    className: faqSeen ? '' : 'unclicked-faq-button',
    theme: isMobile ? 'primary' : 'secondary',
    onClick: handleOnHelpClick,
    faq: true,
  };

  const displayWheelImage = (image, i) => {
    const { cpID, content } = image;

    return (
      <ImageWheelImage
        src={content}
        key={cpID}
        onClick={() => setSelectedImage(i)}
        selected={i === selectedImage}
      />
    );
  };

  const button = {
    className: 'button--image-delete',
    justify: 'center',
    type: 'button',
    onClick: () => onDeleteClick(selectedImage),
    shadow: true,
  };

  if (!filesSet || !insurer) {
    return <Loader />;
  }

  const chooseMiddleComponent = customMiddleLogo
    ? <CompanyLogo {...middleLogo} />
    : <ProgressionIndicator steps={3} current={2} />

  if (isMobile) {
    return (
      <React.Fragment>
        <FAQ
          faqListProps={faqListProps}
          faqs={faqs}
          faqLink={insurer.helpcenterLink}
          customHelpLink={customHelpLink}
          helpLinkText={helpLinkText}
          faqVisible={faqVisible}
        />
        <StyledMain {...main}>
          <NavBar className="image-delete__navbar">
            <Container className="icon-link-container">
              <IconLink {...iconLink([37])} />
            </Container>
            {chooseMiddleComponent}
            <Container className="faq-button-container">
              <IconButton {...iconButton} size="large" />
            </Container>
          </NavBar>
          <Section className="image__delete__section">
            <Section className="image__delete__section_img">
              <DecorativeImage
                className="image__delete__selected"
                imageSrc={carouselImages[selectedImage]}
              />
            </Section>
            <Container className="image__delete__wheel__container">
              <ImageWheelContainer>
                {filesSet.map(displayWheelImage)}
              </ImageWheelContainer>
            </Container>
            <Button {...button}>Verwijder</Button>
          </Section>
        </StyledMain>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <FAQ
        faqListProps={faqListProps}
        faqs={faqs}
        faqLink={insurer.helpcenterLink}
        customHelpLink={customHelpLink}
        helpLinkText={helpLinkText}
        faqVisible={faqVisible}
      />
      <StyledMain {...main}>
        <Container className="image-delete__navbar-container">
          <NavBar>
            <Container className="icon-link-container">
              <IconLink {...iconLink([37])} />
            </Container>
            {chooseMiddleComponent}
            <Container className="faq-button-container">
              <IconButton {...iconButton} size="large" />
            </Container>
          </NavBar>
        </Container>
        <Container className="image-delete__img-container">
          <DecorativeImage
            className="image__delete__selected"
            imageSrc={carouselImages[selectedImage]}
          />
        </Container>

        <ImageWheelContainer>
          {filesSet.map(displayWheelImage)}
        </ImageWheelContainer>
        <Button {...button}>Verwijder</Button>
      </StyledMain>
    </React.Fragment>
  );
}

export default DeleteImagesPage;
