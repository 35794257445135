import React from 'react';
import RadioPage from '@domain/pages/RadioPage';

const recoverableDamage = 'recoverableDamage';
function RecoverableDamage() {
  const fields = [{
    id: 1,
    value: 'driver',
    triggerKeys: [],
    label: 'Ik'
  }, {
    id: 2,
    value: 'counter_party',
    triggerKeys: [],
    label: 'De tegenpartij'
  },
  {
    id: 3,
    value: 'unknown',
    triggerKeys: [],
    label: 'Onbekend'
  }
  ]

  return <RadioPage fieldName={recoverableDamage} styleLabel={'recoverableDamage'} fields={fields} className={'more-opts'} />
}

export default RecoverableDamage;