import React from 'react';
import RadioPage from '@domain/pages/RadioPage';

const glassDamageType = 'damageTypeAddition';
function GlassDamageSort() {

  const fields = [{
    id: 1,
    value: 'ster',
    triggerKeys: [49],
    label: 'Ster'
  }, {
    id: 2,
    value: 'scheur',
    triggerKeys: [50],
    label: 'Scheur'
  }
  ]

  return <RadioPage fieldName={glassDamageType} styleLabel={'glass-damage-sort'} fields={fields} />
}

export default GlassDamageSort;