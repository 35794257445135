import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NEW_FID_FOUND } from '@domain/action-types';
import { useSearchParams } from 'react-router-dom';
import browserStorage from '../../helpers/browserStorage';

// This hook should only ever be loaded once in the entire app

const useFIDChecker = (location) => {
  const [searchP, setSearchP] = useSearchParams()
  const dispatch = useDispatch();
  const pid = useSelector((state) => state.pid);
  const fid = useSelector((state) => state.fid);

  useEffect(() => {
    if (fid) {
      return;
    }

    if (pid) {
      const FID = !!searchP.get('FID')
        ? decodeURIComponent(searchP.get('FID'))
        : browserStorage.getFID() !== 'undefined'
          ? browserStorage.getFID()
          : null
      if (FID) {
        dispatch({ type: NEW_FID_FOUND, fid: FID });
        browserStorage.setFID(FID)
      }
      return
    }

  }, [dispatch, location.search, pid, fid]);
};

export default useFIDChecker;
