import { datadogLogs } from '@datadog/browser-logs'

import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IncidentService } from '@domain/services';
import { INCIDENT_FETCHED, LOGGED_OUT } from '@domain/action-types';
import { browserStorage } from '@domain/helpers';

const useIncidentLoader = () => {
  const dispatch = useDispatch();
  const incidentLoading = useRef(false);
  const incident = useSelector(state => state.incident);
  const token = useSelector(state => state.token);
  const uuid = useSelector(state => state.uuid);

  useEffect(() => {

    const fetchIncident = async () => {
      incidentLoading.current = true;
      try {
        const incident = await IncidentService.get(uuid, token);
        dispatch({ type: INCIDENT_FETCHED, incident });
        incidentLoading.current = false
      } catch ({ error }) {
        // Here we simply want to return the user to the login screen instead of displaying an error
        const errorLogMessage = "Error occurred inside useIncidentLoader trying to retrieve incident (IncidentService.get):"
        const errorInstance = new Error(errorLogMessage)
        datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
        browserStorage.removeAuthenticationToken()
        dispatch({ type: LOGGED_OUT });
        incidentLoading.current = false
      }
    };

    if (!incidentLoading.current && (!incident || (incident && !incident.status)) && token && uuid) {

      fetchIncident();
    }
  }, [incident, dispatch, incidentLoading, token, uuid]);
};

export default useIncidentLoader;
