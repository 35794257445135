import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import { Colors, Shadows } from '@domain/theming';
import { isIE } from 'react-device-detect';

const StyledLabel = styled('label')`
  align-items: center;
  border: solid 1px;
  background: ${Colors.WHITE};
  color: ${Colors.GREY_DARK};
  border-color: ${Colors.GREY_LIGHT};
  border-radius: 16px;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 50%;
  justify-content: space-between;
  font-weight: 700;
  cursor: pointer;
  ${isIE && "height: 64px; //align-items doesn't work in ie with just min-height"}
  min-height: 64px;
  padding: 0 24px;
  text-transform: uppercase;
  text-align: center;
  transition: 160ms cubic-bezier(0.4, 0, 0.2, 1);
  .checkbox__icon {
    color: ${Colors.WHITE};
    background: ${Colors.WHITE};
    border: 2px solid ${Colors.GREY_LIGHT};
    border-radius: 50%;
  }
  @media (hover: hover) {
    :hover {
      transform: scale(0.99);
      transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
  &:focus {
    transform: scale(0.99);
    transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  &:active {
    transform: scale(0.98);
    transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  &.checkbox__label--checked {
    background: ${Colors.BLACK};
    color: ${Colors.WHITE};
    border-color: ${Colors.BLACK};
    box-shadow: ${Shadows.SHADOW6};
    .checkbox__icon {
      color: ${Colors.BLACK};
      background: ${Colors.WHITE};
    }
    @media (hover: hover) {
      &:hover {
        box-shadow: ${Shadows.SHADOW3};
      }
    }
    &:focus {
      box-shadow: ${Shadows.SHADOW3};
    }
    &:active {
      box-shadow: ${Shadows.SHADOW1};
    }
  }
`;

function CheckboxLabel({ children, className, checked, disabled, ...props }) {
  const classes = classNames(
    'checkbox__label',
    {
      'checkbox__label--checked': checked,
      'checkbox__label--disabled': disabled,
    },
    className,
  );

  return (
    <StyledLabel className={classes} {...props}>
      {React.Children.toArray(children)}
    </StyledLabel>
  );
}

CheckboxLabel.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  htmlFor: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default CheckboxLabel;
