import React, { useMemo, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useThemes, useEnterKey, useScaling, useSaveIncidentFactory } from '@domain/hooks';
import { styled, useTheme } from '@mui/material/styles';
import {
  Header,
  Main,
  Text,
  CheckList,
  CheckListItem,
  Button,
  CompanyLogo,
  BackgroundVideo,
  Heading,
  Loader,
  Container,
  Section,
} from '@domain/components';
import { companyNames, proccessType } from '@domain/constants';
import { isMobile } from 'react-device-detect';
import {
  browserStorage,
  chooseLogoSize,
  generatePageContent,
  goToNextPage,
} from '@domain/helpers';
import { useNavigate } from 'react-router-dom'
import { Colors } from '@domain/theming';
import { detectContentType } from '@domain/content'
import { useQuestionnaire } from '@domain/providers';
import { useLocation } from 'react-router-dom';

const StyledMain = styled(Main, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  .welcome__heading--container {
    .h4 {
      color: ${Colors.WHITE};
      margin-bottom: 10px;
    }
    .h1 {
      + h4 {
        // the second heading for Rhion
        margin-top: 50px;
      }
    }
  }
  .welcome__content {
    padding: 0 32px 32px;
    display: flex;
    flex-direction: column;
  }
  .welcome__checklist {
    margin-bottom: 60px;
  }
  .welcome__heading--container {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 40px;
    padding-right: 25px;
    .h1__second__title {
      max-width: 600px;
      padding: 30px 0 20px;
    }
  }
  .welcome__checklist--and--button--container {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: flex-end;
    min-height: none;
  }
  
  .screen-desktop & {
    .welcome__content {
      padding: ${props => props.$scaling.scaleUpTo4K(20)}px ${props => props.$scaling.scaleUpTo4K(40)}px ${props => props.$scaling.scaleUpTo4K(40)}px;
    }
    .welcome__heading--container {
      justify-content: flex-start;
      padding-top: ${props => props.$scaling.scaleUpTo4K(30)}px;
      padding-right: 0;
      .h4 {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(10)}px;
      }
      .h1 {
        + .h4 {
          margin-top: ${props => props.$scaling.scaleUpTo4K(50)}px;
        }
      }
    }
    .welcome__checklist--and--button--container {
      flex-direction: row;
      justify-content: flex-start;
      padding-top: 10px;
      padding-right: 0;
    }
    .welcome__checklist,
    .welcome__button {
      margin: auto 0 0 0;
    }
    .welcome__checklist {
      width: 100%; // for IE
      max-width: ${props => props.$scaling.scaleUpTo4K(450)}px;
      padding-right: ${props => props.$scaling.scaleUpTo4K(50)}px;
    }
    .welcome__button {
      height: ${props => props.$scaling.scaleUpTo4K(64)}px;
      width: ${props => props.$scaling.scaleUpTo4K(240)}px;
      margin: auto 0 0 auto;
    }
    .welcome__text {
      display: inline-block;
      max-width: ${props => props.$scaling.scaleUpTo4K(440)}px;
    }
  }

  @media screen and (max-width: 1028px) {
    .screen-desktop & {
      .welcome__checklist--and--button--container {
        .welcome__checklist {
          padding: 50px 50px 0 0;
        }
      }
    }
  }

  @media screen and (max-width: 580px) {
    .screen-desktop & {
      .welcome__heading--container {
        flex: 0 0 auto;
      }
      .welcome__checklist--and--button--container {
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-end;
        flex: 1 0 auto;
        .welcome__checklist {
          padding: 50px 0 50px 0;
          margin: 0 auto;
        }
        button {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
`;

function Welcome() {

  const navigate = useNavigate()
  const insurer = useSelector(state => state.insurer);
  const incident = useSelector(state => state.incident);
  const files = useSelector(state => state.files)
  const inCreation = useSelector(state => state.inCreation);

  const scaling = useScaling();

  const isVIP = insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand)
  const isThemed = !!insurer && insurer.brand !== companyNames.VIP && insurer.objectType !== proccessType.DI;

  const themes = useMemo(() => isThemed ? [] : ['home-layout'], [isThemed]);

  const themeContext = useTheme();

  useThemes(themes);

  const { questionnaire } = useQuestionnaire()

  const location = useLocation()
  const currentPath = location.pathname;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);

  useEffect(() => {
    if (!!questionnaire && questionnaire.length) {
      const progress = questionnaire.find(page => page.path === currentPath).name
      browserStorage.setProgress(progress);
    }
  }, [currentPath, questionnaire])

  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath

  async function handleOnClick() {
    if (!inCreation) {
      await saveIncident({ ...incident }, isPageBeforeSuccess);
    }
    goToNextPage(navigate, questionnaire, incident, currentPath, files)
  }

  useEnterKey(handleOnClick);

  if (!questionnaire || !insurer || !questionnaire.length) return <Loader />;

  const insurerName = insurer.brandName || 'uw verzekeraar';
  const insurerLogo = insurer.logoURL || '';

  const companyLogo = {
    align: isMobile ? 'center' : 'left',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
    src: insurerLogo,
    alt: insurerName,
  };

  const button = {
    theme: 'primary',
    onClick: handleOnClick,
    className: 'button--next__page welcome__button',
    justify: 'center',
    boxShadow: 3,
  };

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, inCreation, isMobile, pageName)

  const text = content["intro-text"]
  const heading = content["over-title-text"]
  const firstTitle = content["first-title"]
  const secondTitle = content["second-title"]
  const showCheckList = content["show-checklist"]
  const checkList = content["checklist"]

  const generateCheckList = showCheckList ? checkList.map((item, i) => {
    return (
      <CheckListItem icon={item.icon} key={i}>
        {item.text}
      </CheckListItem>
    )
  }) : null

  return (
    <React.Fragment>
      <StyledMain $scaling={scaling}>
        <Header>{insurerLogo && <CompanyLogo {...companyLogo} />}</Header>
        <Section className="welcome__content">
          <Container className="welcome__heading--container">
            {heading && <Heading level="4">{heading}</Heading>}
            {firstTitle && <Heading level="1" className="h1__title">{firstTitle}</Heading>}
            {secondTitle && <Heading level="1" className="h1__second__title">{secondTitle}</Heading>}
            {text && <Text className="welcome__text">{text}</Text>}
          </Container>
          <Container className="welcome__checklist--and--button--container">
            {showCheckList && <CheckList className="welcome__checklist">{generateCheckList}</CheckList>}
            <Button {...button}>Start</Button>
          </Container>
        </Section>
      </StyledMain>
      <BackgroundVideo
        autoPlay={true}
        img={!isVIP || isThemed}
        imgFileName="BSSP03.jpg"
        loop={true}
        type="video/mp4"
        videoFileName="DEF1.mp4"
      />
    </React.Fragment>
  );
}

export default Welcome;
