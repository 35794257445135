import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';

import CheckboxLabel from './CheckboxLabel';

// const GlobalStyle = createGlobalStyle`
//   .radio {
//     // flex: 1 0 45%;
//     flex: 1 0 calc(50% - 18px);
//     margin: 0 8px 16px;
//     text-align: center;
//   }
// `;

const StyledCheckbox = styled('input')`
  display:none;
  width: 1px;
  height: 1px;
  opacity: 0;
  .checkbox__icon {
    margin-left: auto;
    border-radius: 16px;
  }
  @media screen and (max-width: 400px)  {
    .screen-desktop & {
      flex: 1 1 100%;
    }
  }
`;

function Checkbox({
  children,
  className,
  defaultChecked,
  disabled,
  id,
  name,
  onClick,
  value,
}) {
  const checkboxClasses = classNames('checkbox', className)
  const classes = classNames('checkbox__label', {
    'checkbox__label--checked': value,
    'checkbox__label--disabled': disabled,
  });

  return (
    <>
      {/* <GlobalStyle /> */}
      <div className={checkboxClasses}>
        <StyledCheckbox
          className="input--checkbox"
          defaultChecked={defaultChecked}
          disabled={disabled}
          id={id}
          name={name}
          onClick={onClick}
          type="checkbox"
          value={value}
        />
        <CheckboxLabel
          className={classes}
          htmlFor={id}
          defaultChecked={defaultChecked}
        >
          {React.Children.toArray(children)}
        </CheckboxLabel>
      </div>
    </>
  );
}

Checkbox.propTypes = {
  children: PropTypes.any.isRequired,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  value: PropTypes.bool.isRequired,
};

export default Checkbox;
