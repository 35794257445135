import React from 'react';
import PropTypes from 'prop-types';
import { styled, GlobalStyles } from '@mui/material';
import classNames from 'classnames';
import { Colors } from '@domain/theming';
import { isMobile } from 'react-device-detect';
import { useScaling } from '@domain/hooks';

const globalStyle = `
  .validation__container {
    min-height: 50px;
  }
`;

const StyledSpan = styled('span', {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  display: flex;
  margin-bottom: 20px;
font-size: ${props => !isMobile ? props.$scaling.textSize(14) : props.$scaling.textSizeMobile(14)}px;
line-height: ${props => !isMobile ? props.$scaling.textSize(18) : props.$scaling.textSizeMobile(18)}px;
  &.validation-feedback--error {
    color: ${Colors.RED};
  }
`;
// to not break the test that is looking for a 'span' element
StyledSpan.displayName = 'span';
function ValidationFeedback({ children, type = 'info', className }) {
  const scaling = useScaling();
  const classes = classNames('validation-feedback', className, {
    'validation-feedback--error': type === 'error',
    'validation-feedback--info': type === 'info',
  });

  return <>
    <StyledSpan className={classes} $scaling={scaling}>{React.Children.toArray(children)}</StyledSpan>
    <GlobalStyles styles={globalStyle} />
  </>;
}

ValidationFeedback.propTypes = {
  children: PropTypes.any.isRequired,
  type: PropTypes.oneOf(['error', 'info'])
};

export default ValidationFeedback;
