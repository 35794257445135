import React from 'react';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { alpha } from '@mui/material/styles';
const PREFIX = 'Dropdown';

const classes = {
  formControl: `${PREFIX}-formControl`,
  dropdown: `${PREFIX}-dropdown`,
  inputUnderline: `${PREFIX}-inputUnderline`,
  dropdownIcon: `${PREFIX}-dropdownIcon`,
  dropdownUnlabelled: `${PREFIX}-dropdownUnlabelled`,
  dropdownLabel: `${PREFIX}-dropdownLabel`,
  dropdownLabelShrink: `${PREFIX}-dropdownLabelShrink`,
  dropdownLabelFocused: `${PREFIX}-dropdownLabelFocused`,
  menuItem: `${PREFIX}-menuItem`,
  menuItemSelected: `${PREFIX}-menuItemSelected`
};

const StyledFormControl = styled(FormControl)((
  {
    theme
  }
) => ({
  '& .MuiInputUnderline': {
    '&:after': {
      borderColor: theme.colors.primary + ' !important',
    },
    '&:before': {
      borderColor: theme.colors.primary + ' !important',
    }
  },
  '& .MuiInputLabel-formControl': {
    top: '10px'
  },

  [`& .${classes.inputUnderline}`]: {
    borderColor: theme.colors.primary,
  },

  [`& .${classes.dropdownIcon}`]: {
    color: theme.colors.primary,
  },

  [`& .${classes.dropdownUnlabelled}`]: {
    // a dropdown without label will have a reduced height
    // marginTop: theme.spacing(2),
  },

  [`& .${classes.menuItem}`]: {
    '&:hover': {
      backgroundColor: alpha(theme.colors.primary, 0.18)
    },
  },

  [`& .${classes.menuItemSelected}`]: {
    '&&': {
      backgroundColor: alpha(theme.colors.primary, 0.28),
      '&:hover': {
        backgroundColor: alpha(theme.colors.primary, 0.28)
      }
    }
  }
}));

const StyledSelect = styled(Select)((
  {
    theme
  }
) => ({
  lineHeight: '24px',
  fontSize: '24px',
  textAlign: 'left',
}));

const StyledLabel = styled(InputLabel)((
  {
    theme
  }
) => ({
  transform: 'translate(0, 12px) scale(1)',
  fontSize: '24px',
  fontWeight: 'bold',

  [`&.${classes.dropdownLabelShrink}`]: {
    transform: 'translate(0, -20.00px)',
    fontSize: '16px',
    fontWeight: 'bold',
    height: '44px',
    transformOrigin: 'top left',
    color: theme.colors.primary,
  },

  [`&.${classes.dropdownLabelFocused}`]: {
    color: theme.colors.primary,
  },
}));


// import ValidationFeedback from '../ValidationFeedback';

function Dropdown({
  label,
  onChange,
  disabled,
  value,
  valueList = {},
}) {


  if (Array.isArray(valueList)) {

    return (
      <StyledFormControl >
        {!!label && <StyledLabel classes={{ shrink: classes.dropdownLabelShrink, focused: classes.dropdownLabelFocused }}>{label}</StyledLabel>}
        <StyledSelect
          {...(disabled ? { disabled: true } : {})}
          onChange={onChange}
          value={value}
          variant='standard'
        >
          {valueList.map((item, i) => {

            return (
              <MenuItem classes={{ root: classes.menuItem, selected: classes.menuItemSelected }} key={label + item + i} value={item}>{item}</MenuItem>
            );
          })}
        </StyledSelect>
      </StyledFormControl >
    );

  } else {
    // the list is an object
    // where the key is the value
    // and the value attached to the key is the display name
    const valueListKeys = Object.keys(valueList);

    return (
      <StyledFormControl >
        {!!label && <StyledLabel classes={{ root: classes.dropdownLabel, shrink: classes.dropdownLabelShrink, focused: classes.dropdownLabelFocused }}>{label}</StyledLabel>}
        <StyledSelect
          onChange={onChange}
          value={value}
          variant='standard'
        >
          {valueListKeys.map((item, i) => {

            return (
              <MenuItem classes={{ root: classes.menuItem, selected: classes.menuItemSelected }} key={label + item + i} value={item}>{valueList[item]}</MenuItem>
            );
          })}
        </StyledSelect>
      </StyledFormControl>
    );
  }
}

export default Dropdown;
