import React from 'react';
import { isMobile } from 'react-device-detect';

import Color from 'color'
import { styled } from '@mui/material/styles';
import { Colors, Shadows } from '@domain/theming';
import { useScaling } from '@domain/hooks';

const StyledAnchorButton = styled('a', {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${props => props.$scaling.scaleUpTo4K(64)}px;
  max-width: ${props => props.$scaling.scaleUpTo4K(380)}px;
  padding: ${props => props.$scaling.scaleUpTo4K(12)}px ${props => props.$scaling.scaleUpTo4K(24)}px;
  font-size: ${props => props.$dynamicFontSize ? (isMobile
    ? props.$scaling.textSizeMobile(12)
    : props.$scaling.textSize(12)) + 'px' : '12px'}
  font-weight: 700;
  line-height: ${props => props.$dynamicFontSize ? (isMobile
    ? props.$scaling.textSizeMobile(14)
    : props.$scaling.textSize(14)) + 'px' : '14px'}
  letter-spacing: 0.02em;
  text-transform: uppercase;
  background: ${props => props.variant === 'outline' ? 'transparent' : props.$isThemed ? props.theme.colors.primary : Colors.BLACK};
  color: ${props => props.variant === 'outline' ? !!props.$isThemed ? props.theme.colors.primary : Colors.BLACK : props.$isThemed ? props.theme.colors.contrastText : Colors.WHITE};
  border: ${props => props.$scaling.scaleUpTo4K(1)}px solid ${props => !!props.$isThemed ? props.theme.colors.primary : Colors.BLACK};
  border-radius: ${props => props.$scaling.scaleUpTo4K(16)}px;
  cursor: pointer;
  transition: 160ms cubic-bezier(0.4, 0, 0.2, 1);
  text-decoration: none;
  box-shadow: ${props => props.variant !== 'outline' && Shadows.SHADOW6};
  @media (hover: hover) {
    &:hover {
      transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
      box-shadow: ${props => props.variant !== 'outline' && Shadows.SHADOW3};
      background: ${props => props.variant !== 'outline' ? props.$isThemed ? props.theme.colors.primary : Color(Colors.BLACK).rgb().alpha(0.7).string() : Color(Colors.BLACK).rgb().alpha(0.08).string()}
    }
  }
  &:focus {
    transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: ${props => props.variant !== 'outline' && Shadows.SHADOW3};
  }
  &:active {
    transform: scale(0.98);
    transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: ${props => props.variant !== 'outline' && Shadows.SHADOW1};
  }
  &[disabled] {
    box-shadow: none;
  }
  &.white {
    box-shadow: none;
  }
  .card & {
    margin: auto auto 0;
  }
  &.black-and-white {
    position:relative;
    max-width: ${props => props.$scaling.scaleUpTo4K(580)}px;
    background: ${Colors.GREY_LIGHTER};
    border: 0;
    color: ${Colors.BLACK};
    margin: ${props => props.$scaling.scaleUpTo4K(30)}px 0; 
    justify-content: center;
    box-shadow: none;
    &.secondary_button {
      opacity: 0.7;
    }
    &.open {
      box-shadow: ${props => props.variant !== 'outline' && Shadows.SHADOW6};
      background: ${Colors.WHITE};
    }
    .button__icon {
      color: ${Colors.BLACK};
      border-radius: ${props => props.$scaling.scaleUpTo4K(16)}px;
      margin-left: auto;
    }
    @media (hover: hover) {
      &:hover {
        transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
        background: ${Colors.WHITE}
        color: ${Colors.BLACK}
        box-shadow: ${Shadows.SHADOW1};
        &.open {
          box-shadow: ${Shadows.SHADOW6};
          background: ${Colors.GREY_LIGHTER}
        }
        .button__icon {
          color: ${Colors.BLACK};
          border-radius: ${props => props.$scaling.scaleUpTo4K(16)}px;
          margin-left: auto;
        }
      }
    }
  }
  &.volgende-button {
    max-width: ${props => props.$scaling.scaleUpTo4K(580)}px;
    width: ${props => props.$scaling.scaleUpTo4K(240)}px;
    justify-content: space-between;
    background: ${Colors.WHITE};
    border: 0;
    &.disabled {
        color: ${Colors.GREY_LIGHT};
        pointer-events: none;
        cursor: default;
        .button__icon {
          background: ${Colors.GREY_LIGHT};
          color: ${Colors.WHITE};
          border-radius: ${props => props.$scaling.scaleUpTo4K(16)}px;
          margin-left: auto;
        }
      }
    &.enabled {
      color: ${Colors.WHITE};
      border: 0;
      box-shadow: ${Shadows.SHADOW6};
      background: ${Colors.BLACK};
      .button__icon {
        background: ${Colors.WHITE};
        color: ${Colors.BLACK};
        border-radius: ${props => props.$scaling.scaleUpTo4K(16)}px;
        margin-left: auto;
      }
    }
  }
`;
const AnchorButton = ({
  variant,
  target,
  className,
  dynamicFontSize,
  children,
  href,
  isThemed = true,
  onClick
}) => {

  const scaling = useScaling();
  return (
    <StyledAnchorButton
      variant={variant}
      target={target}
      className={className}
      $dynamicFontSize={dynamicFontSize}
      $scaling={scaling}
      href={href}
      $isThemed={true}
      onClick={onClick}
    >
      {React.Children.toArray(children)}
    </StyledAnchorButton>
  );
};

export default AnchorButton;
