import {
  parseFetchResponseToJson,
  camelCaseToPascalCase,
} from '@domain/helpers';

import { endpoints } from '@domain/constants';
import retry from 'fetch-retry'

const fetchWithRetry = retry(fetch, { retries: 3 })

const bodyshopEndpoint = endpoints.bodyshop;

function get(outletUUID, token) {
  const config = {
    method: 'GET',
    headers: camelCaseToPascalCase({
      token,
    }),
  };
  return fetchWithRetry(`${bodyshopEndpoint}/${outletUUID}`, config).then((response) =>
    parseFetchResponseToJson(response, { endpoint: `${bodyshopEndpoint}/${outletUUID}` }),
  );
}

export default {
  get,
};
