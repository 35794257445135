import React from 'react';

import { useScaling } from '@domain/hooks';
import { Colors } from '@domain/theming';
import { isMobile } from 'react-device-detect';
import { styled } from '@mui/material/styles';

const StyledA = styled('a', {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  // font-size: ${props => !isMobile ? props.$scaling.textSize(14) : props.$scaling.textSizeMobile(14)}px;
  // line-height: ${props => !isMobile ? props.$scaling.textSize(18) : props.$scaling.textSizeMobile(18)}px;
  text-decoration: none;
  // display: inline-block;
  .theme-custom & {
    color: ${Colors.BLACK}; 
  }
  .theme-dark & {
    color: ${Colors.WHITE}; 
  }
`;

function StyledLink({ children, ...props }) {
  const scaling = useScaling();
  return <StyledA {...props} $scaling={scaling}><u>{React.Children.toArray(children)}</u></StyledA>;
}

export default StyledLink;