import { datadogLogs } from '@datadog/browser-logs'

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LicensePlateService } from '@domain/services'
import { useSearchParams } from 'react-router-dom'
import browserStorage from '@domain/helpers/browserStorage';
import { TEMP_INCIDENT_UPDATED } from '@domain/action-types';
import { useDispatchError } from '..';

const useLicensePlateUUIDLoader = (location) => {
  const [searchP, setSearchP] = useSearchParams()
  const dispatch = useDispatch();
  const incident = useSelector(state => state.incident);
  const { dispatchNewError } = useDispatchError();

  const [licensePlateUUID, setLicensePlateUUID] = useState(null)

  useEffect(() => {

    const licensePlateUUIDFromUrl = searchP.get('LicensePlateUUID')
      ? decodeURIComponent(searchP.get('LicensePlateUUID'))
      : null

    if (licensePlateUUIDFromUrl) {
      setLicensePlateUUID(licensePlateUUIDFromUrl)
      return
    }

  }, [location.search]);

  useEffect(() => {

    const getlicensePlateWithUUID = async (licensePlateUUIDFromUrl) => {
      try {
        const response = await LicensePlateService.getLicense(licensePlateUUIDFromUrl);

        const newTempIncident = { ...incident, ...response };
        browserStorage.setTempSaved(false)
        browserStorage.saveValuesInLocalStorage(newTempIncident);
        dispatch({
          type: TEMP_INCIDENT_UPDATED,
          incident: newTempIncident,
        })
      } catch ({ error }) {
        const errorLogMessage = "Error occurred inside useLicensePlateUUIDChecker trying to retrieve license plate for schadegarant (LicensePlateService.getLicense):"
        const errorInstance = new Error(errorLogMessage)
        datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
        dispatchNewError(error);
      }
    }

    if (!licensePlateUUID || (incident && incident.licensePlate)) {
      return;
    }

    if (licensePlateUUID) {

      getlicensePlateWithUUID(licensePlateUUID)
      return
    }

  }, [dispatch, licensePlateUUID, incident, dispatchNewError]);
};

export default useLicensePlateUUIDLoader;
