import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import UseIcon from '../../UseIcon';
import { Colors } from '@domain/theming';
import { useScaling } from '@domain/hooks';

const StyledIcon = styled(UseIcon, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  min-width: ${props => props.$scaling.scaleUpTo4K(32)}px;
  min-height: ${props => props.$scaling.scaleUpTo4K(32)}px;
  position: absolute;
  left: 0;
  top: 0;
  background: ${Colors.WHITE};
  color: ${Colors.BLACK};
  border-radius: ${props => props.$scaling.scaleUpTo4K(8)}px;
  &.timeline__icon--dark {
    background-color: ${Colors.BLACK};
    color: ${Colors.WHITE};
  }
  .screen-ie & {
    height: 0;
  }
`;

function TimeLineIcon({ name, className, width, height, muted, dark }) {
  const scaling = useScaling();
  const classes = classNames('timeline__icon', {
    'timeline__icon--muted': muted,
    'timeline__icon--unmuted': !muted,
    'timeline__icon--dark': dark
  });

  return (
    <StyledIcon
      name={name}
      className={classes}
      width={scaling.scaleUpTo4K(width)}
      height={scaling.scaleUpTo4K(height)}
      muted={muted}
      $scaling={scaling}
    />
  );
}

TimeLineIcon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  muted: PropTypes.bool,
  dark: PropTypes.bool
};

export default TimeLineIcon;
