import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import {
  ContainerSP,
  NextButton,
  ToogleCardGroup,
  UseIcon,
} from '@domain/components';
import { useReloadInsurer, useScaling } from '@domain/hooks';
import { goToNextPage } from '@domain/helpers';
import { useNavigate } from 'react-router-dom'
const StyledWrapper = styled('div')`
  height:100%;
  display: flex;
  width: 100%;
  flex-direction: column;
`
const Wrapper = styled('div')` 
    margin: auto 0;
    padding-top: ${props => props.$scaling.scaleUpTo4K(30)}px;

    
  @media screen and (max-width: 900px) {
    padding-top: 0;
  }
`

const StyledContainer = styled(ContainerSP)`
    align-content: space-around;
    flex-wrap: wrap;
`;

const ButtonContainer = styled(ContainerSP)`
    justify-content: center;
    max-width: 100%;
    margin: auto;
    flex: 0 0 auto;
    .volgende-button {
      margin-left: auto;
    }

    @media screen and (max-width: 580px) {
      margin: 0;
      margin-left: auto;
    }
`;

const ButtonWrapper = styled(ContainerSP)`
    align-items: flex-end;
    width: ${props => props.$scaling.scaleUpTo4K(580)}px;
    max-width: 100%;

    .screen-ie & {
      max-width: ${props => props.$scaling.scaleUpTo4K(580)}px;
    }

    @media screen and (max-width: 580px) {
      width: 100%
      max-width: 100%
    }
`;

const FormWrapperRhion = ({ content, isPageBeforeSuccess, saveIncident, questionnaire, currentPath, files }) => {
  const navigate = useNavigate()
  const pid = useSelector((state) => state.pid)
  const incident = useSelector((state) => state.incident)
  const redirectUrls = {
    cascoRedirectURL: `/onboarding/welkom?PID=${pid}&FID=${pid}casco`,
    ownBodyshopRedirectURL: `/onboarding/welkom?PID=${pid}&FID=${pid}em`
  }
  const scaling = useScaling();

  const [currentLink, setCurrentLink] = useState(redirectUrls.cascoRedirectURL)

  const contentCardOne = content['card-one']
  const contentCardTwo = content['card-two']
  const nextButtonText = currentLink === redirectUrls.cascoRedirectURL ? 'Maak een herstelafspraak' : currentLink === redirectUrls.ownBodyshopRedirectURL ? 'Dien offerte in' : 'Maak een keuze'

  const fetching = useRef(false);
  const reloadInsurer = useReloadInsurer()

  async function generateLink(event) {
    event.preventDefault()
    fetching.current = true;
    const incidentSwitched = currentLink === redirectUrls.ownBodyshopRedirectURL ? await saveIncident({ ...incident, switchEM: true }, isPageBeforeSuccess) : await saveIncident({ ...incident }, isPageBeforeSuccess)
    if (Object.keys(incidentSwitched).length) {
      await reloadInsurer()
      fetching.current = false;
      goToNextPage(navigate, questionnaire, incidentSwitched, currentPath, files)
    }
  }

  return (
    <StyledWrapper>
      <Wrapper $scaling={scaling}>
        <StyledContainer
          boxSizing="content-box"
          flexDirection="column"
          alignItems="center"
        >
          <ToogleCardGroup cards={[{ ...contentCardOne, link: redirectUrls.cascoRedirectURL }, { ...contentCardTwo, link: redirectUrls.ownBodyshopRedirectURL, className: 'secondary_button' }]} setCurrentLink={setCurrentLink} initialOpenCard={0} />
        </StyledContainer>
      </Wrapper>
      <ButtonContainer $scaling={scaling}>
        <ButtonWrapper $scaling={scaling}>
          <NextButton
            variant="outline"
            className="volgende-button"
            onClick={generateLink}
            dynamicFontSize={true}
            disabled={!currentLink}
            iconButton={true}
          ><span>{nextButtonText}</span>
            <UseIcon name="arrow-right" className="button__icon" />
          </NextButton>
        </ButtonWrapper>
      </ButtonContainer>
    </StyledWrapper>
  )
}

export default FormWrapperRhion
