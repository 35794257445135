import { object, string } from 'yup';

const damageNumberRegEx = /[^A-Za-z0-9]*/

const validationSchema = object({
  damageNumber: string()
    .matches(damageNumberRegEx, 'Controleer alstublieft uw schadenummer.')
    .label('schadenummer')
    .required(),
});

const validationSchemaNotRequired = object({
  damageNumber: string()
    .matches(damageNumberRegEx, 'Controleer alstublieft uw schadenummer.')
    .label('schadenummer')
});

export { validationSchema, validationSchemaNotRequired };
