import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import FormWrapperRhion from './FormWrapperRhion.jsx'
import InfoBlock from './InfoBlock';
import { Background, ContainerSP, HeaderSP, Loader } from '@domain/components';
import { chooseLogoSize, generatePageContent } from '@domain/helpers';
import { useSaveIncidentFactory, useScaling } from '@domain/hooks';
import { Colors } from '@domain/theming';
import Color from 'color'
import { motion } from 'framer-motion';
import { styled, useTheme } from '@mui/material/styles';
import { isIE } from 'react-device-detect';
import { detectContentType } from '@domain/content';
import { useQuestionnaire } from '@domain/providers';
import { useLocation } from 'react-router-dom';

const StyledContainer = styled(
  motion(ContainerSP),
)`
  .landing__intro-text {
    position: relative;
    &--inner {
      // min-height: 100%;
      top: 0px;
    }
    &--content {
      width: 100%;
      padding-bottom: ${props => props.$scaling.scaleUpTo4K(20)}px;
    }
    &--signature {
      p {
        padding-bottom: 2px;
      }
      .logo {
        margin: 8px 8px 0;
      }
    }
    h1 {
      color: ${props => Colors.WHITE};
      text-shadow: ${props => props.isThemed && `-${props.$scaling.scaleUpTo4K(1)}px ${props.$scaling.scaleUpTo4K(1)}px ${props.$scaling.scaleUpTo4K(2)}px` + Color(props.theme.colors.primary).rgb().alpha(0.5)};
    }
    p {
      margin: 0;
      color: ${props => props.isThemed && Colors.WHITE};
      text-shadow: ${props => props.isThemed && `-${props.$scaling.scaleUpTo4K(1)}px ${props.$scaling.scaleUpTo4K(1)}px ${props.$scaling.scaleUpTo4K(2)}px` + Color(props.theme.colors.primary).rgb().alpha(0.3).string()};
    }
    a {
      text-decoration: none;
      color: ${props => props.isThemed && Colors.WHITE};
    }
    ul {
      margin: ${props => props.$scaling.scaleUpTo4K(15)}px 0;
      font-size: ${props => props.$scaling.scaleUpTo4K(16)}px;
      list-style: none;
      padding-left: ${props => props.$scaling.scaleUpTo4K(30)}px;
      padding-inline: 0;
      li {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(8)}px;
        display: flex;
        align-items: center;
        &::before {
          content: "•";
          color: ${props => props.isThemed && Colors.WHITE};
          font-weight: bold;
          text-align:center;
          flex: 0 0 ${props => props.$scaling.scaleUpTo4K(30)}px;
        }
        p {
          text-align: left;
          flex: 1 1 auto;
        }
      }
    }
  }
  .landing__card-container {
    position: relative;
    &--inner {
     
      btn.main__button--redirect {
        color: ${props => props.theme.colorAsBackground ? Colors.BLACK : props.theme.colors.primary}
      }
    }
  }

  &.spl1 {
    min-height: 100vh;
    .header {
      height: ${props => props.$scaling.scaleUpTo4K(120)}px;
    }
    .landing__intro-text {
      &--content {
        padding-bottom: 0;
        h1 {
          color: ${props => props.theme.colorAsBackground ? Colors.WHITE : props.theme.colors.primary};
          text-shadow: ${props => props.theme.colorAsBackground ? 'none' : `-${props.$scaling.scaleUpTo4K(1)}px ${props.$scaling.scaleUpTo4K(1)}px ${props.$scaling.scaleUpTo4K(2)}px` + Color(props.theme.colors.primary).rgb().alpha(0.5)};
        }
        p {
          margin: 0;
          color: ${props => props.theme.colorAsBackground ? Colors.WHITE : Colors.GREY_DARK};
          text-shadow: ${props => props.theme.colorAsBackground ? 'none' : `-${props.$scaling.scaleUpTo4K(1)}px ${props.$scaling.scaleUpTo4K(1)}px ${props.$scaling.scaleUpTo4K(2)}px` + Color(Colors.WHITE).rgb().alpha(0.3).string()};
        }
        a {
          color: ${props => props.theme.colorAsBackground ? Colors.WHITE : props.theme.colors.primary};
          text-shadow: ${props => props.theme.colorAsBackground ? 'none' : `-${props.$scaling.scaleUpTo4K(1)}px ${props.$scaling.scaleUpTo4K(1)}px ${props.$scaling.scaleUpTo4K(2)}px` + Color(Colors.WHITE).rgb().alpha(0.3).string()};
        }
        svg {
          color: ${props => props.theme.colorAsBackground ? Colors.WHITE : Colors.GREY_DARK};
        }
        ul {
          li {
            &::before {
              color: ${props => props.theme.colorAsBackground ? Colors.WHITE : Colors.GREY_DARK};
            }
            p {
              color: ${props => props.theme.colorAsBackground ? Colors.WHITE : Colors.GREY_DARK};
            }
            svg {
              color: ${props => props.theme.colorAsBackground ? Colors.WHITE : Colors.GREY_DARK};
              fill: ${props => props.theme.colorAsBackground ? Colors.WHITE : Colors.GREY_DARK};
            }
          }
        }
      }
    }
    .landing__card-container {
      &--inner {
        flex-grow: 1;
        width: 100%;
        .main__button--redirect {
          color: ${props => props.theme.colorAsBackground ? Colors.BLACK : props.theme.colors.primary};
          svg {
            fill: ${props => props.theme.colorAsBackground ? Colors.BLACK : props.theme.colors.primary};
            color: ${props => props.theme.colorAsBackground ? Colors.BLACK : props.theme.colors.primary};
          }
        }
        h3 {
          color: ${props => props.theme.colorAsBackground ? Colors.WHITE : Colors.GREY_DARK};
        }
        p {
          color: ${props => props.theme.colorAsBackground ? Colors.WHITE : Colors.GREY_DARK};
        }
        ul {
          li {
            &:before {
              color: ${props => props.theme.colorAsBackground ? Colors.WHITE : Colors.GREY_DARK};
            }
            svg {
              color: ${props => props.theme.colorAsBackground ? Colors.WHITE : Colors.GREY_DARK};
              fill: ${props => props.theme.colorAsBackground ? Colors.WHITE : Colors.GREY_DARK};
            }
          }
        }
        .volgende-button {
          background: ${props => !props.theme.colorAsBackground && props.theme.colors.primary};
          transition: background ease-in-out .25s;
          &.disabled {
            background: ${props => !props.theme.colorAsBackground && Colors.WHITE}
          }
        }
      }
    }
  }
  
  .screen-ie & {
    .landing__card-container {
      &--inner {
        height: 0px;
      }
    }
    &.spl1 {
      .landing__card-container {
        &--inner {
          height: auto;
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    .landing__intro-text {
      margin: 0 auto;
      padding-right: 0;
      min-width: auto;
      flex: 1 0 auto;
      &--inner {
        min-height: auto;
        width: 100%;
        padding-bottom: 60px;
      }
      h1, p {
        text-align: center;
      }
      ul {
        max-width: 300px;
        margin: 15px auto;
        li {
        }
      }
    }
    .landing__card-container {
      overflow: visible;
      flex: 1 0 auto;
      &--inner {
        width: 100%;
        min-height: initial;
        padding: 80px 40px 140px;
      }
    }

    &.spl1 {
      .background-container {
        position:fixed;
      }
      .landing__intro-text {
        &--inner {
          // padding: 120px 60px 0px;
        }
        &--content {
          max-width: 100%;
        }
      }
      .landing__card-container {
        &--inner {
          padding: 20px 60px 60px;
        }
      }
    }
  }


  @media screen and (max-width: 540px) {
    p {
      text-align:justify !important;
    }
    .container.landing__intro-text {
      &--inner {
        padding: 120px 30px 30px;
      }
    }

    &.spl1 {
      text-align:justify !important;
      .landing__intro-text {
        &--inner {
          // padding: 80px 30px 0px;
        }
      }
      .landing__card-container {
        &--inner {
          padding: 15px 30px 30px;
        }
      }
    }
  }

  .screen-mobile & {
  }
`;

const Landing = () => {

  const { questionnaire } = useQuestionnaire();
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer)
  const files = useSelector(state => state.files)

  const location = useLocation()
  const currentPath = location.pathname;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);

  const themeContext = useTheme();

  const scaling = useScaling();

  if (!insurer || !questionnaire) {
    return <Loader />;
  }

  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath

  const headerBackground = insurer.headerBackground || false

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name

  const content = generatePageContent(contentType, insurer, incident, false, false, pageName)

  const infoBlockContent = content["infoBlock"]
  const formBlockContent = content["formBlock"]

  return (
    <StyledContainer
      className='flex-container spl1'
      justifyContent="space-between"
      $scaling={scaling}
      isThemed={true}
    >
      <Background
        imageName="BSSP03.jpg"
        overlayColor={themeContext.colorAsBackground ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.06)'}
        fixed={isIE}
      />
      <HeaderSP background={headerBackground ? Colors.WHITE : 'transparent'} shadow={headerBackground} imgSrc={(!!insurer && insurer.logoURL) || ''} height={chooseLogoSize(themeContext.logoSize)} />
      <ContainerSP
        className="landing__intro-text"
        boxSizing="content-box"
        flexDirection="column"
        flexGrow={'1'}
        flexShrink="0"
        flexBasis={'0px'}
        alignItems="center"
        justifyContent="center"
        margin="0"
        padding="0"
        position={'relative'}
        isThemed={true}
      >
        <ContainerSP
          className="landing__intro-text--inner"
          flexGrow="1"
          flexShrink="0"
          flexBasis="auto"
          justifyContent="center"
          alignItems="center"
          isThemed={true}
          padding={scaling.scaleUpTo4K(120) + 'px ' + scaling.scaleUpTo4K(60) + 'px'}
          maxWidth={scaling.scaleUpTo4K(640) + 'px '}
        >
          <InfoBlock content={infoBlockContent} />
          <ContainerSP
            className="landing__intro-text--signature"
            alignItems="flex-end"
            justifyContent="center"
            flexGrow="0"
            isThemed={true}
            flexBasis={scaling.scaleUpTo4K(80) + 'px'}
            padding={scaling.scaleUpTo4K(20) + 'px'}
          >
          </ContainerSP>
        </ContainerSP>
      </ContainerSP>
      <ContainerSP
        isThemed={true}
        className="landing__card-container"
        flexGrow="1"
        flexShrink="0"
        flexBasis={'0px'}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <ContainerSP
          className="landing__card-container--inner"
          flexDirection="column"
          flexGrow="0"
          isThemed={true}
          alignItems="center"
          padding={scaling.scaleUpTo4K(60) + 'px'}
          maxWidth={scaling.scaleUpTo4K(640) + 'px '}
        >
          <FormWrapperRhion content={formBlockContent} isPageBeforeSuccess={isPageBeforeSuccess} saveIncident={saveIncident} questionnaire={questionnaire} currentPath={currentPath} files={files} />
        </ContainerSP>
      </ContainerSP>
    </StyledContainer>)

}
export default Landing;
