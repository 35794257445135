import { object, string } from 'yup';

export default object({
  driverStreet: string()
    .required()
    .label('Straatnaam'),
  driverCity: string()
    .required()
    .label('Plaats'),
});
