import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { styled, useTheme } from '@mui/material/styles';

import {
  Button,
  DamageRegion,
  NavBar,
  IconLink,
  ProgressionIndicator,
  IconButton,
  FAQ,
  Main,
  GridContainer,
  GridColumn,
  Legend,
  Heading,
  UseIcon,
  Text,
  ValidationFeedback,
  Container,
  Loader,
  CompanyLogo
} from '@domain/components';
import {
  getPrevNextPagePath,
  goToNextPage,
  browserStorage,
  generatePageContent,
  chooseLogoSize
} from '@domain/helpers';
import { useNavigate, useLocation } from 'react-router-dom'
import { useFaq, useEnterKey, useScaling, useSaveIncidentFactory } from '@domain/hooks';
import { deepEqualityLVLOne } from '@domain/helpers';
import validationSchema from './validation-schema';
import { detectContentType } from '@domain/content';
import { useQuestionnaire } from '@domain/providers';

const initialDamage = {
  backRight: false,
  backLeft: false,
  backCentre: false,
  centreLeft: false,
  centreRight: false,
  centre: false,
  frontLeft: false,
  frontRight: false,
  frontCentre: false,
};

const StyledMain = styled(Main, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  exterior__validation {
   width: 100%;
   height: ${props => props.$scaling.scaleUpTo4K(18)}px;
   margin-top: 0;
   margin-bottom: ${props => props.$scaling.scaleUpTo4K(32)}px;
    span {
      display: block;
      text-align: center;
    }
  }
  .grid__column--footer {
    button {
      margin-bottom: ${props => props.$scaling.scaleUpTo4K(46)}px;
    }
  }
  .screen-desktop & {
    .grid__container--inner {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      align-self: stretch;
      min-height: ${props => props.$scaling.scaleUpTo4K(680)}px;
    }
    .grid__column--main {
      flex: 1 1 auto;
      width: 50%;
      height: 50%;
      justify-content: flex-end;
      .h1 {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(20)}px;
      }
    }
    .grid__column--footer {
      padding: ${props => props.$scaling.scaleUpTo4K(40)}px 0 0 ${props => props.$scaling.scaleUpTo4K(40)}px;
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;
      height: 100%;
      justify-content: center;
    }
    .grid__column--extra {
      flex: 1 1 auto;
      width: 50%;
      height: 50%;
      justify-content: flex-start;
      button {
        margin-top: ${props => props.$scaling.scaleUpTo4K(62)}px;
        margin-bottom: 0;
      }
    }
  }
  @media screen and (max-width: 1028px) {
    .screen-desktop & {
      .grid__column {
        max-width: 500px;
        width: 100%;
        height: auto;
        margin: 0 auto;
      }
      .grid__column--main {
        max-width: 580px;
        .h1 {
          margin-bottom: 15px;
          max-width: none;
        }
      }
      .grid__column--footer {
        flex: 1 0 auto;
        position: relative;
        flex-direction: column;
        padding: 0;
      }
      .grid__column--extra {
        align-items: center;
        button {
          margin-top: 0;
        }
      }
    }
  }
  @media screen and (max-width: 580px) {
    .screen-desktop & {
      .grid__column--extra {
        align-items: stretch;
        button {
          width: 100%;
        }
      }
    }
  }
`;

const StyledValidationFeedback = styled(ValidationFeedback)`
  margin: auto;
  @media screen and (max-width: 1028px) {
    margin: auto;
  }
`;

const StyledDamageRegion = styled(DamageRegion)`
  @media screen and (max-width: 1028px) {
    .damage-region {
      max-width: 360px;
      padding-bottom: 0;
    }
  }
`;

function Exterior() {
  const navigate = useNavigate()
  const { questionnaire } = useQuestionnaire();
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)
  const scaling = useScaling();
  const [
    faqVisible,
    handleOnHelpClick,
    clickOutSideFaq,
    faqListProps,
    faqSeen,
  ] = useFaq();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [backButton, setBackButton] = useState(false)
  const [enabledButtonClicked, setEnabledButtonClicked] = useState(false);
  const [errors, setErrors] = useState(null);

  const currentValue = incident.damageIndication || initialDamage;
  const [value, setValue] = useState(currentValue || null);

  const themeContext = useTheme();

  const location = useLocation()
  const currentPath = location.pathname;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);

  useEffect(() => {
    if (!!questionnaire && questionnaire.length) {
      const progress = questionnaire.find(page => page.path === currentPath).name
      browserStorage.setProgress(progress);
    }
  }, [currentPath, questionnaire])

  useEffect(() => {
    async function checkValue() {
      try {
        await validationSchema.validate(value);
        setErrors(null);
      } catch ({ errors }) {
        setErrors(errors);
      }
    }

    checkValue();
  }, [value]);

  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath

  async function handleOnClick() {
    setButtonClicked(true);
    if (!errors) {
      if (!deepEqualityLVLOne(currentValue, value)) {
        await saveIncident({ damageIndication: value }, isPageBeforeSuccess);
      }
      setEnabledButtonClicked(true);
    }
  }

  const pagePath = getPrevNextPagePath(
    questionnaire,
    incident,
    currentPath,
    files
  );

  useEffect(() => {
    if (enabledButtonClicked) {
      const { damageIndication } = incident;
      if (deepEqualityLVLOne(damageIndication, value) && !backButton) {
        goToNextPage(navigate, questionnaire, incident, currentPath, files);
      }
      if (deepEqualityLVLOne(damageIndication, value) && backButton) {
        navigate(pagePath.prevPage);
      }
    }
  }, [enabledButtonClicked, files, incident, value, questionnaire, currentPath, backButton, pagePath.prevPage]);

  useEnterKey(handleOnClick, [errors]);

  function renderError(error) {
    return <StyledValidationFeedback key={error}>{error}</StyledValidationFeedback>;
  }

  if (!insurer || !questionnaire) {
    return <Loader />;
  }

  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;

  const main = {
    faqVisible: faqVisible,
    dimmed: faqVisible,
    className: 'main__damage main__damage--exterior',
    callBack: clickOutSideFaq,
    $scaling: scaling,
  };

  const iconLink = (triggerKey) => ({
    type: 'arrow-left',
    theme: 'secondary',
    to: pagePath.prevPage,
    onClick: async (event) => {
      setBackButton(true)
      await handleOnClick(event)
    },
    replace: true,
    triggerKey
  });

  const iconLinkForward = (triggerKey) => ({
    type: 'arrow-right',
    theme: 'secondary',
    to: nextPage,
    onClick: handleOnClick,
    replace: true,
    triggerKey
  });

  const iconButton = {
    type: faqVisible ? 'arrow-right' : 'questionmark',
    className: faqSeen ? '' : 'unclicked-faq-button',
    theme: 'secondary',
    onClick: handleOnHelpClick,
    faq: true,
  };


  const button = {
    className: 'button--next__page button--icon',
    type: 'button',
    onClick: handleOnClick,
    justify: 'space-between',
    theme: !errors ? 'primary' : 'disabled',
    shadow: true,
    autoFocus: !isMobile,
  };


  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, false, isMobile, pageName)

  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const useCustomLogo = content["use-custom-logo"]
  const legend = content["over-title-text"]
  const heading = content["title"]
  const text = content["text"]
  const faqs = content["faqs"]

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  const chooseMiddleComponent = useCustomLogo
    ? <CompanyLogo {...middleLogo} />
    : <ProgressionIndicator steps={3} current={2} />

  const DisplayNavBar = () => (
    <NavBar>
      <Container className="icon-link-container">
        <IconLink {...iconLink([37])} />
        {nextPage && <IconLink {...iconLinkForward([39])} />}
      </Container>
      {chooseMiddleComponent}
      <Container className="faq-button-container">
        <IconButton {...iconButton} />
      </Container>
    </NavBar>
  );

  function DesktopPage() {
    return (
      <React.Fragment>
        <FAQ
          faqListProps={faqListProps}
          faqs={faqs}
          faqLink={insurer.helpcenterLink}
          customHelpLink={customHelpLink}
          helpLinkText={helpLinkText}
          faqVisible={faqVisible}
        />
        <StyledMain {...main}>
          <DisplayNavBar />
          <GridContainer main={true}>
            <Container className="grid__container--inner">
              <GridColumn
                justify="space-evenly"
                className="grid__column--main"
              >
                <Legend>{legend}</Legend>
                <Heading level="1">{heading}</Heading>
                <Text>{text}</Text>
              </GridColumn>
              <GridColumn className="grid__column--footer">
                <StyledDamageRegion value={value} setValue={setValue} />
                <Container className="exterior__validation">
                  {errors && buttonClicked && errors.map(renderError)}
                </Container>
              </GridColumn>
              <GridColumn className="grid__column--extra">
                <Button {...button}>
                  Volgende
                  <UseIcon name="arrow-right" className="button__icon" />
                </Button>
                <Text className="of--enter">
                  of <b>ENTER</b>
                </Text>
              </GridColumn>
            </Container>
          </GridContainer>
        </StyledMain>
      </React.Fragment>
    );
  }

  function MobilePage() {
    return (
      <React.Fragment>
        <FAQ
          faqListProps={faqListProps}
          faqs={faqs}
          faqLink={insurer.helpcenterLink}
          customHelpLink={customHelpLink}
          helpLinkText={helpLinkText}
          faqVisible={faqVisible}
        />
        <StyledMain {...main}>
          <DisplayNavBar />
          <GridContainer main={true}>
            <GridColumn

              justify="space-evenly"
              className="grid__column--main"
            >
              <Legend>Schade beschrijven</Legend>
              <Heading level="1">Waar is uw auto beschadigd?</Heading>
              <Text>Selecteer één of meerdere plekken op de afbeelding hiernaast. Klik vervolgens op de “volgende” knop om te bevestigen.</Text>
              <StyledDamageRegion value={value} setValue={setValue} />
              <Container className="exterior__validation">
                {errors && buttonClicked && errors.map(renderError)}
              </Container>
            </GridColumn>
            <GridColumn className="grid__column--footer">
              <Button {...button}>
                Volgende
                <UseIcon name="arrow-right" className="button__icon" />
              </Button>
            </GridColumn>
          </GridContainer>
        </StyledMain>
      </React.Fragment>
    );
  }

  if (isMobile) {
    return MobilePage();
  } else {
    return DesktopPage();
  }
}

export default Exterior;
