import { useScaling } from '@domain/hooks';
import { Colors } from '@domain/theming';
import React from 'react';
import { styled } from '@mui/material/styles';
import Text from '../Text';

const StyledTooltip = styled('div', {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  position: absolute;
  top: -100px;
  left: -24px;
  .tooltip {
    position: relative;
    background: ${Colors.BLACK};
    height: 80px;
    width: 100px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    z-index: 10;
    &:after {
      bottom: -20px;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-top-color: ${Colors.BLACK};
      border-width: 10px;
    }
  }
  .tooltip-button {
    background: transparent;
    color: ${Colors.WHITE};
    border: 0;
    flex: 1;
    width: 100%;
    z-index: 10;
    cursor: pointer;
  }
  .view-button:after {
    position: absolute;
    left: 0;
    content: ' ';
    display: block;
    top: 50%;
    border-bottom: 1px solid ${Colors.GREY_LIGHT};
    width: 100%;
  }

  .screen-desktop & {
    top: -${props => props.$scaling.scaleUpTo4K(100)}px;
    left: -${props => props.$scaling.scaleUpTo4K(24)}px;
    .tooltip {
      height: ${props => props.$scaling.scaleUpTo4K(80)}px;
      width: ${props => props.$scaling.scaleUpTo4K(96)}px;
      border-radius: ${props => props.$scaling.scaleUpTo4K(15)}px;
      &:after {
        bottom: -${props => props.$scaling.scaleUpTo4K(20)}px;
        border-width: ${props => props.$scaling.scaleUpTo4K(10)}px;
      }
    }
    .view-button:after {
      border-bottom-width: ${props => props.$scaling.scaleUpTo4K(1)}px;
    }
  }
`;

function Tooltip({ clickDelete, clickView }) {
  const scaling = useScaling();
  return (
    <StyledTooltip className="tooltip-container" $scaling={scaling}>
      <div className="tooltip">
        {clickView && <button
          className="view-button tooltip-button"
          onClick={event => {
            event.stopPropagation();

            // following if statement stops weird ie bug
            // for some reason, the view button gets clicked immediately with clientX = 0 and clientY != 0
            // don't get it at all, but this fixes it
            if (event.clientX === 0 && event.clientY !== 0) {
              return;
            }

            clickView();
          }}
        >
          <Text className="tooltip__text">Bekijken</Text>
        </button>}
        <button
          className="delete-button tooltip-button"
          onClick={event => {
            event.stopPropagation();
            if (event.clientX === 0 && event.clientY !== 0) {
              return;
            }
            clickDelete();
          }}
        >
          <Text className="tooltip__text">Verwijder</Text>
        </button>
      </div>
    </StyledTooltip>
  );
}

export default Tooltip;
