import React from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl'

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  checkedIcon: `${PREFIX}-checkedIcon`
};

const StyledFormControl = styled(FormControl)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  [`& .${classes.icon}`]: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
  },

  [`& .${classes.checkedIcon}`]: {
    backgroundColor: theme.colors.primary + ' !important',
  }
}));

function StyledRadio(props) {


  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export default function OptionsRadios({ options, value, handleChange }) {


  const generateRadios = options.map((radio) => {
    return (
      <FormControlLabel key={radio.name} value={radio.name} control={<StyledRadio color={"default"} />} label={radio.label} />
    )

  })

  return (
    <StyledFormControl component="fieldset">
      <RadioGroup value={value} onChange={handleChange}>
        {generateRadios}
      </RadioGroup>
    </StyledFormControl>
  );
}