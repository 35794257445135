import React from 'react';
import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Input,
  Container,
  Form,
  Heading,
  ValidationFeedback
} from '@domain/components';

const StyledHeading = styled(Heading, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  .theme-custom & {
    color: ${props => props.theme.colors.primary};
  }
  `

function InputDialog({
  open,
  onYesClick = () => { },
  onNoClick = () => { },
  title,
  content,
  yesLabel = "Ja",
  noLabel = "Nee",
  showYesButton = true,
  showNoButton = true,
  handleChange,
  value,
  form,
  formInput,
  errors,
  buttonClicked,
  confirmationMessage,
  onCloseClick,
}) {



  const contentToShow = () => {
    if (confirmationMessage) {
      return (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
          <DialogActions>
            {showNoButton && (
              <Button onClick={onCloseClick} color="primary" autoFocus>
                <span>doorgaan</span>
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      )
    }
    return (
      <>
        <Form {...form} onSubmit={onYesClick} >
          <Container key={formInput.name} className="form-input">
            <StyledHeading level={'4'}>Vertel ons hoe we u kunnen helpen</StyledHeading>
            <Input {...formInput} />
            <Container className="validation__container">
              {buttonClicked && errors[formInput.name] && (
                <ValidationFeedback type="error">{errors[formInput.name]}</ValidationFeedback>
              )}
            </Container>
          </Container>
          <DialogActions>
            {showYesButton && (
              <Button type="submit" color="primary">
                <span>{yesLabel}</span>
              </Button>
            )}
            {showNoButton && (
              <Button onClick={onNoClick} color="primary" autoFocus>
                <span>{noLabel}</span>
              </Button>
            )}
          </DialogActions>
        </Form>
      </>
    )
  }



  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {contentToShow()}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InputDialog;
