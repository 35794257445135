import { useDispatch, useSelector } from 'react-redux';
import routes from '../../../routes';

import {
  CHECKIN_FLOW_DETECTED,
  CHECKOUT_FLOW_DETECTED,
  IN_CREATION,
  INCIDENT_CREATED,
  FLOW_DETECTED,
  LOGGED_IN,
  NEW_FLOW_DETECTED,
  ONBOARDING_FLOW_DETECTED,
  SP_DETAILS_DETECTED,
  SP_DI_DETAILS_DETECTED,
  TOKEN_FOUND,
  TEMP_INCIDENT_CREATED,
  TEMP_INCIDENT_FOUND_IN_BROWSER_STORAGE,
  SP_FOUND_IN_BROWSER_STORAGE,
  UPDATE_CONSENTCODE
} from '@domain/action-types';
import { useEffect, useRef, useState } from 'react';
import { browserStorage } from '@domain/helpers';
import { carBrandsSchadegarant, flows } from '@domain/constants';
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

const { checkin, checkout, incidentNew, onboarding, signIn, trackTrace, incidentNewCorePlatform } = routes;

const useFlowChecker = (location) => {
  const dispatch = useDispatch();
  const [searchP, setSearchP] = useSearchParams()
  const navigate = useNavigate()
  const flow = useSelector(state => state.flow);
  const incident = useSelector(state => state.incident);
  const inCreation = useSelector(state => state.inCreation);
  const insurer = useSelector(state => state.insurer)
  const spDetailsChecked = useRef(false);
  const tempIncidentFromUrl = useRef(false)
  const { pathname } = location;
  const [incidentAttrFromUrl, setIncidentAttrFromUrl] = useState(null)


  useEffect(() => {
    if (flow) {
      browserStorage.setFlow(flow)
    }
  }, [flow]);

  useEffect(() => {
    if (pathname.includes(incidentNew) || pathname.includes(incidentNewCorePlatform)) {
      if (((flow && flow !== flows.NEW) || !flow) && !inCreation) {
        const localStorageList = Object.keys(localStorage);
        const sessionStorageList = Object.keys(sessionStorage);
        browserStorage.clearLocalStorage([...localStorageList], ['consentCode', 'bannerOpen']);
        browserStorage.clearSessionStorage([...sessionStorageList])
        dispatch({ type: IN_CREATION });
        browserStorage.setInCreation(true)
        dispatch({ type: NEW_FLOW_DETECTED });
        dispatch({ type: LOGGED_IN, incident: {} })
      }
      return;
    }

    if (pathname.includes(checkin)) {
      if (flow !== flows.CHECKIN) {
        const localStorageList = Object.keys(localStorage);
        const sessionStorageList = Object.keys(sessionStorage);
        browserStorage.clearLocalStorage([...localStorageList], ['consentCode', 'bannerOpen', 'uuid']);
        browserStorage.clearSessionStorage([...sessionStorageList])
        dispatch({ type: CHECKIN_FLOW_DETECTED });
      }
      if (!!incident && !!incident.status && incident.status !== 'date_planned') {
        dispatch({ type: ONBOARDING_FLOW_DETECTED });
        navigate(routes.onboarding);
        return;
      }
      return;
    }

    if (pathname.includes(checkout)) {
      if (flow !== flows.CHECKOUT) {
        const localStorageList = Object.keys(localStorage);
        const sessionStorageList = Object.keys(sessionStorage);
        browserStorage.clearLocalStorage([...localStorageList], ['consentCode', 'bannerOpen', 'uuid']);
        browserStorage.clearSessionStorage([...sessionStorageList])
        dispatch({ type: CHECKOUT_FLOW_DETECTED });
      }
      if (!!incident && !!incident.status && incident.status !== 'outtake_scheduled') {
        dispatch({ type: ONBOARDING_FLOW_DETECTED });
        navigate(routes.onboarding);
        return
      }
      return
    }

    if (pathname.includes(trackTrace)) {
      if (flow !== flows.ONBOARDING) {
        dispatch({ type: ONBOARDING_FLOW_DETECTED });
      }
      return;
    }

    if (pathname.includes(onboarding)) {
      if (!!incident) {
        if (flow !== flows.ONBOARDING) {
          dispatch({ type: ONBOARDING_FLOW_DETECTED });
        }
        return;
      }
    }

    if (pathname.includes(signIn) && flow === null) {
      if (flow !== flows.ONBOARDING) {
        dispatch({ type: ONBOARDING_FLOW_DETECTED });
      }
      return;
    }

    const storedFlow = browserStorage.getFlow()
    const storedInCreation = browserStorage.getInCreation()

    if (storedInCreation && storedInCreation !== 'false' && !inCreation) {
      dispatch({ type: IN_CREATION });
      dispatch({ type: LOGGED_IN });
      browserStorage.setInCreation(true)
      const incidentNew = browserStorage.pickValuesFromLocalStorage(
        'debiteurNummer',
        'damageDate',
        'damageType',
        'insuranceInformationID',
        'licensePlate',
        'bodyshopNetwork',
        'customerGender',
        'customerFirstName',
        'customerLastName',
        'customerZipCode',
        'customerHouseNumber',
        'customerHouseNumberAddition',
        'customerStreet',
        'customerCity',
        'customerEmail',
        'customerMobileNumber',
        'errorCode',
        'carMake',
        'radius',
        'vehicleIdentificationID',
        'vehicleModelName',
        'vehicleManufacturerName',
        'cCSInformationUUID',
        'redirect',
        'relatieNummer',
        'spCase',
        'url',
        'internalExternal'
      );
      dispatch({ type: TEMP_INCIDENT_FOUND_IN_BROWSER_STORAGE, incident: incidentNew });
      if (incidentNew.spCase === 'true') {
        dispatch({ type: SP_FOUND_IN_BROWSER_STORAGE })
      }
    }
    if (storedFlow === null && flow === null) {
      dispatch({
        type: ONBOARDING_FLOW_DETECTED,
      })
      return;

    } else if (storedFlow !== flow) {
      dispatch({
        type: FLOW_DETECTED,
        flow: storedFlow,
      });
    }
  }, [dispatch, flow, incident, pathname, insurer, inCreation, navigate]);

  useEffect(() => {

    const url = Object.fromEntries(searchP.entries())
    const makeCamelCase = text => !text ? '' : text[0].toLowerCase() + text.slice(1)
    const retrieveAttrFromSearch = fields => Object.entries(url).reduce((acc, [key, value]) => {
      if (key === 'SPCase') {
        return { ...acc, spCase: decodeURIComponent(value) }
      }
      const attrKey = ['uuid', 'fid', 'pid', 'rid', 'url'].includes(key.toLowerCase())
        ? key.toLowerCase()
        : makeCamelCase(key)

      if (!value || !fields.includes(attrKey)) {
        return acc;
      }

      return { ...acc, [attrKey]: decodeURIComponent(value) };
    }, {})


    let incidentFromUrl = retrieveAttrFromSearch([
      'debiteurNummer',
      'damageDate',
      'damageType',
      'insuranceInformationID',
      'licensePlate',
      'bodyshopNetwork',
      'customerGender',
      'customerFirstName',
      'customerLastName',
      'customerZipCode',
      'customerHouseNumber',
      'customerHouseNumberAddition',
      'customerStreet',
      'customerCity',
      'customerEmail',
      'customerMobileNumber',
      'errorCode',
      'carMake',
      'radius',
      'vehicleIdentificationID',
      'vehicleModelName',
      'vehicleManufacturerName',
      'cCSInformationUUID',
      'redirect',
      'relatieNummer',
      'tempSaved',
      'spCase',
      'url',
      'internalExternal'
    ]);

    if ((Object.entries(incidentFromUrl).length || ('PID' in url)) && !incidentAttrFromUrl) {

      if (incidentFromUrl.carMake) {
        const validSGCarmakes = carBrandsSchadegarant.map(brand => brand.replace(/\s+/g, '_').replace("-", '_').replace("\u00eb", "e"))
        const currentCarMake = incidentFromUrl.carMake.replace(/\s+/g, '_').replace("-", '_').replace("\u00eb", "e").toLowerCase()
        const validEncodedCarMake = validSGCarmakes.findIndex(carBrand => carBrand.toLowerCase() === currentCarMake)
        if (validEncodedCarMake >= 0) {
          incidentFromUrl.carMake = carBrandsSchadegarant[validEncodedCarMake]
        }
        if (validEncodedCarMake === -1) {
          const { carMake, ...incidentFromURLNoCarMake } = incidentFromUrl
          incidentFromUrl = incidentFromURLNoCarMake
        }
      }
      setIncidentAttrFromUrl(incidentFromUrl)
    }

    if (flow === flows.ONBOARDING) {
      if (!!url.Token) {
        const urlToken = decodeURIComponent(url.Token);
        dispatch({
          type: TOKEN_FOUND,
          token: urlToken,
        });
        browserStorage.setAuthenticationToken(urlToken)
      }

    }

    if (spDetailsChecked.current || tempIncidentFromUrl.current) {
      return;
    }


    const spCase = url.SPCase
      ? JSON.parse(decodeURIComponent(url.SPCase))
      : false;

    if (!spCase) {
      if (incidentAttrFromUrl) {
        const { consentCode } = retrieveAttrFromSearch(['consentCode']);
        const consentCodeUrl = consentCode ? JSON.parse(consentCode) : null;
        browserStorage.setConsentCode(consentCodeUrl)
        if (consentCodeUrl) {
          dispatch({ type: UPDATE_CONSENTCODE, consentCode: consentCodeUrl })
        }

        if (inCreation) {
          dispatch({ type: TEMP_INCIDENT_CREATED, incident: { ...incidentAttrFromUrl } })
          tempIncidentFromUrl.current = true
          browserStorage.saveValuesInLocalStorage({ ...incidentAttrFromUrl })
          return
        }
        dispatch({ type: INCIDENT_CREATED, incident: { ...incidentAttrFromUrl } })
        tempIncidentFromUrl.current = true
        return
      }
    } else {
      const { token, consentCode } = retrieveAttrFromSearch(['token', 'consentCode']);
      const consentCodeUrl = consentCode ? JSON.parse(consentCode) : null;
      browserStorage.setConsentCode(consentCodeUrl)
      if (consentCodeUrl) {
        dispatch({ type: UPDATE_CONSENTCODE, consentCode: consentCodeUrl })
      }
      if (token === 'undefined' || token === '' || !token) {
        dispatch({
          type: SP_DETAILS_DETECTED,
          incident: {
            ...incidentFromUrl
          }
        });
        spDetailsChecked.current = true;
        browserStorage.saveValuesInLocalStorage({ ...incidentFromUrl })
      } else {
        dispatch({
          type: SP_DI_DETAILS_DETECTED,
          incident: {
            ...incidentFromUrl,
            token
          }
        });
        spDetailsChecked.current = true;
      }
    }
    if (inCreation) {
      dispatch({ type: LOGGED_IN, incidentFromUrl });
    }
  }, [dispatch, flow, inCreation, incident, location.search, pathname, incidentAttrFromUrl]);
};

export default useFlowChecker;
