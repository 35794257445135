import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';

import {
  BackgroundVideo,
  CompanyLogo,
  Container,
  FAQ,
  HeaderGroup,
  Heading,
  IconButton,
  Loader,
  Main,
  NavBar,
  Rating,
  Text,
  TimeLineBlock,
  Section,
  UseIcon,
  Button,
  Paragraph
} from '@domain/components';
import { companyNames, proccessType } from '@domain/constants';
import { useFaq, useSaveIncidentFactory, useScaling } from '@domain/hooks';
import { isMobile } from 'react-device-detect';
import { browserStorage, checkIsFeedback, chooseLogoSize, chooseLogoToBeDisplayed, generatePageContent } from '@domain/helpers';
import { Colors } from '@domain/theming';
import { detectContentType } from '@domain/content';
import { useQuestionnaire } from '@domain/providers';
import { useLocation } from 'react-router-dom';

const StyledMain = styled(Main, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  // content that came from both ./end.scss
  // and ../../../shared/pages/TimelinePage/timeline-page.scss
  // (and one the .navbar stuff from navbar.scss)
  .navbar {
    .company-logo--left {
      max-width: 120px;
    }
    .left-side-logo {
      flex: 1 0 0px;
    }
  }
  .page-wizard__content {
    display: flex;
    flex-direction: column;
    padding: 32px 32px 0;
  }
  .page-wizard__heading {
    flex: 1 1 auto;
    .h1 {
      margin: 30px 0 12px;
      word-break: break-word;
    }
  }
  .page-wizard__info {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    .h4 {
    }
    .button {
      margin-top: 15px;
      width: 100%;
      &__contact {
        height: auto;
        text-align: left;
        padding: 24px;
        .header-group {
          margin-right: auto;
        }
        span {
          text-transform: initial;
          font-weight: normal;
        }
        .h5 {
          margin-bottom: 15px;
        }
      }
    }
  }
  .page-wizard__timeline {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 50px 0;
  }
  .page-wizard__button {
    padding: 32px;
    justify-content: stretch;
    button {
      width: 100%;
    }
  }
  
  .recovery__heading {
    .screen-mobile & {
      padding-bottom: 42px;
    }
    .h4 {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .recovery__info--button {
    display:flex;
    flex-direction: column;
    margin-top: 30px;
    .h4 {
      margin-top: 15px;
    }
    .button {
      margin-top: 15px;
      width: 100%;
      &__contact {
        height: auto;
        text-align: left;
        padding: 24px;
        .button-wrapper {
          justify-content: space-between;
        }
        .header-group {
          margin-right: auto;
        }
        span {
          text-transform: initial;
          font-weight: normal;
        }
        .h5 {
          margin-bottom: 15px;
        }
      }
    }
  }
  .recovery__bodyshop-name {
    .screen-mobile & {
      margin-top: 12px;
    }
  }
  .recovery__bodyshop-description {
    margin-bottom: 60px;
  }
  .recovery__timeline {
    .timeline__item {
      // font-weight: normal;
      // padding-bottom: 5.6vh;
      &:before {
        background-color: ${Colors.WHITE};
        opacity: 1;
      }
      &:last-child {
        padding-bottom: 20px;
      }
    }
    .timeline__item--muted {
      color: ${Colors.WHITE};
      opacity: .6;
      &:before {
        background-color: ${Colors.WHITE};
      }
      .timeline__icon {
        background: ${Colors.WHITE};
        color: ${Colors.GREY_DARKER};
      }
    }
    .contact-person-picture {
      width: ${props => props.$scaling.scaleUpTo4K(32)}px;
      height: ${props => props.$scaling.scaleUpTo4K(32)}px;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 8px;
      object-fit: cover;
      object-position: 0 10%;
    }
  }


  .screen-desktop & {
    .page-wizard__content {
      flex-direction: row;
      padding: ${props => props.$scaling.scaleUpTo4K(22)}px ${props => props.$scaling.scaleUpTo4K(40)}px 0;
      align-items: center;
    }
    .page-wizard__heading {
      padding-left: ${props => props.$scaling.scaleUpTo4K(80)}px;
      .h1 {
        margin-bottom: 0;
      }
    }
    .page-wizard__text {
      display: block;
      margin-top: ${props => props.$scaling.scaleUpTo4K(20)}px;
    }
    .page-wizard__info {
      flex: 1 1 auto;
      width: 50%;
      height: 50%;
      justify-content: flex-start;
    }
    .page-wizard__timeline {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      padding: 0;
      padding-right: ${props => props.$scaling.scaleUpTo4K(120)}px;
      padding-left: ${props => props.$scaling.scaleUpTo4K(40)}px;
      .timeline {
        // margin-top: auto;
        // padding-top: ${props => props.$scaling.scaleUpTo4K(50)}px;
      }
      .timeline--heading {
        width: 100%;
        max-width: ${props => props.$scaling.scaleUpTo4K(350)}px;
        display: block;
      }
    }
    .page-wizard__button {
      padding: ${props => props.$scaling.scaleUpTo4K(40)}px;
      padding-top: 0;
      button {
        margin-left: auto;
      }
    }
    
    .recovery__content {
      padding-bottom: ${props => props.$scaling.scaleUpTo4K(82)}px;
    }
    .recovery__bodyshop-name {
      margin: ${props => props.$scaling.scaleUpTo4K(15)}px 0 ${props => props.$scaling.scaleUpTo4K(20)}px 0;
    }
    .recovery__bodyshop-description {
      margin-top: ${props => props.$scaling.scaleUpTo4K(20)}px;
      margin-bottom: ${props => props.$scaling.scaleUpTo4K(40)}px;
      .h4 {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(2)}px;
      }
    }
    .recovery__info-heading {
      margin-top: ${props => props.$scaling.scaleUpTo4K(20)}px;
    }
    .recovery__info--button {
      flex: 1 1 auto;
      width: 50%;
      height: 50%;
      justify-content: flex-start;
      > div {
        max-width: ${props => props.$scaling.scaleUpTo4K(350)}px;
      }
      .button {
        &__contact {
          padding: ${props => props.$scaling.scaleUpTo4K(24)}px;
          .header-group {
          }
          .h5 {
            margin-bottom: ${props => props.$scaling.scaleUpTo4K(15)}px;
          }
        }
      }
    }
    .page-wizard__heading {
      flex: 2 0 0px;
      display: flex;
      flex-direction:column;
      justify-content: center;
    }
    .recovery__timeline {
      flex: 1 0 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .timeline {
      }
      .h4 {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(10)}px;
        width: 100%;
        max-width: ${props => props.$scaling.scaleUpTo4K(350)}px;
      }
    }
  }
  
  .theme-custom & {
    .recovery__heading {
      .header-group {
       .recovery__info-heading {
          a {
            color: ${props => props.theme.isCustomThemed ? Colors.BLACK : Colors.WHITE}
          }
      }
      color:  ${props => props.theme.isCustomThemed ? Colors.BLACK : Colors.WHITE}
          a {
            color: ${props => props.theme.isCustomThemed ? Colors.BLACK : Colors.WHITE}
          }
      }
      .h4 {
        color: ${props => props.theme.isCustomThemed ? props.theme.colors.primary : Colors.WHITE};
      }
    }
    .recovery__timeline {
      .timeline-checkmark {
        background: ${props => props.theme.isCustomThemed ? Colors.GREEN : props.theme.colors.primary};
      }
      .timeline__item {
        color: ${Colors.WHITE};
        &:before {
          background:${props => props.theme.isCustomThemed ? props.theme.colors.primary : Colors.WHITE};
        }
        .timeline__icon {
          color: ${Colors.WHITE};
          background: ${props => props.theme.colors.primary};
        }
      }
      .h4 {
        color: ${props => props.theme.isCustomThemed ? props.theme.colors.primary : Colors.WHITE};
      }
      .timeline__text {
        color:  ${props => props.theme.isCustomThemed ? props.theme.colors.primary : Colors.WHITE}
      }
    }
  }

  .screen-ie & {
    .button__contact {
      max-height: none;
    }
  }


  @media screen and (max-width: 1263px) {
    .screen-desktop & {
      .page-wizard__heading {
        padding-left: 20px;
      }
      .page-wizard__timeline {
        padding-right: 40px;
      }
    }
  }

  @media screen and (max-width: 1028px) {
    .rating {
      margin-top: 30px;
    }
    .screen-desktop & {
      .page-wizard__content {
        flex-direction: column;
        justify-content: flex-start;
      }
      .page-wizard__heading {
        text-align: center;
        padding: 0;
      }
      .page-wizard__info {
        width: 100%;
        .button {
          max-width: 580px;
        }
      }
      .page-wizard__button {
        padding-top: 50px;
      }
      .page-wizard__text {
        width: auto;
        margin: auto;
      }
      .page-wizard__timeline {
        padding: 50px 0;
        .timeline {
        }
      }
      
      .recovery__bodyshop-name {
        margin: 15px auto 20px;
      }
      .recovery__info--button {
        width: 100%;
        text-align: center;
        >div {
          max-width: none;
        }
        .button {
          max-width: 580px;
          margin: 15px auto 0;
          &__contact {
            .header-group {
            }
          }
        }
      }
      .recovery__bodyshop-description {
        margin: 20px auto 0;
      }
    }
  }

  @media screen and (max-width: 580px) {
    .screen-desktop & {
      .page-wizard__button {
        button {
          width: 100%;
        }
      }
    }
  }

  .screen-ie & {
    .page-wizard__content {
      flex: 1 0 auto;
      height: auto;
      min-height: 454px;
    }
    .page-wizard__info {
      .button__contact {
        max-height: none;
      }
    }
    .page-wizard__timeline {
    }
    .page-wizard__button {
      flex: 0 0 auto;
      button {
        // margin-top: 40%;
      }
    }
    
    .recovery__timeline-and-button {
      display: block;
    }
  
    .recovery__timeline-and-button {
      .timeline {
        margin-top: 33%;
      }
    }
  }
`;

function Success() {

  const scaling = useScaling();
  const hasUpdated = useRef(false);
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer)
  const bodyshopFromStore = useSelector(state => state.bodyshop);
  const spCase = useSelector(state => state.spCase)

  const { questionnaire } = useQuestionnaire();

  const isFeedback = checkIsFeedback(incident)
  const isVIP = !!insurer && insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand)
  const isDI = !!insurer && insurer.objectType === proccessType.DI
  const isThemed = !!insurer && insurer.brand !== companyNames.VIP && insurer.objectType !== proccessType.DI;
  const themeContext = useTheme();
  const checkForOutlet = () => {
    const bs = incident && incident.outletUUID && bodyshopFromStore && bodyshopFromStore.outlets ? bodyshopFromStore.outlets.find(outlet => outlet.uuid === incident.outletUUID) : {}
    if (!!bs) {
      return { ...bodyshopFromStore, ...bs }
    }
    return bodyshopFromStore
  }


  const finalBodyshop = checkForOutlet()

  const bodyshop = isDI
    ? {
      companyName: insurer.brandName,
      contactPersons: [],
      info: '',
    }
    : finalBodyshop

  const bodyshopName = bodyshop ? bodyshop.name || bodyshop.companyName : '';
  const bodyshopFound = !!bodyshopName;
  const steeringNetworkKnown = !!incident.bodyshopNetwork;

  const [
    faqVisible,
    handleOnHelpClick,
    clickOutSideFaq,
    faqListProps,
    faqSeen,
  ] = useFaq();

  const location = useLocation()
  const currentPath = location.pathname;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior, 'success');

  useEffect(() => {

    const storageList = Object.keys(localStorage);
    browserStorage.clearLocalStorage([...storageList], ['consentCode', 'bannerOpen', 'uuid']);

    if (isFeedback) {
      if (hasUpdated.current) {
        return;
      }
      hasUpdated.current = true;
    } else if ((incident && incident.status === 'process_aborted' && incident.cancelReason === 'VIP_not_chosen') ||
      (incident && incident.status === 'damage_cancelled' && incident.cancelReason === 'inactivity_from_the_customer')) {
      hasUpdated.current = true;
      const progressStr = 'success'
      saveIncident({ ...incident, portalStatus: 'Done', progressStr });
    } else {
      if (incident && incident.portalStatus !== 'Done') {
        if (hasUpdated.current || (incident.status && incident.status !== '_new')) {
          return;
        }
        hasUpdated.current = true;
        const progressStr = 'success'
        saveIncident({ ...incident, portalStatus: 'Done', progressStr });
      }
    }
  }, [incident, isFeedback, questionnaire, saveIncident, steeringNetworkKnown]);


  const faqs = useMemo(
    () => [
      {
        question: 'Waarom is dit het gekozen herstelbedrijf voor mijn schade?',
        answer: 'Dit is in uw regio het herstelbedrijf dat is aangesloten bij ons netwerk van zeer hoog gekwalificeerde herstellers. Wij werken alleen met professionele bedrijven die uw auto bij u op komen halen als u dat wilt.',
      },
      {
        question: 'Waarom is er geen hersteller dichterbij huis beschikbaar?',
        answer: 'De VIP Schadeservice werkt met een landelijk netwerk van zeer professionele herstelbedrijven. Omdat wij altijd de haal en breng service aanbieden kan het zijn dat het herstelbedrijf wat verder weg is dan u wellicht gewend bent. Dit verandert echter niets aan de kwaliteit van het herstel. ',
      },
      {
        question: 'Kan ik zelf een hersteller kiezen?',
        answer: 'Binnen de VIP Schadeservice wordt u automatisch gekoppeld aan de meest geschikte schade voor uw auto. Dit wordt gedaan op basis van uw schade, model auto en beschikbaarheid.',
      },
    ], []);

  if (!questionnaire || !questionnaire.length || !insurer || !incident || (!!incident && (!!incident.bodyshopID || !!incident.carRefinisherUUID || !!incident.outletUUID) && !bodyshopFound)) {
    return <Loader />;
  }

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, false, isMobile, pageName, bodyshop)

  const title = content["title"]
  const showFAQ = content["show-faq"]
  const showLeftLogo = content["show-left-logo"]
  const companyMiddleLogo = content["company-middle-logo"]
  const showRating = content["show-rating"]
  const showInfoBlock = content["show-info-block"]
  const infoBlockTitle = content["info-block-title"]
  const infoBlockText = content["info-block-text"]
  const text = content["text"]
  const secondText = content["second-text"]
  const thirdText = content["third-text"]
  const feedbackText = content["feedback-text"]
  const endText = content["end-text"]
  const showBodyshopBlock = content["show-bodyshop-block"]
  const bodyshopBlockTitle = content["bodyshop-block-title"]
  const showTimeline = content["show-timeline"]
  const timelineTitle = content["timeline-title"]
  const timelineShowCheck = content["timeline-show-check"]
  const timeline = content["timeline"]
  const feedbackTimeline = content["feedback-timeline"]
  const failedTimeline = content["failed-timeline"]
  const spcaseFailedTimeline = content["spcase-failed-timeline"]
  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const showBackGround = content["show-background"]
  const useCustomLogo = content["use-custom-logo"]
  const secondTextNoTT = content["second-text-no-track-trace"]

  const customLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }
  const companyLogo = {
    align: companyMiddleLogo ? 'center' : 'left',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
  };

  const middleLogo = {
    ...chooseLogoToBeDisplayed(insurer),
    className: isVIP ? 'vip-logo' : '',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  };

  const chooseLogo = useCustomLogo
    ? <CompanyLogo {...customLogo} />
    : companyMiddleLogo
      ? <CompanyLogo {...companyLogo} />
      : <CompanyLogo {...middleLogo} />

  const main = {
    faqVisible: faqVisible,
    dimmed: faqVisible,
    className: 'main__end',
    callBack: clickOutSideFaq,
    $scaling: scaling,
  };

  const handleOnButtonClick = () => {
    const protocol = bodyshopFromStore.website ? bodyshopFromStore.website.startsWith('http') : null
    if (protocol) {
      window.open(bodyshopFromStore.website, '_blank');
    }
    window.open(`http://${bodyshopFromStore.website}`, '_blank');
  };

  const button = {
    onClick: handleOnButtonClick,
    className: 'button__contact',
    theme: 'secondary',
    justify: 'space-between',
    disabled: !bodyshopFromStore || (bodyshopFromStore && !bodyshopFromStore.website)
  };

  const iconButton = {
    type: faqVisible ? 'arrow-right' : 'questionmark',
    className: faqSeen ? '' : 'unclicked-faq-button',
    theme: 'secondary',
    onClick: handleOnHelpClick,
    faq: true,
  };

  function chooseTimeline() {
    if (isFeedback) {
      return feedbackTimeline
    }
    if (isVIP && !bodyshopFound && !steeringNetworkKnown && spCase) {
      return spcaseFailedTimeline
    }
    if (!bodyshopFound) {
      return failedTimeline
    }
    else {
      return timeline
    }
  }

  const hasTrackTrace = questionnaire.find(page => page.name === "track_trace")
  const finalTimeline = chooseTimeline()

  const activeItem = finalTimeline ? finalTimeline.findIndex(item => item.active) : null
  const timelineIcon = (item, i) => ({
    name: item.icon,
    className: 'timeline__item__icon',
    timeline: true,
    muted: i > activeItem
  });

  return (
    <React.Fragment>
      {(bodyshopFound && showFAQ) &&
        <FAQ
          faqListProps={faqListProps}
          faqs={faqs}
          faqLink={insurer.helpcenterLink}
          customHelpLink={customHelpLink}
          helpLinkText={helpLinkText}
          faqVisible={faqVisible}
        />
      }
      <StyledMain {...main}>
        <NavBar className="navbar-end" isVIP={isVIP}>
          {showLeftLogo ? <Container className="left-side-logo"><CompanyLogo {...companyLogo} /></Container> : <Container className="empty-div" />}
          {chooseLogo}
          {bodyshopFound && showFAQ ? <Container className="faq-button-container"><IconButton {...iconButton} /></Container> : <Container className="empty-div" />}
        </NavBar>
        <Section className="page-wizard__content recovery__content">
          <Container className="page-wizard__heading recovery__heading">
            <HeaderGroup>
              {bodyshopFound &&
                <>
                  {showRating && <Rating />}
                  {title && <Heading level="1" className="recovery__bodyshop-name">{title}</Heading>}
                  {showInfoBlock &&
                    <Container className="recovery__bodyshop-description">
                      {infoBlockTitle && <Heading className="recovery__bodyshop-description--title" level="4">{infoBlockTitle}</Heading>}
                      {infoBlockText && <Text>{infoBlockText}</Text>}
                    </Container>}
                </>
              }
              <Container className="recovery__info-heading">
                <HeaderGroup>
                  {isFeedback && feedbackText && <Text className="recovery__bodyshop-text">{feedbackText}</Text>}
                  {bodyshopFound && text && <Text className="recovery__bodyshop-text">{text}</Text>}
                  {bodyshopFound && secondText && <Paragraph className="recovery__bodyshop-text">{!hasTrackTrace && secondTextNoTT ? secondTextNoTT : secondText}</Paragraph>}
                  {bodyshopFound && thirdText && <Paragraph className="recovery__bodyshop-text">{thirdText}</Paragraph>}
                </HeaderGroup>
                {(showBodyshopBlock && bodyshopFromStore) &&
                  <Container className="recovery__info--button">
                    {bodyshopBlockTitle && <Heading level="4">{bodyshopBlockTitle}</Heading>}
                    <Button {...button}>
                      <HeaderGroup>
                        <Heading level="5">{bodyshopName}</Heading>
                        <Text>{
                          bodyshop.street + ' ' +
                          bodyshop.houseNumber + ','
                        }<br />{
                            bodyshop.zipcode + ' ' +
                            bodyshop.city
                          }</Text>
                      </HeaderGroup>
                      {!button.disabled && <UseIcon name="arrow-right" className="button__icon" />}
                    </Button>
                  </Container>}
                {endText && <Text className="recovery__bodyshop-text"><br />{endText}</Text>}
              </Container>
            </HeaderGroup>
          </Container>
          <Container className="page-wizard__timeline recovery__timeline">
            {(showTimeline && finalTimeline) &&
              <>
                {timelineTitle && <Heading level={'4'} className={"timeline--heading"}>{timelineTitle}</Heading>}
                <TimeLineBlock timeline={finalTimeline} activeItem={activeItem} timelineIcon={timelineIcon} bodyshop={bodyshop} timelineShowCheck={timelineShowCheck} />
              </>}
          </Container>
        </Section>
      </StyledMain>
      {showBackGround && <BackgroundVideo
        type="video/mp4"
        loop={true}
        autoPlay={true}
        videoFileName="DEF2.mp4"
        img={!isVIP || isThemed}
        imgFileName="BSSP03.jpg"
      />}
    </React.Fragment>
  );
}

export default Success;
