// NPM imports.
import React from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

// Domain imports.
import {
  Button,
  CompanyLogo,
  Container,
  GridContainer,
  GridColumn,
  Heading,
  Loader,
  Main,
  NavBar,
  Text,
  UseIcon
} from '@domain/components';
import { companyNames, proccessType } from '@domain/constants'
import { goToNextPage, browserStorage, chooseLogoToBeDisplayed } from '@domain/helpers';
import { useNavigate } from 'react-router-dom'
import questionnaire from '../questionnaire';
import { useLocation } from 'react-router-dom';

const StyledMain = styled(Main)`
.grid-container {
  padding: 0 32px;
}
.checkinout__chauffeur--content {
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  h1 {
    margin-bottom: 5px;
  }
  span {
    font-weight: bold;
    text-transform: uppercase;
  }
}
.checkinout__chauffeur--footer {
  flex: 0 0 auto;
  margin-top: auto;
  padding: 32px;
}
.screen-desktop & {
  button {
    margin-left: auto;
    @media (max-width: 580px) {
      width: 100%;
    }
  }
`;

const ChauffeurIMG = styled('img')`
  max-width: 200px;
  max-height: 200px;
  object-position: center;
  margin-bottom: 40px;
`;

function Chauffeur() {

  const navigate = useNavigate()
  const incident = useSelector(state => state.incident);
  const bodyshop = useSelector(state => state.bodyshop);
  const insurer = useSelector(state => state.insurer);
  const location = useLocation()

  if (!incident || !bodyshop || !insurer) {
    return <Loader />;
  }

  const isVIP = insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand)
  const isThemed = !!insurer && insurer.brand !== companyNames.VIP && insurer.objectType !== proccessType.DI;

  const insurerName = insurer.brandName || 'uw verzekeraar';
  const insurerLogo = insurer.logoURL || '';

  const bodyshopName = bodyshop.companyName;
  const bodyshopLogo = bodyshop.logoEncoded || '';

  const currentPath = location.pathname;

  const progress = questionnaire.find(page => page.path === currentPath).name
  browserStorage.setProgress(progress);

  const onClickButton = () => {
    goToNextPage(navigate, questionnaire, incident, currentPath);
  };

  const companyLogo = {
    align: 'left',
    width: '120',
    height: 32,
    src: insurerLogo,
    alt: insurerName,
  };

  const vipLogo = {
    ...chooseLogoToBeDisplayed(insurer, 'black'),
    className: isVIP ? 'vip-logo' : '',
    align: 'right',
    height: 60,
    // width: 'auto',
  };


  const button = {
    onClick: onClickButton,
    className: 'button--icon button--next__page',
    theme: isThemed ? 'primary' : 'primary',
    justify: 'space-between'
  };

  return (
    <React.Fragment>
      <StyledMain className="main__checkinout--chauffeur">
        <NavBar className="navbar__tablet" isVIP={isVIP}>
          <CompanyLogo {...companyLogo} />
          <Container className="empty-div" />
          {isVIP && <CompanyLogo {...vipLogo} />}
        </NavBar>
        <GridContainer>
          <GridColumn className="checkinout__chauffeur--content">
            <Container className="checkinout__chauffeur--info">
              {!!bodyshopLogo && <ChauffeurIMG src={bodyshopLogo} alt={'logo van ' + bodyshopName} />}
              <Heading level="1">Chauffeur</Heading>
              <Text>van {bodyshopName}</Text>
            </Container>
          </GridColumn>
        </GridContainer>
        <GridContainer className="checkinout__chauffeur--footer">
          <GridColumn>
            <Button {...button}>
              Volgende
              <UseIcon name="arrow-right" className="button__icon" />
            </Button>
          </GridColumn>
        </GridContainer>
      </StyledMain>
    </React.Fragment>
  )
}

export default Chauffeur;
