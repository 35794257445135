import { pascalCaseToCamelCase } from '..';

async function parseFetchResponseToJson(response, reqDetails) {
  if (response.ok && response.status === 200) {
    return response.json().then(pascalCaseToCamelCase);
  } else {
    const stringifiedErrors = await response.text();
    const obj = pascalCaseToCamelCase(JSON.parse(stringifiedErrors));
    throw obj;
  }
}

export default parseFetchResponseToJson;
