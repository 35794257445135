import { companyNames, proccessType } from '@domain/constants'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'

const Consent = () => {

  const consent = useSelector((state) => state.consentCode)
  const insurer = useSelector(state => state.insurer)

  const writeKeyHotjar = process.env.REACT_APP_HOTJAR_API_KEY
  const writeKeyGA = process.env.REACT_APP_GA_API_KEY
  if (insurer) {
    const chooseTitle = () => {
      if (insurer.objectType === companyNames.Schadegarant) {
        return { title: 'eXperience' }
      }
      if (insurer.objectType === proccessType.DI) {
        return { title: 'Digitale intake' }
      } if (insurer.objectType === proccessType.EM || (insurer.objectType === proccessType.Casco && insurer.brand === companyNames.Rhion)) {
        return { title: 'Uw schadebeschrijving' }
      } if (insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand)) {
        return { title: 'VIP Schadeservice' }
      } else {
        return { title: insurer.brandName || '' }
      }
    }

    const title = chooseTitle().title

    if (writeKeyHotjar && writeKeyGA) {
      return (
        <Helmet>
          <script>
            {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('consent', 'default', {
                    'ad_storage': 'denied',
                    'ad_user_data': 'denied',
                    'ad_personalization': 'denied',
                    'analytics_storage': 'denied'
                    });`}
          </script>
          <script
            src={`https://www.googletagmanager.com/gtag/js?id=${writeKeyGA}`}
            async
          ></script>
          <script>
            {`window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                window.gtag = gtag;
                gtag('js', new Date());
                gtag('set', {'cookie_flags': 'Secure'});
                gtag('config', '${writeKeyGA}');`}
          </script>
          {consent && consent.ga && (
            <script>
              {`gtag('consent', 'update', {
                  'ad_user_data': 'granted',
                  'ad_storage': 'granted',
                  'analytics_storage': 'granted'
              })`}
            </script>
          )}
          {(consent && consent.hotjar) && (<script>
            {`
              (function (h, o, t, j, a, r) {
                h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
                h._hjSettings = { hjid: ${writeKeyHotjar}, hjsv: 6 };
                a = o.getElementsByTagName('head')[0];
                r = o.createElement('script'); r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
              })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');`
            }
          </script>)}
        </Helmet>
      )
    }
    if (writeKeyGA) {
      return (
        <Helmet>
          <script>
            {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('consent', 'default', {
                    'ad_storage': 'denied',
                    'ad_user_data': 'denied',
                    'ad_personalization': 'denied',
                    'analytics_storage': 'denied'
                    });`}
          </script>
          <script
            src={`https://www.googletagmanager.com/gtag/js?id=${writeKeyGA}`}
            async
          ></script>
          <script>
            {`window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                window.gtag = gtag;
                gtag('js', new Date());
                gtag('set', {'cookie_flags': 'Secure'});
                gtag('config', '${writeKeyGA}', {'page_title': '${title}'});`}
          </script>
          {consent && consent.ga && (
            <script>
              {`gtag('consent', 'update', {
                  'ad_user_data': 'granted',
                  'ad_storage': 'granted',
                  'analytics_storage': 'granted'
              })`}
            </script>
          )}
        </Helmet>
      )
    }
    if (writeKeyHotjar) {
      return (
        <Helmet>
          {(consent && consent.hotjar) && (<script>
            {`
              (function (h, o, t, j, a, r) {
                h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
                h._hjSettings = { hjid: ${writeKeyHotjar}, hjsv: 6 };
                a = o.getElementsByTagName('head')[0];
                r = o.createElement('script'); r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
              })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');`
            }
          </script>)}
        </Helmet>
      )
    }
    return null
  }
  return null
}
export default Consent