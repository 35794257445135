import React from 'react';
import RadioPage from '@domain/pages/RadioPage';

const payingDamage = 'payingDamage';
function PayingDamage() {

  const fields = [{
    id: 1,
    value: 'insurance',
    triggerKeys: [],
    label: 'De verzekering'
  }, {
    id: 2,
    value: 'driver',
    triggerKeys: [],
    label: 'Ik betaal de schade zelf'
  }
  ]

  return <RadioPage fieldName={payingDamage} styleLabel={'gender'} fields={fields} />
}

export default PayingDamage;
