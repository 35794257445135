import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom';

import { browserStorage } from '@domain/helpers';
import onboardingRoutes from '../../../Onboarding/routes';
import onboardingOwnBodyshopRoutes from '../../../Onboarding/OwnBodyshop/routes';
import routes from '../../../routes';
import { useQuestionnaire } from '@domain/providers';

const pageConfigBodyshopChange = [
  {
    "index": 1,
    "name": "bodyshop_select"
  },
  {
    "index": 2,
    "name": "bodyshop_confirm"
  },
  {
    "index": 3,
    "name": "track_trace"
  }
]

const useSchadegarantBodyshopChange = () => {
  const { onboarding } = routes;
  const { ownbodyshop } = onboardingRoutes;
  const { bodyshopSelect } = onboardingOwnBodyshopRoutes;

  const { updateQuestionnaire } = useQuestionnaire()
  const navigate = useNavigate()
  const schadegarantBodyshopChange = () => {
    // Update questionnaire
    updateQuestionnaire({ configuration: pageConfigBodyshopChange })
    // Set flag to prevent useFlowChecker to switch back to onboarding(TRACKTRACE) flow
    browserStorage.setInBodyshopChange();
    navigate(onboarding + ownbodyshop + bodyshopSelect)
  }

  return schadegarantBodyshopChange;
}

export default useSchadegarantBodyshopChange;
