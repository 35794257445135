import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import Upload from './Upload';
import routes from './routes';

const { upload } = routes;

function DamageImages() {
  return (
    <Routes>
      <Route path={upload} element={<Upload />} />
      <Route path='*' element={<Navigate to={upload} />} />
    </Routes>
  );
}

export default DamageImages;
