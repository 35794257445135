// NPM imports.
import React from 'react';
import { useSelector } from 'react-redux';

import { Loader } from '@domain/components';
import { companyNames, proccessType } from '@domain/constants';
import { useCheckoutQuestionnaire } from '@domain/hooks';
import { InstructionPage } from '@domain/pages';
import { useLocation } from 'react-router-dom';

function Instructions() {


  const incident = useSelector(state => state.incident);
  const bodyshop = useSelector(state => state.bodyshop);
  const insurer = useSelector(state => state.insurer)

  const questionnaire = useCheckoutQuestionnaire()

  if (!incident || !bodyshop || !insurer) {
    return <Loader />;
  }

  const isVIP = insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand)

  const location = useLocation()
  const currentPath = location.pathname;
  const { additionalOuttakeComment, checkOutAddress, customerFirstName, customerLastName, vehicleManufacurerName, vehicleModelName } = incident;

  const progress = questionnaire.find(page => page.path === currentPath).name


  const checklist = {
    driverDocumentToSignCheckOut: 'Documenten ter ondertekening',
    driverJacketCheckOut: isVIP ? 'VIP Schadeservice jas' : 'Schadeservice jas',
    driverSeatCoverCheckOut: isVIP ? 'VIP Stoelhoes' : 'Stoelhoes',
  }

  const infosContent = isVIP
    ? `Haal de ${!vehicleManufacurerName && !vehicleModelName ? 'auto' : vehicleManufacurerName + ' ' + vehicleModelName} bij de klant op bovenstaand adres.
  Vergeet niet samen de Check-out door te lopen, de VIP jas te dragen en de VIP stoelhoes om de berijderstoel te doen.`
    : `Haal de ${!vehicleManufacurerName && !vehicleModelName ? 'auto' : vehicleManufacurerName + ' ' + vehicleModelName} bij de klant op bovenstaand adres.
  Vergeet niet samen de Check-out door te lopen.`;


  const notesContent = additionalOuttakeComment || '';

  return (
    <InstructionPage
      appointmentAdress={checkOutAddress || {}}
      checklist={checklist}
      customerFirstName={customerFirstName}
      customerLastName={customerLastName}
      currentPath={currentPath}
      infosContent={infosContent}
      initProgress={progress}
      notesContent={notesContent}
      questionnaire={questionnaire}
      save={true}
      vehicleManufacurerName={!!vehicleManufacurerName ? vehicleManufacurerName : ''}
      vehicleModelName={!!vehicleModelName ? vehicleModelName : ''}
    />
  )
}

export default Instructions;
