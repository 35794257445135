import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { styled } from '@mui/material/styles'

import { Tooltip } from '../..'
import { Colors } from '@domain/theming'
import { isMobile } from 'react-device-detect'
import { useScaling } from '@domain/hooks'
import Loader from '../../Loader'
import { Stack } from '@mui/material'

const StyledLi = styled('li', {
  shouldForwardProp: (propName) => !propName.startsWith('$'),
})`
  position: relative;
  z-index: 4;
  .theme-custom & {
    .image-checkmark {
      background: ${(props) => props.theme.colors.primary};
    }
  }
  &.image__viewer--dulled {
    filter: brightness(0.3);
  }
  &.image__viewer--noimage {
    .image-container {
      background-image: url(${(props) => props.$borderThumbNail});
      background-size: contain;
      background-repeat: no-repeat;
      background-clip: content-box;
    }
  }
  &.image__viewer--is-dragging {
    z-index: 1;
  }
  .image-checkmark {
    background: ${Colors.BLACK};
    color: ${Colors.WHITE};
    border: 1px solid ${Colors.WHITE};
    border-radius: 50%;
    position: absolute;
    top: -10px;
    right: -10px;
    .screen-ie & {
      border: none;
    }
  }
`

const StyledImg = styled('img', {
  shouldForwardProp: (propName) => !propName.startsWith('$'),
})`
  // height: ${(props) => props.$heightSize}px;
  // width: ${(props) => props.$widthSize}px;
  border-radius: 5px;
  object-fit: cover;
  border: 1px solid ${Colors.BLACK};
  &.image__viewer__img--extra {
    filter: brightness(0);
  }
  &.image__viewer__img--highlighted:not(.extra__images__number) {
    z-index: 2;
  }
  .screen-desktop & {
    height: ${(props) => props.$scaling.scaleUpTo4K(props.$heightSize)}px;
    width: ${(props) => props.$scaling.scaleUpTo4K(props.$widthSize)}px;
  }
  @media screen and (max-width: 580px) {
    height: ${(props) => props.$heightSizeSmall}px;
    width: ${(props) => props.$widthSizeSmall}px;
  }
`

const StyledImageContainer = styled('label', {
  shouldForwardProp: (propName) => !propName.startsWith('$'),
})`
  position: relative;
  height: ${(props) => props.$heightSize}px;
  width: ${(props) => props.$widthSize}px;
  background: transparent;
  border-radius: 8px;
  display: block;
  cursor: ${(props) => (props.$hasOnClick ? 'pointer' : 'default')};
  &.image-container--loading {
    align-items: center;
    cursor: wait;
  }
  .extra__images__number {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${Colors.WHITE};
    font-size: ${(props) =>
    !isMobile
      ? props.$scaling.textSize(12)
      : props.$scaling.textSizeMobile(16)}px;
    line-height: ${(props) =>
    !isMobile
      ? props.$scaling.textSize(20)
      : props.$scaling.textSizeMobile(20)}px;
    width: 100%;
    height: 100%;
  }
  .imageviewer__loader {
    transform: scale(0.75) translate(-7px, -10px);
  }
  .image-loading-circle__outer--black {
    background-color: ${Colors.BLACK};

    .image-loading-circle__inner--white:before {
      background-color: ${Colors.WHITE};
    }
  }
  .screen-desktop & {
    width: ${(props) => props.$scaling.scaleUpTo4K(props.$widthSize)}px;
    height: ${(props) => props.$scaling.scaleUpTo4K(props.$heightSize)}px;
  }
  @media screen and (max-width: 360px) {
    height: ${(props) => props.$heightSizeSmall}px;
    width: ${(props) => props.$widthSizeSmall}px;
  }
`

// TODO: Improve alt text
function ImageViewerGuided({
  src,
  extraImages = 0,
  onClick,
  selected,
  clickDelete,
  clickView,
  dulled,
  loading,
  borderThumbNail = '/img/svg/thumbnail-border.svg',
  htmlFor,
  widthSize = 48,
  widthSizeSmall = 48,
  heightSize = 40,
  heightSizeSmall = 40,
  inputField = null,
  isDragging,
}) {
  const scaling = useScaling()
  const imageViewerClassNames = classNames(
    'image__viewer',
    {
      'image__viewer--image': src,
      'image__viewer--noimage': !src || loading,
    },
    { 'image__viewer--selected': selected },
    { 'image__viewer--dulled': dulled && !selected },
    {
      'image__viewer--is-dragging': isDragging,
    }
  )

  const imageClassNames = classNames(
    'image__viewer__img',
    {
      'image__viewer__img--extra': extraImages > 0,
    },
    {
      'image__viewer__img--highlighted': selected,
    }
  )

  const imageContainerClassNames = classNames('image-container', {
    'image-container--loading': loading,
  })

  const labelContents = () => {
    if (loading) {
      if (extraImages) {
        return (
          <Stack
            height={'100%'}
            width={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Loader size="small" color="white" />
          </Stack>
        )
      }

      return (
        <Stack
          height={'100%'}
          width={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Loader size="small" />
        </Stack>
      )
    }

    if (src) {
      return (
        <div className="">
          <StyledImg
            $widthSize={widthSize}
            $widthSizeSmall={widthSizeSmall}
            $heightSize={heightSize}
            $heightSizeSmall={heightSizeSmall}
            {...(onClick
              ? {
                onClick: (event) => {
                  event.stopPropagation()
                  onClick(event)
                },
              }
              : {})}
            className={imageClassNames}
            src={src}
            alt="Gebruikers foto"
            $scaling={scaling}
          />
          {selected && !extraImages && (
            <Tooltip
              clickDelete={clickDelete}
              {...(clickView && { clickView: clickView })}
            />
          )}
          {extraImages > 0 && (
            <span
              onClick={(event) => {
                event.stopPropagation()
                clickView(0)
              }}
              className="extra__images__number"
            >
              +{extraImages + 1}
            </span>
          )}
        </div>
      )
    }
    if (inputField) {
      return (
        <>
          {inputField}
        </>
      )
    }
  }

  return (
    <StyledLi
      className={imageViewerClassNames}
      $scaling={scaling}
      $widthSize={widthSize}
      $widthSizeSmall={widthSizeSmall}
      $heightSize={heightSize}
      $heightSizeSmall={heightSizeSmall}
      $borderThumbNail={borderThumbNail}
    >
      <StyledImageContainer
        $hasOnClick={Boolean(onClick)}
        className={imageContainerClassNames}
        htmlFor={htmlFor}
        $widthSize={widthSize}
        $widthSizeSmall={widthSizeSmall}
        $heightSize={heightSize}
        $heightSizeSmall={heightSizeSmall}
        $scaling={scaling}
      >
        {labelContents()}
      </StyledImageContainer>
    </StyledLi>
  )
}

ImageViewerGuided.propTypes = {
  src: PropTypes.string,
  extraImages: PropTypes.number,
}

export default ImageViewerGuided
