
import { defaultContent, customContent } from '@domain/content'

const generatePageContent = (contentType, insurer, incident, inCreation, isMobile, pageName, bodyshop, spCase = false) => {

  const defaultTexts = defaultContent(inCreation, incident, isMobile, bodyshop, spCase)
  const defaultPageText = defaultTexts[pageName] || {}

  const customTexts = customContent(contentType, insurer, incident, inCreation, isMobile, bodyshop)
  const customPageText = customTexts[pageName] || {}

  //// prepared to receive content as part of configuration under key 'content'
  const companyCustomTexts = insurer && insurer.configuration ? insurer.configuration.find(page => page.name === pageName) || {} : {}
  const companyCustomPageText = companyCustomTexts['content'] || {}

  const finalContent = { ...defaultPageText, ...customPageText, ...companyCustomPageText }

  return finalContent
}

export default generatePageContent