// NPM imports.
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled, Box } from '@mui/material';
import classNames from 'classnames';

import { useScaling } from '@domain/hooks';
import { isMobile, isTablet } from 'react-device-detect';



const StyledHeading = styled(Box, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  font-size: ${props => props.$basefontsize}px;
  line-height: ${props => props.$baselineheight}px;
`;

function Heading({ children, className, level, onClick }) {
  const { textSize, textSizeMobile } = useScaling();
  const headingLevel = 'h' + (!!level ? level : '5');
  const classes = useMemo(() => classNames(
    {
      h5: !level || level === '5', // defaults to h5
      h1: level === '1',
      h2: level === '2',
      h3: level === '3',
      h4: level === '4',
      h6: level === '6',
      h1__title: className === 'h1__title',
      // h1__mobile: className === 'h1__mobile',
    },
    className
  ), [level, className]);

  const baseFontSize =
    className === 'h1__title' ?
      (isMobile || isTablet) ? 48 : 120 : // h1__title has a different baseFontSize on mobile
      // className === 'h1__mobile' ? 48 :
      level === '1' ? 48 :
        level === '2' ? 32 :
          level === '3' ? 24 :
            level === '4' ? 16 :
              level === '5' ? 14 :
                level === '6' ? 12 : 14; // defaults to h5

  const baseLineHeight =
    className === 'h1__title' ?
      (isMobile || isTablet) ? 40 : 100 : // h1__title has a different baseLineHeight on mobile
      // className === 'h1__mobile' ? 40 :
      level === '1' ? 56 :
        level === '2' ? 46 :
          level === '3' ? 32 :
            level === '4' ? 20 :
              level === '5' ? 18 :
                level === '6' ? 18 : 18; // defaults to h5
  return (
    <StyledHeading
      as={headingLevel}
      className={classes}
      $basefontsize={
        (isMobile || isTablet) ?
          textSizeMobile(baseFontSize) :
          textSize(baseFontSize)
      }
      $baselineheight={
        (isMobile || isTablet) ?
          textSizeMobile(baseLineHeight) :
          textSize(baseLineHeight)
      }
      onClick={onClick}
    >{React.Children.toArray(children)}
    </StyledHeading>
  )
}

Heading.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  level: PropTypes.oneOf(['1', '2', '3', '4', '5', '6'])
};

export default Heading;
