import { UPDATE_CONSENTCODE } from '@domain/action-types'
import { browserStorage } from '@domain/helpers'
import { useState, } from 'react'
import { useDispatch } from 'react-redux'

export const useCookieConsent = () => {

  const [preferences, setPreferences] = useState({ ga: true, hotjar: true })

  const dispatch = useDispatch()

  return {
    preferences,
    updateConsent: (consented) => {
      dispatch({
        type: UPDATE_CONSENTCODE,
        consentCode: consented,
      })
      browserStorage.setConsentCode(consented)
    },
    updatePreferences: (destinationId) => setPreferences((currentState) => ({
      ...currentState,
      [destinationId]: !preferences[destinationId],
    })),
  }
}
