import { object, array, string } from 'yup';

const imageSchema = object({
  cpID: string().required(),
  content: string().required(),
});

const validationSchema = (maxNumberOfImages, validationText, minNumberofImages = 6) => array()
  .of(imageSchema)
  .min(minNumberofImages, validationText)
  .max(maxNumberOfImages, `U kunt maximaal ${maxNumberOfImages} foto's uploaden`)

const validationSchemaNotRequired = maxNumberOfImages => array()
  .of(imageSchema)
  .max(maxNumberOfImages, `U kunt maximaal ${maxNumberOfImages} foto's uploaden`)

export { validationSchema, validationSchemaNotRequired }