import React, { useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { GlobalStyles } from '@mui/material';

import Start from './Start';
import License from './License';
import Name from './Name';
import Postal from './Postal';
import Address from './Address';
import Contact from './Contact';
import Gender from './Gender'
import CarDriver from './CarDriver';
import DriverBirthday from './DriverBirthday';
import DriverContact from './DriverContact';
import DriverAddress from './DriverAddress';
import DriverName from './DriverName'
import DriverPostal from './DriverPostal';
import GenderCompleteOptions from './GenderCompleteOptions'

import routes from './routes';
import { useScaling } from '@domain/hooks';
import { useQuestionnaire } from '@domain/providers';
import { checkforValidPath } from '@domain/helpers';
import { useSelector } from 'react-redux';

const personalStyle = `
  .main__personal {
    .h1 {
      margin-bottom: 42px;
    }
  }

  .screen-desktop & {
    .main__personal {
      .legend {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(24)}px;
      }
      .h1 {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(70)}px;
        // max-width: ${props => props.$scaling.scaleUpTo4K(448)}px;
      }
    }
    .main__personal--gender {
      .h1 {
        // so that the heading is at the same vertical position then on the textInputPage before
        // margin-bottom: ${props => props.$scaling.scaleUpTo4K(93)}px;
      }
    }
  }

  @media screen and (max-width: 1028px)  {
    .screen-desktop & {
      .main__personal {
        .h1 {
          margin-bottom: 50px;
          // max-width: none;
        }
      }
    }
    .screen-ie & {
      .main__personal {
        .legend {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
  
  @media screen and (max-width: 580px) {
    .screen-desktop & {
      .form {
        flex-wrap: wrap;
        .form-input {
          flex: 1 1 100%;
          padding: 0 !important;
        }
      }
    }
    .screen-ie & {
      .main__personal {
        .legend {
          text-align: left;
        }
      }
    }
  }
`;

function Personal() {
  const scaling = useScaling();
  const location = useLocation()

  const { questionnaire } = useQuestionnaire();

  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)

  const validPath = !!questionnaire && !!questionnaire.find(page => page.path === location.pathname)
  const newValidPath = checkforValidPath(incident, questionnaire, files, insurer).path
  const navigate = useNavigate()

  useEffect(() => {
    if (!!questionnaire && questionnaire.length && !validPath) {
      navigate(newValidPath)
    }
  }, [questionnaire, validPath])
  return (
    <>
      <Routes>
        <Route path={routes.start} element={<Start />} />
        <Route path={routes.license} element={<License />} />
        <Route path={routes.name} element={<Name />} />
        <Route path={routes.contact} element={<Contact />} />
        <Route path={routes.postal} element={<Postal />} />
        <Route path={routes.address} element={<Address />} />
        <Route path={routes.gender} element={<Gender />} />
        <Route path={routes.carDriver} element={<CarDriver />} />
        <Route path={routes.carDriverBirthday} element={<DriverBirthday />} />
        <Route path={routes.carDriverContact} element={<DriverContact />} />
        <Route path={routes.carDriverName} element={<DriverName />} />
        <Route path={routes.carDriverPostal} element={<DriverPostal />} />
        <Route path={routes.carDriverAddress} element={<DriverAddress />} />
        <Route path={routes.genderCompleteOptions} element={<GenderCompleteOptions />} />
        <Route path='*' element={<Navigate to={routes.start} />} />
      </Routes>
      <GlobalStyles scaling={scaling} styles={personalStyle} />
    </>
  );
}

export default Personal;
