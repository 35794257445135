import React from 'react';
import { styled } from '@mui/material/styles';

const StyledList = styled('ul')`
  display: flex;
  overflow-x: auto;
  justify-content: flex-start;
  padding: 48px 0;
  .screen-desktop & {
    padding: 0 0 48px;
  }
`;

function ImageWheel({ children }) {
  return <StyledList className="image-wheel">{React.Children.toArray(children)}</StyledList>;
}

export default ImageWheel;
