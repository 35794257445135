import React, { useState } from 'react';
import { Instruction } from '../..';
import { styled } from '@mui/material/styles';
import { useScaling } from '@domain/hooks';

const StyledList = styled('ul', {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  margin-top: ${props => props.$scaling.scaleUpTo4K(60)}px;
  width: 100%;
  .screen-desktop & {
    max-width: ${props => props.$scaling.scaleUpTo4K(320)}px;
  }
  @media screen and (max-width: 1028px) {
    min-height: 350px;
  }
`;

const InstructionsList = ({ children, instructions }) => {
  const scaling = useScaling();
  const [openedItem, setOpenedItem] = useState(null);

  const renderInstruction = (instruction, i) => {
    const { title, explanation, imagePath, imageAlt, className } = instruction;
    const toggleIsOpen = () => setOpenedItem(item => (item === i ? null : i));
    return (
      <Instruction
        title={title}
        explanation={explanation}
        key={title}
        isOpen={openedItem === i}
        toggleIsOpen={toggleIsOpen}
        imagePath={imagePath}
        imageAlt={imageAlt}
        imageClassName={className}
      />
    );
  };
  return (
    <StyledList className="instructions--list" $scaling={scaling}>
      {instructions.map(renderInstruction)}
    </StyledList>
  );
};

export default InstructionsList;
