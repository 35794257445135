import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { UseIcon, Heading } from '@domain/components';
import { Colors } from '@domain/theming';

const StyledHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  .collapsible__header__text {
    display: inline-block;
    max-width: calc(100% - 40px);
    cursor: pointer;
    // padding-top: 7px;
  }
  .faq-icon {
    background: ${Colors.WHITE};
    color: black;
    border-radius: 50%;
    cursor: pointer;
  }
`;

function CollapsibleHeader({ children, onClick, collapsed }) {
  const iconName = collapsed ? 'plus' : 'minus';

  return (
    <StyledHeader className="collapsible__header" onClick={onClick}>
      <Heading level="4" className="collapsible__header__text">
        {React.Children.toArray(children)}
      </Heading>
      <UseIcon name={iconName} className="faq-icon" />
    </StyledHeader>
  );
}

CollapsibleHeader.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired
};

export default CollapsibleHeader;
