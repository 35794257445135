import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  Container,
  FAQ,
  GoogleMap,
  GridContainer,
  GridColumn,
  Heading,
  IconButton,
  IconLink,
  Legend,
  Main,
  NavBar,
  ProgressionIndicator,
  Text,
  UseIcon,
  Paragraph,
  Loader,
  CompanyLogo
} from '@domain/components'
import {
  getPrevNextPagePath,
  goToNextPage,
  browserStorage,
  generatePageContent,
  chooseLogoSize
} from '@domain/helpers';
import { useNavigate, useLocation } from 'react-router-dom'
import { useFaq, useScaling } from '@domain/hooks';
import { styled, useTheme } from '@mui/material/styles';
import { useQuestionnaire } from '@domain/providers';
import routes from '../../../routes';
import { useDispatch } from 'react-redux';
import { UPDATE_EXCLUDED_PAGES } from '@domain/action-types';
import { Colors } from '@domain/theming';
import { detectContentType } from '@domain/content';
import { proccessType } from '@domain/constants';
import { APIProvider } from '@vis.gl/react-google-maps';

const faqs = [
  {
    question: 'Ik wil toch een ander herstelbedrijf, kan dat?',
    answer: 'Ja dat kan, u kunt op de knop ‘Kies een ander herstelbedrijf’ klikken om uw herstelbedrijf te wijzigen.',
  },
];

const StyledMain = styled(Main)`
  .bodyshop-confirm {
    &__container {
      .grid__column {
      }
    }
    &__main {
      .bodyshop-confirm__main--card {
        padding-top: ${props => props.$scaling.scaleUpTo4K(50)}px;
        flex-direction: column;
        p {
          a {
            color: ${Colors.GREY_DARK};
            word-break: break-word;
          }
        }
        .map-container {
          flex: 1 1 auto;
          position: relative;
          height: ${props => props.$scaling.scaleUpTo4K(180)}px;
          .google-map {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
    &__footer {
      margin-top: auto;
      padding-top: ${props => props.$scaling.scaleUpTo4K(40)}px;
      flex-direction: row;
      justify-content: flex-end;
      &--button-container {
        width: 100%;
        .button {
          width: 100%;
        }
      }
      &--button-container-select {
        padding-bottom: ${props => props.$scaling.scaleUpTo4K(40)}px;
        width: 100%;
        .button {
          width: 100%;
        }
      }
    }
  }

  .screen-desktop & {
    .bodyshop-confirm {
      &__container {
      }
      &__main {
        padding: 0;
        .bodyshop-confirm__main--card {
          flex-direction: row;
          .map-info {
            padding-right: ${props => props.$scaling.scaleUpTo4K(100)}px;
            width: 40%;
          }
          .map-container {
            width: 60%;
            height: auto;
          }
          h1 {
            margin-bottom: 0;
          }
          p {
            margin: ${props => props.$scaling.scaleUpTo4K(2)}px 0 ${props => props.$scaling.scaleUpTo4K(30)}px;
            a {
            }
          }
          &--button-container {
            .button {
              margin: 0;
            }
          }
        }
      }
      &__footer {
        margin: auto 0;
        flex-shrink: 2;
        &--button-container {
          width: auto;
          .button {
            width: ${props => props.$scaling.scaleUpTo4K(240)}px;
          }
        }
        &--button-container-select {
          margin: auto;
          width: auto;
          .button {
            width: ${props => props.$scaling.scaleUpTo4K(240)}px;
          }
        }
      }
    }
    .grid__column--main {
      max-width: none;
    }
  }

  @media screen and (max-width: 1028px) {
    .bodyshop-confirm {
      &__container {
      }
      &__main {
        .bodyshop-confirm__main--card {
          .map-info {
            .button-container {
              padding-bottom: ${props => props.$scaling.scaleUpTo4K(30)}px;
            }
          }
        }
      }
      &__footer {
        &--button-container {
          padding-bottom: ${props => props.$scaling.scaleUpTo4K(20)}px;
        }
      }
    }

    .screen-desktop & {
      .bodyshop-confirm {
        &__container {
          .grid__column {
            flex: 0 0 auto;
            padding-top: 0;
          }
        }
        &__main {
          max-width: 500px;
          text-align: center;
          margin: 0 auto;
          .bodyshop-confirm__main--card {
            flex-direction: column;
            margin-top: 60px;
            .map-info {
              width: 100%;
              padding: 0 0 20px;
              .button-container {
                padding-bottom: ${props => props.$scaling.scaleUpTo4K(30)}px;
              }
            }
            .map-container {
              height: 240px;
              width: 100%;
            }
          }
        }
        &__footer {
          max-width: 500px;
          margin: auto auto 0;
          padding-bottom: 0;
          &--button-container {
            padding-top:10px;
             margin: 0;
            width:100%;
            .button {
              width:100%
            }
          }
          &--button-container-select {
            margin: auto auto 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 580px) {
    .bodyshop-confirm {
      &__container {
        .grid__column {
        }
      }
      &__main {
        .bodyshop-confirm__main--card {
        }
      }
      &__footer {
        &--button-container-select {
          padding-bottom: 40px;
        }
      }
    }

    .screen-desktop & {
      .bodyshop-confirm {
        &__container {
          .grid__column {
          }
        }
        &__main {
          text-align: left;
          h1 {
            margin-left: 0;
          }
          .bodyshop-confirm__main--card {
          }
        }
        &__footer {
          &--button-container {
            width: 100%;
            padding-top:10px
            .button {
              width: 100%;
            }
          }
          &--button-container-select {
            padding-bottom: 40px;
            width: 100%;
            justify-content: flex-start;
            .button {
              width: 100%;
            }
          }
        }
      }
    }
  }

`;

const BodyShopButton = styled(Button)`
    margin: 0 auto;
`;


function BodyshopConfirm() {
  const navigate = useNavigate()
  const location = useLocation()
  const { questionnaire, updateQuestionnaire } = useQuestionnaire();
  const bodyshop = useSelector(state => state.bodyshop);
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)

  const inBodyshopChange = browserStorage.getInBodyshopChange();

  const isSG = insurer && insurer.objectType === proccessType.Schadegarant

  const themeContext = useTheme();

  const scaling = useScaling();
  const [
    faqVisible,
    handleOnHelpClick,
    clickOutSideFaq,
    faqListProps,
    faqSeen,
  ] = useFaq();

  const [clickedChooseOtherBSButton, setClickedChooseOtherBSButton] = useState(null)
  const pagesExcluded = useSelector(state => state.pagesExcluded)

  useEffect(() => {
    if (clickedChooseOtherBSButton && questionnaire.some(page => page.name === 'bodyshop_select')) {
      const selectBodyshopPage = questionnaire.find(page => page.name === 'bodyshop_select')
      const selectBodyshopPagePath = selectBodyshopPage.path
      navigate(selectBodyshopPagePath);
    }
  }, [questionnaire, clickedChooseOtherBSButton])

  const dispatch = useDispatch()

  const currentPath = location.pathname;

  useEffect(() => {
    if (!!questionnaire && questionnaire.length) {
      const progress = questionnaire.find(page => page.path === currentPath).name
      browserStorage.setProgress(progress);
    }
  }, [currentPath, questionnaire])

  useEffect(() => {
    const reloadPageConfigForInbsChange = async () => {
      await updateQuestionnaire({ configuration: [{ "index": 1, "name": "bodyshop_select" }, { "index": 2, "name": "bodyshop_confirm" }, { "index": 3, "name": "track_trace" }] });
    }
    if (inBodyshopChange && questionnaire && questionnaire.length !== 3) {
      reloadPageConfigForInbsChange()
    }
  }, [inBodyshopChange, questionnaire, updateQuestionnaire])

  if (!insurer || !questionnaire || !bodyshop || bodyshop.debiteurNummer !== incident.debiteurNummer) {
    return <Loader />;
  }

  const pagePath = inBodyshopChange ? { prevPage: undefined, nextPage: undefined } : getPrevNextPagePath(
    questionnaire,
    incident,
    currentPath,
    files
  );
  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, false, false, pageName, bodyshop)

  const legend = content["over-title-text"]
  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const useCustomLogo = content["use-custom-logo"]
  const text = content["text"]

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  const chooseMiddleComponent = useCustomLogo
    ? <CompanyLogo {...middleLogo} />
    : <ProgressionIndicator steps={3} current={2} />


  const onClickChooseAnotherBS = () => {
    setClickedChooseOtherBSButton(true)
    const pages = pagesExcluded ? pagesExcluded.split(' ') : []
    const updatedPages = pages.includes('bodyshop_select') ?
      pages.filter(page => page !== 'bodyshop_select') : pages

    const updatedPagesExcluded = updatedPages.join(' ')
    if (updatedPagesExcluded !== pagesExcluded) {
      dispatch({
        type: UPDATE_EXCLUDED_PAGES,
        pages: updatedPagesExcluded,
      });
      browserStorage.setPagesExcluded(updatedPagesExcluded)
    }
  }


  const { street, companyName, houseNumber, zipcode, city, phoneNumber, emailAddress, website, longitude, latitude } = bodyshop;
  const locationMaps = { latitude, longitude }


  const { trackTrace } = routes;

  async function handleClick(event) {
    event.preventDefault();
    if (inBodyshopChange) {
      browserStorage.clearInBodyshopChange()
      navigate(trackTrace);
      return
    }
    goToNextPage(navigate, questionnaire, incident, currentPath, files)
  }

  const main = {
    faqVisible: faqVisible,
    dimmed: faqVisible,
    className: 'main__damage main__damage--bodyshop-confirm',
    callBack: clickOutSideFaq,
    $scaling: scaling,
  }

  const iconLink = (triggerKey) => ({
    type: 'arrow-left',
    theme: 'secondary',
    to: pagePath.prevPage,
    replace: true,
    triggerKey
  });

  const iconLinkForward = (triggerKey) => ({
    type: 'arrow-right',
    theme: 'secondary',
    to: nextPage,
    replace: true,
    triggerKey
  });

  const iconButton = {
    type: faqVisible ? 'arrow-right' : 'questionmark',
    className: faqSeen ? '' : 'unclicked-faq-button',
    theme: 'secondary',
    onClick: handleOnHelpClick,
    faq: true,
  };

  const button = {
    className: 'button--icon',
    justify: 'space-between',
    onClick: handleClick,
    shadow: true,
    theme: 'primary',
  };

  const chooseAnotherBodyshopButton = {
    className: 'button--icon',
    justify: 'space-between',
    onClick: onClickChooseAnotherBS,
    shadow: true,
    theme: 'primary',
  };

  const hrefLink = () => {

    const protocol = website ? website.startsWith('http') : null
    if (protocol) {
      return website
    }
    return `http://${website}`
  };

  return (
    <React.Fragment>
      <FAQ
        faqListProps={faqListProps}
        faqs={faqs}
        faqLink={insurer.helpcenterLink}
        customHelpLink={customHelpLink}
        helpLinkText={helpLinkText}
        faqVisible={faqVisible}
      />
      <StyledMain {...main}>
        <NavBar>
          <Container className="icon-link-container">
            {pagePath.prevPage && <IconLink {...iconLink([37])} />}
            {nextPage && <IconLink {...iconLinkForward([39])} />}
          </Container>
          {chooseMiddleComponent}
          <Container className="faq-button-container">
            <IconButton {...iconButton} />
          </Container>
        </NavBar>
        <GridContainer main={true} className="bodyshop-confirm__container">
          <GridColumn className="bodyshop-confirm__main">
            <Legend>{legend}</Legend>
            <Heading level="1">U heeft gekozen voor {companyName}</Heading>
            {text && <Heading level="3">{text}</Heading>}
            <Container flex={true} className="bodyshop-confirm__main--card">
              <Container className="map-info">
                <Heading level="4">Adres</Heading>
                <Paragraph>{`${street} ${houseNumber}`}<br /> {`${zipcode} ${city}`}</Paragraph>
                {phoneNumber && <><Heading level="4">Telefoon</Heading>
                  <Paragraph><a href={'tel:' + phoneNumber}>{phoneNumber}</a></Paragraph></>}
                <Heading level="4">Email</Heading>
                <Paragraph><a href={'mailto:' + emailAddress}>{emailAddress}</a></Paragraph>
                {website && <><Heading level="4">Website</Heading>
                  <Paragraph><a href={hrefLink()} target="blank">{website}</a></Paragraph></>}
                <Container className="button-container">
                  {' '}
                  {!inBodyshopChange && isSG && <BodyShopButton {...chooseAnotherBodyshopButton}>
                    <UseIcon name="arrow-left" className="button__icon--left" />
                    Kies een ander herstelbedrijf
                  </BodyShopButton>}
                </Container>
              </Container>
              <Container className="map-container">
                <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}>
                  <GoogleMap location={locationMaps} />
                </APIProvider>
              </Container>
            </Container>
          </GridColumn>
          <GridColumn
            className=" grid__column--footer bodyshop-confirm__footer"
          >
            <Container className="bodyshop-confirm__footer--button-container">
              {' '}
              <Button {...button}>
                Volgende
                <UseIcon name="arrow-right" className="button__icon" />
              </Button>
              <Text className="of--enter">
                of <b>ENTER</b>
              </Text>
            </Container>
          </GridColumn>
        </GridContainer>
      </StyledMain>
    </React.Fragment>
  )
}

export default BodyshopConfirm;
