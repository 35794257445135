import React from 'react';
import PropTypes from 'prop-types';


import { Label } from '@domain/components';
import { styled } from '@mui/material';

const StyledDiv = styled('div')`
 display:none;
`;

function InputFileNoStyle({ id, onChange, inputRef, children, isDragging, multiple = true, accepts }) {

  return (
    <StyledDiv key={id}>
      <Label htmlFor={`input-file-${id}`}>
        {React.Children.toArray(children)}
      </Label>
      <input
        className="input--file"
        type="file"
        name="photos"
        onChange={onChange}
        id={`input-file-${id}`}
        {...(accepts ? { accept: accepts } : {})}
        {...(multiple ? { multiple: true } : {})}
        autoFocus
        ref={inputRef}
      />
    </StyledDiv>
  );
}

InputFileNoStyle.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default InputFileNoStyle;
