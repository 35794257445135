import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NEW_UUID_FOUND } from '@domain/action-types';
import { browserStorage } from '@domain/helpers';
import { useSearchParams } from 'react-router-dom'
// This hook should only ever be loaded once in the entire app

const useUUIDChecker = location => {
  const [searchP, setSearchP] = useSearchParams()
  const dispatch = useDispatch();
  const uuid = useSelector(state => state.uuid);
  const incident = useSelector(state => state.incident);

  useEffect(() => {
    if (uuid) {
      return;
    }

    const storedUUID = browserStorage.getUUID()
    const uuidFromURL = decodeURIComponent(
      searchP.get('UUID') ?? ''
    );


    const uuidFromURLValid = uuidFromURL === '' || uuidFromURL === 'undefined' ? false : true;
    if (uuidFromURLValid && uuidFromURL !== uuid) {
      if (!!storedUUID && storedUUID !== uuidFromURL) {
        const storageList = Object.keys(localStorage);
        browserStorage.clearLocalStorage([...storageList], ['consentCode', 'bannerOpen']);
      }
      dispatch({ type: NEW_UUID_FOUND, uuid: uuidFromURL });
      browserStorage.setUUID(uuidFromURL)
      return;
    }
    if (!!storedUUID && storedUUID !== '') {
      dispatch({ type: NEW_UUID_FOUND, uuid: storedUUID });
      return;
    }
    const incidentUUID = !!incident ? incident.uuid : '';
    if (!uuidFromURLValid && !storedUUID && !!incidentUUID && incidentUUID !== '') {
      dispatch({ type: NEW_UUID_FOUND, uuid: incident.uuid });
      browserStorage.setUUID(incident.uuid)
      return;
    }
  }, [dispatch, location.search, incident, uuid]);
};

export default useUUIDChecker;
