import React, { useContext, useState, useRef, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  CompanyLogo,
  Container,
  FAQ,
  GridColumn,
  GridContainer,
  Heading,
  IconButton,
  IconLink,
  Legend,
  Loader,
  Main,
  NavBar,
  ProgressionIndicator,
  Radio,
  RadioGroup,
} from '@domain/components';
import { causeDamageCategoriesGlass, proccessType } from '@domain/constants';
import { useFaq, useSaveIncidentFactory } from '@domain/hooks';
import { chooseLogoSize, generatePageContent, getPrevNextPagePath, goToNextPage, pageToNumber } from '@domain/helpers';
import { useQuestionnaire } from '@domain/providers';

//import questionnaire from '../../questionnaire';
import routes from '../routes';
import { detectContentType } from '@domain/content';
import { useTheme } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

function CauseDamage() {

  const navigate = useNavigate()
  const { questionnaire } = useQuestionnaire();
  const causeDamageCategory = 'causeDamage';


  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)
  const isDI = (insurer.objectType === proccessType.DI)
  const [
    faqVisible,
    handleOnHelpClick,
    clickOutSideFaq,
    faqListProps,
    faqSeen,
  ] = useFaq();

  const currentValue = incident[causeDamageCategory];
  const [selectedValue, setSelectedValue] = useState(currentValue);
  const [hasClicked, setHasClicked] = useState(false);
  const requestMade = useRef(false);

  const themeContext = useTheme();

  const location = useLocation()
  const currentPath = location.pathname;

  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);

  const pagePath = getPrevNextPagePath(
    questionnaire,
    incident,
    currentPath,
    files
  );

  const { causeDamageOverig } = routes;
  const currentStep = pageToNumber(currentPath, questionnaire);
  const nextPageEnabled = questionnaire[currentStep].check(incident);
  const nextPagePicked = currentPath + causeDamageOverig

  const nextPage = nextPageEnabled
    ? causeDamageCategoriesGlass.Overig.indexOf(currentValue) >= 0
      ? nextPagePicked
      : pagePath.nextPage !== currentPath
        ? pagePath.nextPage
        : undefined
    : undefined;

  const causeDamage = incident.causeDamage;

  const faqs = useMemo(
    () => [
      {
        question: 'Waarom moet ik opgeven hoe de schade is ontstaan?',
        answer: isDI
          ? 'Deze informatie helpt ons bij het bepalen van het schadebeeld en het inplannen van een reparatie.'
          : 'Dit is informatie die we met uw verzekeraar moeten delen en is daarom altijd onderdeel van een schadedossier. De verzekeraar deelt schades standaard in in verschillende groepen.'
      },
      {
        question: 'Wat betekenen de verschillende oorzaken precies?',
        answer: (
          <dl>
            <dt>Aanrijding - </dt>
            <dd>
              De ruit van uw auto is beschadigd door een aanrijding waarbij er bijvoorbeeld een uitstekend deel door uw ruit heen is gekomen.
            </dd>
            <br />
            <br />
            <dt>Doorscheuren eerdere reparatie - </dt>
            <dd>
              De ster in uw ruit is doorgescheurd na een eerdere reparatie. U kunt het beste teruggaan naar het herstelbedrijf waar u die ruitreparatie heeft laten uitvoeren.
            </dd>
            <br />
            <br />
            <dt>Hagel - </dt>
            <dd>
              De ruit van uw auto is beschadigd als gevolg van een hagelbui. Als er naast ruitschade ook carrosserie- (blik) en/of lakschade is, dan kunt u beter voor een carrosserie schadeherstelbedrijf kiezen. Alle schade wordt dan in één keer hersteld.
            </dd>
            <br />
            <br />
            <dt>Inbraak - </dt>
            <dd>
              De ruit van uw auto is beschadigd als gevolg van een inbraak. Als er naast ruitschade ook carrosserie- (blik) en/of lakschade is, dan kunt u beter voor een carrosserie schadeherstelbedrijf kiezen. Alle schade wordt dan in één keer hersteld.
            </dd>
            <br />
            <br />
            <dt>Molest en/of vandalisme - </dt>
            <dd>
              De ruit van uw auto is beschadigd als gevolg van molest (= niet opzettelijk beschadigen van de ruit) of vandalisme (= opzettelijk beschadigen van de ruit).
            </dd>
            <br />
            <br />
            <dt>Storm - </dt>
            <dd>De ruit van uw auto is beschadigd als gevolg van een storm.</dd>
            <br />
            <br />
            <dt>Steenslag - </dt>
            <dd>
              De ruit van uw auto is beschadigd als gevolg van een of meerdere  stenen die – tijdens het rijden- op de ruit terecht is/zijn gekomen.
            </dd>
            <br />
            <br />
            <dt>Overig - </dt>
            <dd>
              De ruit van uw auto is beschadigd als gevolg van géén van de omschreven gevallen.
            </dd>
          </dl>
        ),
      },
    ],
    [isDI]);

  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath

  useEffect(() => {
    if (hasClicked && selectedValue === currentValue) {
      goToNextPage(navigate, questionnaire, incident, currentPath, files)
    }
  }, [currentValue, goToNextPage, hasClicked, selectedValue, questionnaire, incident, currentPath, files]);

  async function handleChange(event, value) {
    event.preventDefault();
    if (value && !requestMade.current) {
      requestMade.current = true;
      setSelectedValue(value)
      if (value === 'Steenslag') {
        const savedIncident = await saveIncident({ ...incident, causeDamage: value }, isPageBeforeSuccess)
        if (savedIncident) {
          setHasClicked(true)
        }
      } else {
        navigate(currentPath + causeDamageOverig)
      }
    }
  }

  const main = {
    faqVisible: faqVisible,
    dimmed: faqVisible,
    className: 'main__damage',
    callBack: clickOutSideFaq,
  };

  const iconLink = (triggerKey) => ({
    type: 'arrow-left',
    theme: 'secondary',
    to: pagePath.prevPage,
    replace: true,
    triggerKey
  });

  const iconLinkForward = (triggerKey) => ({
    type: 'arrow-right',
    theme: 'secondary',
    to: nextPage,
    replace: true,
    triggerKey
  });

  const iconButton = {
    type: faqVisible ? 'arrow-right' : 'questionmark',
    className: faqSeen ? '' : 'unclicked-faq-button',
    theme: 'secondary',
    onClick: handleOnHelpClick,
    faq: true,
  };

  const radio = (id, value, triggerKeys) => ({
    name: 'cause-damage',
    id: `damage-option-${id}`,
    defaultChecked:
      selectedValue === value ||
      causeDamageCategoriesGlass[value].includes(causeDamage),
    value,
    onClick: handleChange,
    radioClassName: 'radio--damage-cause',
    triggerKeys
  });

  const radioRock = radio(1, 'Steenslag', [49]);

  const radioOther = radio(2, 'Overig', [50]);

  if (!insurer || !incident) {
    return <Loader />;
  }

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, false, false, pageName)

  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const useCustomLogo = content["use-custom-logo"]

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  const chooseMiddleComponent = useCustomLogo
    ? <CompanyLogo {...middleLogo} />
    : <ProgressionIndicator steps={3} current={2} />

  return (
    <React.Fragment>
      <FAQ
        faqListProps={faqListProps}
        faqs={faqs}
        faqLink={insurer.helpcenterLink}
        customHelpLink={customHelpLink}
        helpLinkText={helpLinkText}
        faqVisible={faqVisible}
      />
      <Main {...main}>
        <NavBar>
          <Container className="icon-link-container">
            <IconLink {...iconLink([37])} />
            {nextPage && <IconLink {...iconLinkForward([39])} />}
          </Container>
          {chooseMiddleComponent}
          <Container className="faq-button-container">
            <IconButton {...iconButton} size="large" />
          </Container>
        </NavBar>
        <GridContainer main={true}>
          <GridColumn className="grid__column--main">
            <Legend>Schade beschrijven</Legend>
            <Heading level="1">Hoe is de schade ontstaan?</Heading>
          </GridColumn>

          <GridColumn
            justify="flex-end"
            className="grid__column--footer grid__column--radio-group"
          >
            <RadioGroup onChange={handleChange}>
              <Radio {...radioRock}>Steenslag</Radio>
              <Radio {...radioOther}>Overig</Radio>
            </RadioGroup>
          </GridColumn>
        </GridContainer>
      </Main>
    </React.Fragment>
  );
}

export default CauseDamage;
