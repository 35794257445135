import React from 'react';
import RadioPage from '@domain/pages/RadioPage';

const vehicleSpeed = 'vehicleSpeed';
function Speed() {

  const fields = [{
    id: 1,
    value: '0-30',
    triggerKeys: [49],
    label: '0-30'
  }, {
    id: 2,
    value: '30-60',
    triggerKeys: [50],
    label: '30-60'
  },
  {
    id: 3,
    value: '60-90',
    triggerKeys: [51],
    label: '60-90'
  },
  {
    id: 3,
    value: '90+',
    triggerKeys: [52],
    label: '90+'
  }
  ]

  return <RadioPage fieldName={vehicleSpeed} styleLabel={'speed'} fields={fields} />
}

export default Speed;