import { checkQuestionnaire } from '../checkQuestionnaire';
const goToNextPage = (navigate, questionnaire, incident, currentPath = '', files, isFeedback = false) => {

  const progress = questionnaire.findIndex(page => page.path === currentPath)

  const nextPage = checkQuestionnaire(
    progress + 1,
    questionnaire,
    incident,
    files,
    isFeedback
  );
  navigate(nextPage);
};

export default goToNextPage;
