// NPM imports.
import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import { isMobile, isTablet } from 'react-device-detect';
import { useScaling } from '@domain/hooks';

const StyledText = styled('span', {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
display: block;
font-size: ${props => (isMobile || isTablet) ? props.$scaling.textSizeMobile(14) : props.$scaling.textSize(14)}px;
line-height: ${props => (isMobile || isTablet) ? props.$scaling.textSizeMobile(18) : props.$scaling.textSize(18)}px;
  &.text--faq-veelgestelde-vragen {
    margin-right: ${props => props.$scaling.scaleUpTo4K(10)}px;
  }
  @media screen and (max-width: 580px) {
    &.text--faq-veelgestelde-vragen {
      display: none;
    }
  }
`;

function Text({ children, className }) {
  const scaling = useScaling();
  const classes = classNames('text', className);

  return <StyledText className={classes} $scaling={scaling}>{React.Children.toArray(children)}</StyledText>;
}

Text.propTypes = {
  children: PropTypes.any,
  classNames: PropTypes.string
};

export default Text;
