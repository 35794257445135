import React from 'react';
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { isMobile } from 'react-device-detect';
import { useScaling } from '@domain/hooks';

const PREFIX = 'VerticalToggleButtons';

const classes = {
  root: `${PREFIX}-root`,
  grouped: `${PREFIX}-grouped`,
  root2: `${PREFIX}-root2`
};

const Root = styled(' StyledToggleButtonGroup', {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})(() => ({
  [`& .${classes.root2}`]: {
    height: '100%',
    fontSize: props => props.$dynamicFontSize ? (isMobile
      ? props.$scaling.textSizeMobile(14)
      : props.$scaling.textSize(14)) + 'px'
      : '14'
  }
}));

const StyledToggleButtonGroup = ToggleButtonGroup;

const VerticalToggleButtons = ({ value, handleChange, dynamicFontSize, arrayOfButtons }) => {

  const scaling = useScaling();


  const buttons = arrayOfButtons.map((button => {
    return (
      <ToggleButton value={button.value} aria-label={button.ariaLabel} className={classes.root} key={button.value}>
        {button.label}
      </ToggleButton>
    )
  }))

  return (
    <StyledToggleButtonGroup
      value={value}
      orientation='vertical'
      aria-label="select-damagetype"
      exclusive={true}
      onChange={handleChange}
      classes={{
        root: classes.root,
        grouped: classes.grouped
      }}>
      {buttons}
    </ StyledToggleButtonGroup>
  );
}

export default VerticalToggleButtons
