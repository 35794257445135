import React, { useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import objectFitImages from 'object-fit-images';
import { useTheme } from '@mui/material';

// const mainGradient = (props: any) => `linear-gradient(0deg, ${Color(props.theme.palette.secondary.main).rgb()} 6%, ${Color(props.theme.palette.primary.main).rgb().alpha(0.88)} 26%, ${Color(props.theme.palette.primary.main).rgb().alpha(0.88)} 74%,  ${Color(props.theme.palette.secondary.main).rgb()} 96%)`;

const BackgroundContainer = styled('div', {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  position: ${props => props.$fixed ? 'fixed' : 'absolute'};
  width: 100%;
  height: 120%;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover
    filter: blur(1px) brightness(1.18);
    // transform: rotateY(180deg);
  }
  &:after {
    content: "";
    height: 100%;
    width: 100%;
    background: ${props => props.$overlayColor};
    position: absolute;
    top: 0;
    left: 0;
  }

  .screen-ie & {
    img {
      height: 120%;
      min-height: 600px;
      width: 100vw;
      object-fit: cover;
      font-family: 'object-fit: cover';
    }
  }
`;

const StyledVideo = styled('video')`

  .screen-desktop & {
  }
`;

const StyledImage = styled('img')` 

  .screen-desktop & {
  }
`;

const Background = ({
  videoName,
  imageName,
  overlayColor,
  overlayValue,
  fixed = false,
}) => {

  const themeContext = useTheme();
  useEffect(() => {
    if (imageName) {
      objectFitImages('#background-image-custom');
    }

  }, [imageName])

  return (
    <BackgroundContainer
      className="background-container"
      $overlayColor={overlayColor}
      $fixed={fixed}
    >
      {videoName &&
        <StyledVideo
          className="background-video"
          autoPlay={true}
          muted={true}
          loop={true}
          playsInline={true}
        >
          <source src={`/video/${videoName}`} type="video/mp4" />
        </StyledVideo>
      }
      {imageName &&
        <StyledImage
          id={'background-image-custom'}
          className="background-image"
          src={themeContext.backgroundImage || `/img/${imageName}`}
          alt='Achtergrond'
        />
      }

    </BackgroundContainer>
  )
}

export default Background;
