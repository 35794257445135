import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { goToNextPage, chooseLogoToBeDisplayed, browserStorage, chooseLogoSize } from '@domain/helpers';
import { useThemes, useEnterKey, useScaling } from '@domain/hooks';
import { styled, useTheme } from '@mui/material/styles';

import {
  Button,
  CompanyLogo,
  Container,
  HeaderGroup,
  IconLink,
  Main,
  NavBar,
  Section,
  Text,
  Timeline,
  TimelineIcon,
  TimelineItem,
  UseIcon,
  BackgroundVideo,
  Heading,
} from '@domain/components';
import { Colors } from '@domain/theming';
import { companyNames, proccessType } from '@domain/constants';
import { useNavigate } from 'react-router-dom';

const StyledMain = styled(Main, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  .page-wizard__content {
    display: flex;
    flex-direction: column;
    padding: 32px 32px 0;
  }
  .page-wizard__heading {
    flex: 1 1 auto;
    .h1 {
      margin: 30px 0 12px;
      word-break: break-word;
    }
  }
  .page-wizard__info {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    .screen-desktop & {
      flex: 1 1 auto;
      width: 60%;
      height: 50%;
      justify-content: flex-start;
    }
    .button {
      margin-top: 15px;
      margin-bottom:15px;
      width: 100%;
      &__contact {
        height: auto;
        text-align: left;
        padding: 24px;
        .header-group {
          margin-right: auto;
        }
        span {
          text-transform: initial;
          font-weight: normal;
        }
        .h5 {
          margin-bottom: 15px;
        }
      }
    }
  }
  .page-wizard__timeline {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 50px 0;
    .page-wizard__timeline--heading {
      margin-bottom: 10px;
    }
  }
  .page-wizard__button {
    padding: 0 32px 46px;
    justify-content: stretch;
    button {
    }
  }
  .recovery {
    margin: 0 0 ${props => props.$scaling.scaleUpTo4K(10)}px 0;
  }
  .screen-desktop & {
    .page-wizard__content {
      flex-direction: row;
      padding: 22px 40px 0;
      align-items: center;
    }
    .page-wizard__heading {
      padding-left: ${props => props.$scaling.scaleUpTo4K(80)}px;
      .h1 {
        margin-bottom: 0;
        // max-width: ${props => props.$scaling.scaleUpTo4K(500)}px;
      }
    }
    .page-wizard__text {
      display: block;
      // max-width: ${props => props.$scaling.scaleUpTo4K(380)}px;
      margin-top: ${props => props.$scaling.scaleUpTo4K(20)}px;
      + .page-wizard__text {
        margin-top: 0;
      }
    }
    .page-wizard__info {
      flex: 1 1 auto;
      width: 60%;
      height: 50%;
      justify-content: flex-start;
    }
    .page-wizard__timeline {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      padding: 0;
      padding-right: ${props => props.$scaling.scaleUpTo4K(120)}px;
      padding-left: ${props => props.$scaling.scaleUpTo4K(40)}px;
      .page-wizard__timeline--heading {
        width: 100%;
        max-width: ${props => props.$scaling.scaleUpTo4K(350)}px;
        display: block;
      }
      .timeline {
      }
    }
    .page-wizard__button {
      padding: 0 ${props => props.$scaling.scaleUpTo4K(40)}px ${props => props.$scaling.scaleUpTo4K(40)}px;
      button {
        margin-left: auto;
      }
    }
  }

  .theme-custom & {
    .page-wizard__timeline--heading {
      color: ${props => props.theme.colors.primary};
    }
    .timeline__item {
      color: ${props => props.theme.colors.primary};
      .timeline__icon {
        color: ${props => Colors.WHITE};
        background: ${props => props.theme.colors.primary};
      }
    }
  }
  .theme-dark & {
    .timeline__item {

      color: ${Colors.WHITE};
      .timeline__icon {
        color: ${Colors.GREY_DARKER};
        background: ${Colors.WHITE};
      }
    }
  }

  @media screen and (max-width: 1263px) {
    .screen-desktop & {
      .page-wizard__heading {
        padding-left: 20px;
      }
      .page-wizard__timeline {
        padding-right: 40px;
      }
    }
  }

  @media screen and (max-width: 1028px)  {
    .screen-desktop & {
      .page-wizard__content {
        flex-direction: column;
        justify-content: flex-start;
      }
      .page-wizard__heading {
        text-align: center;
        padding: 0;
      }
      .page-wizard__info {
        width: 100%;
        .button {
          max-width: 580px;
        }
      }
      .page-wizard__button {
        padding-top: 50px;
        display: flex;
        justify-content:center;
        .button {
          max-width:500px;
          margin-left: 0;
        }
      }
      .page-wizard__text {
        width: auto;
        margin: auto;
      }
      .page-wizard__timeline {
        padding: 50px 0;
        .timeline {
        }
      }
    }
    .screen-tablet & {
      .page-wizard__content {
        flex-direction: column;
      }
      .page-wizard__heading {
        flex: 1 1 50%;
        margin-bottom:20%;
      }
      .page-wizard__timeline {
        flex: 1 1 50%;
        padding-right:40px;
      }
    }

  @media screen and (max-width: 580px)  {
    .screen-desktop & {
      .page-wizard__button {
        button {
          width: 100%;
        }
      }
    }
  }

  .screen-ie & {
    .page-wizard__content {
      flex: 1 0 auto;
      height: auto;
      min-height: 454px;
    }
    .page-wizard__button {
      flex: 0 0 auto;
    }
  }
`;

function TimelinePage({
  allActive,
  backgroundVideo = false,
  buttonDisplayed = true,
  buttonText,
  currentPath,
  headerGroupContent,
  files,
  infoGroupContent,
  nextPage,
  prevPage,
  questionnaire,
  saveIncident,
  showTimeline = true,
  timeline,
  timelineHeader,
  unmuted,
  isVIP,
  showExtraBlock = false,
  extraBlock,
  customMiddleLogo = false,
  middleLogo,
}) {

  const navigate = useNavigate()
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const scaling = useScaling();

  const isThemed = !!insurer && insurer.brand !== companyNames.VIP && insurer.objectType !== proccessType.DI;
  const themeContext = useTheme()
  const themes = useMemo(() => isThemed ? [] : !backgroundVideo ? ['theme-dark'] : ['home-layout'], [backgroundVideo, isThemed]);

  useThemes(themes);

  useEffect(() => {
    if (!!questionnaire && questionnaire.length) {
      const progress = questionnaire.find(page => page.path === currentPath).name
      browserStorage.setProgress(progress);
    }
  }, [currentPath, questionnaire])

  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath
  async function handleOnClick() {
    await saveIncident({ ...incident }, isPageBeforeSuccess);
    goToNextPage(navigate, questionnaire, incident, currentPath === '' ? '' : currentPath, files ? files : {});
  }

  useEnterKey(handleOnClick);

  const iconLink = (triggerKey) => ({
    type: 'arrow-left',
    theme: isThemed ? 'secondary' : 'primary',
    to: prevPage,
    replace: true,
    triggerKey
  });

  const iconLinkForward = (triggerKey) => ({
    type: 'arrow-right',
    theme: isThemed ? 'secondary' : 'primary',
    to: nextPage,
    replace: true,
    triggerKey
  });

  const companyLogo = {
    ...chooseLogoToBeDisplayed(insurer),
    className: isVIP ? 'vip-logo' : '',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  };

  const timelineIcon = (item, i) => ({
    name: item.icon,
    className: 'timeline__item__icon',
    timeline: true,
    muted: i !== unmuted || !allActive
  });

  const button = {
    onClick: handleOnClick,
    className: 'button--icon button--next__page',
    theme: isThemed ? 'primary' : 'secondary',
    justify: 'space-between'
  };

  const chooseMiddleLogo = customMiddleLogo
    ? <CompanyLogo {...middleLogo} />
    : <CompanyLogo {...companyLogo} />

  return (
    <React.Fragment>
      <StyledMain className="main__damage--start" $scaling={scaling}>
        <NavBar blackPage={!isThemed && !backgroundVideo} isVIP={isVIP}>
          <Container className="icon-link-container">
            {!!prevPage && <IconLink {...iconLink([37])} />}
            {!!nextPage && <IconLink {...iconLinkForward([39])} />}
          </Container>
          {chooseMiddleLogo}
          {<div className="empty-div" />}
        </NavBar>
        <Section className="page-wizard__content">
          <Container className="page-wizard__heading">
            <HeaderGroup className={"header-group-timeline"}>{headerGroupContent}</HeaderGroup>
            {infoGroupContent &&
              <Container className="page-wizard__info">
                {infoGroupContent}
              </Container>}
            {showExtraBlock && <>{extraBlock}</>}
          </Container>

          <Container className="page-wizard__timeline">
            {timelineHeader && <Heading level={'4'} className="page-wizard__timeline--heading">{timelineHeader}</Heading>}
            {showTimeline && <Timeline>
              {timeline.map((item, i) => (
                <TimelineItem key={i} muted={i !== unmuted} active={i === unmuted}>
                  <TimelineIcon {...timelineIcon(item, i)} />
                  <Text className="timeline__text">{item.text}</Text>
                </TimelineItem>
              ))}
            </Timeline>}
          </Container>
        </Section>
        <Container className="page-wizard__button">
          {buttonDisplayed &&
            <Button {...button}>
              {!!buttonText ? buttonText : 'Volgende'}
              <UseIcon name="arrow-right" className="button__icon" />
            </Button>}
        </Container>
      </StyledMain>
      {backgroundVideo &&
        <BackgroundVideo
          type="video/mp4"
          loop={true}
          autoPlay={true}
          videoFileName="DEF2.mp4"
          img={!isVIP || isThemed}
          imgFileName="bs19.jpg"
        />}
    </React.Fragment>
  );
}

export default TimelinePage;
