import { datadogLogs } from '@datadog/browser-logs'

import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'

import { TEMP_INCIDENT_UPDATED } from '@domain/action-types';
import { LicensePlateService } from '@domain/services';
import { useDispatchError } from '..';
import { browserStorage } from '@domain/helpers';

function useTempIncidentLicensePlateCheck(location) {
  // Only happens with Digital Intake cases and Schadegarant cases 
  // that redirect to CP without going trough the starting page
  // The incident will only be created in the core platform
  // after all necessary data was collected but we still need
  // to do a licensePlate check.

  // Currently it happens on the first step, so we also need
  // to create the incident for the first time.

  const dispatch = useDispatch();
  const { dispatchNewError } = useDispatchError();
  const pid = useSelector(state => state.pid);
  const fid = useSelector(state => state.fid)
  const rid = useSelector(state => state.rid)
  const uuid = useSelector(state => state.uuid)
  const currentIncident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer) || {
    brandName: 'emptyInsurer',
    HelpcenterLink: 'emptyHelpcenterLink',
  }

  const checkingLicensePlate = useRef(false);

  const tempIncidentLicensePlateCheck = useCallback(
    async function (newDetails) {
      if (checkingLicensePlate.current) {
        return;
      }

      // check for newattributes before sending the info to core platform
      const newAttibutes = Object.keys(newDetails)
      const noChanges = newAttibutes.every(name => {
        if (name === 'damageDate') {
          return moment(newDetails[name]).format('YYYYMMDD') === moment(currentIncident[name]).format('YYYYMMDD')
        }
        return newDetails[name] === currentIncident[name]
      })

      if (noChanges) {
        if (currentIncident.vehicleIdentificationID) {
          return currentIncident;
        }
      }
      checkingLicensePlate.current = true;
      const { licensePlate } = newDetails || null;

      try {
        const response = await LicensePlateService.check(pid, licensePlate, fid, newDetails.damageDate, rid, insurer, uuid);
        // It will respond with part of an incident
        // But we might also have saved other new data that wasn't passed
        // to the corePlatform but needs to be saved in the store
        // Initializes the flag to know if temp data was saved to peristent incident and to the core platform
        const { status, ...responseWithNoStartus } = response
        const newTempIncident = { ...currentIncident, ...newDetails, ...responseWithNoStartus };

        if (response.errorCode === 19 || response.errorCode === 1 || response.errorCode === 2) {
          checkingLicensePlate.current = false;
          return newTempIncident
        }
        browserStorage.setTempSaved(false)
        browserStorage.saveValuesInLocalStorage(newTempIncident);
        dispatch({
          type: TEMP_INCIDENT_UPDATED,
          incident: newTempIncident,
        })
        checkingLicensePlate.current = false;
        return newTempIncident;
      } catch ({ error }) {
        const errorLogMessage = "Error occurred trying to use licensePlateCheck (LicensePlateService.check):"
        const errorInstance = new Error(errorLogMessage)
        datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
        dispatchNewError(error);
      }
      checkingLicensePlate.current = false;
    },
    [pid, dispatch, dispatchNewError, currentIncident, fid, rid, insurer, uuid]
  );

  return tempIncidentLicensePlateCheck;
}

export default useTempIncidentLicensePlateCheck;
