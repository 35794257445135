// NPM imports.
import React, { useMemo } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
// Bounded context imports.
import routes from './routes'
import Instructions from './Instructions'
import Start from './Start'
import Chauffeur from './Chauffeur'
import Overview from './Overview'
// import AgreementRecovery from './AgreementRecovery'
import ReplacementVehicle from './ReplacementVehicle'
import Questions from './Questions'
import OwnRisk from './OwnRisk'
// import Signing from './Signing'
//import End from './End'
import Success from './Success';
import { useThemes } from '@domain/hooks'
import { companyNames, proccessType } from '@domain/constants'

function CheckOut() {
  const insurer = useSelector(state => state.insurer);

  const isThemed = !!insurer && insurer.brand !== companyNames.VIP && insurer.objectType !== proccessType.DI;
  const themes = useMemo(() => [isThemed ? 'theme-custom' : 'theme-def'], [isThemed]);
  useThemes(themes);

  return (
    <React.Fragment>
      <Routes>
        <Route path={routes.instructions} element={<Instructions />} />
        <Route path={routes.start} element={<Start />} />
        <Route path={routes.chauffeur} element={<Chauffeur />} />
        <Route path={routes.overview} element={<Overview />} />
        <Route path={routes.replacementVehicle} element={<ReplacementVehicle />} />
        <Route path={routes.ownRisk} element={<OwnRisk />} />
        <Route path={routes.questions} element={<Questions />} />
        <Route path={routes.success} element={<Success />} />
        <Route path='*' element={<Navigate to={routes.instructions} />} />
      </Routes>
    </React.Fragment>
  );
}

export default CheckOut
