import React from 'react';
import { isMobile } from 'react-device-detect';
import { styled } from '@mui/material/styles';

import { Colors, Shadows } from '@domain/theming';
import Color from 'color';
import { useScaling } from '@domain/hooks';
import Loader from '../Loader';

const StyledButton = styled('button', {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${props => props.$dynamicFontSize ? (props.$scaling.scaleUpTo4K(64) + 'px') : '64px'};
  padding: 12px 24px;
  font-size: ${props => props.$dynamicFontSize ? (isMobile
    ? props.$scaling.textSizeMobile(12)
    : props.$scaling.textSize(12)) + 'px' : '12'}
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: center;
  background: ${props => props.$isThemed ? props.theme.colors.primary : Colors.BLACK};
  color: ${props => props.theme.colors.contrastText};
  border: 1px solid
    ${props => props.theme.colors.primary};
    border-radius: 16px;
    cursor: ${props => props.$variant === 'disabled' ? 'not-allowed' : 'pointer'};
    transition: 160ms cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: ${Shadows.SHADOW6};
    @media (hover: hover) {
      &:hover {
        transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
        box-shadow: ${Shadows.SHADOW3};
        background: ${props => props.$variant !== 'outline' ? props.$isThemed ? props.theme.colors.primary : Color(Colors.BLACK).rgb().alpha(0.7).string() : Color(Colors.BLACK).rgb().alpha(0.08).string()}
      }
    }
    &:focus {
      transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
      box-shadow: ${Shadows.SHADOW3};
    }
    &:active {
      transform: scale(0.98);
      transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
      box-shadow: ${Shadows.SHADOW1};
    }
    &[disabled] {
      box-shadow: none;
    }
    &.outline {
      box-shadow: none;
    }
    .card & {
      margin: auto auto 0;
    }
    .card form & {
      margin: 30px auto 0;
    }
`;

const StyledButtonWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
`;

function SubmitButton({
  value,
  variant = 'primary',
  disabled,
  type = 'submit',
  children,
  dynamicFontSize,
  loading,
  isThemed = true
}) {

  const styledButtonTheme = disabled ? 'disabled' : variant;
  const scaling = useScaling();

  return (
    <StyledButton
      type={type}
      value={value}
      className={variant === 'outline' ? 'button--submit ' + variant : 'button--submit'}
      $variant={styledButtonTheme}
      disabled={disabled}
      $dynamicFontSize={dynamicFontSize}
      $scaling={scaling}
      $isThemed={isThemed}
    >
      <StyledButtonWrapper className="button-wrapper">
        {loading ?
          <Loader color="white" size="small" /> :
          React.Children.toArray(children)}
      </StyledButtonWrapper>
    </StyledButton>
  );
};

export default SubmitButton;
