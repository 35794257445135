import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';

import {
  BackgroundVideo,
  CompanyLogo,
  Form,
  Heading,
  Input,
  Loader,
  Main,
  ValidationFeedback,
  NavBar,
} from '@domain/components';
import { companyNames, errorConstants, proccessType } from '@domain/constants';
import { chooseLogoSize, chooseLogoToBeDisplayed } from '@domain/helpers';
import { useSignIn, useThemes, useScaling } from '@domain/hooks';
import { isMobile } from 'react-device-detect';

const StyledMain = styled(Main, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  .authentication-page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 40px;
  }
  &.main--dimmed.main--more-dimmed {
    &:after {
      z-index: -1;
      background-color: rgba(0, 0, 0, 0.75);
    }
  }
`;

const StyledForm = styled(Form, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  position: relative;
  margin-top: ${props => props.$scaling.scaleUpTo4K(100)}px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: ${props => props.$scaling.scaleUpTo4K(300)}px;
`;

const StyledValidationContainer = styled('div', {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  text-align: center;
  height: ${props => props.$scaling.scaleUpTo4K(50)}px;
  .validation-feedback.authentication--error {
    font-size: ${props => !isMobile ? props.$scaling.textSize(16) : props.$scaling.textSizeMobile(16)}px;
    line-height: ${props => !isMobile ? props.$scaling.textSize(20) : props.$scaling.textSizeMobile(20)}px;
      justify-content: center;
      display: inline-block;
    }
`;

function SignIn() {

  const [value, setValue] = useState('');
  const uuid = useSelector(state => state.uuid);
  const pid = useSelector(state => state.pid);
  const insurer = useSelector(state => state.insurer);

  const isThemed = !!insurer && insurer.brand !== companyNames.VIP && insurer.objectType !== proccessType.DI;
  const isSG = !!insurer && insurer.objectType === proccessType.Schadegarant;
  const themes = useMemo(() => [isThemed ? 'theme-custom' : 'theme-dark'], [isThemed]);
  useThemes(themes);
  const themeContext = useTheme()
  const [attempted, setAttempted] = useState(false);
  const [attemptedValue, setAttemptedValue] = useState("");
  const loggingIn = useRef(false);
  const signIn = useSignIn();
  const [errorCode, setErrorCode] = useState(0);
  const scaling = useScaling();

  async function handleOnChange(event) {
    const newValue = event.target.value.toUpperCase();
    // Next line checks that the license plate is 6 characters that are only letters or numbers
    // So 09SPDN will match, but 09-S-PDN will not, and neither will 09§PDN
    const newValueIsAlphaNumeric = newValue.match('^[a-zA-Z0-9]*$');
    if (newValueIsAlphaNumeric && newValue.length <= 6) {
      setValue(newValue);
    }
  }

  function errorMessage(errorCode) {
    if (errorCode === 0) {
      return;
    }
    const { httpErrorMessages } = errorConstants;
    return httpErrorMessages[errorCode] || httpErrorMessages.default;
  }

  useEffect(() => {
    // Because logIn uses asynchronous logic it needs to be declared as a function inside of useEffect and then called
    // We could also declare it outside the hook, or use .then and .catch methods
    async function logIn() {
      if (value.length === 6) {
        if (loggingIn.current === true) {
          return;
        }
        loggingIn.current = true;
        if (!attempted) {
          setAttempted(true);
        }
        if (attemptedValue !== value) {
          const error = await signIn(value, uuid);
          if (error) {
            setAttemptedValue(value)
            const { errorCode } = error;
            setErrorCode(errorCode);
          }
        }
        loggingIn.current = false;
      }
    }
    logIn();
  }, [value, attempted, signIn, uuid, attemptedValue]);

  if (!insurer && (!!pid || !!uuid)) {
    return <Loader />;
  }

  const isVIP = !!insurer && insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand)

  const companyLogo = {
    ...chooseLogoToBeDisplayed(insurer),
    className: isVIP ? 'vip-logo' : '',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  const middleLogo = {
    src: insurer && insurer.logoURL ? insurer.logoURL : '',
    alt: insurer && insurer.brandName ? insurer.brandName : 'uw verzekeraar',
    height: 60,
    // width: 'auto',
  }

  const chooseMiddleLogo = isSG
    ? <CompanyLogo {...middleLogo} />
    : <CompanyLogo {...companyLogo} />

  if (insurer && insurer.specialHandling && insurer.specialHandling === 'IncidentStorage') {
    return (
      <React.Fragment>
        {uuid && (
          <NavBar isVIP={isVIP}>
            <div></div>
            {chooseMiddleLogo}
            <div></div>
          </NavBar>
        )}
        <StyledMain dimmed={!uuid} className="main--more-dimmed" $scaling={scaling}>
          <div className="authentication-page-content">
            <Heading level="3" className="authentication-heading">
              Helaas is uw dossier niet meer beschikbaar, neem contact op met uw verzekeraar voor meer informatie.
            </Heading>
          </div>
        </StyledMain>
        {!isSG && <BackgroundVideo
          type="video/mp4"
          loop={true}
          autoPlay={true}
          videoFileName="DEF1.mp4"
          img={!isVIP || isThemed}
          imgFileName="BSSP03.jpg"
        />}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {!uuid && (
        <NavBar isVIP={isVIP}>
          <div></div>
          {chooseMiddleLogo}
          <div></div>
        </NavBar>
      )}
      <StyledMain dimmed={!uuid} className="main--more-dimmed" $scaling={scaling}>
        <div className="authentication-page-content">
          <Heading level="2" className="authentication-heading">
            {uuid
              ? 'Vul uw kenteken in ter verificatie:'
              : 'Volg de link in de email die u heeft ontvangen om gebruik te maken van deze website'}
          </Heading>
          {uuid && (
            <StyledForm
              className="authentication-form"
              onSubmit={e => e.preventDefault()}
              $scaling={scaling}
            >
              <Input
                disabled={!uuid}
                type="text"
                name="customerLicensePlate"
                onChange={handleOnChange}
                placeholder=""
                valid={errorCode === 0}
                id="customerLicensePlate"
                label=""
                textColor={isSG ? "#000000" : "#FFFFFF"}
                autoFocus
                borderColor="#FFFFFF"
                displayEndAdornment={false}
                value={value}
                textAlign="center"
                letterSpacing="0.6em"
              />
              <StyledValidationContainer
                className="validation-error-container--authentication"
                $scaling={scaling}
              >
                {errorCode !== 0 && (
                  <ValidationFeedback
                    type="error"
                    className="authentication--error"
                  >
                    {errorMessage(errorCode)}
                  </ValidationFeedback>
                )}
              </StyledValidationContainer>
            </StyledForm>
          )}
        </div>
      </StyledMain>
      {!isSG && <BackgroundVideo
        type="video/mp4"
        loop={true}
        autoPlay={true}
        videoFileName="DEF1.mp4"
        img={!isVIP || isThemed}
        imgFileName="BSSP03.jpg"
      />}
    </React.Fragment>
  );
}

export default SignIn;
