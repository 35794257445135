import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  Button,
  NavBar,
  ProgressionIndicator,
  Main,
  ImageWheelContainer,
  ImageWheelImage,
  DecorativeImage,
  Container,
  Section,
  IconLink,
  Loader,
} from '@domain/components';
import { isMobile } from 'react-device-detect';
import { getPrevNextPagePath, stripLastPathSegment } from '@domain/helpers';

import imageRoutes from '../routes';
import { useFilesEndpoint } from '@domain/hooks';
import questionnaire from '../../questionnaire';
import { useLocation, useNavigate } from 'react-router-dom';

function ImageDelete() {

  const intakeImages = useSelector(state => state.intakeImages);
  const selectedImage = useSelector(state => state.selectedImage);
  const { deleteFile, setSelectedImage } = useFilesEndpoint('intakeImages');
  const incident = useSelector(state => state.incident);

  const location = useLocation()
  const navigate = useNavigate()
  const currentPath = location.pathname;
  const prevPage = stripLastPathSegment(currentPath) + imageRoutes.upload;
  const pagePath = getPrevNextPagePath(questionnaire, incident, stripLastPathSegment(currentPath));
  const { nextPage } = pagePath;
  function onDeleteClick(i) {
    deleteFile(intakeImages[i]);
  }

  useEffect(() => {
    if (selectedImage === null) {
      setSelectedImage(0);
    }
  }, [selectedImage, setSelectedImage]);

  useEffect(() => {
    return () => setSelectedImage(null);
  }, [setSelectedImage]);

  useEffect(() => {
    if (intakeImages && selectedImage && !intakeImages[selectedImage]) {
      setSelectedImage(selectedImage - 1);
    }

    if (intakeImages && intakeImages.length === 0) {
      navigate(stripLastPathSegment(currentPath) + imageRoutes.upload);
    }
  }, [intakeImages, currentPath, selectedImage, setSelectedImage]);

  const carouselImages = intakeImages ? intakeImages.map(image => image.content) : [];


  const main = {
    className: 'main__image-delete',
  };

  const iconLink = {
    type: 'arrow-left',
    theme: isMobile ? 'primary' : 'secondary',
    to: prevPage,
  };

  const iconLinkForward = {
    type: 'arrow-right',
    theme: isMobile ? 'primary' : 'secondary',
    to: nextPage && nextPage,
  };

  const displayWheelImage = (image, i) => {
    const { cpID, content } = image;

    return (
      <ImageWheelImage
        src={content}
        key={cpID}
        onClick={() => setSelectedImage(i)}
        selected={i === selectedImage}
      />
    );
  };

  const button = {
    className: 'button--image-delete',
    justify: 'center',
    type: 'button',
    onClick: () => onDeleteClick(selectedImage),
    shadow: true,
  };

  if (!intakeImages) {
    return <Loader />;
  }

  if (isMobile) {
    return (
      <React.Fragment>
        <Main {...main}>
          <NavBar className="image-delete__navbar">
            <Container className="icon-link-container">
              <IconLink {...iconLink} />
              {nextPage && <IconLink {...iconLinkForward} />}
            </Container>
            <ProgressionIndicator steps={3} current={2} color="white" />
            <Container className="empty-div" />
          </NavBar>
          <Section className="image__delete__section">
            <DecorativeImage
              className="image__delete__selected"
              imageSrc={carouselImages[selectedImage]}
            />
            <Container className="image__delete__wheel__container">
              <ImageWheelContainer>
                {intakeImages.map(displayWheelImage)}
              </ImageWheelContainer>
            </Container>
            <Button {...button}>Verwijder</Button>
          </Section>
        </Main>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Main {...main}>
        <Container className="image-delete__navbar-container">
          <NavBar>
            <Container className="icon-link-container">
              <IconLink {...iconLink} />
              {nextPage && <IconLink {...iconLinkForward} />}
            </Container>
            <ProgressionIndicator steps={3} current={2} color="white" />
            <Container className="empty-div" />
          </NavBar>
        </Container>
        <Container className="image-delete__img-container">
          <DecorativeImage
            className="image__delete__selected"
            imageSrc={carouselImages[selectedImage]}
          />
        </Container>

        <ImageWheelContainer>
          {intakeImages.map(displayWheelImage)}
        </ImageWheelContainer>
        <Container className="image-delete__btn-container">
          <Button {...button}>Verwijder</Button>
        </Container>
      </Main>
    </React.Fragment>
  );
}

export default ImageDelete;
