import { datadogLogs } from "@datadog/browser-logs";

async function encodeFileInBase64(file) {
  try {
    const encodedFile = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(String(reader.result));
      reader.onerror = (err) => reject(err)
      reader.readAsDataURL(file);
    });

    return encodedFile;
  } catch (error) {
    const errorLogMessage = `Error occurred during encode of files (encodeFileInBase64):`
    const errorInstance = new Error(errorLogMessage)
    datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
  }
}

export default encodeFileInBase64;
