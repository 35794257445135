// NPM imports
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { styled } from '@mui/material/styles';
import { Colors } from '@domain/theming';
import { useScaling } from '@domain/hooks';

const StyledLi = styled('li', {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  display: flex;
  color: ${Colors.WHITE};
  list-style: none;
  position: relative;
  flex: 0 0 100px;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 40px 44px;
  font-weight: normal;
  transition: 1s;
  opacity: .3;
  &:not(:last-child) {
    &:before {
      content: '';
      width: ${props => props.$scaling.scaleUpTo4K(1).toFixed()}px;
      background-color: ${Colors.WHITE};
      position: absolute;
      top: calc(${props => props.$scaling.scaleUpTo4K(32)}px + ${props => props.$scaling.scaleUpTo4K(4)}px);
      left: ${props => props.$scaling.scaleUpTo4K(16)}px;
      height: calc(100% - (${props => props.$scaling.scaleUpTo4K(32)}px + ${props => props.$scaling.scaleUpTo4K(8)}px));
      z-index: 0;
      opacity: 1;
    }
  }
  &:last-child {
    padding-bottom: ${props => props.$scaling.scaleUpTo4K(20)}px;
  }
  &:not(.timeline__item--muted) {
    opacity: 1;
  }
  .timeline__text {
    font-weight: inherit;
    min-height: ${props => props.$scaling.scaleUpTo4K(32)}px;
    padding-left: ${props => props.$scaling.scaleUpTo4K(24)}px;
    display:block;
    width: 100%;
  }
  .timeline__subtext {
    padding-left: 24px;
    margin-top: 5px;
    font-weight: normal;
    width: 100%;
    display: block;
  }
  .contact-person-picture {
    width: ${props => props.$scaling.scaleUpTo4K(32)}px;
    height: ${props => props.$scaling.scaleUpTo4K(32)}px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: ${props => props.$scaling.scaleUpTo4K(8)}px;
    object-fit: cover;
    object-position: 0 10%;
  }
  .timeline-checkmark {
    background: ${Colors.BLACK};
    color: ${Colors.WHITE};
    border: 1px solid ${Colors.WHITE};
    border-radius: 50%;
    position: absolute;
    top: -10px;
    left: 22px;
  }
  &.timeline__item--muted {
    font-weight: normal;
  }
  &.timeline__item--active {
    font-weight: bold;
    &:not(:last-child) {
      &:before {
        opacity: .3;
      }
    }
  }

  .screen-desktop & {
    padding: 0 0 ${props => props.$scaling.scaleUpTo4K(48)}px ${props => props.$scaling.scaleUpTo4K(40)}px;
    &:last-child {
      padding-bottom: 0;
    }
    .timeline__text,
    .timeline__subtext {
      padding-left: ${props => props.$scaling.scaleUpTo4K(24)}px;
    }
  }

  .theme-custom & {
    color: ${props => props.theme.colors.primary};
    &:before {
      background-color: ${props => props.theme.colors.primary};
    }
    .timeline__icon {
      background: ${props => props.theme.colors.primary};
      color: ${props => Colors.WHITE};
    }
    .timeline-checkmark {
      background: ${props => props.theme.colors.primary};
    }
  }

  @media screen and (max-width: 1263px)  {
    .screen-desktop & {
      .timeline__text,
      .timeline__subtext {
        padding-left: 15px;
      }
    }
  }
`;

function TimelineItem({ active, children, muted, className }) {
  const scaling = useScaling();
  const classes = classNames('timeline__item', {
    'timeline__item--muted': muted,
    'timeline__item--active': active,
  });
  return <StyledLi className={classes} data-testid={active ? 'timeline-item--active' : muted ? 'timeline-item--muted' : 'timeline-item'} $scaling={scaling}>{React.Children.toArray(children)}</StyledLi>;
}

TimelineItem.propTypes = {
  children: PropTypes.array.isRequired,
  muted: PropTypes.bool,
  className: PropTypes.string
};

export default TimelineItem;
