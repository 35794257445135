import React from 'react';

import { render } from '@testing-library/react';
import {
  Route, RouterProvider,
  createMemoryRouter,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider, createTheme } from '@mui/material';
import { createCustomTheme } from '@domain/helpers';
import { Colors } from '@domain/theming';
import { QuestionnaireProvider } from '@domain/providers';
import { HelmetProvider } from 'react-helmet-async'
const branding = {
  primaryColor: Colors.GREY_DARKER,
  // secondaryColor: '#FFFFFF',
  // fontFamily: 'Gabriel sans',
  // logoURL: '',
  //  faviconURL: 
};

const theme = createCustomTheme(branding);
const muiDefaultTheme = createTheme();
const muiTheme = { ...muiDefaultTheme, ...theme };


const renderInProvider = (
  ui,
  {
    store = {},
  }
) => {
  const Wrapper = ({ children }) => (
    <Provider store={store}>
      <HelmetProvider>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={muiTheme}>
            <StyledEngineProvider injectFirst>
              {children}
            </StyledEngineProvider>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </HelmetProvider>
    </Provider>
  )
  return {
    ...render(ui, { wrapper: Wrapper })
  }
}

const renderInRouter = (
  ui,
  {
    store = {},
    route = '/',
    history = createMemoryRouter([{ path: route, element: ui }], { initialEntries: [route] }),
  } = {}
) => {
  const Wrapper = ({ children }) => (
    <Provider store={store}>
      <HelmetProvider>
        <QuestionnaireProvider>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={muiTheme}>
              <StyledEngineProvider injectFirst>
                <RouterProvider router={history}>
                  {children}
                </RouterProvider>
              </StyledEngineProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </QuestionnaireProvider>
      </HelmetProvider>
    </Provider>
  )
  return {
    ...render(ui, {
      wrapper: Wrapper
    }),
    history,
  }
}

const renderInRoute = (
  ui,
  {
    path = "/",
    route = '/',
    history = createMemoryRouter([{ path: route, element: ui }], { initialEntries: [route] }),
    store = {},
  } = {}
) => {

  const Wrapper = ({ children }) => (
    <Provider store={store}>
      <HelmetProvider>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={muiTheme}>
            <StyledEngineProvider injectFirst>
              <RouterProvider router={history}>
                <Route path={path} component={ui} />
              </RouterProvider>
            </StyledEngineProvider>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </HelmetProvider>
    </Provider>
  )
  return {
    ...render(
      ui, {
      wrapper: Wrapper,
    }
    ),
    history,
  };
}

export default {
  renderInProvider,
  renderInRoute,
  renderInRouter,
}
