// NPM imports
import React from 'react';
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles';
import { useScaling } from '@domain/hooks';

const StyledList = styled('ul', {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  padding: 20px 0;
  .screen-desktop & {
    max-width: ${props => props.$scaling.scaleUpTo4K(350)}px;
  }
`;

function Timeline({ children }) {
  const scaling = useScaling();
  return <StyledList className="timeline" $scaling={scaling}>{React.Children.toArray(children)}</StyledList>;
}

Timeline.propTypes = {
  children: PropTypes.any.isRequired,
}

export default Timeline;
