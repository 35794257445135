import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { COMPANY_TOKEN_FOUND } from '@domain/action-types';
import { browserStorage } from '@domain/helpers';

// This hook should only ever be loaded once in the entire app
const useCompanyTokenChecker = location => {
  const [searchP, setSearchP] = useSearchParams()
  const uuid = useSelector(state => state.uuid);
  const companyToken = useSelector(state => state.companyToken);
  const incident = useSelector(state => state.incident);
  const dispatch = useDispatch();

  // We check the URL, followed by local storage, followed by the incident object for a company token
  useEffect(() => {
    if (!uuid) {
      return;
    }

    if (companyToken) {
      return;
    }

    const companyTokenFromURL = searchP.get('CompanyToken')

    const companyTokenFromURLValid = companyTokenFromURL === '' || companyTokenFromURL === 'undefined' || companyTokenFromURL === null ? false : true;
    if (companyTokenFromURLValid && companyTokenFromURL !== companyToken) {
      dispatch({
        type: COMPANY_TOKEN_FOUND,
        companyToken: companyTokenFromURL,
      });
      browserStorage.setCompanyToken(companyTokenFromURL)
      return;
    }

    const storedCompanyToken = browserStorage.getCompanyToken()
    const storedCompanyTokenValid = storedCompanyToken === '' || storedCompanyToken === 'undefined' || storedCompanyToken === null ? false : true;

    if (storedCompanyTokenValid && storedCompanyToken !== companyToken) {
      dispatch({
        type: COMPANY_TOKEN_FOUND,
        companyToken: storedCompanyToken,
      });
      return;
    }

    const incidentCompanyToken = incident ? incident.companyToken : '';

    if (incidentCompanyToken && incidentCompanyToken !== companyToken && incidentCompanyToken !== '') {
      dispatch({
        type: COMPANY_TOKEN_FOUND,
        companyToken: incidentCompanyToken,
      });
      browserStorage.setCompanyToken(companyTokenFromURL)
      return;
    }
  }, [companyToken, dispatch, incident, location.search, uuid]);
};

export default useCompanyTokenChecker;
