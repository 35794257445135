import React from 'react';
import RadioPage from '@domain/pages/RadioPage';

const customerGender = 'customerGender';
function Gender() {

  const fields = [{
    id: 1,
    value: 'male',
    triggerKeys: [77, 109, 49],
    label: 'Man'
  }, {
    id: 2,
    value: 'female',
    triggerKeys: [86, 118, 50],
    label: 'Vrouw'
  }
  ]

  return <RadioPage fieldName={customerGender} styleLabel={'gender'} fields={fields} />
}

export default Gender;