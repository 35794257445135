// NPM imports.
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { styled } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';
import { useScaling } from '@domain/hooks';

const StyledLegend = styled('legend', {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
font-size: ${props => !isMobile ? props.$scaling.textSize(16) : props.$scaling.textSizeMobile(16)}px;
line-height: ${props => !isMobile ? props.$scaling.textSize(20) : props.$scaling.textSizeMobile(20)}px;
  color: inherit;
  font-weight: bold;
  margin-bottom: 16px;
  .theme-custom & {
    color: ${props => props.theme.colors.primary};
  }
  .screen-desktop & {
    margin: 0;
    width: 100%;
  }
`;

function Legend({ children, className }) {
  const scaling = useScaling();
  const classes = classNames('legend', className)

  return <StyledLegend className={classes} $scaling={scaling}>{React.Children.toArray(children)}</StyledLegend>
}

Legend.propTypes = {
  children: PropTypes.string.isRequired,
}

export default Legend
