import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { ContainerSP, HeaderSP, Loader } from '@domain/components';
import { chooseLogoSize, generatePageContent } from '@domain/helpers';
import { useSaveIncidentFactory, useScaling } from '@domain/hooks';
import { Colors, Gradients } from '@domain/theming';
import { motion } from 'framer-motion';
import { styled, useTheme } from '@mui/material/styles';
import InfoBlock from './InfoBlock';
import FormWrapperSG from './FormWrapperSG';
import { detectContentType } from '@domain/content';
import { useQuestionnaire } from '@domain/providers';
import { useLocation } from 'react-router-dom';

const StyledDiv = styled('div')`
min-height: 100vh;
.screen-ie & {
  .landing__card-container {
    &--inner {
      min-height: 100vh;
    }
  }
}

`

const StyledContainer = styled(motion(ContainerSP))`
  .landing__intro-text {
    position: relative;
    &--inner {
      // min-height: 100%;
      top: 0px;
    }
    &--content {
      width: 100%;
      padding-bottom: ${props => props.$scaling.scaleUpTo4K(20)}px;
    }
    &--signature {
      p {
        padding-bottom: 2px;
      }
      .logo {
        margin: 8px 8px 0;
      }
    }
    h1 {
      color: ${Colors.GREY_DARK};
      text-shadow: 'none';
    }
    p {
      margin: 0;
      color: ${Colors.GREY_DARK};
      text-shadow: 'none';
    }
    a {
      text-decoration: none;
      color: ${Colors.GREY_DARK};
    }
    ul {
      margin: ${props => props.$scaling.scaleUpTo4K(15)}px 0;
      font-size: ${props => props.$scaling.scaleUpTo4K(16)}px;
      list-style: none;
      padding-left: ${props => props.$scaling.scaleUpTo4K(30)}px;
      padding-inline: 0;
      li {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(8)}px;
        display: flex;
        align-items: center;
        &::before {
          content: "•";
          color: ${Colors.GREY_DARK};
          font-weight: bold;
          text-align:center;
          flex: 0 0 ${props => props.$scaling.scaleUpTo4K(30)}px;
        }
        p {
          text-align: left;
          flex: 1 1 auto;
        }
      }
    }
  }
  .landing__card-container {
    position: relative;
    &--inner {

      btn.main__button--redirect {
        color: ${props => props.theme.colorAsBackground ? Colors.BLACK : props.theme.colors.primary}
      }
    }
  }

  &.spl2 {
    .landing__intro-text {
      &--inner {
        // padding: ${props => props.$scaling.scaleUpTo4K(60)}px;
      }
    }
  }

  .screen-ie & {
    .landing__card-container {
      &--inner {
        height: 0px;
      }
    }
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    .landing__intro-text {
      margin: 0 auto;
      padding-right: 0;
      min-width: auto;
      flex: 1 0 auto;
      &--inner {
        min-height: auto;
        width: 100%;
        padding-bottom: 60px;
      }
      h1, p {
        text-align: center;
      }
      ul {
        max-width: 300px;
        margin: 15px auto;
        li {
        }
      }
    }
    .landing__card-container {
      overflow: visible;
      flex: 1 0 auto;
      &--inner {
        width: 100%;
        min-height: initial;
        padding: 80px 40px 140px;
      }
    }

    &.spl2 {
      .background-container {
        position:fixed;
      }
      .landing__intro-text {
        &--inner {
          padding-top: 160px;
        }
      }
      .landing__card-container {
        &--inner {
          padding: 40px 40px 140px;
        }
      }
    }
  }


  @media screen and (max-width: 540px) {
    p {
      text-align:justify !important;
    }
    .container.landing__intro-text {
      &--inner {
        padding: 120px 30px 30px;
      }
    }

    &.spl2 {
      text-align:justify !important;
      .landing__intro-text {
        &--inner {
          padding: 60px 30px 30px;
        }
      }
      .landing__card-container {
        &--inner {
          padding: 15px 30px 30px;
        }
      }
    }

`;


const DamageType = () => {

  const { questionnaire } = useQuestionnaire();
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer)
  const files = useSelector(state => state.files)

  const location = useLocation()
  const currentPath = location.pathname;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);

  const themeContext = useTheme();

  const scaling = useScaling();

  if (!insurer || !questionnaire || !incident) {
    return <Loader />;
  }

  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name

  const content = generatePageContent(contentType, insurer, incident, false, false, pageName)

  const infoBlockContent = content["infoBlock"]
  const formBlockContent = content["formBlock"]

  return <StyledDiv>
    <HeaderSP fixed={false} shadow={true} background={Gradients().HEADER} imgSrc={(!!insurer && insurer.logoURL) || ''} height={chooseLogoSize(themeContext.logoSize)} />
    <StyledContainer
      className='flex-container spl2'
      justifyContent="space-between"
      hasTopBar={true}
      $scaling={scaling}
    >
      <ContainerSP
        className="landing__intro-text"
        boxSizing="content-box"
        flexDirection="column"
        flexGrow={'1'}
        flexShrink="0"
        flexBasis={'0px'}
        alignItems="center"
        justifyContent="center"
        margin="0"
        padding="0"
        position={'relative'}
      >
        <ContainerSP
          className="landing__intro-text--inner"
          flexGrow="1"
          flexShrink="0"
          flexBasis="auto"
          justifyContent="center"
          alignItems="center"
          padding={scaling.scaleUpTo4K(120) + 'px ' + scaling.scaleUpTo4K(60) + 'px'}
          maxWidth={scaling.scaleUpTo4K(640) + 'px'}
        >
          <InfoBlock content={infoBlockContent} />
        </ContainerSP>
      </ContainerSP>
      <ContainerSP
        className="landing__card-container"
        flexGrow="1"
        flexShrink="0"
        flexBasis={'0px'}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <ContainerSP
          className="landing__card-container--inner"
          flexDirection="column"
          flexGrow="0"
          alignItems="center"
          padding={scaling.scaleUpTo4K(60) + 'px'}
          maxWidth={scaling.scaleUpTo4K(640) + 'px '}
        >
          <FormWrapperSG content={formBlockContent} saveIncident={saveIncident} isPageBeforeSuccess={isPageBeforeSuccess} currentPath={currentPath} files={files} questionnaire={questionnaire} />
        </ContainerSP>
      </ContainerSP>
    </StyledContainer>
  </StyledDiv>
}
export default DamageType;