import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { styled } from '@mui/material/styles';
import colors from '../../theming/colors';
import shadows from '../../theming/shadows';
import { useScaling } from '@domain/hooks';
import Loader from '../Loader';

const StyledButton = styled('button', {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  height: ${props => props.$scaling.scaleUpTo4K(64)}px;
  width: 100%;
  text-transform: uppercase;
  border-radius: ${props => props.$scaling.scaleUpTo4K(16)}px;
  padding: ${props => props.$scaling.scaleUpTo4K(12)}px ${props => props.$scaling.scaleUpTo4K(24)}px;
  font-size: ${props => props.$scaling.textSizeMobile(12)}px;
  line-height: ${props => props.$scaling.textSizeMobile(18)}px;
  font-weight: 700;
  display: block;
  // align-items: stretch;
  cursor: pointer;
  border: 0;
  transition: 160ms cubic-bezier(0.4, 0, 0.2, 1);
  @media (hover: hover) {
    &:hover {
      // transform: scale(0.99);
      transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
  &:focus {
    // transform: scale(0.99);
    transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  &:active {
    transform: scale(0.98);
    transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  &.button--icon {
    .button-wrapper {
      justify-content: space-between;
    }
  }
  .button__icon {
    border-radius: ${props => props.$scaling.scaleUpTo4K(16)}px;
    margin-left: auto;
  }
  .button__icon--left {
    border-radius: ${props => props.$scaling.scaleUpTo4K(16)}px;
    margin-right: auto;
  }
  &.button--primary {
    background: ${colors.BLACK};
    color: ${colors.WHITE};
    box-shadow: ${shadows.SHADOW6};
    .theme-dark & {
      background:  ${colors.GREY_DARKER};
    }
    .button__icon {
      background: ${colors.WHITE};
      color: ${colors.BLACK};
    }
    .button__icon--left {
      background: ${colors.WHITE};
      color: ${colors.BLACK};
    }
    @media (hover: hover) {
      &:hover {
        box-shadow: ${shadows.SHADOW3};
      }
    }
    &:focus {
      box-shadow: ${shadows.SHADOW3};
    }
    &:active {
      box-shadow: ${shadows.SHADOW1};
    }
    
    .theme-dark & {
      background:  ${colors.GREY_DARKER};
    }
    .theme-custom & {
      background: ${props => props.theme.colors.primary};
      .button__icon {
        color: ${props => props.theme.colors.primary};
      }
      .button__icon--left {
        color: ${props => props.theme.colors.primary};
      }
    }
  }
  
  &.button--secondary {
    background: ${colors.WHITE};
    color: ${colors.BLACK};
  
    .button__icon {
      background: ${colors.BLACK};
      color: ${colors.WHITE};
    }
    .button__icon--left {
      background: ${colors.BLACK};
      color: ${colors.WHITE};
    }
    .theme-custom & {
      color: ${props => props.theme.colors.primary};
      border: ${props => '1px solid ' + props.theme.colors.primary};
      .button__icon {
        background: ${props => props.theme.colors.primary};
      }
      .button__icon--left {
        background: ${props => props.theme.colors.primary};
      }
      &:hover {
        color: ${props => props.theme.colors.dark};;
        border-color: ${props => props.theme.colors.dark};;
        background: ${props => props.theme.colors.dark};;
        .button__icon {
          background: ${props => props.theme.colors.dark};;
        }
        .button__icon--left {
          background: ${props => props.theme.colors.dark};;
        }
      }
    }
  }
  
  &.button--disabled {
    color: ${colors.GREY_LIGHT};
    background-color: ${colors.WHITE};
    cursor: not-allowed;
    .button__icon {
      background: ${colors.GREY_LIGHT};
      color: ${colors.WHITE};
    }
    .button__icon--left {
      background: ${colors.GREY_LIGHT};
      color: ${colors.WHITE};
    }
  }
  &.button__callback.button--disabled {
    text-transform: none;
    height: auto;
    padding: 24px;
    cursor: default;
    box-shadow: ${shadows.SHADOW1};
    .button-wrapper {
      height: 100%;
      display: block;
      font-weight: normal;
    }
    &:hover, &:active, &:focus {
      box-shadow: ${shadows.SHADOW1};
      transform: none;
    }
  }

  &.button__contact.button--disabled {
    text-transform: none;
    height: auto;
    padding: 24px;
    cursor: default;
    box-shadow: ${shadows.SHADOW1};
    &:hover, &:active, &:focus {
      box-shadow: ${shadows.SHADOW1};
      transform: none;
    }
  }  
  
  &.button--justify__flex-start {
    justify-content: flex-start;
  }

  &.button--justify__flex-end {
    justify-content: flex-end;
  }

  &.button--justify__center {
    justify-content: center;
  }

  &.button--justify__space-between {
    justify-content: space-between;
  }

  &.button--justify__space-around {
    justify-content: space-around;
  }

  &.button--justify__space-evenly {
    justify-content: space-evenly;
  }

  .loader {
    height: 30px;
    width: 30px;
  }

  .screen-desktop & {
    width: ${props => props.$scaling.scaleUpTo4K(240)}px;
       @media screen and (max-width: 1028px) and (min-width: 581px) {
                width:100%;
              }
    font-size: ${props => props.$scaling.textSize(12)}px;
    line-height: ${props => props.$scaling.textSize(18)}px;
    &.button--secondary {
      border-width: ${props => props.$scaling.scaleUpTo4K(1).toFixed()}px;
    }
  }
  .screen-tablet & {
    width: 100%;
  }
  .screen-ie & {
    max-height: ${props => props.$scaling.scaleUpTo4K(64)}px;
  }


  
   + .of--enter {
    width: ${props => props.$scaling.scaleUpTo4K(240)}px;
    margin-top: ${props => props.$scaling.scaleUpTo4K(15)}px;
    display: inline-block;
    padding-left: ${props => props.$scaling.scaleUpTo4K(22)}px;
    .grid__column--main &,
    .grid__column--extra & {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 1028px) {
    + .of--enter {
      display: none;
    }
  }

`;

const StyledButtonWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
`;

function Button({
  as,
  children,
  theme = 'primary',
  type = 'submit',
  disabled,
  onClick,
  className,
  justify,
  form,
  shadow,
  autoFocus,
  loading,
}) {
  const scaling = useScaling();
  const classes = classNames(
    'button',
    className,
    {
      'button--primary': theme === 'primary',
      'button--secondary': theme === 'secondary',
      'button--disabled': theme === 'disabled',
    },
    { 'button--disabled': disabled },
    { [`button--justify__${justify}`]: justify },
    {
      'button--shadow': shadow && theme === 'primary',
    },
  );

  return (
    <StyledButton
      as={as}
      className={classes}
      type={type}
      disabled={disabled}
      onClick={onClick}
      form={form}
      autoFocus={autoFocus}
      $scaling={scaling}
    >
      <StyledButtonWrapper className="button-wrapper">
        {loading ?
          <Loader color="white" size="small" /> :
          React.Children.toArray(children)}
      </StyledButtonWrapper>
    </StyledButton>
  );
}

Button.propTypes = {
  children: PropTypes.any.isRequired,
  theme: PropTypes.oneOf(['primary', 'secondary', 'disabled']),
  type: PropTypes.oneOf(['button', 'submit']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  form: PropTypes.string,
  justify: PropTypes.string,
};

export default Button;
