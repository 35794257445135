import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles';
import classNames from 'classnames'

import TextField from '@mui/material/TextField';
import { Colors } from '@domain/theming';

const StyledTextArea = styled(TextField, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  .MuiInputBase-multiline {
    font-family: 'Gabriel Sans';
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    min-height: 140px;
    padding: 20px;
    border: 2px dashed ${Colors.GREY_PRIMARY};
    background-color: ${Colors.WHITE};
    resize: none;
    border-radius: 16px;
    textarea {
      min-height: 100px;
    }
    &:before, &:after {
      content: none;
    }
    &:focus {
      border-color: ${Colors.GREY_DARK};
    }

    &::-webkit-input-placeholder {
      text-align: center;
    }
    &:-moz-placeholder {
      text-align: center;
    }
    &::-moz-placeholder {
      text-align: center;
    }
    &:-ms-input-placeholder {
      text-align: center;
    }
  }
  .screen-ie & {
    height: 0;
  }
`;

function Textarea({
  className,
  cols,
  defaultValue,
  disabled,
  id,
  name,
  onBlur,
  onChange,
  placeholder,
  rows,
  value,
}) {
  const classes = classNames('textarea', className, {
    'textarea--disabled': disabled,
  })

  return (
    <StyledTextArea
      className={classes}
      defaultValue={defaultValue}
      disabled={disabled}
      id={id || "text"}
      multiline
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      minRows={rows}
    />
  )
}

Textarea.propTypes = {
  cols: PropTypes.number,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  value: PropTypes.string,
}

export default Textarea
