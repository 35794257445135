import { object, string } from 'yup';

export default object({
  driverFirstName: string()
    .required()
    .label('Voornaam'),
  driverLastName: string()
    .required()
    .label('Achternaam'),
});
