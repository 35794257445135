// NPM imports.
import React from 'react';
import { useSelector } from 'react-redux';

// Domain imports.
import {
  Heading, Loader,
} from '@domain/components';
import { getPrevNextPagePath } from '@domain/helpers';
import { useSaveIncident } from '@domain/hooks';
import { TimelinePage } from '@domain/pages';

import questionnaire from '../questionnaire';
import { companyNames, proccessType } from '@domain/constants';
import { useLocation } from 'react-router-dom';

function Overview() {

  const location = useLocation()
  const currentPath = location.pathname;
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer)

  const saveIncident = useSaveIncident();

  const pagePath = getPrevNextPagePath(questionnaire, incident, currentPath);

  const headerGroupContent = (
    <>
      <Heading level={'1'} className="checkinout__timeline--title">Check-in overzicht</Heading>
    </>
  );

  if (!incident || !insurer) {
    return <Loader />;
  }

  const isVIP = insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand);
  const isNotObjectTypeDefined = !insurer.objectType
  const timeline = [
    {
      text: 'Intake auto',
      icon: 'towtruck',
    },
    {
      text: 'Documenten tekenen',
      icon: 'invoice',
    },
    {
      text: 'Auto innemen',
      icon: 'car-out',
    },
    {
      text: 'Vragen',
      icon: 'questionmark',
    },
  ];

  const insurerName = insurer.brandName || 'uw verzekeraar';
  const insurerLogo = insurer.logoURL || '';

  const middleLogo = {
    src: insurerLogo,
    alt: insurerName,
    className: isVIP ? 'vip-logo' : '',
    height: '60',
    // width: 'auto',
  }

  return (
    <TimelinePage
      buttonText="Volgende"
      currentPath={currentPath}
      headerGroupContent={headerGroupContent}
      nextPage={pagePath.nextPage}
      prevPage={pagePath.prevPage}
      questionnaire={questionnaire}
      saveIncident={saveIncident}
      timeline={timeline}
      unmuted={0}
      allActive={true}
      customMiddleLogo={isNotObjectTypeDefined}
      middleLogo={middleLogo}
    />
  )
}

export default Overview;
