import React from 'react';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { alpha } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';
import { useScaling } from '@domain/hooks';

const PREFIX = 'StyledDropdown';

const classes = {
  formControl: `${PREFIX}-formControl`,
  select: `${PREFIX}-select`,
  dropdown: `${PREFIX}-dropdown`,
  inputInput: `${PREFIX}-inputInput`,
  inputUnderline: `${PREFIX}-inputUnderline`,
  dropdownIcon: `${PREFIX}-dropdownIcon`,
  dropdownUnlabelled: `${PREFIX}-dropdownUnlabelled`,
  dropdownLabel: `${PREFIX}-dropdownLabel`,
  dropdownLabelShrink: `${PREFIX}-dropdownLabelShrink`,
  dropdownLabelFocused: `${PREFIX}-dropdownLabelFocused`,
  menuItem: `${PREFIX}-menuItem`,
  menuItemSelected: `${PREFIX}-menuItemSelected`
};

const StyledFormControl = styled(FormControl, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})((
  {
    theme,
  }
) => ({
  '& .MuiInputUnderline': {
    '&:after': {
      borderColor: theme.colors.primary + ' !important',
    },
    '&:before': {
      borderColor: theme.colors.primary + ' !important',
    }
  },

  [`& .${classes.menuItem}`]: {
    '&:hover': {
      backgroundColor: alpha(theme.colors.primary, 0.18)
    },
  },

  [`& .${classes.menuItemSelected}`]: {
    '&&': {
      backgroundColor: alpha(theme.colors.primary, 0.28),
      '&:hover': {
        backgroundColor: alpha(theme.colors.primary, 0.28)
      }
    }
  }
}));

const StyledSelect = styled(Select, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})((
  {
    theme, $scaling, $customBaseSize
  }
) => ({
  [`&.${classes.select}`]: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
    height:
      (!isMobile
        ? $scaling.textSize(!!$customBaseSize ? ($customBaseSize * 1.5) : 67)
        : $scaling.textSizeMobile(!!$customBaseSize ? $customBaseSize * 1.5 : 67)) + 'px'
  },
  [`&.${classes.inputInput}`]: {
    textAlign: 'left',
    marginTop: 'auto',
    paddingTop:
      ((!isMobile ?
        $scaling.textSize(6) :
        $scaling.textSizeMobile(6)) + 'px'),
    paddingBottom:
      ((!isMobile ?
        $scaling.textSize(7) :
        $scaling.textSizeMobile(7)) + 'px'),
    height:
      (!isMobile
        ? $scaling.textSize(!!$customBaseSize ? $customBaseSize * 1.75 : 56)
        : $scaling.textSizeMobile(!!$customBaseSize ? $customBaseSize * 1.75 : 56)) + 'px'
  },
  textAlign: 'left',
  alignItems: 'center',
  fontSize:
    (isMobile
      ? $scaling.dropdownSizeMobile($customBaseSize || 32, null)
      : $scaling.dropdownSize($customBaseSize || 32, null)) + 'px',
  fontWeight: 'bold',
  lineHeight:
    (!isMobile
      ? $scaling.textSize(!!$customBaseSize ? $customBaseSize * 1.125 : 36)
      : $scaling.textSizeMobile(!!$customBaseSize ? $customBaseSize * 1.125 : 36)) + 'px',
  marginBottom: '0',
  marginTop: 'auto',
  height:
    (!isMobile
      ? $scaling.textSize(!!$customBaseSize ? $customBaseSize * 1.75 : 56)
      : $scaling.textSizeMobile(!!$customBaseSize ? $customBaseSize * 1.75 : 56)) + 'px'
}));

const StyledLabel = styled(InputLabel)((
  {
    theme, $scaling
  }
) => ({
  transform: 'translate(0, 12px) scale(1)',
  fontSize: '24px',
  fontWeight: 'bold',

  [`&.${classes.dropdownLabelShrink}`]: {
    height: '44px',
    color: theme.colors.primary,
    fontSize: ((!isMobile ?
      $scaling.textSize(16) :
      $scaling.textSizeMobile(16)) + 'px'),
    fontWeight: 'bold',
    transform: `translate(0, -${((!isMobile ?
      $scaling.textSize(20) :
      $scaling.textSizeMobile(20)))}px)`,
    lineHeight: ((!isMobile ?
      $scaling.textSize(14) :
      $scaling.textSizeMobile(14)) + 'px'),
    letterSpacing: '0.0625em',
    transformOrigin: 'top left',
    '.theme-custom &': {
      color: theme.colors.primary,

    }
  },

  [`&.${classes.dropdownLabelFocused}`]: {
    color: theme.colors.primary,
  },
}));

function StyledDropdown({
  label,
  onChange,
  value,
  valueList = {},
  className,
  customBaseSize
}) {
  const scaling = useScaling()

  if (Array.isArray(valueList)) {

    return (
      <StyledFormControl  >
        {!!label && <StyledLabel $scaling={scaling} classes={{ shrink: classes.dropdownLabelShrink, focused: classes.dropdownLabelFocused }}>{label}</StyledLabel>}
        <StyledSelect
          $scaling={scaling}
          customBaseSize={customBaseSize}
          onChange={onChange}
          value={value}
          variant='standard'
          classes={{ select: classes.select, nativeInput: classes.inputInput }}
        >
          {valueList.map((item, i) => {

            return (
              <MenuItem classes={{ root: classes.menuItem, selected: classes.menuItemSelected }} key={label + item + i} value={item}>{item}</MenuItem>
            );
          })}
        </StyledSelect>
      </StyledFormControl >
    );

  } else {
    // the list is an object
    // where the key is the value
    // and the value attached to the key is the display name
    const valueListKeys = Object.keys(valueList);

    return (
      <StyledFormControl $scaling={scaling}  >
        {!!label && <StyledLabel $scaling={scaling} classes={{ root: classes.dropdownLabel, shrink: classes.dropdownLabelShrink, focused: classes.dropdownLabelFocused }}>{label}</StyledLabel>}
        <StyledSelect
          $scaling={scaling}
          customBaseSize={customBaseSize}
          onChange={onChange}
          variant='standard'
          classes={{ select: classes.select, nativeInput: classes.inputInput }}
          value={value}
        >
          {valueListKeys.map((item, i) => {

            return (
              <MenuItem classes={{ root: classes.menuItem, selected: classes.menuItemSelected }} key={label + item + i} value={item}>{valueList[item]}</MenuItem>
            );
          })}
        </StyledSelect>
      </StyledFormControl>
    );
  }
}

export default StyledDropdown;
