import React from 'react';
import { isMobile } from 'react-device-detect';

import { styled } from '@mui/material/styles';
import { Colors, Shadows } from '@domain/theming';
import { useScaling } from '@domain/hooks';
import classNames from 'classnames';

const StyledAnchorButton = styled('a', {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})(({ target, className }) => ({
  display: flex,
  maxWidth: props => props.$scaling.scaleUpTo4K(580) + 'px',
  width: props => props.$scaling.scaleUpTo4K(240) + 'px',
  justifyContent: 'space-between',
  border: 0,
  color: Colors.WHITE,
  boxShadow: props => props.$iconButton && Shadows.SHADOW6,
  background: Colors.BLACK,
  flexDirection: 'row',
  alignItems: 'center',
  height: props => props.$scaling.scaleUpTo4K(64) + 'px',
  padding: '12px 24px',
  fontSize:
    props => props.dynamicFontSize ? (isMobile
      ? props.$scaling.textSizeMobile(14) + 'px'
      : props.$scaling.textSize(14)) + 'px' : '12px',
  fontWeight: 700,
  lineHeight: '14px',
  letterSpacing: '0.02em',
  textTransform: 'uppercase',
  borderRadius: props => props.$scaling.scaleUpTo4K(16) + 'px',
  cursor: 'pointer',
  transition: `160ms, cubic-bezier(0.4, 0, 0.2, 1)`,
  textDdecoration: 'none',
  textAlign: 'right',
  '.button__icon': {
    background: Colors.WHITE,
    color: Colors.BLACK,
    borderRadius: props => props.$scaling.scaleUpTo4K(16) + 'px',
    marginLeft: 'auto',
  },
  '&.icon-button': {
    padding: '0 0 0 15px',
    justifyContent: 'flex-end',
    background: 'transparent',
    textShadow: `4px 3px 3px rgba(0, 0, 0, 0.28)`,
    width: 'auto',
    '.button__icon': {
      height: props => props.$scaling.scaleUpTo4K(64) + 'px',
      width: props => props.$scaling.scaleUpTo4K(64) + 'px',
      borderRadius: '50%',
      marginLeft: '30px',
      boxShadow: Shadows.SHADOW6,
    }
  },
  '&.disabled': {
    color: Colors.GREY_LIGHT,
    pointerEvents: 'none',
    cursor: 'default',
    boxShadow: none,
    background: Colors.WHITE,
    '.button__icon': {
      background: Colors.GREY_LIGHT,
      color: Colors.WHITE,
      boxShadow: 'none',
    },
    '&.icon-button': {
      background: 'transparent',
      opacity: 0.66,
      '.button__icon': {
        background: Colors.WHITE,
        color: Colors.GREY_DARK,
      }
    }
  },
  '@media(hover: hover)': {
    '&:hover': {
      transition: `280ms cubic-bezier(0.4, 0, 0.2, 1)`,
      boxShadow: props => props.$iconButton && Shadows.SHADOW3,
      '&.icon-button': {
        color: Colors.GREY_LIGHT,
        '.button__icon': {
          background: Colors.GREY_LIGHT
        }
      }
    }
  },
  ' &: focus': {
    transition: `280ms cubic-bezier(0.4, 0, 0.2, 1)`,
    boxShadow: props => props.$iconButton && Shadows.SHADOW3,
    '&.icon-button': {
      color: Colors.GREY_LIGHT,
      '.button__icon': {
        background: Colors.GREY_LIGHT
      }
    }
  },
  '&:active': {
    transform: `scale(0.98)`,
    transition: `280ms cubic-bezier(0.4, 0, 0.2, 1)`,
    boxShadow: props => props.$iconButton && Shadows.SHADOW1,
    '&.icon-button': {
      color: Colors.GREY_LIGHT,
      '.button__icon': {
        background: Colors.GREY_LIGHT
      }
    }
  }
}))

const NextButton = ({
  className,
  children,
  disabled,
  dynamicFontSize,
  href,
  iconButton,
  onClick,
  target,
  variant,
}) => {

  const classNamesCombined = classNames(className, iconButton && 'icon-button', disabled && 'disabled')

  const scaling = useScaling()
  return (
    <StyledAnchorButton
      variant={variant}
      target={target}
      className={classNamesCombined}
      dynamicFontSize={dynamicFontSize}
      $scaling={scaling}
      $iconButton={iconButton}
      href={href}
      onClick={onClick}
    >
      {React.Children.toArray(children)}
    </StyledAnchorButton>
  );
};

export default NextButton;
