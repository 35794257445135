import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { styled, GlobalStyles } from '@mui/material';

import UseIcon from '../UseIcon';
import { isMobile } from 'react-device-detect';
import Text from '../Text';
import { Colors } from '@domain/theming';
import { useScaling } from '@domain/hooks';

const globalStyle = `
  .faq-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
  }
`;

const StyledButton = styled('button', {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  width: ${props => props.$scaling.scaleUpTo4K(33)}px;
  height: ${props => props.$scaling.scaleUpTo4K(33)}px;
  border: 0;
  cursor: pointer;
  background: transparent;
  padding: 0;
  .icon-link-container .icon-link + & {
    margin-left: ${props => props.$scaling.textSize(20)}px;
  }
  &.icon-button-icon--faq {
    display:flex;
    align-items: center;
    justify-content: flex-end;
    width: auto;
  }
  .theme-custom & {
    &.icon-button-icon--faq {
      span {
        color: ${props => props.theme.colors.primary};
      }
      &:hover {
        .icon-button-icon--secondary {
        background: ${props => props.theme.colors.dark};
        border-color: ${props => props.theme.colors.dark};
        }
        span {
          color: ${props => props.theme.colors.dark};
        }
      }
    }
  }

  .screen-ie & {
    &.icon-button-icon--faq {
      svg {
        width: ${props => props.$scaling.scaleUpTo4K(33)}px;
        height: ${props => props.$scaling.scaleUpTo4K(33)}px;
        border-width: 0;
      }
    }
  }

  @media screen and (max-width: 360px) {
    width: 28px;
    height: 28px;
    svg {
      width: 26px;
      height: 26px;
    }
    .icon-link-container .icon-link + & {
        margin-left: 10px;
    }
  }
`;

const StyledUseIcon = styled(UseIcon, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background: transparent;
  color: ${Colors.BLACK};
  border: ${props => props.$scaling.scaleUpTo4K(1).toFixed()}px solid ${Colors.WHITE};
  box-sizing: content-box;
  &.icon-button-icon {
    &--primary {
      background: ${Colors.WHITE};
      color: ${Colors.BLACK};
    }
    &--secondary {
      background: ${Colors.BLACK};
      color: ${Colors.WHITE};
      border-color: ${Colors.BLACK};
    }
    &--tertiary {
      background: transparent;
      color: ${Colors.WHITE};
      border-color: ${Colors.WHITE};
    }
  }
  .screen-desktop & {
    width: ${props => props.$scaling.textSize(31)}px;
    height: ${props => props.$scaling.textSize(31)}px;
  }
  .theme-custom &{
    color: ${props => props.theme.colors.primary};
    &.icon-button-icon {
      &--primary {
      }
      &--secondary {
        background: ${props => props.theme.colors.primary};
        color: ${props => Colors.WHITE};
        border-color: ${props => props.theme.colors.primary};
        &:hover {
          background: ${props => props.theme.colors.dark};
          border-color: ${props => props.theme.colors.dark};
        }
      }
      &--tertiarty {
        color: ${props => props.theme.colors.primary};
        border-color: ${props => props.theme.colors.primary};
      }
    }
  }
`;

function IconButton({ type, theme = 'primary', onClick, className, faq }) {
  const scaling = useScaling();
  const svgClasses = classNames('icon-button-icon', {
    'icon-button-icon--primary': theme === 'primary', //black on white
    'icon-button-icon--secondary': theme === 'secondary', //white on black
    'icon-button-icon--tertiary': theme === 'tertiary' // white outline
  });

  const buttonClassName = classNames('icon-button', { 'icon-button-icon--faq': faq }, className);

  return (
    <>
      <GlobalStyles styles={globalStyle} />
      <StyledButton
        className={buttonClassName}
        type="button"
        onClick={event => {
          event.stopPropagation();
          onClick(event);
        }}
        $scaling={scaling}
      >
        {!isMobile && faq && <Text className="text--faq-veelgestelde-vragen">Veelgestelde vragen</Text>}
        <StyledUseIcon name={type} className={svgClasses} $scaling={scaling} />
      </StyledButton>
    </>
  );
}

IconButton.propTypes = {
  type: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  outline: PropTypes.bool,
  onClick: PropTypes.func,
  faq: PropTypes.bool,
};

export default IconButton;
