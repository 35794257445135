// NPM imports.
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';


// Domain imports.
import {
  Button,
  Container,
  GridContainer,
  GridColumn,
  Heading,
  IconLink,
  ImageViewerContainer,
  InputFile,
  Legend,
  Loader,
  Main,
  NavBar,
  Paragraph,
  ProgressionIndicator,
  Text,
  UseIcon,
  ValidationFeedback,
  ImageViewer,
} from '@domain/components'
import { getPrevNextPagePath, stripLastPathSegment } from '@domain/helpers';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'
// Routes for checkin flow.
import imageRoutes from '../routes';
import { useFilesEndpoint } from '@domain/hooks';
import questionnaire from '../../questionnaire';


const StyledMain = styled(Main)`
  .grid-container {
    .checkinout__question--title {
      flex: 1 1 0px;
      .screen-tablet & {
        max-width: 480px;
      }
      .legend {
        font-size: 16px;
        line-height: 20px;
      }
      .h1 {
        font-size: 48px;
        line-height: 56px;
      }
      p {
        font-size: 14px;
        line-height: 18px;
      }
    }
    .checkinout__question--question {
      flex: 1 1 0px;
      .screen-desktop:not(.screen-tablet) & {
        max-width: 540px;
        margin-left: auto;
        padding-left: 60px;
      }x
    }
    .checkinout__question--footer {
      .screen-desktop:not(.screen-tablet) & {
        position:absolute;
        padding: 40px;
        bottom: 0;
        right: 0;
        flex: 1 1 0px;
        z-index: 3;
      }
      .screen-tablet & {
        padding-top: 20px;
      }
    }
  }
  @media (max-width: 1028px) {
    .screen-desktop:not(.screen-tablet) & {
      .grid-container {
        .checkinout__question--title {
          text-align: center;
          margin: auto;
        }
        .checkinout__question--question {
          margin: auto;
          padding: 60px 0 0 0;
        }
        .checkinout__question--footer {
          position: relative;
          padding: 0;
          width: 100;
        }
      }
    }
  }
`;

const StyledImageViewerContainer = styled(ImageViewerContainer)`
  justify-content: space-between;
  .image__viewer {
    margin-right: 16px;
    margin-left:16px;
    .image-container {
      height: 64px;
      width: 64px;
      .image__viewer__img {
        height: 64px;
        width: 64px;
      }
      .loader {
        height: 64px;
        width: 64px;
      }
      .sk-fading-circle {
        height: 64px;
        width: 64px;
      }
    }
  }
`;

const StyledButton = styled(Button)`
  @media screen and (max-width: 580px) {
    .screen-desktop & {
      width: 100%;
    }
  }
`;


function Upload() {
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const intakeImages = useSelector(state => state.intakeImages || []);
  const selectedImage = useSelector(state => state.selectedImage);

  // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useState(null);
  const {
    uploadImages,
    deleteFile,
    numOfUploads,
    setSelectedImage,
    largeFileUpload,
    nonImageUpload,
  } = useFilesEndpoint('intakeImages');
  const [buttonClicked, setButtonClicked] = useState(false);
  const [imagesLength, setImagesLength] = useState(intakeImages.length);
  // const saveIncident = useSaveIncident();

  const navigate = useNavigate()
  const addUploads = useCallback(
    function addUploads(event) {
      event.preventDefault();
      if (event.type === 'drop') {
        uploadImages(event.dataTransfer.files, incident);
      } else {
        uploadImages(event.target.files, incident);
      }
    },
    [incident, uploadImages],
  );

  const nextButtonEnabled = imagesLength >= 1 ? true : false;
  const currentPath = stripLastPathSegment(location.pathname);
  const pagePath = getPrevNextPagePath(questionnaire, incident, currentPath, intakeImages);
  const { nextPage, prevPage } = pagePath;
  // const nextPageEnabled = !!nextPage;


  async function handleOnClick() {
    setButtonClicked(true);
    // if (!errors && !!nextPage) {
    //not sure about the check on next page here
    // saveIncident({ ...incident });
    // const nextPageManual = stripLastPathSegment(currentPath) + routes.
    if (!errors) {
      navigate(nextPage);
    }
    // }
  }





  useEffect(() => {
    // async function checkForErrors() {
    //   try {
    //     await validationSchema.validate(intakeImages, { abortEarly: true });
    //     setErrors(null);
    //   } catch ({ errors }) {
    //     setErrors(errors);
    //   }
    // }
    const newLength = intakeImages.length;
    setImagesLength(length => {
      if (newLength > length) {
        return newLength;
      } else {
        return newLength;
      }
    });
    // checkForErrors();
  }, [intakeImages]);


  useEffect(() => {
    if (selectedImage !== null) {
      if (intakeImages === null || intakeImages.length === 0) {
        setSelectedImage(null);
      } else if (intakeImages !== null && !intakeImages[selectedImage]) {
        setSelectedImage(null);
      }
    }
  }, [intakeImages, selectedImage, setSelectedImage]);


  if (!incident || !insurer || !intakeImages) {
    return <Loader />;
  }

  function showDeletePage() {
    const { inspect } = imageRoutes;
    const imageDeleteLink = currentPath + inspect;
    navigate(imageDeleteLink);
  }

  function clickMain(event) {
    setSelectedImage(null);
  }

  const imagesDisplayed = [...intakeImages, {}, {}, {}, {}, {}].slice(0, 5);

  function renderImage(image, i, errors) {
    const selected = i === selectedImage && (i !== 5 || intakeImages.length === 5);
    // TODO: Clean this up
    const extraImages =
      intakeImages.length >= 5
        ? i === 4
          ? Math.max(intakeImages.length + numOfUploads - 5, 0)
          : 0
        : 0;
    const loading = () => {
      if (numOfUploads === 0) {
        return false;
      }
      if (i === 4 && intakeImages.length >= 5) {
        return true;
      }
      if (!image.content && i < intakeImages.length + numOfUploads) {
        return true;
      }
    };

    const { cpID, content } = image;
    return (
      <ImageViewer
        src={content || null}
        key={cpID || i}
        onClick={() => {
          setSelectedImage(i);
        }}
        htmlFor={!content ? 'input-file' : ''}
        selected={selected}
        clickDelete={() => deleteFile(image)}
        clickView={() => showDeletePage(i)}
        extraImages={extraImages}
        dulled={selectedImage !== null && selected !== i}
        loading={loading()}
      // isDragging={isDragging}
      />
    );
  }


  const main = {
    dimmed: selectedImage !== null,
    className: 'main__checkinout main__checkinout--uploads',
    callBack: clickMain,
  };

  const iconLink = {
    type: 'arrow-left',
    theme: 'secondary',
    to: prevPage,
    replace: true,
  };

  const iconLinkForward = {
    type: 'arrow-right',
    theme: 'secondary',
    to: nextButtonEnabled && nextPage && nextPage,
    replace: true,
  };

  const button = {
    theme: !errors ? 'primary' : 'disabled',
    disabled: !!errors,
    onClick: handleOnClick,
    justify: 'space-between',
    shadow: true,
    className: 'image-upload__button button--icon',
  };


  return (
    <React.Fragment>
      <StyledMain {...main}>
        <NavBar>
          <Container className="icon-link-container">
            <IconLink {...iconLink} />
            {!!nextButtonEnabled && <IconLink {...iconLinkForward} />}
          </Container>
          <ProgressionIndicator steps={3} current={2} />
          <Container className="empty-div" />
        </NavBar>
        <GridContainer main={true}>
          <GridColumn className="checkinout__question--title">
            <Legend>Schade beschrijven</Legend>
            <Heading level="1">Foto’s van de schade</Heading>
            <Paragraph
              className="image-uploads__help"
            >
              Upload overzichtsfoto’s van de auto en detailfoto’s van de schade
            </Paragraph>
          </GridColumn>

          <GridColumn
            className="checkinout__question--question"
            justify="flex-start"
          >
            <StyledImageViewerContainer>
              {imagesDisplayed.map(renderImage)}
            </StyledImageViewerContainer>
            <InputFile onChange={addUploads}>
              <UseIcon name="icon-file" />
              <Text>
                <b>Maak een foto om te uploaden</b>
              </Text>
              <Text>Of kies uit de bibliotheek</Text>
            </InputFile>
            <Container className="uploads__validation">
              {errors &&
                buttonClicked &&
                errors.map(error => (
                  <ValidationFeedback type={buttonClicked ? 'error' : 'info'} key={error}>{error}</ValidationFeedback>
                ))}
              {largeFileUpload && (
                <ValidationFeedback>
                  Foto's moeten 5MB of minder zijn
                </ValidationFeedback>
              )}
              {nonImageUpload && (
                <ValidationFeedback>
                  Upload alleen jpg- of png-bestanden
                </ValidationFeedback>
              )}
            </Container>
          </GridColumn>
          <GridColumn className="grid__column--footer checkinout__question--footer">
            <StyledButton {...button}>
              Volgende
              <UseIcon name="arrow-right" className="button__icon" />
            </StyledButton>
          </GridColumn>
        </GridContainer>
      </StyledMain>
    </React.Fragment>
  )
}

export default Upload;
