const addMetaToImage = (image) => {
  const preFix = 'data:image/png;base64,';
  const content = `${preFix}${image.content}`;
  const fileName = image.fileName
  const cpID = image.cpID;
  const status = image.status
  const fileAction = image.fileAction
  const metadadata = image.metadadata
  return {
    ...image,
    cpID,
    fileName,
    status,
    content,
    fileAction,
    metadadata
  };
};

export default addMetaToImage;
