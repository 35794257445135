import { object, string } from 'yup';

const mileageRegEx = /^[0-9]+$/i;


const validationSchema = object({
  vehicleMileage: string()
    .matches(mileageRegEx, 'Controleer alstublieft uw kilomaterstand.')
    .label('kilometerstand')
    .required(),
});

const validationSchemaNotRequired = object({
  vehicleMileage: string()
    .matches(mileageRegEx, 'Controleer alstublieft uw kilomaterstand.')
    .label('kilometerstand')
});

export { validationSchema, validationSchemaNotRequired };
