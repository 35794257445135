import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import generateValidationSchema from './validation-schema';

import { companyNames, proccessType } from '@domain/constants';
import { useSaveIncidentFactory } from '@domain/hooks';
import { TextInputsPage } from '@domain/pages';
import { useQuestionnaire } from '@domain/providers';
import { getPrevNextPagePath, browserStorage, generatePageContent, chooseLogoSize } from '@domain/helpers';
import { useLocation } from 'react-router-dom';
import { Loader } from '@domain/components';
import { detectContentType } from '@domain/content';
import { useTheme } from '@mui/material';

const fieldsPick = [
  {
    name: 'licensePlate',
    mandatory: 'true',
  },
  {
    name: 'damageDate',
    mandatory: 'true',
  },
];


function License() {

  const { questionnaire } = useQuestionnaire();
  const spCase = useSelector(state => state.spCase);
  const inCreation = useSelector(state => state.inCreation);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)
  const isVIP = (insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand));
  const isSchadegarant = (insurer.objectType === proccessType.Schadegarant)
  const incident = useSelector((state) => state.incident);
  const themeContext = useTheme();

  const [isSaving, setIsSaving] = useState(false);
  const [usedLicenses, setUsedLicenses] = useState([]);
  const [invalidLicenses, setInvalidLicenses] = useState([]);
  const dateTimeFormat = 'YYYY-MM-DDT12:mm:ss.sssZ';
  const dateFormat = 'YYYY-MM-DD';

  const tempSaved = browserStorage.getTempSaved()

  const location = useLocation()
  const currentPath = location.pathname;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior, 'license');


  const fields = useMemo(() => [
    {
      name: 'licensePlate',
      label: 'Kenteken',
      type: 'text',
      disabled: !inCreation || (spCase && isVIP) || tempSaved || isSchadegarant,
    },
    {
      name: 'damageDatePortal',
      label: 'Schadedatum',
      type: 'date',
      displayLabel: false,
      disabled: spCase && isVIP && inCreation,
    },
  ], [inCreation, isVIP, spCase, tempSaved, isSchadegarant]);

  const validationSchema = useMemo(
    () => generateValidationSchema(invalidLicenses, usedLicenses),
    [invalidLicenses, usedLicenses],
  );

  const convertDateToDateTime = useCallback(function convert({
    licensePlate,
    damageDatePortal,
  }) {
    const damageDate = moment(damageDatePortal).format(dateTimeFormat);
    return { damageDate, licensePlate };
  },
    []);

  const convertDateTimeToDate = useCallback(function convert({
    licensePlate,
    damageDate,
  }) {
    if (!damageDate) {
      return { licensePlate, damageDatePortal: '' };
    }

    const damageDatePortal = moment(damageDate).format(dateFormat);
    return { damageDatePortal, licensePlate };
  },
    []);

  const controlValue = (name, value) => {
    if (name !== 'licensePlate') {
      return value;
    }
    const nonAlphanumeric = /[^A-Z0-9]+/gi;
    const newValue = value
      .toUpperCase()
      .replace(nonAlphanumeric, '')
      .slice(0, 6);
    return newValue;
  };

  const checkLicensePlate = useCallback((incident, postedIncident) => {
    const { errorCode } = incident;
    if (errorCode === 1 || errorCode === 2) {
      return false;
    }
    return incident.licensePlate === postedIncident.licensePlate;
  }, []);

  const checkDamageDate = useCallback((incident, postedIncident) => {
    const damageDate = moment(incident.damageDate).format(dateFormat);
    const postedDamageDate = moment(postedIncident.damageDate).format(
      dateFormat,
    );

    return damageDate === postedDamageDate;
  }, []);

  const customCheck = useMemo(
    () => ({
      licensePlate: checkLicensePlate,
      damageDate: checkDamageDate,
    }),
    [checkDamageDate, checkLicensePlate],
  );

  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath

  async function onValidated(values) {
    if (isSaving) {
      return;
    }
    setIsSaving(true);


    const newIncident = await saveIncident({ ...values }, isPageBeforeSuccess);
    if (!newIncident) {
      return;
    }
    setIsSaving(false);
    const { errorCode } = newIncident;
    if (errorCode === 19 || errorCode === 1) {
      setInvalidLicenses(licenses => [...licenses, values.licensePlate]);
      return;
    }
    if (errorCode === 2) {
      setUsedLicenses(licenses => [...licenses, values.licensePlate]);
      return;
    }
    return true
  }

  if (!insurer || !questionnaire) {
    return <Loader />;
  }

  const pagePath = getPrevNextPagePath(
    questionnaire,
    incident,
    currentPath,
    files
  );

  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, inCreation, false, pageName, null, spCase)

  const heading = content["title"]
  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const useCustomLogo = content["use-custom-logo"]
  const faqs = content["faqs"]

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }
  return (
    <TextInputsPage
      buttonText={!!inCreation ? 'Volgende' : 'Correct'}
      controlValue={controlValue}
      convertPortalValuesToServerValues={convertDateToDateTime}
      convertServerValuesToPortalValues={convertDateTimeToDate}
      currentPath={currentPath}
      customCheck={customCheck}
      customOnValidated={onValidated}
      faqs={faqs}
      fields={fields}
      fieldsPick={fieldsPick}
      formID="license"
      heading={heading}
      nextPage={nextPage}
      prevPage={pagePath.prevPage}
      progressIndicatorCurrent={1}
      progressIndicatorSteps={3}
      questionnaire={questionnaire}
      saveIncident={saveIncident}
      validationSchema={validationSchema}
      isVIP={isVIP}
      customHelpLink={customHelpLink}
      helpLinkText={helpLinkText}
      customMiddleLogo={useCustomLogo}
      middleLogo={middleLogo}
      files={files}
    />
  );
}

export default License;
