const routes = {
  start: '/overzicht',
  otherFiles: '/aanvullende-documenten',
  greenCard: '/groene-kaart',
  policeReport: '/proces-verbaal',
  licensePlateRegister: '/kentekenbewijs',
  saf: '/saf',
  damageImages: '/fotos',
  extraDamageImages: '/meer-schades-fotos',
  guidedDamageImages: '/fotos-uploaden'
};

export default routes;
