import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NEW_PID_FOUND } from '@domain/action-types';
import { browserStorage } from '@domain/helpers';
import { useSearchParams } from 'react-router-dom'
// This hook should only ever be loaded once in the entire app

const usePIDChecker = (location) => {
  const dispatch = useDispatch();
  const [searchP, setSearchP] = useSearchParams()
  const pid = useSelector((state) => state.pid);

  function extractSubdomain(splittedUrl) {

    if (splittedUrl.length < 3) {
      return '';
    }
    return splittedUrl[0] === 'www' ? splittedUrl[1] : splittedUrl[0];
  }

  useEffect(() => {
    if (pid) {
      browserStorage.setPID(pid)
    }
  }, [pid])

  useEffect(() => {
    if (pid) {
      return;
    }

    const storedPID = browserStorage.getPID()

    // The PID used to come from the subdomain, now from a URL parameter
    // Keeping the subdomain check for now but should be clean up when
    // confirmed that it isn't in use anymore
    const host = window.location.host;
    const parts = host.split('.');
    const subdomain = extractSubdomain(parts)
    // If we get more than 3 parts, then we have a subdomain
    // INFO: This could be 4, if you have a co.uk TLD or something like that.

    const subdomainValid =
      subdomain === '' ||
        subdomain === '192' ||
        subdomain === 'undefined' ||
        subdomain === 'test' ||
        subdomain === 'www' ||
        subdomain === 'accp' ||
        subdomain === 'schadeservice-dev' ||
        subdomain === 'schadeservice-staging' ||
        subdomain === 'schadeservice-mvp' ||
        subdomain === 'vipschadeservice' ||
        subdomain === 'mijnschade' ||
        subdomain === 'mijnschades' ||
        subdomain === 'portal'
        ? false
        : true;

    if (subdomainValid && subdomain !== pid) {
      dispatch({ type: NEW_PID_FOUND, pid: subdomain });
      return;
    }
    if (((!pid || pid === '') && !subdomainValid)) {
      //trying to access portal.mijnschade.com means the pid is a URL parameter

      const PID = decodeURIComponent(
        searchP.get('PID') ?? '',
      )
      const pid = decodeURIComponent(
        searchP.get('pid') ?? '',
      );

      const pidFromURL = PID !== 'undefined' && PID !== '' ? PID : pid
      const pidFromURLValid =
        !pidFromURL || pidFromURL === '' || pidFromURL === 'undefined'
          ? false
          : true;
      if (pidFromURLValid) {
        dispatch({ type: NEW_PID_FOUND, pid: pidFromURL });
        browserStorage.setPID(pidFromURL)
        return;
      }
    }

    if (!!storedPID && storedPID !== '') {
      dispatch({ type: NEW_PID_FOUND, pid: storedPID });
      return;
    }
  }, [dispatch, location.search, pid]);

};

export default usePIDChecker;
