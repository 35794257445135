import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { styled } from '@mui/material/styles';

import RadioLabel from './RadioLabel';

import { useKeyboardShortcut, useScaling } from "@domain/hooks";

const StyledRadioDiv = styled('div', {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  flex: 1 0 auto;
  flex-basis: calc(50% - ${props => props.$scaling.scaleUpTo4K(18)}px); // IE bug 'flex-basis: calc()' cannot be in shorthand 'flex'
  margin: 0 ${props => props.$scaling.scaleUpTo4K(8)}px ${props => props.$scaling.scaleUpTo4K(16)}px;
  text-align: center;
  width: calc(50% - 18px);
  .input--radio {
    display:none;
    width: 1px;
    height: 1px;
    opacity: 0;
  }

  @media screen and (max-width: 400px) {
    .screen-desktop & {
      flex: 1 1 100%;
    }
  }
`;

function Radio({
  children,
  className,
  name,
  id,
  value,
  defaultChecked,
  disabled,
  onClick,
  radioClassName,
  autoFocus,
  triggerKeys
}) {
  const scaling = useScaling();
  const radioClasses = classNames('radio', radioClassName);
  const classes = classNames(
    'radio__input',
    {
      'radio__input--checked': defaultChecked,
      'radio__input--disabled': disabled
    },
    className
  );

  const radioRef = useRef(null);

  useKeyboardShortcut(triggerKeys, () => radioRef.current.click())

  return (
    <StyledRadioDiv className={radioClasses} $scaling={scaling}>
      <input
        className="input--radio"
        type="radio"
        id={id}
        name={name}
        value={value}
        defaultChecked={defaultChecked}
        disabled={disabled}
        autoFocus={autoFocus}
        onChange={onClick}
      />
      <RadioLabel
        className={classes}
        htmlFor={id}
        defaultChecked={defaultChecked}
        onClick={event => onClick(event, value, name)}
        ref={radioRef}
      >
        {React.Children.toArray(children)}
      </RadioLabel>
    </StyledRadioDiv>
  );
}

Radio.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func
};

export default Radio;
