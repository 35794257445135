import { datadogLogs } from '@datadog/browser-logs'

import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import questionnaires from '@domain/questionnaires';

// import All questionnaires in one {}


const useQuestionnaire = (flowOverwrite = '') => {
  const storedFlow = useSelector(state => state.flow);
  const insurer = useSelector(state => state.insurer);
  const pagesExcluded = useSelector(state => state.pagesExcluded);

  const [currentFlow, setCurrentFlow] = useState();


  const jumpOverString = !!pagesExcluded ? pagesExcluded : '';
  const flow = flowOverwrite !== '' ? flowOverwrite : storedFlow.toLowerCase();

  const getFinalizedQuestionnaire = useCallback(() => {

    const configQ = (!!insurer && insurer.configuration) || [];
    const selectedQ = configQ.map(item => questionnaires.mainQuestionnaire.find(el => {
      return (el.name === item.name)
    }));

    const filteredQuestionnaire = !!jumpOverString ? selectedQ.filter(i => !jumpOverString.includes(i.name)) : selectedQ;
    return filteredQuestionnaire;
  }, [insurer, jumpOverString]);

  const [finalQuestionnaire, setFinalQuestionnaire] = useState(getFinalizedQuestionnaire());

  useEffect(() => {
    const generateQuestionnaire = async () => {
      try {
        const generatedQuestionnaire = await getFinalizedQuestionnaire();
        setFinalQuestionnaire(generatedQuestionnaire.length > 0 ? generatedQuestionnaire : undefined);
        setCurrentFlow(flow);
        return;
      } catch (error) {
        const errorLogMessage = "Error generating questionnaire"
        const errorInstance = new Error(errorLogMessage)
        datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
        return {};
      }
    }
    if (!!insurer) {
      generateQuestionnaire();
    }
  }, [currentFlow, flow, insurer, getFinalizedQuestionnaire, setFinalQuestionnaire, pagesExcluded]);

  return finalQuestionnaire;
}

const useCheckoutQuestionnaire = () => {

  const incident = useSelector(state => state.incident);
  const [finalQuestionnaire, setFinalQuestionnaire] = useState(questionnaires.checkoutQuestionnaire)

  useEffect(() => {
    if (incident && incident.mobilityOption && incident.mobilityOption !== "replacement_vehicle") {
      setFinalQuestionnaire(questionnaires.checkoutQuestionnaire.filter(page => page.name !== 'replacementVehicle'))
    }
  }, [incident])

  return finalQuestionnaire
}

export { useQuestionnaire, useCheckoutQuestionnaire };
