import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';

const StyledList = styled('ul')`
  padding: 0;
  list-style-type: none;
`;

function CheckList({ children, className }) {
  const classes = classNames('checklist', className);

  return <StyledList className={classes}>{React.Children.toArray(children)}</StyledList>;
}

CheckList.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export default CheckList;
