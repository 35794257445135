// NPM imports.
import React, { useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { GlobalStyles } from '@mui/material';

// Bounded context imports.
import Start from './Start';
import Offer from './Offer';
import BodyshopSelectEM from './BodyshopSelectEM';
import BodyshopConfirm from './BodyshopConfirm';
import BodyshopSelect from './BodyshopSelect';
import BodyshopSelectIntakePlus from './BodyshopSelectIntakePlus'

// Routes for damage flow.
import routes from './routes';
import { useScaling } from '@domain/hooks';

import { useQuestionnaire } from '@domain/providers';
import { checkforValidPath } from '@domain/helpers';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const ownBodyshopStyle = `
  .main__uploads {
    .h1 {
      margin-bottom: 42px;
    }
  }
  .screen-desktop & {
    .main__uploads {
      .grid__column--main {
        padding-right: 0;
      }
      .legend {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(24)}px;
      }
      h1 {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(50)}px;
        // max-width: 500px;
      }
      h1 + .button {
        margin-top: ${props => props.$scaling.scaleUpTo4K(70)}px;
      }
    }
  }
  @media screen and (max-width: 1028px) {
    .screen-desktop & {
      .main__uploads {
        .grid__column--main {
          padding-right: 0;
        }
        .damage-region {
          margin: auto;
        }
        h1 {
          margin: 0 auto 50px;
        }
      }
    }
    .screen-ie & {
      .main__uploads {
        .grid-container__main {
          // display:block;
          legend {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
  @media screen and (max-width: 580px) {
    .screen-desktop & {
      .main__uploads {
        h1 {
          margin-left: 0;
        }
      }
    }
    .screen-ie & {
      .main__uploads {
        .grid-container__main {
          legend {
            text-align: left;
          }
        }
      }
    }
  }
`;

function OwnBodyshop() {
  const scaling = useScaling();
  const location = useLocation()

  const { questionnaire } = useQuestionnaire();

  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)

  const validPath = !!questionnaire && !!questionnaire.find(page => page.path === location.pathname)
  const newValidPath = checkforValidPath(incident, questionnaire, files, insurer).path
  const navigate = useNavigate()

  useEffect(() => {
    if (!!questionnaire && questionnaire.length && !validPath) {
      navigate(newValidPath)
    }
  }, [questionnaire, validPath])

  return (
    <>
      <Routes>
        <Route path={routes.bodyshopSelect} element={<BodyshopSelect />} />
        <Route path={routes.bodyshopConfirm} element={<BodyshopConfirm />} />
        <Route path={routes.start} element={<Start />} />
        <Route path={routes.offer} element={<Offer />} />
        <Route path={routes.bodyshopSelectEM} element={<BodyshopSelectEM />} />
        <Route path={routes.bodyshopSelectIntakePlus} element={<BodyshopSelectIntakePlus />} />
        <Route path='*' element={<Navigate to={routes.start} />} />
      </Routes>
      <GlobalStyles scaling={scaling} styles={ownBodyshopStyle} />
    </>
  );
}

export default OwnBodyshop;
