import React, { useEffect } from 'react';

import { Map, Marker, useApiIsLoaded, useMap } from '@vis.gl/react-google-maps'
import { styled } from '@mui/material/styles';
import Button from '../Button';
import Container from '../Container';

const containerStyle = {
  // width: '100%',
  // maxWidth: '600px',
  // height: '400px',
  position: 'relative',
};

const MapContainer = styled('div')`
  display: flex;
  >div {
    flex: 1 1 auto;
  }
`;

const StyledContainer = styled(Container)`
  display:flex;
  flex: 1 1 0px;
  padding-top: 10px;
 button {
    height: 42px;
    border-radius:10px;
  }
  .search-button {
    width: 100%;
  }
`;

function GoogleMap({
  location,
  zoom = 10,
  showDirectionsButton = false,
}) {

  const isLoaded = useApiIsLoaded()
  const map = useMap('google-map-script')

  useEffect(() => {
    if (map) {
      map.setCenter({
        lat: location.latitude,
        lng: location.longitude,
      })
    }
  }, [map, location])

  async function handleOnClick() {
    const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${location.latitude},${location.longitude}`
    window.open(mapsUrl, '_blank')
  }

  const button = {
    theme: 'secondary',
    onClick: handleOnClick,
    className: "search-button",
    justify: 'center',
  };

  return isLoaded ? (
    <>
      <MapContainer className="google-map">
        <Map
          style={containerStyle}
          defaultZoom={zoom}
          disableDefaultUI={true}
          reuseMaps={true}
          id='google-map-script'
          defaultCenter={{
            lat: location.latitude,
            lng: location.longitude
          }}
        >
          <Marker position={{
            lat: location.latitude,
            lng: location.longitude
          }} />
        </Map>
      </MapContainer>
      <StyledContainer>
        {showDirectionsButton && <Button {...button}>Open op google maps</Button>}
      </StyledContainer>
    </>
  ) : <></>
}
export default GoogleMap;