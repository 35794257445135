import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';

const StyledForm = styled('form')`
  .form--personal__details {
    margin-top: auto;
    margin-bottom: 20px;
  }
  .form-input {
    padding-right: 5px;
    margin-top: 20px;
  }

  .screen-desktop & {
    display: flex;
    .form-input {
      flex: 1 1 0px;
      padding-right: 50px;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 1028px) {
    
    .screen-desktop & {
      margin-bottom: 0;
      padding-top: 50px;
      padding-bottom: 20px;
        flex-direction: column;
        .form-input {
          padding-bottom: 20px;
          padding-right: 0;
        }
    }
  }

`;

function Form({ children, className, ...props }) {
  const classes = classNames('form', className);

  // Enter key won't work on IE when form submit is hidden
  return (
    <StyledForm className={classes} {...props} noValidate>
      {React.Children.toArray(children)}
    </StyledForm>
  );
}

Form.propTypes = {
  children: PropTypes.any.isRequired,
  onSubmit: PropTypes.func,
  id: PropTypes.string
};

export default Form;
