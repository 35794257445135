import { object, string } from 'yup';
import moment from 'moment';

const pastDate = date => {
  const inputDate = moment(date);
  const now = moment();
  return !inputDate.isAfter(now);
};

const validDate = date => {
  return date !== 'Invalid date';
};

const min18Years = (date) => {
  const inputDate = moment(date).startOf('day')
  const now = moment().startOf('day')

  const age = now.diff(inputDate, 'years', true)
  return age >= 18
}

const maxYear1900 = (date) => {
  const year = moment(date).year()
  const after1900 = year >= 1900

  return after1900
}

const validationSchema =
  object({
    driverBirthDayPortal: string()
      .required()
      .test(
        'Date must not be in future',
        'De datum mag niet in de toekomst liggen',
        pastDate,
      )
      .test('Date must be valid', 'Voeg een geldige datum toe', validDate)
      .test('Must be older than 18', 'U moet minstens 18 jaar oud zijn', min18Years)
      .test('Invalid birth year', 'Ongeldig geboortejaar', maxYear1900)
      .label('Geboortedatum'),
  });

export default validationSchema;
