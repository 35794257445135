import useBodyshopListsLoader from '../useBodyshopListsLoader';
import useBodyshopLoader from '../useBodyshopLoader';
import useFIDChecker from '../useFIDChecker'
import useCompanyTokenChecker from '../useCompanyTokenChecker';
import useExcludedPagesChecker from '../useExcludedPagesChecker';
import useFlowChecker from '../useFlowChecker';
import useFilesLoader from '../useFilesLoader';
import useIncidentLoader from '../useIncidentLoader';
import useInsurerLoader from '../useInsurerLoader';
import useLicensePlateUUIDLoader from '../useLicensePlateUUIDLoader';
import usePIDChecker from '../usePIDChecker';
import useRIDChecker from '../useRIDChecker';
import useTokenChecker from '../useTokenChecker';
import useUUIDChecker from '../useUUIDChecker';

const useGlobalSideEffects = (location) => {
  // These hooks hydrate the store with the relevant tokens
  usePIDChecker(location);
  useFIDChecker(location);
  useRIDChecker(location);
  useFlowChecker(location);
  useUUIDChecker(location);
  useTokenChecker();

  useCompanyTokenChecker(location);

  // These hooks load the relevant data, and if necessary will reload data
  useLicensePlateUUIDLoader(location);
  useIncidentLoader();
  useInsurerLoader();
  useBodyshopLoader();
  useFilesLoader();
  useBodyshopListsLoader();

  //This hook defines pages to be skiped on the current flow
  useExcludedPagesChecker(location);
};

export default useGlobalSideEffects;
