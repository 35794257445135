import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux'

import { TextInputsPage } from '@domain/pages';
import generateValidationSchema from './validation-schema';

function Postal({
  buttonText,
  currentPath,
  faqs,
  heading,
  nextPage,
  prevPage,
  questionnaire,
  saveIncident,
  isVIP,
  customHelpLink,
  helpLinkText,
  customMiddleLogo,
  middleLogo,
  files
}) {
  const [badZipCodes, setBadZipCodes] = useState(() => []);

  const incident = useSelector(state => state.incident)
  const fields = useMemo(
    () => [
      {
        name: 'customerZipCode',
        label: 'Postcode',
        mandatory: true,
      },
      {
        name: 'customerHouseNumber',
        label: 'Huisnummer',
        mandatory: true,
      },
      {
        name: 'customerHouseNumberAddition',
        label: 'Toevoeging',
        mandatory: false,
      },
    ],
    [],
  );

  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath

  const onValidated = useCallback(
    async function onValidated(values) {
      const customerZipCode = values.customerZipCode
        .toUpperCase()
        .replace(/ /g, '');
      const valuesCopy = {
        ...values,
        customerZipCode,
      };

      const returnedIncident = await saveIncident({
        ...incident,
        ...valuesCopy,
      }, isPageBeforeSuccess);

      if (!returnedIncident) {
        return;
      }

      const invalidPostCode = 3;
      const {
        errorCode,
        customerZipCode: returnedCustomerZipCode,
      } = returnedIncident;
      if (errorCode === invalidPostCode || !returnedCustomerZipCode) {
        setBadZipCodes(codes => [...codes, customerZipCode]);
        return
      }
      return true
    },
    [saveIncident, incident, isPageBeforeSuccess],
  );

  const validationSchema = useMemo(
    () => generateValidationSchema(badZipCodes),
    [badZipCodes],
  );

  const customCheck = useMemo(
    () => ({
      customerZipCode: (
        { customerZipCode },
        { customerZipCode: postedZipCode },
      ) => {
        if (!customerZipCode || !postedZipCode) {
          return false;
        }
        const sanitiseZipCode = (postcode) => {
          return postcode.replace(' ', '').toUpperCase;
        };

        return (
          sanitiseZipCode(customerZipCode) === sanitiseZipCode(postedZipCode)
        );
      },
    }),
    [],
  );

  return (
    <TextInputsPage
      buttonText={buttonText || 'Correct'}
      currentPath={currentPath}
      customCheck={customCheck}
      customOnValidated={onValidated}
      faqs={faqs}
      fields={fields}
      formID="postal"
      heading={heading}
      nextPage={nextPage}
      prevPage={prevPage}
      progressIndicatorCurrent={1}
      progressIndicatorSteps={3}
      questionnaire={questionnaire}
      saveIncident={saveIncident}
      validationSchema={validationSchema}
      isVIP={isVIP}
      customHelpLink={customHelpLink}
      helpLinkText={helpLinkText}
      customMiddleLogo={customMiddleLogo}
      middleLogo={middleLogo}
      files={files}
    />
  );
}

export default Postal;
