import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import { motion } from "framer-motion"

const variants = {
  closed: {
    overflow: 'hidden',
    height: 0
  },
  open: {
    height: 'auto'
  }
}
const StyledContent = styled(motion.div)`
  &.collapsible__content--collapsed {
    overflow: hidden;
    height: 0;
  }
`;
function CollapsibleContent({ children, collapsed = true }) {
  const classes = classNames('collapsible__content', {
    'collapsible__content--collapsed': collapsed
  });

  return (
    <StyledContent className={classes} animate={collapsed ? 'closed' : 'open'} variants={variants}>
      {React.Children.toArray(children)}
    </StyledContent>
  );
}

CollapsibleContent.propTypes = {
  children: PropTypes.any.isRequired,
  collapsed: PropTypes.bool.isRequired
};

export default CollapsibleContent;
