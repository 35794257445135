import React, { forwardRef, Children } from 'react'
import './paragraph.scss';
import { styled } from '@mui/material/styles';

const StyledParagraph = styled('p', {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  text-align: ${props => props.$textAlign};
  margin: ${props => props.$removeMargin ? '0' : '0 auto'};
  padding-top: ${props => props.$paddingTop};
  padding-bottom: ${props => props.$paddingBottom}
`;

const ParagraphSP = forwardRef(({
  level,
  textAlign,
  marginTop,
  removeMargin,
  paddingTop,
  paddingBottom,
  children
}, ref) => {

  const usedClass =
    level === 1
      ? 'h1'
      : level === 2
        ? 'h2'
        : level === 3
          ? 'h3'
          : level === 4
            ? 'h4'
            : level === 6
              ? 'h6'
              : 'h5'
  return (
    <StyledParagraph
      displayName='a'
      ref={ref}
      className={usedClass}
      $level={level}
      $textAlign={textAlign}
      $marginTop={marginTop}
      $removeMargin={removeMargin}
      $paddingTop={paddingTop}
      $paddingBottom={paddingBottom}
    >
      {Children.toArray(children)}
    </StyledParagraph>
  );
});

ParagraphSP.displayName = 'ParagraphSP'
export default ParagraphSP;
