import React, { useState, useCallback, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  Container,
  FAQ,
  GridContainer,
  GridColumn,
  Heading,
  IconButton,
  IconLink,
  Legend,
  ListSelection,
  Main,
  NavBar,
  ProgressionIndicator,
  Text,
  UseIcon,
  Loader,
  CompanyLogo,
  Form,
  ValidationFeedback,
  Input,
  StyledDropdown
} from '@domain/components';
import {
  getPrevNextPagePath,
  goToNextPage,
  browserStorage,
  generatePageContent, chooseLogoSize, mapYupValidationSchemaErrors
} from '@domain/helpers';
import { useNavigate, useLocation } from 'react-router-dom'
import { useFaq, useScaling, useEnterKey, useGetBodyshopLists, useSaveIncidentFactory } from '@domain/hooks';
import { styled, useTheme } from '@mui/material/styles';
import { detectContentType } from '@domain/content';
import { useQuestionnaire } from '@domain/providers';
import { isMobileOnly } from 'react-device-detect';
import { validationSchema } from './validation-schema'


const faqs = [
  {
    question: 'Waarom moet ik het merk van mijn auto aangeven?',
    answer: 'Wij hebben het merk van uw auto nodig om een geschikt herstelbedrijf te vinden.',
  },
  {
    question: 'Waarom moet ik de radius invullen?',
    answer: 'We gebruiken de radius om alle geschikte herstelbedrijven voor u in kaart te brengen.',
  },
  {
    question: 'Wat gebeurt er als ik een andere postcode of plaatsnaam invul?',
    answer: 'We zullen op basis van de door u aangeven postcode of plaatsnaam herstelbedrijven tonen die beschikbaar zijn in de door u opgegeven locatie.',
  },
];

const StyledMain = styled(Main, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  .bodyshop-select {
    &__container {
      justify-content: center;
      .grid__column {
        flex: 0 0 auto;
        &.bodyshop-select__main {
          padding-top: 30px;
        }
      }
    }
    &__title {
      flex: 1;
      h1 {
        margin-bottom: 0;
      }
    }
    &__main {
      padding: 20px 0;
      // flex-direction: row;
      }
    }
    &__footer {
      flex-direction: row;
      justify-content: flex-end;
      &--button-container {
        width: 100%;
        .button {
          width: 100%;
        }
      }
    }
  }

  .screen-desktop & {
    .bodyshop-select {
      &__container {
        // padding: 0 ${props => props.$scaling.scaleUpTo4K(120)}px ${props => props.$scaling.scaleUpTo4K(82)}px;
        flex-direction: column;
      }
      &__title {
        padding: ${props => props.$scaling.scaleUpTo4K(60)}px 0 ${props => props.$scaling.scaleUpTo4K(30)}px;
      }
      &__main {
        padding: 0;
        flex-direction: column;
      }
      &__footer {
        // padding-bottom: ${props => props.$scaling.scaleUpTo4K(60)}px;
        &--button-container {
          width: auto;
          .button {
            width: ${props => props.$scaling.scaleUpTo4K(240)}px;
          }
        }
      }
    }
    .grid__column--main {
      max-width: none;
    }
  }

  @media screen and (max-width: 1028px) {
    .screen-desktop & {
      .bodyshop-select {
        &__container {
          .grid__column {
            flex: 0 0 auto;
            padding-top: 0;
          }
        }
        &__title {
          text-align: center;
          h1 {
            max-width: none;
          }
        }
        &__main {
        }
        &__footer {
          max-width: 500px;
          margin: auto auto 0;
          padding-bottom: 0;
          &--button-container {
            margin: 0;
            width:100%;
            .button {
              width:100%
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 580px) {
    .bodyshop-select {
      &__container {
        .grid__column {
          &.bodyshop-select__main {
            flex: 1 0 auto;
          }
        }
      }
      &__title {
        h1 {
        }
      }
      &__main {
        .list__container {
        }
      }
      &__footer {
      }
    }

    .screen-desktop & {
      .bodyshop-select {
        &__container {
          .grid__column {
          }
        }
        &__title {
          text-align: left;
          h1 {
            margin-left: 0;
            flex-grow: 0;
          }
        }
        &__main {
          .list__container {
          }
        }
        &__footer {
          &--button-container {
            width: 100%;
            .button {
              width: 100%;
            }
          }
        }
      }
    }
  }
`;


const ListContainer = styled(Container, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  display: flex;
  flex: 1 1 auto;
  &.list-bodyshop__container {
    min-height: 260px;
  }
  &.list-control__container {
    flex-direction: column;
    padding-bottom: 40px;
    > div {
      width: 100%;
    }
    .MuiFormControl-root {
      flex: 1 1 0px;
      margin: 0 0 20px;
      .MuiInputLabel {
        &-root {
          font-size: 24px;
        }
        &-shrink {
          font-size: 14px;
        }
      }
    }
    .form-postcode-city {
      width: 35%;
      align-self: flex-end;
      display:flex;
      flex-direction:column;
    }
    .list-control__button-container {
      display:flex;
      flex: 1 1 0px;
      button {
        height: 42px;
      }
    }
  }
  .loader {
    padding: ${props => props.$scaling.scaleUpTo4K(60)}px;
    padding-top: ${props => props.$scaling.scaleUpTo4K(40)}px;
  }
  .screen-desktop & {
      margin: 0 -${props => props.$scaling.scaleUpTo4K(20)}px;
      ul {
        margin: 0 ${props => props.$scaling.scaleUpTo4K(20)}px ${props => props.$scaling.scaleUpTo4K(40)}px;
      }
      .loader {
        padding-top: ${props => props.$scaling.scaleUpTo4K(80)}px;
      }
    &.list-control__container {
      flex-direction: row;
      max-width: 65%;
      &.button-opt {
        max-width: 73%;
      }
      .MuiFormControl-root {
        flex: 1 1 0px;
        margin: 0 ${props => props.$scaling.scaleUpTo4K(20)}px;
          height:auto
      }
      .validation__container {
        padding-left: ${props => props.$scaling.scaleUpTo4K(20)}px;
      }
      .list-control__button-container {
        flex: 1 1 0px;
        button {
          width: auto;
          margin: auto 0 auto ${props => props.$scaling.scaleUpTo4K(10)}px;
        }
      }
    }
  }
  @media screen and (max-width: 1028px) {
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0px;
    &.list-control__container {
      .MuiFormControl-root {
        margin: ${props => props.$scaling.scaleUpTo4K(20)}px 0 ${props => props.$scaling.scaleUpTo4K(20)}px 0;
      }
      max-width: 100%;
      padding-bottom: 60px;
      flex-direction: column;
      justify-content: flex-start;
      .form-postcode-city {
        width: 100%;
        align-self: flex-start;
        padding-bottom:${props => props.$scaling.scaleUpTo4K(30)}px
      }
    }
    .screen-desktop & {

      &.list-control__container {
      flex-direction: column;
       max-width: 100%;
        }
      .list-control__container {
        flex-direction: column;
         max-width: 100%;
        }
      .list-control__button-container {
           margin-top:${props => props.$scaling.scaleUpTo4K(30)}px;
           width:100%;
           display:flex;
           justify-content:center;
        }
      }
    .main__damage--bodyshop-select & {
      ul.MuiList-root {
        min-width: 250px;
        max-width: 100%;
        // + ul.MuiList-root {
        //   + ul.MuiList-root {
        //     //third block should be full width when wrapped
        //     width: 100%;
        //     max-width: 100%;
        //   }
        }
      }
    }
  }
  @media screen and (max-width: 580px) {
    flex-direction: column;
    flex-wrap: nowrap;
    ul {
      min-height: 220px;
    }
    .main__damage--bodyshop-select & {
      ul.MuiList-root {
        width: 100%;
        max-width: 100%;
      }
    }
    &.list-bodyshop__container {
      min-height: 220px;
      width: 80%;
      min-width: 250px;
      align-self: center;
    }
    &.list-control__container {
      padding-bottom: 20px;
    }

    .screen-desktop & {
      margin: 0;
      ul {
        margin: 0 0 30px;
        min-height: 260px;
      }
      &.list-control__container {
        flex-direction: column;
        padding-bottom: 20px;
        max-width: 100%;
        &.button-opt {
          max-width: 73%;
        }
      .list-control__container {
        flex-direction: column;
        .MuiFormControl-root {
          max-width: 100%;
          margin: ${props => props.$scaling.scaleUpTo4K(20)}px 0 ${props => props.$scaling.scaleUpTo4K(20)}px 0;
          .form-postcode-city {
            width: 100%;
            align-self: flex-start;
          }
        }
        .list-control__button-container {
          .button {
            margin-left: 0;
            width: 100%;
          }
        }
      }
      &.list-bodyshop__container {
        min-height: 260px;
      }
    }
  }
`;

function BodyshopSelectIntakePlus() {

  const navigate = useNavigate()
  const location = useLocation()
  const { questionnaire } = useQuestionnaire();
  const bsLists = useSelector(state => state.bodyshopLists);
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const token = useSelector(state => state.token);
  const files = useSelector(state => state.files)

  const themeContext = useTheme();
  const getBodyshopLists = useGetBodyshopLists();
  const scaling = useScaling();
  const [
    faqVisible,
    handleOnHelpClick,
    clickOutSideFaq,
    faqListProps,
    faqSeen,
  ] = useFaq();

  const field = {
    name: 'zipCode',
    label: 'Postcode',
    mandatory: false,
  }

  const form = {
    id: 'zipCode'
  };

  const currentValue = incident.carRefinisherUUID
  const [carRefinisherUUID, setCarRefinisherUUID] = useState(currentValue);
  const localRadius = browserStorage.getRadius() || null
  const searchZipcode = browserStorage.getSearchZipcode() || incident.customerZipCode
  const [selectedList, setSelectedList] = useState();
  const [radius, setRadius] = useState(localRadius || '50');
  const [values, setValues] = useState({ [field.name]: searchZipcode });
  const [errors, setErrors] = useState({});
  const [listsLoadingError, setListsLoadingError] = useState(false);
  const [listsLoading, setListsLoading] = useState(false);
  const [listsLoadingEmpty, setListsLoadingEmpty] = useState(false);

  const [buttonClicked, setButtonClicked] = useState(false);

  const damageType = incident.damageType || '';

  const nextPageEnabled = !!carRefinisherUUID && carRefinisherUUID !== '';


  const currentPath = location.pathname;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);

  useEffect(() => {
    if (!!questionnaire && questionnaire.length) {
      const page = questionnaire.find(page => page.path === currentPath)
      const progress = page ? page.name : null
      if (progress) {
        browserStorage.setProgress(progress);
      }

    }
  }, [currentPath, questionnaire])

  useEffect(() => {
    async function testInputs() {
      try {
        await validationSchema.validate(values, { abortEarly: false });
        setErrors({});
      } catch (errorResult) {
        setErrors(mapYupValidationSchemaErrors(errorResult.inner));
      }
    }
    testInputs();
  }, [values]);

  useEffect(() => {
    if (!bsLists) {
      return;
    }
    if (
      Object.keys(bsLists).length === 0
    ) {
      setListsLoadingEmpty(true);
    } else {
      setListsLoadingEmpty(false);
    }
    setListsLoading(false);
  }, [bsLists]);

  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath

  async function handleClick(event) {
    event.preventDefault();
    const selectedCarRefinisherUUID = carRefinisherUUID ? carRefinisherUUID : currentValue;
    const savedIncident = await saveIncident({ ...incident, carRefinisherUUID: selectedCarRefinisherUUID, token: token }, isPageBeforeSuccess)
    if (savedIncident) {
      browserStorage.setSearchZipcode(values.zipCode)
      goToNextPage(navigate, questionnaire, savedIncident, currentPath, files)
    }
  }

  async function handleChange(event) {
    const { name, value } = event.target;
    const valueToSet = value;
    setValues(vals => ({ ...vals, [name]: valueToSet }));
  }

  const formInput = ({ name, label, type, displayLabel = true }) => ({
    type: 'text',
    name,
    placeholder: '1234AB',
    id: name,
    valid: !errors[name] || !buttonClicked,
    onChange: handleChange,
    label,
    regexPass: !errors[name],
    value: values[name],
    displayLabel,
    displayEndAdornment: false,
    customBaseSize: 23
  });

  const handleOnChangeRadius = (event) => {
    setRadius(event.target.value);
    browserStorage.setRadius(event.target.value)
  }

  async function handleOnClickSearch(event) {
    event.preventDefault();
    setButtonClicked(true)
    try {
      await validationSchema.validate(values, { abortEarly: false });

      setListsLoading(true);
      const searchZipCode = values.zipCode
        .toUpperCase()
        .replace(/ /g, '');

      const checkZipCode = searchZipCode
      const listsLoaded = await getBodyshopLists(undefined, radius, checkZipCode, null);
      if (!listsLoaded) {
        setListsLoadingError(true);
      } else {
        setListsLoadingError(false);
      }
    } catch (errorResult) {
      setErrors(mapYupValidationSchemaErrors(errorResult.inner));
    }
  }

  const renderBodyshopLists = useCallback(() => {
    if (!bsLists || Object.keys(bsLists).length === 0) {
      return;
    }
    if (listsLoading) {
      return <Loader />;
    }
    const bsListsNames = Object.keys(bsLists)
    const handleClickItem = ({ title, listTitle }) => {
      const selectedBodyshop = bsLists[listTitle].find(item => item.companyName === title);
      setSelectedList(listTitle);
      setCarRefinisherUUID(selectedBodyshop.uuid);
    }

    const generateBodyshopList = () => {
      const formattedList = {};
      Object.keys(bsLists).map((key, i) => {
        formattedList[key] = bsLists[key];
        formattedList[key].map((item, i) => {
          item.title = bsLists[key][i].companyName;
          item.subtitle = bsLists[key][i].city;
          return item;
        })
        return key;
      });

      return formattedList;
    };
    const bodyshopList = generateBodyshopList();
    return (
      <>
        <ListSelection
          large={damageType !== 'glass'}
          listItems={bodyshopList[bsListsNames[0]]}
          listTitle={bsListsNames[0]}
          onClickItem={handleClickItem}
          selectedListName={selectedList}
          {...(carRefinisherUUID ? { preselectedItemUUID: carRefinisherUUID } : {})}
        />
        {bsListsNames.length >= 2 && <ListSelection
          large={damageType !== 'glass'}
          listItems={bodyshopList[bsListsNames[1]]}
          listTitle={bsListsNames[1]}
          onClickItem={handleClickItem}
          selectedListName={selectedList}
          {...(carRefinisherUUID ? { preselectedItemUUID: carRefinisherUUID } : {})}
        />}
        {damageType !== 'glass' && bsListsNames.length >= 3 &&
          <ListSelection
            large={damageType !== 'glass'}
            listItems={bodyshopList[bsListsNames[2]]}
            listTitle={bsListsNames[2]}
            onClickItem={handleClickItem}
            selectedListName={selectedList}
            {...(carRefinisherUUID ? { preselectedItemUUID: carRefinisherUUID } : {})}
          />
        }
      </>
    );
  }, [bsLists, damageType, listsLoading, carRefinisherUUID, selectedList]);

  const onEnterKey = (event) => {
    event.preventDefault();
    if (nextPageEnabled) {
      handleClick(event)
    }
  }

  useEnterKey(onEnterKey)

  if (!insurer || !incident || !questionnaire || (questionnaire && !questionnaire.length)) {
    return <Loader />
  }

  const pagePath = getPrevNextPagePath(
    questionnaire,
    incident,
    currentPath,
    files,
  );
  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;

  const main = {
    faqVisible: faqVisible,
    dimmed: faqVisible,
    className: 'main__damage main__damage--bodyshop-select',
    callBack: clickOutSideFaq,
    $scaling: scaling,
  }

  const iconLink = (triggerKey) => ({
    type: 'arrow-left',
    theme: 'secondary',
    to: pagePath.prevPage,
    replace: true,
    triggerKey
  });

  const iconLinkForward = (triggerKey) => ({
    type: 'arrow-right',
    theme: 'secondary',
    to: nextPage,
    replace: true,
    triggerKey
  });

  const iconButton = {
    type: faqVisible ? 'arrow-right' : 'questionmark',
    className: faqSeen ? '' : 'unclicked-faq-button',
    theme: 'secondary',
    onClick: handleOnHelpClick,
    faq: true,
  };

  const button = {
    className: 'button--icon',
    justify: 'space-between',
    onClick: handleClick,
    shadow: true,
    theme: nextPageEnabled ? 'primary' : 'disabled',
  };

  const searchButton = {
    disabled: (buttonClicked && errors[field.name]) || (!values.zipCode),
    theme: (buttonClicked && errors[field.name]) || (!values.zipCode) ? 'disabled' : 'primary',
    shadow: false,
    onClick: handleOnClickSearch,
    className: "search-button"
  }

  const radiusValueList = {
    '5': '5 km',
    '10': '10 km',
    '15': '15 km',
    '20': '20 km',
    '25': '25 km',
    '30': '30 km',
    '40': '40 km',
    '50': '50 km'
  }

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, false, false, pageName)

  const legend = content["over-title-text"]
  const heading = content["title"]
  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const useCustomLogo = content["use-custom-logo"]

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  const chooseMiddleComponent = useCustomLogo
    ? <CompanyLogo {...middleLogo} />
    : <ProgressionIndicator steps={3} current={2} />

  return (
    <React.Fragment>
      <FAQ
        faqListProps={faqListProps}
        faqs={faqs}
        faqLink={insurer.helpcenterLink}
        customHelpLink={customHelpLink}
        helpLinkText={helpLinkText}
        faqVisible={faqVisible}
      />
      <StyledMain {...main}>
        <NavBar>
          <Container className="icon-link-container">
            {pagePath.prevPage && <IconLink {...iconLink([37])} />}
            {nextPage && <IconLink {...iconLinkForward([39])} />}
          </Container>
          {chooseMiddleComponent}
          <Container className="faq-button-container">
            <IconButton {...iconButton} />
          </Container>
        </NavBar>
        <GridContainer main={true} className="bodyshop-select__container" direction='column'>
          <GridColumn className="bodyshop-select__title">
            <Legend>{legend}</Legend>
            <Heading level="1">{heading}</Heading>
          </GridColumn>
          <GridColumn className="grid__column--main bodyshop-select__main">
            <ListContainer
              className="list__container list-control__container"
              $scaling={scaling}
            >
              <Form className={'form-postcode-city'} {...form} >
                <Container className="form-input">
                  <Input
                    {...formInput(field)}
                    autoFocus={!isMobileOnly}
                  />
                </Container>
                <Container className="validation__container">
                  {buttonClicked && errors[field.name] && (
                    <ValidationFeedback type="error" $scaling={scaling}>{errors[field.name]}</ValidationFeedback>
                  )}
                </Container>
              </Form>
              <StyledDropdown
                id="dropdownRadius"
                label="Radius"
                onChange={handleOnChangeRadius}
                value={radius}
                valueList={radiusValueList}
                $scaling={scaling}
                customBaseSize={24}
              />
              <Container className="list-control__button-container" >
                <Button {...searchButton}>
                  {(buttonClicked && errors[field.name]) || (!values.zipCode) ? 'Vul de postcode in a.u.b.' : 'Resultaten tonen'}</Button>
              </Container>
            </ListContainer>
            <Container>
              {
                listsLoadingError &&
                <ValidationFeedback>
                  Deze postcode is ongeldig. Controleer en zoek opnieuw</ValidationFeedback>
              }
              {
                listsLoadingEmpty &&
                <ValidationFeedback>Er zijn geen resultaten voor de combinatie van postcode en straal.</ValidationFeedback>
              }
            </Container>
            <ListContainer className="list__container list-bodyshop__container" $scaling={scaling}>
              {renderBodyshopLists()}
            </ListContainer>
          </GridColumn>
          <GridColumn
            className=" grid__column--footer bodyshop-select__footer"
          >
            <Container className="bodyshop-select__footer--button-container">
              {' '}
              <Button {...button}>
                Volgende
                <UseIcon name="arrow-right" className="button__icon" />
              </Button>
              <Text className="of--enter">
                of <b>ENTER</b>
              </Text>
            </Container>
          </GridColumn>
        </GridContainer>
      </StyledMain>
    </React.Fragment >
  )
}

export default BodyshopSelectIntakePlus;
