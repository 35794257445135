import React from 'react';
import { styled, Box, Stack, DialogContent, Dialog } from '@mui/material';
import Modal from '@mui/material/Modal';

const PREFIX = 'ImageDialog';

const classes = {
  modal: `${PREFIX}-modal`,
  paper: `${PREFIX}-paper`,
  img: `${PREFIX}-img`
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-container': {
    [theme.breakpoints.up('lg')]: {
      width: '100%',
    },
  },
  '.MuiPaper-root': {
    [theme.breakpoints.up('lg')]: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      minWidth: '60%',
    },
  },
}))

function ImageDialog({
  handleClose,
  open,
  src,
  alt
}) {



  return (
    <StyledDialog onClose={handleClose} open={open} closeAfterTransition>
      <Box>
        <DialogContent
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Stack
            padding={2}
            sx={{
              height: { lg: 'min-content' },
              maxHeight: '100%',
              maxWidth: '100%',
              width: { lg: 'min-content' },
            }}
          >
            <img src={src} alt={alt} className={classes.img} />
          </Stack>
        </DialogContent>
      </Box>
    </StyledDialog>
  );
}


export default ImageDialog;