// NPM imports.
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// Domain imports.
import {
  Button,
  Checkbox,
  Container,
  GridColumn,
  GridContainer,
  Heading,
  IconLink,
  Legend,
  Loader,
  Main,
  NavBar,
  ProgressionIndicator,
  UseIcon,
} from '@domain/components'
import { getPrevNextPagePath } from '@domain/helpers';
import { useSaveIncident, useCheckoutQuestionnaire } from '@domain/hooks';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { isIE } from 'react-device-detect';


const StyledMain = styled(Main)`
  .grid-container {
    .checkinout__question--title {
      flex: 1 1 0px;
      max-width: ${isIE ? '100%' : '480px'};
      .legend {
        font-size: 16px;
        line-height: 20px;
      }
      .h1 {
        font-size: 48px;
        line-height: 56px;
      }
      p {
        font-size: 14px;
        line-height: 18px;
      }
    }
    .checkinout__question--question {
      flex: 1 1 0px;
      .screen-desktop:not(.screen-tablet) & {
        max-width: 540px;
        margin-left: auto;
        padding-left: 60px;
      }
    }
    .checkinout__question--footer {
      z-index: 3;
      .screen-desktop:not(.screen-tablet) & {
        position:absolute;
        padding: 40px;
        bottom: 0;
        right: 0;
        flex: 1 1 0px;
      }
    }
  }
  @media (max-width: 1028px) {
    .screen-desktop:not(.screen-tablet) & {
      .grid-container {
        .checkinout__question--title {
          text-align: center;
          margin: auto;
        }
        .checkinout__question--question {
          margin: auto;
          padding: 60px 0 0 0;
        }
        .checkinout__question--footer {
          position: relative;
          padding: 0;
          width: 100;
        }
      }
    }
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 16px;
`;

const StyledButton = styled(Button)`
  @media screen and (max-width: 580px) {
    .screen-desktop & {
      width: 100%;
    }
  }
`;

function OwnRisk() {


  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);

  const questionnaire = useCheckoutQuestionnaire()

  const [ownRiskPaid, setOwnRiskPaid] = useState(incident.ownRiskPaid || false);
  const saveIncident = useSaveIncident();

  // const nextButtonEnabled = !errors ? true : false;
  const nextButtonEnabled = true;
  const location = useLocation()
  const navigate = useNavigate()
  const currentPath = location.pathname;
  const pagePath = getPrevNextPagePath(questionnaire, incident, currentPath, []);
  const { nextPage, prevPage } = pagePath;

  const checkCheckbox = (func) => () => {
    func(prevValue => {
      const newValue = !prevValue;
      return newValue;
    })
  }

  const ownRiskPaidName = 'ownRiskPaid';

  async function handleOnClick() {
    await saveIncident({
      ...incident,
      [ownRiskPaidName]: ownRiskPaid,
    });
    navigate(nextPage);
  }

  if (!incident || !insurer) {
    return <Loader />;
  }


  const main = {
    className: 'main__checkinout main__checkinout--own-risk',
  };

  const iconLink = {
    type: 'arrow-left',
    theme: 'secondary',
    to: prevPage,
    replace: true,
  };

  const iconLinkForward = {
    type: 'arrow-right',
    theme: 'secondary',
    to: nextButtonEnabled && nextPage && nextPage,
    replace: true,
  };

  const button = {
    theme: nextButtonEnabled ? 'primary' : 'disabled',
    onClick: handleOnClick,
    justify: 'space-between',
    shadow: true,
    className: 'image-upload__button button--icon',
  };


  return (
    <React.Fragment>
      <StyledMain {...main}>
        <NavBar>
          <Container className="icon-link-container">
            <IconLink {...iconLink} />
            {!!nextButtonEnabled && <IconLink {...iconLinkForward} />}
          </Container>
          <ProgressionIndicator steps={3} current={2} />
          <Container className="empty-div" />
        </NavBar>
        <GridContainer main={true}>
          <GridColumn className="checkinout__question--title" justify="flex-start">
            <Legend>Eigen risico</Legend>
            <Heading level="1">Uw eigen risico bedraagt €{incident.deductibles}. We willen het eigen risico graag nu met u afrekenen. </Heading>
          </GridColumn>

          <GridColumn
            className="checkinout__question--question"

            justify="flex-start"
          // className="grid__column--main"
          >
            <StyledCheckbox
              name="Eigen risico is betaald tijdens de check-out"
              id="Eigen risico is betaald tijdens de check-out"
              value={ownRiskPaid}
              onClick={checkCheckbox(setOwnRiskPaid)}
            >Eigen risico is betaald tijdens de check-out
              <UseIcon name="checkmark" className="checkbox__icon" />
            </StyledCheckbox>
          </GridColumn>
          <GridColumn className="grid__column--footer checkinout__question--footer">
            <StyledButton {...button}>
              Volgende
              <UseIcon name="arrow-right" className="button__icon" />
            </StyledButton>
          </GridColumn>
        </GridContainer>
      </StyledMain>
    </React.Fragment>
  )
}

export default OwnRisk;
