import { datadogLogs } from '@datadog/browser-logs'

import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { InsurerService } from '@domain/services';
import { INSURER_FETCHED, HTTP_ERROR_ENCOUNTERED } from '@domain/action-types';
import { proccessType } from '@domain/constants';
import { browserStorage } from '@domain/helpers';

function useInsurerLoader() {
  // const companyToken = useSelector(state => state.companyToken);
  const insurer = useSelector(state => state.insurer);
  const uuid = useSelector(state => state.uuid);
  const pid = useSelector(state => state.pid);
  const fid = useSelector(state => state.fid)
  const rid = useSelector(state => state.rid)
  const dispatch = useDispatch();
  const insurerLoading = useRef(false);
  const incident = useSelector(state => state.incident)

  useEffect(() => {
    if (!insurerLoading.current && !insurer && uuid) {
      const getInsurer = async () => {
        try {
          const newInsurer = await InsurerService.get(uuid);
          dispatch({ type: INSURER_FETCHED, insurer: newInsurer });
        } catch ({ error }) {
          const errorLogMessage = "Error occurred inside useInsurerLoader trying to retrieve branding (InsurerService.get):"
          const errorInstance = new Error(errorLogMessage)
          datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
          dispatch({
            type: HTTP_ERROR_ENCOUNTERED,
            error,
          });
        }
      };
      insurerLoading.current = true;
      getInsurer();
    }
    if (!insurerLoading.current && !insurer && pid && !uuid) {
      const getInsurer = async (FID, RID) => {
        try {
          const newInsurer = await InsurerService.getBranding(pid, FID, RID);
          dispatch({ type: INSURER_FETCHED, insurer: newInsurer });
        } catch ({ error }) {
          const errorLogMessage = "Error occurred inside useInsurerLoader trying to retrieve branding (InsurerService.getBranding):"
          const errorInstance = new Error(errorLogMessage)
          datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
          dispatch({
            type: HTTP_ERROR_ENCOUNTERED,
            error,
          });
        }
      };

      if (insurer && insurer.objectType === proccessType.Schadegarant) {
        if (!!incident) {
          const fid = incident.fid
          insurerLoading.current = true;
          getInsurer(fid);
          return
        }
        const fid = browserStorage.getFID()
        if (!!fid) {
          insurerLoading.current = true;
          getInsurer(fid);
          return
        }
      }
      if (fid && rid) {
        insurerLoading.current = true;
        getInsurer(fid, rid);
        return
      }
      if (fid) {
        insurerLoading.current = true;
        getInsurer(fid);
      }
      insurerLoading.current = true;
      getInsurer(null);
    }

    if (insurer) {
      insurerLoading.current = false;
    }
  }, [pid, insurerLoading, insurer, uuid, dispatch, incident, fid, rid]);
}

export default useInsurerLoader;
