import React, { Children, forwardRef } from 'react'
import { styled } from '@mui/material/styles';

const StyledContainerSP = styled('div', {
  shouldForwardProp: (propName) => !propName.toString().startsWith('$')
})`
  background: ${props => props.$background};
  box-sizing: ${props => props.$boxSizing};
  display: ${props => props.$display || 'flex'};
  position: ${props => props.$position};
  flex-direction: ${props => props.$flexDirection || 'row'};
  flex: ${props =>
    `${props.$flexGrow || 1} ${props.$flexShrink || 0} ${props.$flexBasis ||
    'auto'}`};
  flex-wrap: ${props => props.$flexWrap};
  justify-content: ${props => props.$justifyContent};
  align-items: ${props => props.$alignItems};
  min-width: ${props => !!props.$minWidth && typeof props.$minWidth === 'number' ? props.$$scaling.scaleUpTo4K(props.$minWidth) : props.$minWidth || 0};
  min-height: 0;
  width: ${props => !!props.$width && typeof props.$width === 'number' ? props.$$scaling.scaleUpTo4K(props.$width) : props.$width};
  height: ${props => !!props.$height && typeof props.$width === 'number' ? props.$$scaling.scaleUpTo4K(props.$height) : props.$height};
  max-width: ${props => !!props.$maxWidth && typeof props.$width === 'number' ? props.$$scaling.scaleUpTo4K(props.$maxWidth) : props.$maxWidth};
  max-height: ${props => !!props.$maxHeight && typeof props.$width === 'number' ? props.$$scaling.scaleUpTo4K(props.$maxHeight) : props.$maxHeight};
  margin: ${props => props.$margin};
  margin-top: ${props => props.$marginTop};
  margin-right: ${props => props.$marginRight};
  margin-bottom: ${props => props.$marginBottom};
  margin-left: ${props => props.$marginLeft};
  padding: ${props => props.$padding};
  padding-top: ${props => props.$paddingTop};
  padding-right: ${props => props.$paddingRight};
  padding-bottom: ${props => props.$paddingBottom};
  padding-left: ${props => props.$paddingLeft};
`;

const ContainerSP = forwardRef(({
  background,
  boxSizing,
  className,
  display,
  position,
  flexGrow,
  flexShrink,
  flexBasis,
  flexDirection,
  flexWrap,
  justifyContent,
  alignItems,
  width,
  height,
  maxWidth,
  maxHeight,
  minWidth,
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  children
}, ref) => {

  //const usedClass = classNames('container', className)
  return (
    <StyledContainerSP
      ref={ref}
      className={usedClass}
      $background={background}
      $boxSizing={boxSizing}
      $display={display}
      $position={position}
      $flexGrow={flexGrow}
      $flexShrink={flexShrink}
      $flexBasis={flexBasis}
      $flexDirection={flexDirection}
      $flexWrap={flexWrap}
      $justifyContent={justifyContent}
      $alignItems={alignItems}
      $width={width}
      $height={height}
      $maxWidth={maxWidth}
      $maxHeight={maxHeight}
      $minWidth={minWidth}
      $margin={margin}
      $marginTop={marginTop}
      $marginRight={marginRight}
      $marginBottom={marginBottom}
      $marginLeft={marginLeft}
      $padding={padding}
      $paddingTop={paddingTop}
      $paddingRight={paddingRight}
      $paddingBottom={paddingBottom}
      $paddingLeft={paddingLeft}
    >
      {Children.toArray(children)}
    </StyledContainerSP>
  );
});
ContainerSP.displayName = 'ContainerSP'
export default ContainerSP
