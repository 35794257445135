import { datadogLogs } from '@datadog/browser-logs'

import { useSelector, useDispatch } from 'react-redux';
import { InsurerService } from '@domain/services';
import { INSURER_FETCHED, HTTP_ERROR_ENCOUNTERED } from '@domain/action-types';

function useReloadInsurer() {
  const uuid = useSelector(state => state.uuid);
  const dispatch = useDispatch()

  const reloadInsurer = async () => {
    try {
      const newInsurer = await InsurerService.get(uuid);
      await dispatch({ type: INSURER_FETCHED, insurer: newInsurer });
    } catch ({ error }) {
      const errorLogMessage = "Error occurred inside useReloadInsurer trying to retrieve branding (InsurerService.get):"
      const errorInstance = new Error(errorLogMessage)
      datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
      dispatch({
        type: HTTP_ERROR_ENCOUNTERED,
        error,
      });
    }
  };

  return reloadInsurer
}

export default useReloadInsurer
