import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { useSaveIncidentFactory } from '@domain/hooks';
import { TextInputsPage } from '@domain/pages';

// import questionnaire from '../../questionnaire';
import { chooseLogoSize, generatePageContent, getPrevNextPagePath } from '@domain/helpers';
import { Loader } from '@domain/components';
import { detectContentType } from '@domain/content';
import { useQuestionnaire } from '@domain/providers';
import { useTheme } from '@mui/material';
import { validationSchema, validationSchemaNotRequired } from './validation-schema'
import { useLocation } from 'react-router-dom';

const fields = [
  {
    name: 'customerPolisNumber',
    label: 'Polisnummer',
  },
];


function PolicyNumber() {

  const { questionnaire } = useQuestionnaire();
  const insurer = useSelector(state => state.insurer)
  const incident = useSelector(state => state.incident);
  const files = useSelector(state => state.files)
  const location = useLocation()
  const currentPath = location.pathname;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined
  const themeContext = useTheme();
  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);

  if (!insurer || !questionnaire) {
    return <Loader />;
  }

  const pagePath = getPrevNextPagePath(
    questionnaire,
    incident,
    currentPath,
    files,
  );
  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, false, false, pageName)

  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const useCustomLogo = content["use-custom-logo"]
  const legend = content["over-title-text"]
  const header = content["title"]
  const faqs = content["faqs"]

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  const validationSchemaToBeUsed = () => {
    if (page.required === false) {
      return validationSchemaNotRequired
    }
    return validationSchema
  }

  return (
    <TextInputsPage
      buttonText={'Volgende'}
      currentPath={currentPath}
      fields={fields}
      formID="polisnummer"
      legend={legend}
      heading={header}
      nextPage={nextPage}
      prevPage={pagePath.prevPage}
      progressIndicatorCurrent={1}
      progressIndicatorSteps={3}
      questionnaire={questionnaire}
      saveIncident={saveIncident}
      validationSchema={validationSchemaToBeUsed()}
      customHelpLink={customHelpLink}
      helpLinkText={helpLinkText}
      customMiddleLogo={useCustomLogo}
      middleLogo={middleLogo}
      files={files}
      faqs={faqs}
    />
  );
}

export default PolicyNumber;
