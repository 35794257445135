import React from 'react';

import { ButtonSP, Card, ContainerSP, DatePickerSP, FormSP, HeadingSP, LicenseInput, SubmitButton, ToggleButtons } from '@domain/components';

import { useScaling } from '@domain/hooks';

import Color from 'color'
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';

import { Colors } from '@domain/theming';


const cardGradient = 'linear-gradient(342deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.18) 37%, rgba(0,0,0,0.08) 91%)';

const FormCard = styled(motion(Card, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
}))`
   background-image: ${cardGradient};
        border: 3px solid ${Colors.WHITE};
        border-radius: 0px;
        flex-grow: 0;
        width: 100%;
        position:relative;
        max-width: ${props => props.$scaling.scaleUpTo4K(520)}px;
        padding: 0;
        margin: auto;
        align-self: flex-start;
        align-items: flex-end;
        opacity: .85;
        will-change: transform, opacity;
        &.card-form-grey {
          background-image: linear-gradient(
            358deg,
            ${Colors.GREY_LIGHT} -10%,
            ${Color(Colors.GREY_LIGHT).lighten(0.06).hex()} 33%,
            ${Colors.GREY_LIGHT} 55%
            );
            .button {
              border-color: ${Colors.BLACK};
              color: ${Colors.BLACK};
              &:hover {
                background: ${Color(Colors.BLACK).rgb().alpha(0.08).string()}
              }
            }
        }

        &.single-card {
          min-height:  ${props => props.$scaling.scaleUpTo4K(468)}px;
          height: 0; //IE
        }
        + .card {
          margin-top: ${props => props.$scaling.scaleUpTo4K(60)}px;
          margin-bottom: ${props => props.$scaling.scaleUpTo4K(468)}px;
        }
        p, h3, input, label {
        }
        @media screen and (min-width:1920px) {
          &.large-width-out {
            max-width: ${props => props.$scaling.scaleUpTo4K(650)}px;
          }
        }
        @media screen and (max-width: 900px) {
          + .card {
            margin-top: 40px;
            margin-bottom:30px;
          }
        }
        @media screen and (max-width: 420px) {
          &.card.card-form {
            padding: 0;
          }
        }     
      `;

const FormCardContainer = styled(
  motion(ContainerSP, {
    shouldForwardProp: (propName) => !propName.startsWith('$')
  }),
)`
        padding: ${props => props.$scaling.scaleUpTo4K(30)}px ${props => props.$scaling.scaleUpTo4K(30)}px ${props => props.$scaling.scaleUpTo4K(40)}px;
        will-change: transform, opacity;
        + .card-form__container--response {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        .card-form__button--redirect {
          max-width: 100%;
          text-align: center;
          + .card-form__button--redirect {
            margin-top: ${props => props.$scaling.scaleUpTo4K(30)}px;
          }
        }
        h4, h5 {
          // color: ${props => props.theme.colors.primary};
          color: ${props => props.theme.isCustomThemed ? Colors.GREY_DARKER : props.theme.colors.primary};
        }
      `;

const CaseForm = styled(motion(FormSP, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
}))`
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      max-width: ${props => props.$scaling.scaleUpTo4K(500)}px;
      padding: 0;
      // justify-content: space-between;
      .card-form & .button--submit {
        margin-top: auto;
      }
      > p,
      > ul,
      > h3 {
        max-width: 100%;
      }
      ul {
        padding-inline-start: 0px;
        padding-inline-end: 0px;
        padding-left: ${props => props.$scaling.scaleUpTo4K(18)}px;
        padding-bottom: ${props => props.$scaling.scaleUpTo4K(15)}px;
        font-size: ${props => props.$scaling.textSize(13)}px;
      }
      @media screen and (min-width:1920px) {
        &.large-width {
          max-width: ${props => props.$scaling.scaleUpTo4K(610)}px;
        }
      }
    `;

const StyleCarddHeadingWithCloseButton = styled(HeadingSP, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
    flex:1 0 auto;
    margin-left: ${props => props.$scaling.scaleUpTo4K(40)}px;
    @media screen and (max-width:900px) {
      margin-left: 10px;
    }
  `;

const StyledCloseButton = styled(ButtonSP, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
    .card-form__header-group & {
      flex: 0 0 auto;
      width: ${props => props.$scaling.scaleUpTo4K(30)}px;
      height: ${props => props.$scaling.scaleUpTo4K(30)}px;
      padding: 0;
      margin: 0 0 0 auto;
      border-radius: ${props => props.$scaling.scaleUpTo4K(10)}px;
      box-shadow: none;
    }
  `;
const formVariants = (scaling) => ({
  hidden: {
    opacity: 0,
    y: scaling.scaleUpTo4K(50),
    transition: {
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      opacity: {
        duration: 0.68,
        delay: 0.3,
      },
      y: {
        delay: 0.3,
        type: 'spring',
        stiffness: 40,
      }
    },
  }
})

const formInnerVariants = {
  hidden: {
    opacity: 0,
    y: '20%',
    transition: {
      duration: 0.12,
      y: {
        delay: 0.12,
      },
    },
    transitionEnd: {
      zIndex: 0,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    zIndex: 1,
    transition: {
      type: 'spring',
      stiffness: 30,
      mass: 0.2,
      opacity: {
        delay: 0.22,
        duration: 0.18,
      },
    },
  },
};

const FormSG = ({ verifyAndSubmit, isLoading, onChangeCreation, onBlurLicensePlate, onBlurDamageDate, handleResetCreation, handleSubmitDamageType, handleDamageType, shouldShowError, showError, formDataCreation, incident, licenseCreationBlurred, dateCreationBlurred, errors, damageType, content }) => {

  const contentCardOne = content['card-one']
  const contentCardTwo = content['card-two']

  const typeOfDamageOptions = [{ value: "Carrosserie", label: "Autoschade", ariaLabel: "car" }, { value: "CarrosserieGlas", label: "Auto en ruitschade", ariaLabel: "both" }, { value: "Glas", label: "Ruitschade", ariaLabel: "glass" }]

  const scaling = useScaling()
  const { button: buttonCardOne, title: titleCardOne } = contentCardOne
  const { button: buttonCardTwo, title: titleCardTwo } = contentCardTwo

  const { licensePlate, damageDate } = formDataCreation;

  return (
    <FormCard
      className="card-form single-card"
      shadow={4}
      variants={formVariants(scaling)}
      initial="hidden"
      animate="visible"
      $scaling={scaling}
    >
      <FormCardContainer
        className="card-form__container--fields"
        flexDirection="column"
        flexGrow="1"
        flexShrink="1"
        width="100%"
        variants={formInnerVariants}
        initial={false}
        animate={!formDataCreation.damageType ? 'visible' : 'hidden'}
        $scaling={scaling}
      >
        <CaseForm
          onSubmit={handleSubmitDamageType}
          $scaling={scaling}
        >
          {titleCardOne && <HeadingSP
            level={4}
            textAlign="center"
            marginBottom={scaling.scaleUpTo4K(30) + 'px'}
            marginTop={scaling.scaleUpTo4K(10) + 'px'}
            initial={false}
          >
            {titleCardOne}
          </HeadingSP>}
          <ToggleButtons handleChange={handleDamageType} value={damageType} dynamicFontSize={true} arrayOfButtons={typeOfDamageOptions} />
          {<SubmitButton
            value="Jouw opties"
            disabled={false}
            dynamicFontSize={true}
            isThemed={true}
          ><span>{buttonCardOne || 'Volgende'}</span>
          </SubmitButton>}
        </CaseForm>
      </FormCardContainer>
      <FormCardContainer
        className="card-form__container--response"
        flexDirection="column"
        flexGrow="1"
        flexShrink="1"
        width="100%"
        variants={formInnerVariants}
        initial={false}
        animate={formDataCreation.damageType.length ? 'visible' : 'hidden'}
        $scaling={scaling}
      >
        <ContainerSP
          className="card-form__header-group"
          flexGrow="0"
          margin="0px"
          alignItems="center"
          justifyContent="center"
        >
          {titleCardTwo && <StyleCarddHeadingWithCloseButton
            level={4}
            textAlign="center"
            $scaling={scaling}
          >
            {titleCardTwo}
          </StyleCarddHeadingWithCloseButton>}
          <StyledCloseButton
            variant="outline"
            onClick={handleResetCreation}
            $scaling={scaling}
            $isThemed={true}
          >
            X
          </StyledCloseButton>
        </ContainerSP>
        <CaseForm
          onSubmit={verifyAndSubmit}
          $scaling={scaling}
        >
          <LicenseInput
            onChange={onChangeCreation}
            value={licensePlate || ''}
            name="licensePlate"
            type="text"
            label="Kenteken"
            onBlur={onBlurLicensePlate}
            disabled={true}
            error={shouldShowError('licensePlate', licenseCreationBlurred)}
            dynamicFontSize={true}
          />
          {showError('licensePlate', licenseCreationBlurred)}
          <DatePickerSP
            value={damageDate || ''}
            onChange={onChangeCreation}
            disabled={true}
            label="Schadedatum"
            onBlur={onBlurDamageDate}
            error={shouldShowError('damageDate', dateCreationBlurred)}
            dynamicFontSize={true}
          />
          {showError('damageDate', dateCreationBlurred)}
          <SubmitButton
            value="Jouw opties"
            disabled={!!errors}
            dynamicFontSize={true}
            loading={isLoading}
            isThemed={true}
          ><span>{buttonCardTwo || 'Schadedossier aanmaken'}</span>
          </SubmitButton>
        </CaseForm>
      </FormCardContainer>
    </FormCard>
  )
};
export default FormSG
