import React, { useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  CompanyLogo,
  Container,
  FAQ,
  GridColumn,
  GridContainer,
  Heading,
  IconButton,
  IconLink,
  Legend,
  Loader,
  Main,
  NavBar,
  ProgressionIndicator,
  Radio,
  RadioGroup,
} from '@domain/components';
import { causeDamageCategoriesDropdown, proccessType } from '@domain/constants';
import { chooseLogoSize, generatePageContent, getPrevNextPagePath, pageToNumber } from '@domain/helpers';
import { useFaq, useRadio } from '@domain/hooks';
import { useQuestionnaire } from '@domain/providers';

import routes from '../routes';
import { detectContentType } from '@domain/content';
import CauseDamageGlass from '../CauseDamageGlass';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function CauseDamage() {

  const { questionnaire } = useQuestionnaire();
  const causeDamageCategory = 'causeDamage';


  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)
  const isDI = (insurer.objectType === proccessType.DI)
  const [
    faqVisible,
    handleOnHelpClick,
    clickOutSideFaq,
    faqListProps,
    faqSeen,
  ] = useFaq();

  const currentValue = incident[causeDamageCategory];

  const themeContext = useTheme();
  const navigate = useNavigate()
  const currentPath = '/onboarding/schade-beschrijven/schadeoorzaken'
  const pagePath = getPrevNextPagePath(
    questionnaire,
    incident,
    currentPath,
    files
  );

  const { causeDamageAanrijding, causeDamageAnders } = routes;
  const currentStep = pageToNumber(currentPath, questionnaire);
  const nextPageEnabled = questionnaire[currentStep].check(incident);
  const nextPage = nextPageEnabled
    ? causeDamageCategoriesDropdown.Aanrijding.indexOf(currentValue) >= 0
      ? currentPath + causeDamageAanrijding
      : currentPath + causeDamageAnders
    : undefined;


  const pickNextPage = value => {
    if (value === 'Aanrijding') {
      return currentPath + causeDamageAanrijding;
    }
    if (value === 'Anders') {
      return currentPath + causeDamageAnders;
    }
  };

  const causeDamage = incident.causeDamage;

  const checkIsValueIsChosen = useCallback(
    (initialValue, value, id) => {
      if (id === 3) {
        return causeDamageCategoriesDropdown.Anders.includes(causeDamage);
      }
      return false;
    },
    [causeDamage],
  );


  const faqs = useMemo(
    () => [
      {
        question: 'Waarom moet ik opgeven hoe de schade is ontstaan?',
        answer: isDI
          ? 'Deze informatie helpt ons bij het bepalen van het schadebeeld en het inplannen van een reparatie.'
          : 'Dit is informatie die we met uw verzekeraar moeten delen en is daarom altijd onderdeel van een schadedossier. De verzekeraar deelt schades standaard in in verschillende groepen.'
      },
      {
        question: 'Wat betekenen de verschillende oorzaken precies?',
        answer: (
          <dl>
            <dt>Aanrijding met voertuig - </dt>
            <dd>
              U heeft een aanrijding gehad met een ander gemotoriseerd voertuig, dit
              kan bijvoorbeeld een auto, busje, vrachtwagen of scooter zijn.
            </dd>
            <br />
            <br />
            <dt>Aanrijding met voetganger/fiets - </dt>
            <dd>
              U heeft een aanrijding gehad met een niet-gemotoriseerd voertuig zoals
              een fiets of een voetganger.
            </dd>
            <br />
            <br />
            <dt>Aanrijding met dier - </dt>
            <dd>
              U heeft een aanrijding gehad met een dier op de weg, bijvoorbeeld een
              hond, hert of gans.
            </dd>
            <br />
            <br />
            <dt>Aanrijding met (vast) object - </dt>
            <dd>
              U heeft bijvoorbeeld een aanrijding gehad met een hekje, een vangrail
              of een bord langs de weg.
            </dd>{' '}
            <br />
            <br />
            <dt>Inbraak - </dt>
            <dd>
              Er is in uw auto ingebroken en er zijn eigendommen van u uit de auto
              gestolen of er zijn onderdelen van uw auto uit uw auto gestolen.
            </dd>{' '}
            <br />
            <br />
            <dt>Vandalisme - </dt>
            <dd>Uw auto is (opzettelijk) beschadigd aan binnen- of buitenkant.</dd>
            <br />
            <br />
            <dt>Hagel/storm - </dt>
            <dd>
              Uw auto heeft schade opgelopen als gevolg van een hagelbui of een
              storm.
            </dd>
            <br />
            <br />
            <dt>Brand - </dt>
            <dd>
              Uw auto heeft schade opgelopen als gevolg van een brand in of in de
              buurt van uw auto.
            </dd>
          </dl>
        ),
      },
    ],
    [isDI]);

  //// This useRadio is ok ( doenst use goToNextPage function)
  const [handleChange, isAutoFocus, selectedValue] = useRadio(
    currentValue,
    causeDamageCategory,
    value => navigate(pickNextPage(value)),
    value => navigate(pickNextPage(value)),
    checkIsValueIsChosen,
  );

  const main = {
    faqVisible: faqVisible,
    dimmed: faqVisible,
    className: 'main__damage',
    callBack: clickOutSideFaq,
  };

  const iconLink = (triggerKey) => ({
    type: 'arrow-left',
    theme: 'secondary',
    to: pagePath.prevPage,
    replace: true,
    triggerKey
  });

  const iconLinkForward = (triggerKey) => ({
    type: 'arrow-right',
    theme: 'secondary',
    to: nextPage,
    replace: true,
    triggerKey
  });

  const iconButton = {
    type: faqVisible ? 'arrow-right' : 'questionmark',
    className: faqSeen ? '' : 'unclicked-faq-button',
    theme: 'secondary',
    onClick: handleOnHelpClick,
    faq: true,
  };

  const radio = (id, value, triggerKeys) => ({
    name: 'cause-damage',
    id: `damage-option-${id}`,
    defaultChecked:
      selectedValue === value ||
      causeDamageCategoriesDropdown[value].includes(causeDamage),
    value,
    onClick: handleChange,
    radioClassName: 'radio--damage-cause',
    autoFocus: isAutoFocus(value, id),
    triggerKeys
  });

  const radioCollision = radio(1, 'Aanrijding', [49]);

  const radioOther = radio(2, 'Anders', [50]);

  if (!insurer || !incident) {
    return <Loader />;
  }

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, false, false, pageName)

  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const useCustomLogo = content["use-custom-logo"]

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  const chooseMiddleComponent = useCustomLogo
    ? <CompanyLogo {...middleLogo} />
    : <ProgressionIndicator steps={3} current={2} />

  if (incident.damageType === 'Glas') {
    return <CauseDamageGlass />
  }

  return (
    <React.Fragment>
      <FAQ
        faqListProps={faqListProps}
        faqs={faqs}
        faqLink={insurer.helpcenterLink}
        customHelpLink={customHelpLink}
        helpLinkText={helpLinkText}
        faqVisible={faqVisible}
      />
      <Main {...main}>
        <NavBar>
          <Container className="icon-link-container">
            <IconLink {...iconLink([37])} />
            {nextPage && <IconLink {...iconLinkForward([39])} />}
          </Container>
          {chooseMiddleComponent}
          <Container className="faq-button-container">
            <IconButton {...iconButton} />
          </Container>
        </NavBar>
        <GridContainer main={true}>
          <GridColumn className="grid__column--main">
            <Legend>Schade beschrijven</Legend>
            <Heading level="1">Hoe is de schade ontstaan?</Heading>
          </GridColumn>

          <GridColumn
            justify="flex-end"
            className="grid__column--footer grid__column--radio-group"
          >
            <RadioGroup onChange={handleChange}>
              <Radio {...radioCollision}>Aanrijding</Radio>
              <Radio {...radioOther}>Anders</Radio>
            </RadioGroup>
          </GridColumn>
        </GridContainer>
      </Main>
    </React.Fragment>
  );
}

export default CauseDamage;
