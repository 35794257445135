import { datadogLogs } from "@datadog/browser-logs";

import imageCompression from 'browser-image-compression'
import { isIE } from 'react-device-detect';
import { splitStringAtFirstComma, encodeFileInBase64 } from '@domain/helpers';
import { proccessType } from '@domain/constants';

async function optimizeImages(uploads, insurer) {
  const options = insurer && insurer.objectType === proccessType.Intake
    ? {
      maxSizeMB: 0.5,
      useWebWorker: true,
      initialQuality: 0,
      maxIteration: 20
    }
    : {
      maxSizeMB: 1,
      useWebWorker: true,
      initialQuality: 0,
      maxIteration: 20
    }

  if (isIE) {
    return uploads
  }

  const optimizedUploads = Object.entries(uploads).reduce(async (acc, [key, file]) => {
    const files = await acc

    if (file.type.startsWith('image')) {
      try {
        var blob = await imageCompression(file, options)
        files[key] = blob
        return files
      }
      catch (error) {
        const errorLogMessage = "error compressing image"
        const errorInstance = new Error(errorLogMessage)
        datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
      }
    }
    files[key] = file
    return files

  }, Promise.resolve({}))

  return optimizedUploads
}

async function prepareFileForUploading(file, extraDamageImage, imageCategory) {
  const encodedFile = await encodeFileInBase64(file);
  if (!encodedFile) {
    return null;
  }
  const [metadata, content] = splitStringAtFirstComma(encodedFile);
  const { name } = file
  const nameToSave = name.slice(0, 100)
  const extraDamageImg = extraDamageImage ? { extraDamageImage: extraDamageImage } : {}
  const imageCat = imageCategory ? { imageCategory: imageCategory } : {}
  return {
    fileName: nameToSave,
    content,
    cpID: 'CP' + Math.random().toString(), // can we guarantee uniqueness?
    fileAction: 'create',
    metadata,
    ...extraDamageImg,
    ...imageCat
  };
}

async function prepareFilesForUploading(files, insurer, extraDamageImage, imageCategory) {

  const optimizedUploads = await optimizeImages(files, insurer)

  const arrayOfFiles = await Promise.all(Object.values(optimizedUploads).map((optmizedImg) => prepareFileForUploading(optmizedImg, extraDamageImage, imageCategory)));

  return arrayOfFiles;
}

export default prepareFilesForUploading;
