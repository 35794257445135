import { datadogLogs } from '@datadog/browser-logs'

import { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux'

import { PdfDossierService } from '@domain/services';

// import useDispatchError from '../useDispatchError';

const usePdfDossierEndpoint = () => {
  const uuid = useSelector(state => state.uuid);
  const token = useSelector(state => state.token);
  const pdfDossierLoading = useRef(false);


  const getPdfDossier = useCallback(
    async function getPdfDossier() {
      if (pdfDossierLoading.current) {
        return;
      }
      pdfDossierLoading.current = true;

      try {
        const response = await PdfDossierService.get(token, uuid);
        pdfDossierLoading.current = false;
        return response;

      } catch ({ error }) {
        const errorLogMessage = "Error occurred inside usePdfDossierEndpoint trying to retrieve dossier (PdfDossierService.get):"
        const errorInstance = new Error(errorLogMessage)
        datadogLogs.logger.error(errorLogMessage, { error: error }, errorInstance)
        pdfDossierLoading.current = false;
        return { error };
      }
    },
    [token, uuid],
  );

  return {
    getPdfDossier,
  };
};

export default usePdfDossierEndpoint;
