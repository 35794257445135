import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';

import {
  CompanyLogo,
  Container,
  FAQ,
  GridColumn,
  GridContainer,
  Heading,
  IconButton,
  IconLink,
  Legend,
  Loader,
  Main,
  NavBar,
  ProgressionIndicator,
  Radio,
  RadioGroup,
} from '@domain/components';
import { causeDamageCategories } from '@domain/constants';
import { chooseLogoSize, generatePageContent, getPrevNextPagePath, pageToNumber, stripLastPathSegment } from '@domain/helpers';
import { useFaq, useRadio } from '@domain/hooks';
import { useQuestionnaire } from '@domain/providers';

import routes from '../routes';
import { detectContentType } from '@domain/content';
import { useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

function CauseDamage() {

  const { questionnaire } = useQuestionnaire();
  const causeDamageCategory = 'causeDamage';


  const themeContext = useTheme();

  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)

  const [
    faqVisible,
    handleOnHelpClick,
    clickOutSideFaq,
    faqListProps,
    faqSeen,
  ] = useFaq();

  const currentValue = incident[causeDamageCategory];


  const location = useLocation()
  const navigate = useNavigate()
  const currentPath = location.pathname;

  const pagePath = getPrevNextPagePath(
    questionnaire,
    incident,
    currentPath,
    files
  );

  const { causeDamageAanrijding, causeDamageAnders } = routes;
  const currentStep = pageToNumber(currentPath, questionnaire);
  const nextPageEnabled = questionnaire[currentStep].check(incident);
  const nextPage = nextPageEnabled
    ? causeDamageCategories.Aanrijding.indexOf(currentValue) >= 0
      ? stripLastPathSegment(currentPath) + causeDamageAanrijding
      : stripLastPathSegment(currentPath) + causeDamageAnders
    : undefined;


  const pickNextPage = value => {
    if (value === 'Aanrijding') {
      return stripLastPathSegment(currentPath) + causeDamageAanrijding;
    }
    if (value === 'Anders') {
      return stripLastPathSegment(currentPath) + causeDamageAnders;
    }
  };

  const causeDamage = incident.causeDamage;

  const checkIsValueIsChosen = useCallback(
    (initialValue, value, id) => {
      if (id === 3) {
        return causeDamageCategories.Anders.includes(causeDamage);
      }
      return false;
    },
    [causeDamage],
  );

  //// This useRadio is ok ( doenst use goToNextPage function)
  const [handleChange, isAutoFocus, selectedValue] = useRadio(
    currentValue,
    causeDamageCategory,
    value => navigate(pickNextPage(value)),
    value => navigate(pickNextPage(value)),
    checkIsValueIsChosen,
  );

  const main = {
    faqVisible: faqVisible,
    dimmed: faqVisible,
    className: 'main__damage',
    callBack: clickOutSideFaq,
  };

  const iconLink = (triggerKey) => ({
    type: 'arrow-left',
    theme: 'secondary',
    to: pagePath.prevPage,
    replace: true,
    triggerKey
  });

  const iconLinkForward = (triggerKey) => ({
    type: 'arrow-right',
    theme: 'secondary',
    to: nextPage,
    replace: true,
    triggerKey
  });

  const iconButton = {
    type: faqVisible ? 'arrow-right' : 'questionmark',
    className: faqSeen ? '' : 'unclicked-faq-button',
    theme: 'secondary',
    onClick: handleOnHelpClick,
    faq: true,
  };

  const radio = (id, value, triggerKeys) => ({
    name: 'cause-damage',
    id: `damage-option-${id}`,
    defaultChecked:
      selectedValue === value ||
      causeDamageCategories[value].includes(causeDamage),
    value,
    onClick: handleChange,
    radioClassName: 'radio--damage-cause',
    autoFocus: isAutoFocus(value, id),
    triggerKeys
  });

  const radioCollision = radio(1, 'Aanrijding', [49]);

  const radioOther = radio(2, 'Anders', [50]);

  if (!insurer) {
    return <Loader />;
  }

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, false, false, pageName)

  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const useCustomLogo = content["use-custom-logo"]
  const legend = content["over-title-text"]
  const heading = content["title"]
  const faqs = content["faqs"]

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  const chooseMiddleComponent = useCustomLogo
    ? <CompanyLogo {...middleLogo} />
    : <ProgressionIndicator steps={3} current={2} />

  return (
    <React.Fragment>
      <FAQ
        faqListProps={faqListProps}
        faqs={faqs}
        faqLink={insurer.helpcenterLink}
        customHelpLink={customHelpLink}
        helpLinkText={helpLinkText}
        faqVisible={faqVisible}
      />
      <Main {...main}>
        <NavBar>
          <Container className="icon-link-container">
            <IconLink {...iconLink([37])} />
            {nextPage && <IconLink {...iconLinkForward([39])} />}
          </Container>
          {chooseMiddleComponent}
          <Container className="faq-button-container">
            <IconButton {...iconButton} size="large" />
          </Container>
        </NavBar>
        <GridContainer main={true}>
          <GridColumn className="grid__column--main">
            <Legend>{legend}</Legend>
            <Heading level="1">{heading}</Heading>
          </GridColumn>

          <GridColumn
            justify="flex-end"
            className="grid__column--footer grid__column--radio-group"
          >
            <RadioGroup onChange={handleChange}>
              <Radio {...radioCollision}>Aanrijding</Radio>
              <Radio {...radioOther}>Anders</Radio>
            </RadioGroup>
          </GridColumn>
        </GridContainer>
      </Main>
    </React.Fragment>
  );
}

export default CauseDamage;
