// NPM imports.
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// Domain imports.
import {
  Button,
  Checkbox,
  Container,
  GridColumn,
  GridContainer,
  Heading,
  IconLink,
  Legend,
  Loader,
  Main,
  NavBar,
  ProgressionIndicator,
  UseIcon,
} from '@domain/components'
import { getPrevNextPagePath, stripLastPathSegment } from '@domain/helpers';
import { useSaveIncident, useCheckoutQuestionnaire } from '@domain/hooks';
import { styled } from '@mui/material/styles';
// Routes for checkin flow.
import routes from '../routes';
import { useLocation, useNavigate } from 'react-router-dom';

const StyledMain = styled(Main)`
  .grid-container {
    .checkinout__question--title {
      flex: 1 1 0px;
      .screen-tablet & {
        max-width: 480px;
      }
      .legend {
        font-size: 16px;
        line-height: 20px;
      }
      .h1 {
        font-size: 48px;
        line-height: 56px;
      }
      p {
        font-size: 14px;
        line-height: 18px;
      }
    }
    .checkinout__question--question {
      flex: 1 1 0px;
      .screen-desktop:not(.screen-tablet) & {
        max-width: 540px;
        margin-left: auto;
        padding-left: 60px;
      }
      .checkbox {
        margin-bottom: 16px;
      }
    }
    .checkinout__question--footer {
      z-index: 3;
      .screen-desktop:not(.screen-tablet) & {
        position:absolute;
        padding: 40px;
        bottom: 0;
        right: 0;
        flex: 1 1 0px;
      }
    }
  }
  @media (max-width: 1028px) {
    .screen-desktop:not(.screen-tablet) & {
      .grid-container {
        .checkinout__question--title {
          text-align: center;
          margin: auto;
        }
        .checkinout__question--question {
          margin: auto;
          padding: 60px 0 0 0;
        }
        .checkinout__question--footer {
          position: relative;
          padding: 0;
          width: 100;
        }
      }
    }
  }
  @media (max-width: 580px) {
    .screen-desktop:not(.screen-tablet) & {
      .grid-container {
        .checkinout__question--footer {
          button {
            width: 100%;
          }
        }
      }
    }
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 16px;
`;

const StyledButton = styled(Button)`
  @media screen and (max-width: 580px) {
    .screen-desktop & {
      width: 100%;
    }
  }
`;

function ReplacementVehicle() {


  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);

  const questionnaire = useCheckoutQuestionnaire()

  const [replacementVehicleReturnFuel, setReplacementVehicleReturnFuel] = useState(incident && incident.replacementVehicleReturnFuel === 'yes' ? true : false || false);
  const [replacementVehicleReturnDamage, setReplacementVehicleReturnDamage] = useState(incident && incident.replacementVehicleReturnDamage === 'yes' ? true : false || false);
  const [replacementVehicleReturnItems, setReplacementVehicleReturnItems] = useState(incident && incident.replacementVehicleReturnItems === 'yes' ? true : false || false);
  const [replacementVehicleReturnKey, setReplacementVehicleReturnKey] = useState(incident && incident.replacementVehicleReturnKey === 'yes' ? true : false || false);

  const saveIncident = useSaveIncident();

  const nextButtonEnabled =
    // replacementVehicleReturnDamage &&
    // replacementVehicleReturnFuel &&
    // replacementVehicleReturnItems &&
    // replacementVehicleReturnKey;
    true;
  const location = useLocation()
  const navigate = useNavigate()
  const currentPath = location.pathname;
  const pagePath = getPrevNextPagePath(questionnaire, incident, currentPath, []);
  const { nextPage, prevPage } = pagePath;

  const checkCheckbox = (func) => () => {
    func(prevValue => {
      const newValue = !prevValue;
      return newValue;
    })
  }

  const noOwnRiskRoute = stripLastPathSegment(currentPath) + routes.questions;

  async function handleOnClick() {
    await saveIncident({
      ...incident,
      "replacementVehicleReturnFuel": replacementVehicleReturnFuel ? 'yes' : 'no',
      "replacementVehicleReturnDamage": replacementVehicleReturnDamage ? 'yes' : 'no',
      "replacementVehicleReturnItems": replacementVehicleReturnItems ? 'yes' : 'no',
      "replacementVehicleReturnKey": replacementVehicleReturnKey ? 'yes' : 'no',
    });
    if (incident.OwnRiskPaid === true || incident.deductibles === 0) {

      navigate(noOwnRiskRoute)

    } else {
      navigate(nextPage);
    }
  }

  if (!incident || !insurer) {
    return <Loader />;
  }


  const main = {
    className: 'main__checkinout main__checkinout--replacement-vehicle',
  };

  const iconLink = {
    type: 'arrow-left',
    theme: 'secondary',
    to: prevPage,
    replace: true,
  };

  const iconLinkForward = {
    type: 'arrow-right',
    theme: 'secondary',
    to: (incident.OwnRiskPaid || incident.deductibles === 0) ? noOwnRiskRoute : nextButtonEnabled && nextPage && nextPage,
    replace: true,
  };

  const button = {
    theme: nextButtonEnabled ? 'primary' : 'disabled',
    disabled: !nextButtonEnabled,
    onClick: handleOnClick,
    justify: 'space-between',
    shadow: true,
    className: 'image-upload__button button--icon',
  };


  return (
    <React.Fragment>
      <StyledMain {...main}>
        <NavBar>
          <Container className="icon-link-container">
            <IconLink {...iconLink} />
            {!!nextButtonEnabled && <IconLink {...iconLinkForward} />}
          </Container>
          <ProgressionIndicator steps={3} current={2} />
          <Container className="empty-div" />
        </NavBar>
        <GridContainer main={true}>
          <GridColumn className="checkinout__question--title" justify="flex-start">
            <Legend>Inspectie vervangend vervoer</Legend>
            <Heading level="1">Controle van het vervangend vervoer</Heading>
          </GridColumn>

          <GridColumn
            className="checkinout__question--question"
            justify="flex-start"
          // className="grid__column--main"
          >
            <StyledCheckbox
              name="Volgetankt afgeleverd"
              id="Volgetankt afgeleverd"
              value={replacementVehicleReturnFuel}
              onClick={checkCheckbox(setReplacementVehicleReturnFuel)}
            >Volgetankt afgeleverd
              <UseIcon name="checkmark" className="checkbox__icon" />
            </StyledCheckbox>
            <StyledCheckbox
              name="Zijn er schades"
              id="Zijn er schades"
              value={replacementVehicleReturnDamage}
              onClick={checkCheckbox(setReplacementVehicleReturnDamage)}
            >Geen nieuwe schades
              <UseIcon name="checkmark" className="checkbox__icon" />
            </StyledCheckbox>
            <StyledCheckbox
              name="Box voor persoonlijke items"
              id="Box voor persoonlijke items"
              value={replacementVehicleReturnItems}
              onClick={checkCheckbox(setReplacementVehicleReturnItems)}
            >Box voor persoonlijke items
              <UseIcon name="checkmark" className="checkbox__icon" />
            </StyledCheckbox>
            <StyledCheckbox
              name="Sleuteloverdracht"
              id="Sleuteloverdracht"
              value={replacementVehicleReturnKey}
              onClick={checkCheckbox(setReplacementVehicleReturnKey)}
            >Sleuteloverdracht
              <UseIcon name="checkmark" className="checkbox__icon" />
            </StyledCheckbox>
          </GridColumn>
          <GridColumn className="grid__column--footer checkinout__question--footer">
            <StyledButton {...button}>
              Volgende
              <UseIcon name="arrow-right" className="button__icon" />
            </StyledButton>
          </GridColumn>
        </GridContainer>
      </StyledMain>
    </React.Fragment>
  )
}

export default ReplacementVehicle;
