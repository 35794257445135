import React from 'react';
import RadioPage from '@domain/pages/RadioPage';

const carOwner = 'carOwner';
function CarOwner() {

  const fields = [{
    id: 1,
    value: 'Eigen auto',
    triggerKeys: [],
    label: 'Eigen auto'
  }, {
    id: 2,
    value: 'Lease auto',
    triggerKeys: [],
    label: 'Lease auto'
  },
  {
    id: 3,
    value: 'Zakelijke auto',
    triggerKeys: [],
    label: 'Zakelijke auto'
  }
  ]

  return <RadioPage fieldName={carOwner} styleLabel={'carOwner'} fields={fields} className={'more-opts'} />
}

export default CarOwner;