const routes = {
  start: '/overzicht',
  license: '/kenteken',
  name: '/naam',
  postal: '/postcode-gegevens',
  address: '/adres-gegevens',
  contact: '/contact-gegevens',
  gender: '/geslacht-gegevens',
  genderCompleteOptions: '/geslacht',
  carDriver: '/bestuurder',
  carDriverName: '/bestuurder-naam',
  carDriverContact: '/bestuurder-contact',
  carDriverPostal: '/bestuurder-postcode',
  carDriverBirthday: '/bestuurder-verjaardag',
  carDriverAddress: '/bestuurder-adres'
};

export default routes;
