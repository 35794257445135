// NPM imports.
import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

// Domain imports.
import {
  Button,
  CompanyLogo,
  Container,
  GridColumn,
  GridContainer,
  Heading,
  IconLink,
  Legend,
  Loader,
  Main,
  NavBar,
  ProgressionIndicator,
  Textarea,
  UseIcon,
  ValidationFeedback,
} from '@domain/components'
import { chooseLogoSize, generatePageContent, getPrevNextPagePath, goToNextPage, mapYupValidationSchemaErrors } from '@domain/helpers';
import { useSaveIncidentFactory, useScaling } from '@domain/hooks';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom'
// Routes for checkin flow.
import { useQuestionnaire } from '@domain/providers';
import { detectContentType } from '@domain/content';

import validationSchema from './validation-schema';

const StyledMain = styled(Main, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
.grid-container {
  .grid__column--main {
    .MuiFormControl-root {
    }
  }
}
@media (max-width: 1028px) {
  .screen-desktop:not(.screen-tablet) & {
    .grid-container {
      .checkinout__question--title {
        text-align: center;
        margin: auto;
      }
      .checkinout__question--question {
        margin: auto;
        padding: 60px 0 0 0;
      }
      .checkinout__question--footer {
        position: relative;
        padding: 0;
        width: 100;
      }
    }
  }
}
@media (max-width: 580px) {
  .screen-desktop:not(.screen-tablet) & {
    .grid-container {
      .checkinout__question--footer {
        button {
          width: 100%;
        }
      }
    }
  }
}
`;


const StyledValidationFeedback = styled(ValidationFeedback, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  .main__personal--date & {
    max-width: ${props => props.$scaling.scaleUpTo4K(338)}px;
    margin: 0;
  }
`;

const StyledTextArea = styled(Textarea)`
  margin-bottom: 16px
`;

function ExtraDamageDescription() {

  const navigate = useNavigate()
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)
  const { questionnaire } = useQuestionnaire();

  const attributeName = 'extraDamageDescription';
  const currentValue = incident && incident[attributeName] ? incident[attributeName] : ''

  const themeContext = useTheme();

  const [extraDamageDescription, setExtraDamageDescription] = useState(currentValue);
  const [buttonClicked, setButtonClicked] = useState(false)
  const [errors, setErrors] = useState({});

  const scaling = useScaling();

  useEffect(() => {
    async function testInputs() {
      try {
        await validationSchema.validate({ extraDamageDescription }, { abortEarly: false });
        if (errors[attributeName]) {
          setErrors({});
        }
      } catch (errorResult) {
        if (!errors[attributeName]) {
          setErrors(mapYupValidationSchemaErrors(errorResult.inner));
        }
      }
    }
    testInputs();
  }, [extraDamageDescription, errors]);

  const nextButtonEnabled = !errors[attributeName] ? true : false;

  const location = useLocation()
  const currentPath = location.pathname;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);

  const pagePath = getPrevNextPagePath(
    questionnaire,
    incident,
    currentPath,
    files,
  );

  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;

  const handleChange = (event) => {
    setExtraDamageDescription(event.target.value);
  };

  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath

  async function handleOnClick() {
    setButtonClicked(true)
    if (!errors[attributeName]) {
      const savedIncident = await saveIncident({
        ...incident,
        [attributeName]: extraDamageDescription,
      }, isPageBeforeSuccess);
      if (savedIncident) {
        goToNextPage(navigate, questionnaire, savedIncident, currentPath, files)
      }
    }
  }

  if (!incident || !insurer || !questionnaire) {
    return <Loader />;
  }

  const main = {
    className: 'main__personal',
    $scaling: scaling
  };

  const iconLink = {
    type: 'arrow-left',
    theme: 'secondary',
    to: pagePath.prevPage,
    replace: true,
  };

  const iconLinkForward = {
    type: 'arrow-right',
    theme: 'secondary',
    to: nextPage,
    replace: true,
  };

  const button = {
    theme: nextButtonEnabled ? 'primary' : 'disabled',
    onClick: handleOnClick,
    justify: 'space-between',
    shadow: true,
    className: 'image-upload__button button--icon',
  };

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, false, false, pageName)

  const legend = content["over-title-text"]
  const header = content["title"]
  const useCustomLogo = content["use-custom-logo"]

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  const chooseMiddleComponent = useCustomLogo
    ? <CompanyLogo {...middleLogo} />
    : <ProgressionIndicator steps={3} current={2} />
  return (
    <React.Fragment>
      <StyledMain {...main}>
        <NavBar>
          <Container className="icon-link-container">
            <IconLink {...iconLink} />
            {nextPage && <IconLink {...iconLinkForward} />}
          </Container>
          {chooseMiddleComponent}
          <Container className="empty-div" />
        </NavBar>
        <GridContainer main={true}>
          <GridColumn className="grid__column--main">
            <Legend>{legend}</Legend>
            <Heading level={'1'}>{header}</Heading>
            <StyledTextArea
              name="extraDamageDescription"
              id="extraDamageDescription"
              onChange={(e) => handleChange(e)}
              placeholder="Beschrijven"
              rows={2}
              defaultValue={extraDamageDescription}
            />
            <Container className="validation__container">
              {buttonClicked && errors[attributeName] && (
                <StyledValidationFeedback type="error" $scaling={scaling}>{errors[attributeName]}</StyledValidationFeedback>
              )}
            </Container>
          </GridColumn>
          <GridColumn className="grid__column--footer">
            <Button {...button}>
              Volgende
              <UseIcon name="arrow-right" className="button__icon" />
            </Button>
          </GridColumn>
        </GridContainer>
      </StyledMain>
    </React.Fragment>
  )
}

export default ExtraDamageDescription
